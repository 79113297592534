import * as React from 'react';
import {Accordion, Icon} from "semantic-ui-react";
import {useState} from 'react';

interface AccordionWrapperProps {
    accordionHeader: string
}

export const AccordionWrapper: React.FC<AccordionWrapperProps> = ({accordionHeader, children}) => {
    const [active, setActive] = useState<boolean>(true);

    const handleClick = () => {
        setActive((prevActive) => !prevActive);
    };

    return (
        <div style={{paddingBottom:'20px'}}>
            <Accordion styled={true} fluid={true}>
                <Accordion.Title active={active} icon={'plus'}
                                 onClick={handleClick}
                                 style={{backgroundColor:'#f3f4f5'}}>
                    <Icon name={active ? 'minus' : 'plus'}
                          style={{float: 'right', cursor: 'pointer'}}
                    />
                    {accordionHeader}
                </Accordion.Title>
                <Accordion.Content active={active}>
                    {children}
                </Accordion.Content>
            </Accordion>
        </div>
    );
}