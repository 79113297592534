import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {useState} from 'react';
import axios from 'axios';
import {DropDownOption} from './DropDownCell';
import {isDifferent} from '../../../services/data-grid-service';

interface ChildApiDropDownCellProps extends GridCellProps {
    optionApi: string,
    parentField: string
}

export const ChildApiDropDownCell = (props: ChildApiDropDownCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const parentDataValue = dataItem[props.parentField];
    const dataValue: string = field && parentDataValue && dataItem[field] ? dataItem[field] : '';
    const dataOption: DropDownOption = {text: dataValue, value: dataValue};
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value.value
            });
        }
    };
    
    const [options, setOptions] = useState<Array<DropDownOption>>([dataOption]);
    
    const onOpen = () => {
        if (!parentDataValue) {
            return;
        }
        setOptions([{text: 'Loading', value: ''}]);
        const params = new URLSearchParams();
        params.set(props.parentField, parentDataValue);
        axios.get(props.optionApi, {params})
            .then((resp) => {
                setOptions(resp.data.sort().map((item: string) => ({
                    text: item,
                    value: item
                })));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit ?
                isChanged ?
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        disabled={!parentDataValue}
                        title={originalValue}
                    />
                    :
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        disabled={!parentDataValue}
                    />
                :
                dataValue
            }
        </td>
    );
};

export const getChildApiDropDownCell = (optionApi: string, parentField: string) => {
    return (props: GridCellProps) => (
        <ChildApiDropDownCell {...props} optionApi={optionApi} parentField={parentField}/>
    );
};