import {Dimmer, Loader} from "semantic-ui-react";
import * as React from "react";

interface LoadingProps {
    active: boolean
}

interface LoadingState {

}

export class Loading extends React.Component<LoadingProps, LoadingState> {
    constructor(props: LoadingProps, state: LoadingState) {
        super(props, state);
        this.state = {};
    }

    public render(): React.ReactNode {
        return (
            <Dimmer active={this.props.active} inverted={true}>
                <Loader content='Loading' />
            </Dimmer>
        )
    }
}

