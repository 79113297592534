import {GridCellProps} from '@progress/kendo-react-grid';
import {isDifferent} from '../../../services/data-grid-service';
import {Input} from '@progress/kendo-react-inputs';

// Use for generic Input cells that don't require any special processing or formatting.
export const InputCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value = field && (dataItem[field] || dataItem[field] === '' || dataItem[field] === 0) ? dataItem[field] : '';
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);

    const onChange = (e: any) => {
        if (props.onChange) { // only change if all characters are numbers
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };
    
    const displayOriginalValue = (value: any) => (
        (!value && value !== 0) ? 'no value' : value
    );

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center', backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit
                ? isChanged
                    ? <Input value={value} onChange={onChange} title={displayOriginalValue(originalValue)}/>
                    : <Input value={value} onChange={onChange}/>
                : value
            }
        </td>
    );
};