import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';
import React from 'react';

interface AddPanelProps {
    addRecord: () => void
}

const AddPanel: React.FC<AddPanelProps> = ({addRecord}) => {
    return (
        <>
            <AppBarSection>
                <Button onClick={addRecord}>Add</Button>
            </AppBarSection>
            <AppBarSpacer style={{width: 4}}/>
        </>
    );
};
export default AddPanel;