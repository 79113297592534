import {GridCellProps} from '@progress/kendo-react-grid';
import {Checkbox, CheckboxProps} from 'semantic-ui-react';

interface RemoveCellProps extends GridCellProps {
    remove: (id: number, willRemove: boolean) => void
}

export const RemoveCell = (props: RemoveCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem} = props;
    const {id, removed} = dataItem;
    const tdClassName = 'k-table-td k-grid-edit-cell';

    const onChange = (e: any, data: CheckboxProps) => {
        const check: boolean = data.checked as boolean;
        props.remove(id, check);
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center'}}
        >
            <Checkbox checked={removed} onChange={onChange}/>
        </td>
    );
};

export const getRemoveCell = (remove: (id: number, willRemove: boolean) => void) => {
    return (props: GridCellProps) => (
        <RemoveCell {...props} remove={remove}/>
    );
};