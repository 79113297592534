import * as React from 'react';
import {Button, Container, Icon} from "semantic-ui-react";
import '../../../CSS/react-table-custom-orig.css';
import GridUtil from "../../common/GridUtil";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import ReactTable from "react-table";
import * as actionTypes from "../../../store/actions/actionTypes";
import AdminService from "../../../services/admin-services";
import GeneralService from "../../../services/general-services";

interface EntityGridState {
    data: any;
    columns: any;
    metaData: any;
    filtered: any;
    openModal: boolean,
    status: number,
    loading: boolean
}

interface EntityGridProps {
    currentUser: string
    currentRow: any
    updateCurrentRow: (currentRow: any) => void;
    updateAddOrModifyEntity: (addOrModifyEntity: string) => void;
    entityModalActive: boolean;
    updateEntityModalActive: (entityModalActive: boolean) => void;
    updateAllEntities: (allEntities: any) => void;
    activeTab: string;
    allEntities: any;
}

const generalService = new GeneralService();
const adminService = new AdminService();
class EntityGrid extends React.Component<EntityGridProps, EntityGridState> {
    constructor(props: EntityGridProps, state: EntityGridState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [
                {
                    Header:'Action',
                    accessor:'action',
                    width: 80
                },
                {
                    Header:'Id',
                    accessor:'id',
                    width: 80
                },{
                    Header:'Name',
                    accessor:'nm'
                },{
                    Header:'Long Name',
                    accessor:'longNm'
                },
                {
                    Header:'Entity Type Long Name',
                    accessor:'entityTypeLongName'
                }
            ],
            metaData: [],
            filtered: [],
            openModal: false,
            status: 200,
            loading: true
        };
    }

    componentDidMount(): void {
        /**Get all data when the Entity Grid component mounts*/
        this.fetchData();
    }

    getActions = (key: number) =>{
        /**JSX for the actions that will be added to each record in the grid. For now, we have removed the delete action
         * and are only populating the Edit action.*/
        return(
            <div>
                <Icon id={key} color={'blue'} name={'edit'} onClick={this.takeAction} disabled={false} style={{cursor: 'pointer'}}/>
                {/*<Icon id={key} color={'red'} name={'delete'} onClick={this.takeAction} disabled={false} style={{cursor: 'pointer'}}/>*/}
            </div>
        )
    }

    takeAction = (e:any) => {
        /**Defines the actions that will take place when one of the action buttons is clicked for a record in the grid.
         * The conditional statement is still included because we previously had a delete action is well. Keeping for now
         * so that when/if we decide to add the delete action, we can add the conditional for it easily.*/
        let currentRow:any = this.state.data.find((obj:any) => obj.id === parseInt(e.target.id))
        let actionType: any = e.target.className;
        //Update current row in redux store so that we are working with correct data in the Entity Modal
        this.props.updateCurrentRow(currentRow);
        //We clicked edit row
        if(actionType.includes('edit')) {
            //Open modal to modify Entity or relationship info
            this.props.updateAddOrModifyEntity('Modify');
            this.props.updateEntityModalActive(true);
            this.fetchData();
        }
    }

    fetchData = () => {
        adminService.getEntityGridData().then((response: any) => {
            let finalData: any = response.data.map((c: any) => ({...c, action: this.getActions(c.id)}));
            this.setState({data: finalData, status: 200, loading: false});
            this.props.updateAllEntities(finalData);
        });
    };

    public render() {
        /**JSX for displaying the grid using ReactTable module*/
        const {data} = this.state;
        return (
            <div>
                <Container fluid={true} style={{paddingTop:'10px', paddingBottom: '10px'}}>
                    <ReactTable
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={100}
                        filterable={true}
                        filtered={this.state.filtered}
                        style={{height: '500px', width: '100%'}}
                        className='-highlight'
                        defaultFilterMethod={GridUtil.filterMethod}
                        onFilteredChange={filtered => this.setState({filtered})}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: 'black',
                                    color: 'white',
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth, textAlign: "center"}})}
                    />
                    <Loading active={this.state.loading}/>
                </Container>
                <Button size={'mini'} icon={true} labelPosition='left' onClick={generalService.downloadExcel} name={'responsibleentity'} color={'black'}>
                    <Icon name='download'/>
                    Download
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        currentRow: state.defaultReducer.currentRow,
        addOrModifyEntity: state.entityManagementReducer.addOrModifyEntity,
        entityModalActive: Boolean(state.entityManagementReducer.entityModalActive),
        activeTab: state.defaultReducer.activeTab,
        allEntities: state.entityManagementReducer.allEntities,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentRow: (currentRow: boolean) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}
        ),
        updateAddOrModifyEntity: (addOrModifyEntity: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYENTITY, payload: addOrModifyEntity}
        ),
        updateEntityModalActive: (entityModalActive: boolean) => dispatch(
            {type: actionTypes.SET_ENTITYMODALACTIVE, payload: entityModalActive}
        ),
        updateAllEntities: (allEntities: any) => dispatch(
            {type: actionTypes.SET_ALLENTITIES, payload: allEntities}
        ),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(EntityGrid)
