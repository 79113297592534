import * as React from 'react';
import {Button, Dropdown, Form, Header, Icon, Label, Modal, ModalProps} from "semantic-ui-react";
import axios from "axios";
import {CommentData} from "./Comment";
import {connect} from "react-redux";

interface CommentReplyModalProps {
    comment: CommentData,
    handleCommentReply(newComment: CommentData): void,
}

type OwnProps = CommentReplyModalProps & ModalProps;

interface PropsFromState {
    userEntities: any[]
}

type AllProps = OwnProps & PropsFromState;

interface CommentReplyModalState {
    open: boolean,
    entities: Array<object>,
    selectedEntity: string,
    postingId: number,
    parentId: number,
    privateCheck: boolean,
    commentText: string,
    admin: boolean,
    entityLock: string,
    loading: boolean
}

class CommentReplyModal extends React.Component<AllProps, CommentReplyModalState> {
    _isMounted: boolean = false;

    constructor(props: AllProps, state: CommentReplyModalState) {
        super(props, state);
        console.log("comment reply constructor");
        this.state = {
            open: false,
            entities: [],
            selectedEntity: '',
            postingId: this.props.comment.postingsId,
            parentId: this.props.comment.id,
            privateCheck: this.props.comment.isPrivate,
            commentText: '',
            admin: false,
            entityLock: this.props.comment.isPrivate ? this.props.comment.entityLock : '',
            loading: true
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getUserEntity();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleFieldChange = (e:any, value:any) => {
        this.setState({ [e.target.name.toString()] : value.value } as any);
    };

    handleReply = () => {
        const data = {
            'postingId': this.state.postingId.toString(),
            'parentId': this.state.parentId.toString(),
            'respEntNameType': this.state.selectedEntity,
            'entityLock': this.props.comment.entityLock,
            'comment': this.state.commentText,
            'isPrivate': this.state.privateCheck.toString(),
        };
        axios.post('/api/auth/comments/comment',data,{
            withCredentials: true,
            responseType: 'json'
        }).then((resp) => {
            this.props.handleCommentReply(resp.data);
        }).catch((resp) => {
            console.log('CommentReplyModal - handleReply failed:',resp.data);
        });
        this.toggleModal();
    };

    toggleModal = () => {
        this.setState((prevState) => {
            return ({open: !prevState.open})
        });
    };

    handleSelectChange = (e: any, data: any) => {
        this.setState({selectedEntity: data.value});
    };

    selectUserEntity = () => {
        if(this.state.entities.length === 0){
            return ( <Label>{this.state.selectedEntity}</Label> );
        }


        return (
            <Dropdown placeholder="Select Entity"
                      selection={true}
                      clearable={true}
                      options={this.state.entities}
                      onChange={this.handleSelectChange}/>
        )
    };

    getUserEntity = () => {
        this.setState({loading: true});
        let dropdownItems: Array<object> = [];
        let entity = '';
        let spp: boolean = false;
        this.props.userEntities.forEach((item: any) => {
            entity = item.respEntTypeNm;
            if (entity === 'SPP(ISO)') {spp = true;}
            dropdownItems.push({key: entity, text: entity, value: entity});
        });

            if(this._isMounted) {
                if (spp) {
                    this.setState({selectedEntity: 'SPP(ISO)', admin: true})
                } else if (dropdownItems.length === 1) {
                    this.setState({selectedEntity: entity});
                } else if (this.props.comment.isPrivate) {
                    this.setState({selectedEntity: this.props.comment.entityLock})
                } else {
                    this.setState({entities: [...dropdownItems]});
                }
            }
    };

    render() {
        console.log("comment reply")
        let privacyHeader = 'Public';
        let privacyContent = 'Everyone can view this comment';

        if(this.state.privateCheck) {
            privacyHeader = 'Private';
            privacyContent = 'Only your entity and SPP can view this comment';
        }

        return(
            <>
                <Button floated={'left'} size={'mini'} color={'black'} onClick={this.toggleModal}>
                    <Icon name='comment'/>
                    Reply
                </Button>
                <Modal open={this.state.open} closeOnDimmerClick={true} closeIcon={true} onClose={this.toggleModal}>
                    <Modal.Header>Reply</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Header as={'h2'}>
                                <Header.Content>
                                    {privacyHeader}
                                    <Header.Subheader>{privacyContent}</Header.Subheader>
                                </Header.Content>
                            </Header>
                            {this.selectUserEntity()}
                            <Form.Input name={'commentText'} label={'Comment'} labelPosition={'left'} onChange={this.handleFieldChange} />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleReply} content={'Submit'}/>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        userEntities: state.authReducer.userEntities
    }
};

export default connect<PropsFromState, {}, OwnProps>(mapStateToProps, {})(CommentReplyModal);