import { createStore, compose } from 'redux';
import rootReducer from "./reducers";

export default function configureStore(){

    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose || compose; //add support for redux dev tools
    // uncomment the following line and comment out previous line to use tracing in REDUX dev tools causing performance issues, so only use when needed
    //const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25}) as typeof compose || compose; //add support for redux dev tools

    return createStore(rootReducer,
        composeEnhancers()
    );
}