import * as React from 'react';
import {Container, Header, Segment} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import NotificationsGrid from "./NotificationsGrid";
import axios from "axios";
import {toast} from "react-toastify";
import * as actionTypes from "../../store/actions/actionTypes";

interface NotificationTrackingState {
}

interface NotificationTrackingProps {
    updateNotificationCount: (notificationCount: number) => void,
}

class NotificationTracking extends React.Component<RouteComponentProps & NotificationTrackingProps,NotificationTrackingState> {
    constructor(props: RouteComponentProps & NotificationTrackingProps, state: NotificationTrackingState) {
        super(props, state);
        this.state = {
        }
    }

    componentDidMount(): void {
        axios.get(`/api/auth/notificationTracking/markAsReadByUser`)
            .then(() => {
                this.props.updateNotificationCount(0);
            }).catch(resp => {
            toast.error(`Error marking notifications as read - ${resp.message}`);
        });
    }

    public render() {
        return (
            <Container fluid={true} style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
                <Header size={'large'}>Notifications</Header>
                <Segment>
                    <NotificationsGrid/>
                </Segment>
            </Container>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        notificationCount: state.defaultReducer.notificationCount
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateNotificationCount: (notificationCount: number) => dispatch (
            {type: actionTypes.UPDATE_NOTIFICATION_COUNT, payload: notificationCount}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTracking)