import * as React from 'react';
import {
    Container,
    DropdownItemProps, Header,
} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import AttachmentChangesetsGrid from "./AttachmentChangesetsGrid";
import {AccordionSegment} from "../../common/AccordionSegment";
import AttachmentGrid from "./AttachmentGrid";

interface AttachmentsHistoryState {
    subYears: DropdownItemProps[]
    tables: DropdownItemProps[]
    table: string
    year: number
    tablesLoading: boolean
    yearsLoading: boolean
    exporting: boolean
    historical: any[]
    historicalMetaData: any[]
    historicalLoading: boolean
    hideGrid: boolean
    currentChangeset: Changeset
    newChangesetRow: any
    uploadFile:any
}

interface AttachmentsHistoryProps {
    currentChangeset: Changeset
}

export class AttachmentsHistory extends React.Component<RouteComponentProps<{}>& AttachmentsHistoryProps, AttachmentsHistoryState> {
    constructor(props: RouteComponentProps<{}>& AttachmentsHistoryProps, state: AttachmentsHistoryState) {
        super(props, state);
        this.state = {
            subYears: [],
            tables: [],
            table: '',
            year: 0,
            tablesLoading: true,
            yearsLoading: true,
            exporting: false,
            historical: [],
            historicalMetaData: [],
            historicalLoading: false,
            hideGrid: true,
            currentChangeset: {
                name: '',
                number: 0,
                comments: '',
                status: '',
                changeSetAuthorName:''
            },
            newChangesetRow: [],
            uploadFile: {}
        };
    }

    componentDidMount(): void {
        this.getAttachments();

    }

    getAttachments = () =>{
        console.log('Getting attachments')
    }

    changesetState = (changeset: any) =>{
        this.setState({currentChangeset: changeset})
    }


    render() {
        return (
            <Container fluid={true} style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'20px'}}>
                <Header size={'large'}>Attachment History</Header>
                <AccordionSegment segmentHeader={'Changesets With Attachments'} size={'tiny'}>
                    <AttachmentChangesetsGrid updateChangesetState={this.changesetState}/>
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Attached Documents'} contentHidden={this.state.currentChangeset.number === 0} size={'tiny'}>
                    <AttachmentGrid/>
                </AccordionSegment>
            </Container>
        )
    }

}