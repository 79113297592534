import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Grid, GridColumn, GridItemChangeEvent} from '@progress/kendo-react-grid';
import axios from 'axios';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import {ApiValidations, ColumnMeta, DataUpdate, UiValidations} from '../../interfaces/grid-interfaces';
import {
    SubmissionsGridProps,
    YearlyEnergyPeakData,
    YearlyEnergyPeakEditData
} from '../../interfaces/submissions-interface';
import {
    deepCopyGridData,
    deepCopyGridDataWithOriginal, numberBoundValidationOnEdit, numberBoundValidationOnUpload,
    processColumnMeta, replaceSpaceWithUnderscore, toastPreSaveValidationErrors,
    updateEditedRows
} from '../../services/data-grid-service';
import {getDecimalCell} from '../common/grid/DecimalCell';
import {InputCell} from '../common/grid/InputCell';
import TooltipContainer from '../common/grid/TooltipContainer';
import ExportPanel from '../common/action-panel/ExportPanel';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import ImportPanel from '../common/action-panel/ImportPanel';
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import RefreshPanel from '../common/action-panel/RefreshPanel';
import {toast} from 'react-toastify';
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import format from "date-fns/format";

const YearlyEnergyPeakGrid: React.FC<SubmissionsGridProps> = ({title, submitEntId, dataApi, season, isStaff, readRole, modifyRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<YearlyEnergyPeakData>>([]);
    const [originalData, setOriginalData] = useState<Array<YearlyEnergyPeakData>>([]);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState(false);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);

    const prevSubmitEntId = useRef<number>();

    useEffect(() => {
        axios.get(`${dataApi}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    useEffect(() => {
        setValidationErrors([]);
    }, [submitEntId]);

    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        if (submitEntId) {
            setLoadingData(true);
            const params = new URLSearchParams();
            params.set('submitEntId', submitEntId.toString());
            Promise.all([axios.get(dataApi, {params}), axios.get(`/api/auth/ra/season-window/${season}`)])
                .then((resp) => {
                    setInEdit(false);
                    setData(deepCopyGridDataWithOriginal(resp[0].data));
                    setOriginalData(deepCopyGridData(resp[0].data));
                    setDataErrors([]);
                    if (!isStaff) {
                        if (resp[1].data === false) {
                            toast.info(`Resource Adequacy window is closed for the ${season} season`, {toastId: `toast-info-${season}-window`});
                        }
                        setSeasonOpen(resp[1].data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoadingData(false);
                });
        } else {
            setData([]);
            setOriginalData([]);
        }
    }, [dataApi, isStaff, readRole, season, submitEntId]);

    useEffect(() => {
        if (prevSubmitEntId.current !== submitEntId) {
            setInEdit(false);
            setEditedRows([]);
            prevSubmitEntId.current = submitEntId;
        }
        getData();
    }, [getData, submitEntId]);

    const refreshData = () => {
        getData();
    };

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        const localData = data.map((item) => {
            if (item.id === event.dataItem.id) {
                const dataItem = {...item};
                dataItem[field] = event.value;
                if ((event.value || event.value === 0) && (['s1Val', 's2Val', 's3Val', 's4Val', 's5Val', 's6Val', 's7Val', 's8Val', 's9Val', 's10Val', 's11Val', 's12Val'].includes(field))) {
                    numberBoundValidationOnEdit(event.value, field, setDataErrors, item, columnMeta, 999999.999, 0);
                }
                return dataItem;
            } else {
                return item;
            }
        });

        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, event.dataItem.id, field, event.value));
        }
    };

    const handleUpload = (uploadedData: Array<YearlyEnergyPeakEditData>) => {
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.vRowNm === item.vRowNm);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const dataUploaded: Array<YearlyEnergyPeakEditData> = [];
        localData.forEach((u) => {
            dataUploaded.push(u as YearlyEnergyPeakEditData);
        });

        setData(dataUploaded);
    };

    const processUploadEdits = (upload: YearlyEnergyPeakEditData, item: YearlyEnergyPeakEditData) => {
        // need to pass every field change to updateEditedRows
        if (upload.s1Val !== item.s1Val) {
            let field = 's1Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s2Val !== item.s2Val) {
            let field = 's2Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s3Val !== item.s3Val) {
            let field = 's3Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s4Val !== item.s4Val) {
            let field = 's4Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s5Val !== item.s5Val) {
            let field = 's5Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s6Val !== item.s6Val) {
            let field = 's6Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s7Val !== item.s7Val) {
            let field = 's7Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s8Val !== item.s8Val) {
            let field = 's8Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s9Val !== item.s9Val) {
            let field = 's9Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s10Val !== item.s10Val) {
            let field = 's10Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s11Val !== item.s11Val) {
            let field = 's11Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s12Val !== item.s12Val) {
            let field = 's12Val';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.comments?.toLowerCase() !== item.comments?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'comments', upload.comments));
        }

        return {
            ...item,
            s1Val: upload.s1Val,
            s2Val: upload.s2Val,
            s3Val: upload.s3Val,
            s4Val: upload.s4Val,
            s5Val: upload.s5Val,
            s6Val: upload.s6Val,
            s7Val: upload.s7Val,
            s8Val: upload.s8Val,
            s9Val: upload.s9Val,
            s10Val: upload.s10Val,
            s11Val: upload.s11Val,
            s12Val: upload.s12Val,
            comments: upload.comments
        };
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    };

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
    };

    const save = () => {
        if (editedRows.length === 0) {
            cancelEdits();
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }

        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        axios.post(dataApi, updatedData)
            .then(() => {
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(data);
        }
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate/${submitEntId}`)
            .then(response => {
                setValidationErrors(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const DecimalCell = useMemo(() => getDecimalCell(3, true, 999999.999, 0), []);

    return (
        <div>
            <ActionPanel title={title}>
                {modifyRole && seasonOpen && <SavePanel inEdit={inEdit} save={save} disabled={!submitEntId} toggleEdit={toggleEdit}/>}
                {inEdit && <ImportPanel api={`${dataApi}/upload/${submitEntId}`} setUploadRecords={handleUpload}/>}
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loadingData} getData={refreshData}/>}
                {!inEdit && <ExportPanel exportData={excelExport} disabled={inEdit}/>}
                {title !== 'Yearly Energy Submissions' && !inEdit && modifyRole && seasonOpen && <ValidatePanel disabled={(inEdit)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <TooltipContainer>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                    {submitEntId && 
                        <Grid
                            data={data}
                            dataItemKey='id'
                            resizable={true}
                            onItemChange={itemChange}
                            editField='inEdit'
                        >
                        {
                            columnMeta.length
                                ? columnMeta.map((col: ColumnMeta, index) => {
                                    switch (col.nm) {
                                        case 'vRowNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='244px' editable={false}/>
                                        case 'seasonNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='79px' editable={false}/>
                                        case 's1Val':
                                        case 's2Val':
                                        case 's3Val':
                                        case 's4Val':
                                        case 's5Val':
                                        case 's6Val':
                                        case 's7Val':
                                        case 's8Val':
                                        case 's9Val':
                                        case 's10Val':
                                        case 's11Val':
                                        case 's12Val':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' cell={DecimalCell}/>
                                        case 'comments':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={InputCell}/>
                                        case 'lastUserModBy':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' editable={false}/>
                                        case 'lastUserModDt':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        default:
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                    }
                                })
                                : <GridColumn/>
                        }
                    </Grid>}
                </ExcelExport>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={title}/>}
        </div>
    );
};

export default YearlyEnergyPeakGrid;