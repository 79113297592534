import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList, DropDownListFilterChangeEvent} from '@progress/kendo-react-dropdowns';
import {isDifferent} from '../../../services/data-grid-service';
import {useState} from 'react';
import {CompositeFilterDescriptor, filterBy, FilterDescriptor} from '@progress/kendo-data-query';

export interface DropDownOption {
    text: string,
    value: string | number
}

interface DropDownCellProps extends GridCellProps {
    options: Array<DropDownOption>,
    placeholder: string
}

export const DropDownCell = (props: DropDownCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value = field && dataItem[field] ? dataItem[field] : '';
    const dataValue = field && dataItem[field] ? dataItem[field] : '';
    const placeholder = props.placeholder ? `Select ${props.placeholder}...` : '';
    const defaultItem = {text: placeholder, value: ''};
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    const [data, setData] = useState<Array<DropDownOption>>(props.options.slice());
    
    const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        const dataOptions = props.options.slice();
        return filterBy(dataOptions, filter);
    };
    
    const filterChange = (event: DropDownListFilterChangeEvent) => {
        setData(filterData(event.filter));
    };
    
    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value.value === '' ? null : event.target.value.value
            });
        }
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit
                ? isChanged
                    ? <DropDownList
                        onChange={onChange}
                        data={data}
                        value={props.options.find((o) => o.value === dataValue)}
                        textField='text'
                        defaultItem={defaultItem}
                        filterable={true}
                        onFilterChange={filterChange}
                        title={originalValue === null ? 'null' : originalValue}
                    />
                    : <DropDownList
                        onChange={onChange}
                        data={data}
                        value={props.options.find((o) => o.value === dataValue)}
                        textField='text'
                        defaultItem={defaultItem}
                        filterable={true}
                        onFilterChange={filterChange}
                    />
                : value.toString()
            }
        </td>
    );
};

export const getDropDownCell = (options: Array<DropDownOption>, placeholder: string) => {
    return (props: GridCellProps) => (
        <DropDownCell {...props} options={options} placeholder={placeholder}/>
    );
};

export const getYesNoDropDownCell = () => {
    const options: Array<DropDownOption> = [
        {text: 'YES', value: 'YES'},
        {text: 'NO', value: 'NO'}
    ];
    
    return (props: GridCellProps) => (
        <DropDownCell {...props} options={options} placeholder={' '}/>
    );
};

export const getInternalExternalDropDownCell = (placeholder: string) => {
    const options: Array<DropDownOption> = [
        {text: 'Internal', value: 'Internal'},
        {text: 'External', value: 'External'}
    ];

    return (props: GridCellProps) => (
        <DropDownCell {...props} options={options} placeholder={placeholder}/>
    );
};