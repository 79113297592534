import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {Container, Header, Menu} from 'semantic-ui-react';
import logo from '../SPPLogo.png';
import {connect} from "react-redux";
import history from '../../src/history';
import GeneralService from "../services/general-services";
import MenuItemComponent from "./MenuItemComponent";

interface NavMenuProps {
    currentUser:string,
    location: any,
}

interface NavMenuState {
    loggedOut: boolean,
}

const generalService = new GeneralService();
class NavMenu extends React.Component<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps, state: NavMenuState) {
        super(props,state);
        this.state = {
            loggedOut: false,
        }
    }

    getMenuItems = () => {
            if (!generalService.loggedIn(true)) {
                return (
                    <Menu inverted={true} >
                        <img src={logo} alt={"logo"} height={'60px'} />
                        <Header as="h3" color={"grey"}>Engineering Data Submission Tool</Header>
                    </Menu>
                )
            }

        return (
          <Menu className={'navMenu'} inverted={true} fixed={'top'}>
              <img src={logo} alt={"logo"} height={'60px'} />
              <Header as="h3" color={"grey"}>Engineering Data Submission Tool</Header>
              <MenuItemComponent />
              <Menu.Menu position={'right'}>
                  <Menu.Item as={NavLink} to={'/ChangePassword'} exact={true}>
                      {this.props.currentUser ? `Hello ${this.props.currentUser}!` : ''}
                  </Menu.Item>
                  <Menu.Item onClick={this.handleLogOut}>Log off</Menu.Item>
              </Menu.Menu>
          </Menu>
      )
    };

    handleLogOut = () => {
        generalService.logout()
            .then(() => {
                history.push({pathname: '/login', state: {from: this.props.location}});
            }).catch(() => {
            console.log("logout failed");
        })
    };

    public render() {
        return (
            <Container fluid={true}>
                {this.getMenuItems()}
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
    }
};

export default connect(mapStateToProps, {})(NavMenu);