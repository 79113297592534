import * as React from 'react';
import {Container} from "semantic-ui-react";
import ReactTable from 'react-table';
import axios from 'axios';
import '../../../CSS/react-table-custom-orig.css';
import GridUtil from "../../common/GridUtil";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import {toast} from "react-toastify";

interface AttachmentGridState {
    data: any;
    columns: any;
    edit: boolean;
    updatedData: any;
    currentRow: number;
    redirect: boolean;
    metaData: any;
    filtered: any;
    openModal: boolean,
    selectedRowColor: string,
    status: number,
    loading: boolean,
    showAddRowsButton: boolean,
    rowsToAddToChangeset: any,
    currentChangesetNumber: number
    editing: any[]
    uploadFiles: any[]
}

interface AttachmentGridProps {
    currentChangeset: Changeset
    currentUser: string
}

let fileDownload = require('js-file-download');
let some = require('lodash/some');
class AttachmentGrid extends React.Component<AttachmentGridProps, AttachmentGridState> {
    constructor(props: AttachmentGridProps, state: AttachmentGridState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [
                {
                    Header:'Changeset Id',
                    accessor:'chgSId'
                },{
                    Header:'File Name',
                    accessor:'fileNm',
                    Cell: this.downloadAttachment
                },{
                    Header:'Created By',
                    accessor:'creBy'
                },{
                    Header:'Created Date',
                    accessor:'creDt'
                },{
                    Header:'Modified By',
                    accessor:'modBy'
                },{
                    Header:'Modified Date',
                    accessor:'modDt'
                },{
                    Header:'Comments',
                    accessor:'comments'
                }
            ],
            edit: false,
            updatedData:[],
            currentRow: -1,
            redirect:false,
            metaData: [],
            filtered: [],
            openModal: false,
            selectedRowColor: '',
            status: 200,
            loading: true,
            showAddRowsButton: false,
            rowsToAddToChangeset: [],
            currentChangesetNumber: 0,
            editing:[],
            uploadFiles:[]
        };
    }

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[] });
    };

    getFiltered = () => {
        if (this.state.filtered) {
            return 'visible'
        }
        return 'hidden'
    };

    downloadAttachment = (cellInfo:any) => {
        return (
            <div
                style={{
                    color: 'blue',
                    cursor:'pointer'}}
                onClick={() => {
                    this.downloadFile(cellInfo.original.fileNm);
                }}
                suppressContentEditableWarning={true}
            >{cellInfo.value}</div>
        )
    };

    addAttachment = () => {
        let data:any[] = [];
        if (this.state.data) {
            data = [...this.state.data]
        }
        if (!some(data,{fileNm:null})) {
            data.push({
                chgSId: this.props.currentChangeset.number,
                creBy: this.props.currentUser,
                modBy: this.props.currentUser,
                fileNm: null,
                id: data.length
            });
            let editing = [...this.state.editing];
            editing.push({index:data.length - 1,value:true});
            this.setState({data,editing});
        }
    };

    downloadFile = (fileName: string) => {
        let data = new FormData();
        data.set('changesetId', this.props.currentChangeset.number.toString())
        data.set('fileName', fileName);
        axios.post('/api/auth/downloadAttachment', data, {responseType: 'arraybuffer'})
            .then(resp => {
                fileDownload(resp.data, fileName);
            })
            .catch(error => {
                toast.error(`Error downloading file ${fileName} - ${error.message}`)
            });
    };

    fetchData = () => {
        let params = new URLSearchParams();
        params.set('changesetId', String(this.props.currentChangeset.number))
        axios.get(`/api/auth/attachment`, {params})
            .then(resp => {
            if (resp.data) {
                this.setState({data:resp.data, status: resp.status, loading: false});
            } else {
                this.setState({loading:false,data:[]})
            }
        }).catch(resp => {
            this.setState({status: resp.status, loading: false})
        });
        this.setState({currentChangesetNumber:this.props.currentChangeset.number,loading:true,editing:[]})
    };

    componentDidUpdate() {
        if (this.props.currentChangeset.number !== this.state.currentChangesetNumber) {
            this.fetchData();
        }
    }

    public render() {
        const {data} = this.state;
        return (
            <Container fluid={true} style={{paddingLeft:'50px', paddingRight:'50px'}}>
                <ReactTable
                    data={data}
                    columns={this.state.columns}
                    defaultPageSize={10}
                    filterable={true}
                    filtered={this.state.filtered}
                    style={{height: '500px', width: '100%'}}
                    className='-highlight'
                    defaultFilterMethod={GridUtil.filterMethod}
                    onFilteredChange={filtered => this.setState({filtered})}
                    getTheadProps={() => {
                        return {
                            style: {
                                background: 'black',
                                color: 'white',
                                borderRadius: '5px'
                            }
                        };
                    }}
                    getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth, textAlign: "center"}})}
                />
                <Loading active={this.state.loading}/>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentChangeset: state.changeSetReducer.currentChangeset,
        currentUser: state.authReducer.currentUser
    }
};

export default connect(mapStateToProps)(AttachmentGrid)
