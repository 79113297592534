import {useEffect, useState} from 'react';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import {Button} from '@progress/kendo-react-buttons';
import {Loader} from '@progress/kendo-react-indicators';
import {Container, Header} from 'semantic-ui-react';
import axios from 'axios';
import HistoricalGrid from './HistoricalGrid';
import {historyTables} from './history-tables';
import {ColumnMeta} from '../../../interfaces/grid-interfaces';

const HistoricalPage = () => {

    const [subYearOptions, setSubYearOptions] = useState<Array<string>>([]);
    const [userCategories, setUserCategories] = useState<Array<string>>([]);
    const [subYear, setSubYear] = useState<string>('');
    const [tabNmOptions, setTabNmOptions] = useState<Array<string>>([]);
    const [tabNm, setTabNm] = useState<string>('');
    const [seasonOptions, setSeasonOptions] = useState<Array<string>>([]);
    const [season, setSeason] = useState<string>('');
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<any>>([]);
    const [gridVisible, setGridVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        axios.get('/api/auth/historical/historicalSubYears')
            .then((resp) => {
                const subyears = [...resp.data];
                setSubYearOptions(subyears);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    
    useEffect(() => {
        axios.get('/api/auth/user/categories')
            .then((resp) => {
                setUserCategories(resp.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    
    const handleSubYearChange = (event: DropDownListChangeEvent) => {
        const year = event.target.value
        setSubYear(year);
        const tabNmList = Array.from(new Set(historyTables
            .filter((item) => item.firstSubYear <= year && (!item.lastSubYear || (!!item.lastSubYear && item.lastSubYear >= year)) && userCategories.includes(item.category))
            .map((item) => item.visNm)));
        setTabNmOptions(tabNmList);
        if (!!tabNm) {
            if (tabNmList.includes(tabNm)) {
                resetSeasonOptions(year, tabNm);
            } else {
                setTabNm('');
                setSeason('');
            }
        } else {
            setSeason('');
        }
    };
    
    const handleTabNmChange = (event: DropDownListChangeEvent) => {
        const tab = event.target.value;
        setTabNm(tab);
        resetSeasonOptions(subYear, tab);
    };
    
    const handleSeasonChange = (event: DropDownListChangeEvent) => {
        setSeason(event.target.value);
    }
    
    const resetSeasonOptions = (subYear: string, tabNm: string) => {
        const year = parseInt(subYear);
        const seasonList = Array.from(new Set(historyTables
            .filter((item) => item.firstSubYear <= year && (item.lastSubYear === null || item.lastSubYear >= year) && item.visNm === tabNm && userCategories.includes(item.category))
            .map((item) => item.season)));
        let seasonNm = season;
        if (seasonList.length === 1) {
            seasonNm = seasonList[0];
        } else if (!seasonList.includes(seasonNm)) {
            seasonNm = '';
        } 
        setSeasonOptions(seasonList);
        setSeason(seasonNm);
    };
    
    const lowercaseKeys = (obj: any) => {
        return Object.keys(obj).reduce((accumulator, key) => {
            accumulator[key.toLowerCase()] = obj[key];
            return accumulator;
        }, {});
    };
    
    const getHistory = () => {
        const year = parseInt(subYear);
        const historyTabs = historyTables
            .filter((item) => item.firstSubYear <= year && (!item.lastSubYear || (!!item.lastSubYear && item.lastSubYear >= year)) && item.visNm === tabNm && item.season === season && userCategories.includes(item.category));
        if (!historyTabs.length) {
            return;
        } else {
            setGridVisible(false);
        }
        const table = historyTabs[0];
        setTitle(`${subYear} ${season === 'All' ? '' : `${season} `}${table.visNm}`)
        const params = new URLSearchParams();
        params.set('subYear', subYear);
        setLoading(true);
        if (table.parameters.includes('tabName')) {
            params.set('tabName', table.tabNm);
            axios.get(table.api, {params})
                .then((resp) => {
                    setColumnMeta(resp.data.columnMetaDataList
                        .map((item: any) => ({
                            ...item,
                            nm: item.nm.toLowerCase()
                        }))
                        .sort((a: ColumnMeta, b: ColumnMeta) => a.sortId - b.sortId));
                    setData(resp.data.data.map((item: any) => (lowercaseKeys(item))));
                    setGridVisible(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            Promise.all([axios.get(`${table.api}/columns`, {params}), axios.get(table.api, {params})])
                .then((resp) => {
                    setColumnMeta(resp[0].data.map((item: any) => ({...item})));
                    setData(resp[1].data.map((item: any) => ({...item})));
                    setGridVisible(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    };

    return (
        <Container fluid={true} style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <Header size="large">Historical Dataset</Header>
            <span>Submittal Year:</span>
            <DropDownList
                data={subYearOptions}
                value={subYear}
                onChange={handleSubYearChange}
                style={{width: '250px'}}
            />
            <span> Data Set:</span>
            <DropDownList
                data={tabNmOptions}
                value={tabNm}
                onChange={handleTabNmChange}
                style={{width: '275px'}}
            />
            <span> Season:</span>
            <DropDownList
                data={seasonOptions}
                value={season}
                onChange={handleSeasonChange}
                style={{width: '250px'}}
            />
            <span> </span>
            <Button onClick={getHistory} disabled={!(season && tabNm && subYear)}>
                Show History {loading && <Loader size='small' type='pulsing'/>}
            </Button>
            <br/>
            <br/>
            {gridVisible &&
                <HistoricalGrid
                    columnMeta={columnMeta}
                    data={data}
                    title={title}
                />
            }
        </Container>
    );
};

export default HistoricalPage;
