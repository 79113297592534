import * as React from 'react';
import BigCalendar, {View} from "react-big-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Grid, Segment} from "semantic-ui-react";
import PostingModal from "./Comment/PostingModal";
import axios from "axios";
import {toast} from "react-toastify";
import {connect} from 'react-redux';
import CategoryDropDown from "./common/CategoryDropDown";

interface Event {
    id: number
    title: string
    startDate: Date
    endDate: Date
}

interface CalendarProps {
    currentUser: string,
    roles: any[],
    userEntities: string[],
    currentCategory: string
}

interface CalendarState {
    creatingEvent: boolean,
    startDate: string,
    endDate: string,
    postingTitle: string,
    reminders: any,
    action: string,
    events: any[],
    loading: boolean,
    postings: any[],
    currentPosting: any,
    currentView: View,
    admin: boolean,
    recordTypes: string[],
    currentRecordTab: string
}

const localizer = BigCalendar.momentLocalizer(moment);
const find = require('lodash/find');

class Calendar extends React.Component<CalendarProps, CalendarState> {
    _isMounted = false;

    constructor(props: CalendarProps, state: CalendarState) {
        super(props, state);
        this.state = {
            creatingEvent: false,
            startDate: '',
            endDate: '',
            postingTitle: '',
            reminders: [],
            action: '',
            events: [],
            loading: true,
            postings: [],
            currentPosting: {},
            currentView: 'month',
            admin: this.getStaffRole(),
            recordTypes: [],
            currentRecordTab: 'Capacity Adjustments'
        };
    }

    componentDidMount() {
        this._isMounted = true;

        setTimeout(this.getPostings, 0);
    }

    componentDidUpdate(prevProps: Readonly<CalendarProps>, prevState: Readonly<CalendarState>, snapshot?: any): void {
        if (prevProps !== this.props && !this.state.loading) {
            this.getPostings();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getStaffRole = () => {
        return !!this.props.roles.find((role) => {
            return role.SppStaff === true;
        });
    };

    getPostings = () => {
        axios.get(`/api/auth/postingsByCategory/${this.props.currentCategory}`)
            .then((response) => {
                let events: Event[] = response.data.map((event: any) => {
                    let start = event.startDate.split(' ')[0].split('/');
                    let end = event.endDate.split(' ')[0].split('/');
                    return ({
                        id: event.id,
                        title: event.posting,
                        startDate: new Date(start[2], start[0] - 1, start[1], 8, 0, 0),
                        endDate: new Date(end[2], end[0] - 1, end[1], 17, 0, 0)
                    });
                });
                if (this._isMounted) {
                    this.setState({events: events, postings: response.data, loading: false});
                }
            })
            .catch((error) => {
                if (error.message) {
                    toast.error(`Error retrieving postings from the server - ${error.message}`)
                }
                this.setState({loading: false});
            })
            .finally(() => {
                this.setState({loading: false});
            });
        this.setState({loading: true});
    };

    selectDate = (slotInfo: any) => {
        if (this.state.admin) {
            let startDate = localizer.format(slotInfo.start, "MM/DD/YYYY h:mm:ss", 'en');
            let endDate = localizer.format(slotInfo.end, "MM/DD/YYYY h:mm:ss", 'en');
            this.setState({creatingEvent: true, startDate, endDate})
        }
    };

    cancelEventModal = () => {
        this.setState({creatingEvent: false})
    };

    addPosting = (posting: any) => {
        let startDates = posting.startDate.split(' ')[0].split('/');
        let start = new Date(startDates[2], startDates[0] - 1, startDates[1], 8, 0, 0);
        let endDates = posting.endDate.split(' ')[0].split('/');
        let end = new Date(endDates[2], endDates[0] - 1, endDates[1], 17, 0, 0);
        let event: Event = {
            id: posting.id,
            title: posting.posting,
            startDate: start,
            endDate: end
        };

        this.setState((prevState) => {
            return {
                events: [...prevState.events, event],
                postings: [...prevState.postings, posting],
                creatingEvent: false,
            }
        });
    };


    eventDoubleClick = (event: Event, e: React.SyntheticEvent<HTMLElement>) => {
        let currentPosting = find(this.state.postings, {id: event.id, posting: event.title});
        this.setState({currentPosting})
    };

    onViewChange = (view: View) => {
        this.setState({currentView: view})
    };

    updateRecordTypes = (recordTypes: string[]) => {
        this.setState({recordTypes: recordTypes});
    };

    updateCurrentRecordTab = (currentRecordTab: string) => {
        this.setState({currentRecordTab: currentRecordTab});
    };

    public render() {
        return (
            <Segment loading={this.state.loading}>
            <Grid padded={true}>
                <Grid.Row>
                    <Grid.Column>
                        <div style={{float: 'right'}}>
                            <CategoryDropDown
                                recordTypes={this.state.recordTypes}
                                updateRecordTypes={this.updateRecordTypes}
                                currentRecordTab={this.state.currentRecordTab}
                                updateCurrentRecordTab={this.updateCurrentRecordTab}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Segment style={{height: '600px'}}>
                            {
                                <PostingModal
                                    open={this.state.creatingEvent}
                                    onClose={this.cancelEventModal}
                                    currentUser={this.props.currentUser}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    toggle={this.addPosting}
                                />
                            }
                            <BigCalendar
                                localizer={localizer}
                                startAccessor="startDate"
                                endAccessor="endDate"
                                events={this.state.events}
                                selectable={true}
                                onSelectSlot={this.selectDate} onSelectEvent={this.eventDoubleClick}
                                popup={true}
                                onView={this.onViewChange}
                                view={this.state.currentView}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
        )

    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        roles: state.authReducer.roles,
        userEntities: state.defaultReducer.userEntities,
        currentCategory: state.defaultReducer.currentCategory
    }
};

export default connect(mapStateToProps)(Calendar);