import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import ResourceOwnershipGrid from './ResourceOwnershipGrid';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';

const ResourceOwnershipPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);

    useEffect(() => {
        setPageRole(getCompressedPageRole('ResourceOwnership', roles));
    }, [roles]);

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size={'large'}>Resource Ownership</Header>
            <AccordionWrapper accordionHeader={'Resource Ownership Summer'}>
                <ResourceOwnershipGrid
                    {...pageRole}
                    title='Resource Ownership Summer'
                    dataApi='/api/auth/ra/resource-ownership/summer'
                    season='Summer'
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Resource Ownership Winter'}>
                <ResourceOwnershipGrid
                    {...pageRole}
                    title='Resource Ownership Winter'
                    dataApi='/api/auth/ra/resource-ownership/winter'
                    season='Winter'
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(ResourceOwnershipPage);