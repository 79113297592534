import React from 'react';
import {Loader} from '@progress/kendo-react-indicators';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';
import {toast} from "react-toastify";

// If parent grid has both inEdit and inVerify, then disabled value is {inEdit || inVerify}
interface RefreshPanelProps {
    disabled: boolean,
    getData: () => void,
    loading?: boolean
}

const RefreshPanel: React.FC<RefreshPanelProps> = ({disabled, getData, loading = false}) => {

    const handleRefresh = () => {
        getData();
        toast.info('Data has been refreshed.');
    }

    return (
        <>
            <AppBarSection>
                <Button disabled={disabled} onClick={handleRefresh}>
                    Refresh
                    {loading && <Loader size='small' type='pulsing'/>}
                </Button>
            </AppBarSection>
            <AppBarSpacer style={{width: 4}}/>
        </>
    );
};

export default RefreshPanel;