import * as React from 'react';
import {Redirect, RouteComponentProps} from "react-router";
import {
    Button,
    ButtonProps, CheckboxProps,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Grid,
    Header, Icon, Input, Modal, Popup,
    Radio,
    Segment
} from "semantic-ui-react";
import axios from 'axios';
import {toast} from "react-toastify";
import {connect} from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import {Principal} from "../../../auth";
import AdminService from "../../../services/admin-services";
import GridUtil, {getColumnMetaByHeaderName, getColumnWidth} from "../../common/GridUtil";
import ReactTable from "react-table";
import RolloverModal from "./RolloverModal";

interface TemplateData {
    data: any[],
    columnMetaDataList: any[]
}

interface RollOverState {
    rolloverYears: DropdownItemProps[]
    originalMdSubmittalYear: number
    mdSubmittalYear: number
    originalRaSubmittalYear: number
    raSubmittalYear: number
    templateSubmittalYear: number
    categories: DropdownItemProps[]
    selectedCategory: string
    templateData: TemplateData,
    templateDataLoading: boolean
    mdRadioValue: number
    raRadioValue: number
    modDevCb: string
    resAdqCb: string
    rollOverError: boolean
    lockOutLoading: boolean
    confirmRollForward: boolean
    columns: any[]
    editing: any[]
    rolloverStatus: string
    updatedData: any[],
    modalOpen: boolean,
    originalData: any,
    operationType: string,
    deleteModalActive: boolean,
    currentRow: any,
    rolloverLoading: boolean,
    raRole: boolean,
    mdRole: boolean
}

interface RollOverProps extends RouteComponentProps {
    setErrorMessage: (message: string) => void
    roles:Principal[]
    currentUser: string
    currentRow: any
    updateCurrentRow: (currentRow: any) => void;
    rolloverModalActive: boolean
    updateRolloverModalActive: (rolloverModalActive: boolean) => void
}

const maxBy = require ('lodash/maxBy');
const cloneDeep = require('lodash/cloneDeep');
const some = require('lodash/some');
const client = new AdminService();
class RollOver extends React.Component<RollOverProps, RollOverState> {
    constructor(props: RollOverProps, state: RollOverState) {
        super(props, state);
        this.state = {
            rolloverYears:[],
            originalMdSubmittalYear: 0,
            mdSubmittalYear:0,
            originalRaSubmittalYear: 0,
            raSubmittalYear:0,
            templateSubmittalYear:0,
            categories: [],
            selectedCategory: '',
            templateData: {data: [], columnMetaDataList: []},
            templateDataLoading: false,
            mdRadioValue: 0,
            raRadioValue: 0,
            modDevCb: '',
            resAdqCb: '',
            rollOverError: false,
            lockOutLoading: false,
            confirmRollForward: false,
            columns: [],
            editing: [],
            rolloverStatus: '',
            updatedData: [],
            modalOpen: false,
            originalData: {},
            operationType: '',
            deleteModalActive: false,
            currentRow: {},
            rolloverLoading: false,
            raRole: false,
            mdRole: false
        };
    }

    handleChange = (e:any, data?: DropdownProps) => {
        let cellInfo;
        if (data !== undefined) {
            cellInfo = JSON.parse(data.cellinfo);
        } else {
            cellInfo = JSON.parse(e.currentTarget.dataset.cellinfo);
        }
        let updatedData = [...this.state.updatedData];

        if (cellInfo.original[cellInfo.column.id] !== e.target.textContent) {
            if (!updatedData[cellInfo.index]) {
                updatedData[cellInfo.index] = cloneDeep(this.state.templateData.data[cellInfo.index]);
            }
            updatedData[cellInfo.index][cellInfo.column.id] = e.target.textContent;
            this.setState({updatedData});
        }
    };

    componentDidUpdate(prevProps: Readonly<RollOverProps>, prevState: Readonly<RollOverState>, snapshot?: any): void {
        /** Checks values of state and props to determine if a modal has been closed, and refreshes the grid.*/
        if (prevProps.rolloverModalActive !== this.props.rolloverModalActive || prevState.deleteModalActive !== this.state.deleteModalActive) {
            this.showTemplate();
        }
    }

    styleEditableCells = (cellInfo:any) => {
        let columnMeta = getColumnMetaByHeaderName(this.state.templateData.columnMetaDataList,cellInfo.column.id);
        if (columnMeta.dropDownValues !== undefined && columnMeta.dropDownValues !== null
            && columnMeta.editInd === "1" && some(this.state.editing,{index:cellInfo.index,value:true})) {
            let options = columnMeta.dropDownValues.map((ddv: any) => {
                return {key: ddv, value: ddv, text: ddv}
            });

            return (
                <div key={Date.now()}>
                    <Dropdown upward={false} options={options} selection={true} cellinfo={JSON.stringify(cellInfo)}
                              onChange={this.handleChange} search={true} fluid={true}
                              clearable={true} defaultValue={cellInfo.original[cellInfo.column.id] != null ? cellInfo.original[cellInfo.column.id].toString() : cellInfo.original[cellInfo.column.id]}
                    />
                </div>
            )
        }
        return (
            <div
                contentEditable={some(this.state.editing,{index:cellInfo.index,value:true}) && columnMeta.editInd === "1"}
                suppressContentEditableWarning={true}
                onBlur={this.handleChange}
                data-cellinfo={JSON.stringify(cellInfo)}
                style={{borderStyle:some(this.state.editing,{index:cellInfo.index,value:true}) && this.isRolloverComplete() ? 'none' : 'inset'}}
            >{cellInfo.original[cellInfo.column.id]}</div>
        )
    };

    getUtilityColumn = () => {
        return {
            Header: <Popup
                trigger={<Icon name={'add'} onClick={this.addRow} style={{cursor: 'pointer'}}/>}
                content={'Add New Row'}
            />,
            Cell: (cellInfo: any) => {
                return (
                    <Grid columns={2}>
                        <Grid.Column>
                           <Popup content={'Edit'} trigger={<Icon color={'blue'} name={'edit'}
                                                                  data-name={'edit'} data-cell={JSON.stringify(cellInfo)}
                                                                  onClick={this.toggleEditing} disabled={this.isRolloverComplete()}
                                                                  style={{cursor: 'pointer', paddingLeft: '25px'}}/>}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Popup content={'Delete'} trigger={<Icon color={'red'} name={'delete'} data-cell={JSON.stringify(cellInfo)}
                                                                  onClick={this.clickDelete} disabled={this.isRolloverComplete()}
                                                                  style={{cursor: 'pointer'}}/>}/>
                        </Grid.Column>
                    </Grid>
                )
            },
            filterable: false,
            sortable: false,
            width: 130
        };
    };

    getMaxId = () => {
        return maxBy(this.state.templateData.data,'id');
    };

    addRow = () => {
        let templateData:TemplateData = {
            data: [...this.state.templateData.data],
            columnMetaDataList: [...this.state.templateData.columnMetaDataList]
        };
        let jObject = {};
        if ((templateData.columnMetaDataList !== null) && (typeof templateData.columnMetaDataList !== "undefined")) {
            templateData.columnMetaDataList.sort(function (a: any, b: any) {
                return a.sortId - b.sortId;
            });
            for (let i = 0; i < templateData.columnMetaDataList.length; i++) {
                if (templateData.columnMetaDataList[i].visInd === "1") {
                    let colName = templateData.columnMetaDataList[i].nm.charAt(0).toLowerCase() + templateData.columnMetaDataList[i].nm.substring(1);
                    switch (colName) {
                        case 'category':
                            jObject[colName] = this.state.selectedCategory;
                            break;
                        case 'id':
                            jObject[colName] = this.getMaxId().id + 1;
                            break;
                        case 'subYearNm':
                            jObject[colName] = this.state.templateSubmittalYear;
                            break;
                        case 'tabNm':
                            if (this.state.selectedCategory === 'Model Development') {
                                jObject[colName] = 'Transactions'
                            }
                            break;
                        case 'modBy':
                        case 'creBy':
                            jObject[colName] = this.props.currentUser;
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        this.setState({templateData: templateData, operationType: 'add'});
        this.props.updateCurrentRow(jObject);
        this.props.updateRolloverModalActive(true);
    };

    getCategories = () => {
        axios.get('/api/auth/admin/categories',{
            withCredentials:true
        }).then(resp => {
            let categories:DropdownItemProps[] = resp.data
                .filter((cat: string) => (cat === 'Model Development' && this.state.mdRole))
                .map((cat: string) => ({key:cat,value:cat,text:cat}));
            this.setState({categories})
        }).catch(resp => {
            toast.error(`Error retrieving categories - ${resp.message}`)
        })
    };

    getCurrentSubYear = () => {
        Promise.all([
            axios.get('/api/auth/admin/subyear/MDWG'),
            axios.get('/api/auth/admin/subyear/RA')
        ]).then(responses => {
            this.setState({
                originalMdSubmittalYear: responses[0].data,
                originalRaSubmittalYear: responses[1].data
            });
        }).catch(error => {
            toast.error(`Error retrieving current submittal years - ${error.message}`);
        });
    };

    getRolloverYears = () => {
        axios.get('/api/auth/rollover/subYears',{
            withCredentials:true
        }).then(resp => {
            let rolloverYears:DropdownItemProps[] = resp.data.map((year:number) => ({id:year,text:year,value:year}));
            this.setState({rolloverYears})
        }).catch(resp => {
            toast.error(`Error retrieving rollover years - ${resp.message}`)
        })
    };

    setSubmittalYear = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        // data.name === 'MD'
        let submittalYear = this.state.mdSubmittalYear;
        if (data.name === 'RA') {
            submittalYear = this.state.raSubmittalYear;
        }
        axios.put(`/api/auth/rollover/updateSubYear`, {
            category: data.name,
            subYear: submittalYear
        }).then(() => {
            toast.success(`${data.name} submittal year was successfully set to ${submittalYear}`);
            this.getCurrentSubYear();
        }).catch(error => {
            toast.error(`Error updating submittal year - ${error.message}`);
        });
    };

    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({ [data.name.toString()] : data.value } as any);
    };

    showTemplate = () => {
        axios.post('/api/auth/rollover/rolloverTemplate',{
            category: this.state.selectedCategory,
            subYear: this.state.templateSubmittalYear
        },{
            withCredentials: true
        }).then(resp => {
            this.getRolloverStatus();
            this.generateColumnsFromMetaData(resp.data.columnMetaDataList);
            this.setState({templateData:resp.data,templateDataLoading:false})
        }).catch(resp => {
            toast.error(`Error retrieving template from the server - ${resp.message}`);
            this.setState({templateDataLoading:false})
        });
        this.setState({templateDataLoading:true})
    };

    getRolloverStatus = () => {
        let params = new URLSearchParams();
        params.append('category', this.state.selectedCategory);
        params.append('subYear', this.state.templateSubmittalYear.toString());
        axios.get('/api/auth/rollover/status', {params})
            .then(resp => {
                this.setState({rolloverStatus:resp.data});
                if (resp.data === '') {
                    toast.warn(`No rollover template available for ${this.state.selectedCategory} for ${this.state.templateSubmittalYear}.`);
                }
            }).catch(error => {
                toast.error(error.message);
        });
    };
    
    isRolloverComplete = () => (this.state.rolloverStatus === 'COMPLETE');

    generateColumnsFromMetaData = (columnMetaData:any[]) => {
        let columns: any[] = [];
        if ((columnMetaData !== null) && (typeof columnMetaData !== "undefined")) {
            columns = columnMetaData.sort((a: any, b: any) => a.sortId - b.sortId)
                .filter((c: any) => c.visInd === '1')
                .map((c: any) => ({
                    Header: c.vnm,
                    accessor: c.nm.charAt(0).toLowerCase() + c.nm.substring(1),
                    Cell: this.styleEditableCells,
                    width: getColumnWidth(this.state.templateData.data, c.nm.charAt(0).toLowerCase() + c.nm.substring(1), c.vnm, c.dropDownValues)
                }));
            columns.unshift(this.getUtilityColumn());
        }
        this.setState({columns})
    };

    createTemplate = () => {
        axios.post('/api/auth/rollover/createTemplate',{
            category: this.state.selectedCategory,
            subYear: this.state.templateSubmittalYear
        }).then(resp => {
            if (resp.status === 200) {
                this.showTemplate();
            }
            this.setState({templateDataLoading:false})
        }).catch(resp => {
            toast.error(`Error creating template - ${resp.message}`);
            this.setState({templateDataLoading:false})
        });
        this.setState({templateDataLoading:true})
    };

    confirmRollForward = () => {
        this.setState({confirmRollForward:true})
    };

    rollForward = () => {
        axios.post('/api/auth/rollover/performRollover',{
            category: this.state.selectedCategory,
            subYear: this.state.templateSubmittalYear
        }).then(() => {
            this.setState({rolloverStatus: 'COMPLETE'});
        }).catch(resp => {
            toast.error(`Error creating template - ${resp.message}`);
        }).finally(() => {
            this.setState({rolloverLoading: false, templateDataLoading: false});
        });
        this.setState({
            confirmRollForward: false, 
            rolloverLoading: true, 
            templateDataLoading: true
        });
    };

    mdRadioChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        this.setState({mdRadioValue:data.value as number});
    };

    toggleEditing = (event:any) => {
        this.setState({operationType: 'modify'})
        let cellInfo = JSON.parse(event.currentTarget.dataset.cell);
        this.props.updateCurrentRow(cellInfo.original);
        this.props.updateRolloverModalActive(true);
    };

    openModal(): void{
        this.setState({modalOpen:true})
    }

    setWindow = () => {
        axios.post('/api/auth/rollover/setWindow',{
            modDev: this.state.mdRadioValue,
            resAdq: this.state.raRadioValue
        },{
            withCredentials: true
        }).then(() => {
            toast.success("Success updating window");
        }).catch(resp => {
            toast.error(`Error updating window - ${resp.message}`)
        })
    };

    closeModal = () => {
        this.setState({confirmRollForward:false})
    };

    rolloverConfirmationModal = () => {
        return (
            <Modal open={this.state.confirmRollForward} closeOnDimmerClick={true} closeIcon={true} onClose={this.closeModal}>
                <Modal.Header>Confirm Rollover</Modal.Header>
                <Modal.Content>Are you sure you want to roll forward?</Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.rollForward}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                    <Button color='red' onClick={this.closeModal}>
                        <Icon name='remove'/> No
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    getSubmittalWindowData = () => {
        client.getLockOutData()
            .then((data:any) => {
                let raRadio = 1;
                let mdRadio = 1;
                if (data.raLocked) {
                    raRadio = 0;
                }
                if (data.modDevLocked) {
                    mdRadio = 0;
                }
                this.setState({raRadioValue: raRadio, mdRadioValue: mdRadio});
            })
            .finally(() => {
                this.setState({lockOutLoading: false})
            });
        this.setState({lockOutLoading: true});
    };

    componentDidMount(): void {
        this.getRoles();
        this.getSubmittalWindowData();
        this.getRolloverYears();
        this.getCurrentSubYear();
        this.getCategories();
    };
    
    getRoles = () => {
        let raRole = false;
        let mdRole = false;
        this.props.roles.forEach(role => {
            if (role.cat === 'ModDev') {
                mdRole = true;
            } else if (role.cat=== 'ResAdq') {
                raRole = true;
            }
        });
        this.setState({raRole, mdRole});
    };

    renderLockoutSection = () => {
        if(!this.state.mdRole) {
            return null;
        }
        return (
            <Grid.Column>
                <Segment loading={this.state.lockOutLoading}>
                    <b>Lockout Members for Rollover(Close/Open)</b>
                    <Grid columns={2}>
                        <Grid.Row/>
                        {this.state.mdRole && <Grid.Column>
                            <b>Model Development:</b>
                            <Segment>
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Radio label={'Open'} value={1}
                                               checked={this.state.mdRadioValue === 1}
                                               onChange={this.mdRadioChange}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Radio label={'Close'} value={0}
                                               checked={this.state.mdRadioValue === 0}
                                               onChange={this.mdRadioChange}/>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </Grid.Column>}
                        <Grid.Column>
                            <Button size={'mini'} color={'black'} content={'Set Window'}
                                    onClick={this.setWindow}/>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Grid.Column>
        );
    };


    clickDelete = (event:any) =>{
        let cellInfo = JSON.parse(event.currentTarget.dataset.cell);
        this.props.updateCurrentRow(cellInfo.original);
        this.setState({currentRow: cellInfo.original, deleteModalActive: true});
    }

    closeDeleteModal = () =>{
        this.setState({deleteModalActive: false});
        this.props.updateCurrentRow({});
    }

    deleteAction = () =>{
        let data = {
            id: this.props.currentRow.id,
            subYearId: parseInt(this.props.currentRow.subYearNm),
            tabNm: this.props.currentRow.tabNm
        }
        client.deleteSubPlanYearSeasonRel(data).then(this.closeDeleteModal);
    }

    render() {
        if(this.state.rollOverError) {
            return (
                <Redirect to={'/Error'} />
            )
        }

        return (
            <div style={{paddingLeft:'20px',paddingRight:'20px', paddingTop:'50px'}}>
                <div/>
                <RolloverModal theData={this.state.templateData.data} operationType={this.state.operationType}/>

                <Modal size={"large"} open={this.state.deleteModalActive}>
                    <Modal.Header>
                        Confirm Delete
                    </Modal.Header>
                    <Modal.Content>
                        <div className={'addContactDiv'}>
                            <div>Are you sure you want to delete submittal year <b>{this.state.currentRow.subYearNm}</b> and planning year <b>{this.state.currentRow.planYearNm}</b> from the
                                rollover template?</div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>

                        <Button content={'Yes'} color={'blue'} onClick={this.deleteAction}/>

                        <Button content={'No'} color={'red'} onClick={this.closeDeleteModal}/>

                    </Modal.Actions>
                </Modal>

                {this.rolloverConfirmationModal()}
                <Header size={'large'}>Roll Over</Header>
                <Grid columns={2} padded={true}>
                    <Grid.Column>
                        <Segment>
                            <b>Manage Submittal Templates and Rollover Data</b>
                            <Grid padded={true}>
                                {this.state.mdRole && <Grid.Row>
                                    <Segment>
                                        <b>Set Model Development Basecase Screens to Selected Submittal Year</b>
                                        <Grid columns={3} padded={true}>
                                            <Grid.Column>
                                                <b>Current Submittal Year:</b>
                                                <Input type={'text'} disabled={true} value={this.state.originalMdSubmittalYear}/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <b>Submittal Year:</b>
                                                <Dropdown search={true} selection={true} placeholder={'Select Submittal Year'} name={'mdSubmittalYear'} options={this.state.rolloverYears} onChange={this.dropDownChange}/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button color={'black'} size={'mini'} name={'MD'} onClick={this.setSubmittalYear}>Set MD Submittal Year</Button>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                </Grid.Row>}
                                <Grid.Row>
                                    <Segment>
                                        <b>View Templates</b>
                                        <Grid columns={3} padded={true}>
                                            <Grid.Column>
                                                <b>Category:</b>
                                                <Dropdown search={true} selection={true} name={'selectedCategory'} placeholder={'Select Table'} options={this.state.categories} onChange={this.dropDownChange}/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <b>Submittal Year:</b>
                                                <Dropdown search={true} selection={true} placeholder={'Select Submittal Year'} name={'templateSubmittalYear'} options={this.state.rolloverYears} onChange={this.dropDownChange}/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button color={'black'} size={'mini'} content={'Show Template'} onClick={this.showTemplate}/>
                                            </Grid.Column>
                                        </Grid>
                                        <Segment>
                                            <b>Manage Submittal Template</b>
                                            <Grid columns={2}>
                                                <Grid.Row/>
                                                <Grid.Column>
                                                    <Button size={'mini'} color={'black'} content={'Create Template'} onClick={this.createTemplate} disabled={this.isRolloverComplete()}/>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Button size={'mini'} color={'black'} content={'Roll Forward Data'} onClick={this.confirmRollForward} loading={this.state.rolloverLoading}/>
                                                </Grid.Column>
                                            </Grid>
                                        </Segment>
                                    </Segment>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                    {this.renderLockoutSection()}
                </Grid>
                <ReactTable
                    data={this.state.templateData.data}
                    columns={this.state.columns}
                    filterable={true}
                    style={{height:'500px'}}
                    defaultPageSize={20}
                    loading={this.state.templateDataLoading}
                    defaultFilterMethod={GridUtil.filterMethod}
                    getTheadProps={() => {
                        return {
                            style: {
                                background: "black",
                                color: "white",
                                borderRadius: '5px'
                            }
                        };
                    }}
                    getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                    getTdProps={(state: any, rowInfo: any, column: any) => {
                        let columnMeta = getColumnMetaByHeaderName(this.state.templateData.columnMetaDataList, column.id);
                        if (columnMeta && columnMeta.dropDownValues) {
                            return {
                                style: {
                                    overflow: 'visible'
                                }
                            };
                        } else {
                            return {};
                        }
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        ...state,
        roles: state.authReducer.roles,
        currentUser: state.authReducer.currentUser,
        currentRow: state.defaultReducer.currentRow,
        rolloverModalActive: state.rolloverReducer.rolloverModalActive,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentRow: (currentRow: any) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}
        ),
        updateUserPrincipal: (roles: Principal[]) => dispatch(
            {type: actionTypes.UPDATE_USER_PRINCIPAL, payload: roles}),
        setErrorMessage: (message: string) => dispatch (
            {type: actionTypes.SET_ERROR_MESSAGE, payload: message}
        ),
        updateRolloverModalActive: (rolloverModalActive: boolean) => dispatch(
            {type: actionTypes.SET_ROLLOVERMODALACTIVE, payload: rolloverModalActive}
        ),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(RollOver);