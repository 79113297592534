import {GridCellProps} from '@progress/kendo-react-grid';

export const MonthGridDescriptorCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;

    const descriptorRowNm = (descriptor: string) => {
        switch (descriptor) {
            case 'MnthlyNetEnergyForLoad':
                return 'Net Energy For Load (Units - GWh)';
            case 'MnthlyPeakDemand':
                return 'Peak Demand (Units - MW)';
            default:
                return descriptor;
        }
    };
    
    const value = field && dataItem[field] ? descriptorRowNm(dataItem[field]) : '';

    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {value}
        </td>
    );
};