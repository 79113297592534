import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {SubmittingEntityDropdown} from './SubmittingEntityDropdown';
import MonthlyDemandGrid from './MonthlyDemandGrid';
import YearlyEnergyPeakGrid from './YearlyEnergyPeakGrid';
import YearlyDemandGrid from './YearlyDemandGrid';
import {connect} from 'react-redux';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';

const DemandAndEnergyPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    const [submitEntId, setSubmitEntId] = useState<number>();

    useEffect(() => {
        setPageRole(getCompressedPageRole('DemandAndEnergy', roles));
    }, [roles]);

    return (
        <Container fluid style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <Header size="large">Demand and Energy</Header>
            <SubmittingEntityDropdown submitEntId={submitEntId} setSubmitEntId={setSubmitEntId}/>
            <br/>
            <br/>
            <AccordionWrapper accordionHeader={'Summer Monthly Demand and Energy'}>
                <MonthlyDemandGrid
                    title='Summer Monthly Demand and Energy'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/monthly/summer'
                    season='Summer'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Winter Monthly Demand and Energy'}>
                <MonthlyDemandGrid
                    title='Winter Monthly Demand and Energy'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/monthly/winter'
                    season='Winter'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Shoulder Monthly Demand and Energy'}>
                <MonthlyDemandGrid
                    title='Shoulder Monthly Demand and Energy'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/monthly/shoulder'
                    season='Summer'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Yearly Energy Submissions'}>
                <YearlyEnergyPeakGrid
                    title='Yearly Energy Submissions'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/energy'
                    season='current'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Summer Yearly Peak Demand'}>
                <YearlyEnergyPeakGrid
                    title='Summer Yearly Peak Demand'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/peak/summer'
                    season='Summer'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Winter Yearly Peak Demand'}>
                <YearlyEnergyPeakGrid
                    title='Winter Yearly Peak Demand'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/peak/winter'
                    season='Winter'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Summer Demand-Side Management Submissions'}>
                <YearlyDemandGrid
                    title='Summer Demand-Side Management Submissions'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/demand/summer'
                    season='Summer'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Winter Demand-Side Management Submissions'}>
                <YearlyDemandGrid
                    title='Winter Demand-Side Management Submissions'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/demand/winter'
                    season='Winter'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(DemandAndEnergyPage);
