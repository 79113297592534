import * as React from 'react';
import {Button, Container, Grid, Icon} from "semantic-ui-react";
import ReactTable from 'react-table';
import axios from 'axios';
import '../../../CSS/react-table-custom-orig.css';
import GridUtil from "../../common/GridUtil";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import {toast} from "react-toastify";

interface AttachmentGridState {
    data: any;
    columns: any;
    edit: boolean;
    updatedData: any;
    currentRow: number;
    redirect: boolean;
    metaData: any;
    filtered: any;
    openModal: boolean,
    selectedRowColor: string,
    status: number,
    loading: boolean,
    showAddRowsButton: boolean,
    rowsToAddToChangeset: any,
    currentChangesetNumber: number
    editing: any[]
    uploadFiles: any[]
}

interface AttachmentGridProps {
    currentChangeset: Changeset
    currentUser: string
}

let fileDownload = require('js-file-download');
let some = require('lodash/some');
let find = require('lodash/find');
class AttachmentGrid extends React.Component<AttachmentGridProps, AttachmentGridState> {
    constructor(props: AttachmentGridProps, state: AttachmentGridState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [],
            edit: false,
            updatedData:[],
            currentRow: -1,
            redirect:false,
            metaData: [],
            filtered: [],
            openModal: false,
            selectedRowColor: '',
            status: 200,
            loading: true,
            showAddRowsButton: false,
            rowsToAddToChangeset: [],
            currentChangesetNumber: 0,
            editing:[],
            uploadFiles:[]
        };
    }

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[] });
    };

    getFiltered = () => {
        if (this.state.filtered) {
            return 'visible'
        }
        return 'hidden'
    };

    downloadAttachment = (cellInfo:any) => {
        return (
            <div
                style={{
                    color: 'blue',
                    cursor:'pointer'}}
                onClick={() => {
                    this.downloadFile(cellInfo.original.fileNm);
                }}
                suppressContentEditableWarning={true}
            >{cellInfo.value}</div>
        )
    };

    handleChange = (e:any) => {
        let data = [...this.state.data];
        let cellInfo = JSON.parse(e.currentTarget.dataset.cellinfo);
        if (cellInfo.original[cellInfo.column.id] !== e.target.textContent) {
            data[cellInfo.index][cellInfo.column.id] = e.target.textContent;
            this.setState({data});
        }
    };

    toggleEditing = (event:any) => {
        let cellInfo = JSON.parse(event.currentTarget.dataset.cell);
        let editValue = event.currentTarget.dataset.name;
        let editing = [...this.state.editing];

        if (!find(editing,{index:cellInfo.index})) {
            editing.push({index:cellInfo.index,value:editValue === 'edit'})
        } else {
            let editingRow = find(editing,{index:cellInfo.index});
            editingRow.value = editValue === 'edit';
        }

        this.setState({editing});
    };

    update = (event:any) => {
        let cellInfo = JSON.parse(event.currentTarget.dataset.cell);
        axios.put('/api/auth/attachment', cellInfo.original)
            .then(response => {
            if (response.status === 200 && response.data === 1) {
                toast.success('Record updated successfully');
                let editing = [...this.state.editing];
                let editedRow = find(this.state.editing,{index:cellInfo.index});
                editedRow.value = false;
                this.setState({loading:false,editing})
            } else {
                toast.warn('Error updating record');
                this.setState({loading:false})
            }
        }).catch(resp => {
            toast.warn(`Error updating record - ${resp.message}`);
            this.setState({loading:false})
        });
        this.setState({loading:true})
    };

    delete = (event:any) => {
        let cellInfo = JSON.parse(event.currentTarget.dataset.cell);
        const data = {
            chgSId: cellInfo.original.chgSId,
            fileNm: cellInfo.original.fileNm
        };
        if (cellInfo.original.fileNm) {
            axios.delete('/api/auth/attachment',{data})
                .then(response => {
                if (response.status === 200 && response.data === 1) {
                    toast.success('Record deleted successfully');
                    let data = this.state.data.filter((d:any) => {
                        return d.fileNm !== cellInfo.original.fileNm
                    });
                    this.setState({loading:false,data})
                } else {
                    toast.warn('Error deleting record');
                    this.setState({loading:false})
                }
            });
            this.setState({loading:true})
        } else {
            toast.success('Record deleted successfully');
            let data = this.state.data.filter((d:any) => {
                return d.id !== cellInfo.original.id
            });
            this.setState({loading:false,data})
        }
    };

    getActionButtons = (cellInfo:any) => {

        let editButton = <span className={'link-button'} data-name={'edit'} data-cell={JSON.stringify(cellInfo)} onClick={this.toggleEditing}>Edit</span>;
        let updateButton = <span className={'link-button'} data-name={'update'} data-cell={JSON.stringify(cellInfo)} onClick={this.update}>Update</span>;
        let cancelButton = <span className={'link-button'} data-name={'cancel'} data-cell={JSON.stringify(cellInfo)} onClick={this.toggleEditing}>Cancel</span>;
        let deleteButton = <span className={'link-button'} data-name={'delete'} data-cell={JSON.stringify(cellInfo)} onClick={this.delete}>Delete</span>;

        return (
                <Grid columns={4} textAlign={"center"}>
                    <Grid.Column>
                        {some(this.state.editing,{index:cellInfo.index,value:true}) ? updateButton : editButton}
                    </Grid.Column>
                    <Grid.Column/>
                    <Grid.Column>
                        {some(this.state.editing,{index:cellInfo.index,value:true}) ? cancelButton : deleteButton}
                    </Grid.Column>
                </Grid>
            )
    };

    fileUploaded = (e: any) => {
        let fileExtension = e.target.files[0].name.toLowerCase();
        if (e.target.files.length !== 0) {
            if (fileExtension.endsWith('csv') || fileExtension.endsWith('xlsx')
                || fileExtension.endsWith('pdf') || fileExtension.endsWith('msg')) {
                let uploadFiles = [...this.state.uploadFiles];
                uploadFiles[e.currentTarget.dataset.index] = e.target.files[0];
                this.setState({uploadFiles})
            } else {
                toast.warn('You can only upload PDF, MSG, CSV, or XLSX files')
            }
        }
    };

    styleEditableCells = (cellInfo:any) => {
        return (
            <div
                contentEditable={some(this.state.editing,{index:cellInfo.index,value:true})}
                suppressContentEditableWarning={true}
                onBlur={this.handleChange}
                key={cellInfo.index}
                data-cellinfo={JSON.stringify(cellInfo)}
                style={{borderStyle:some(this.state.editing,{index:cellInfo.index,value:true}) ? 'inset' : 'none'}}
            >{cellInfo.original[cellInfo.column.id]}</div>
        )
    };

    inputClick = (event:any) => {
        document.getElementById(`uploadInput${event.currentTarget.dataset.index}`)!.click();
    };

    uploadAttachment = (cellInfo:any) => {
        if (cellInfo.original.fileNm !== null && cellInfo.original.fileNm !== '') {
            return;
        }
      	let validUploadFile = false;
        if (this.state.uploadFiles.length > 0 && this.state.uploadFiles![cellInfo.index]) {
            let fileExtension = this.state.uploadFiles![cellInfo.index].name.toLowerCase();
            if (this.state.uploadFiles[cellInfo.index]) {
                if (fileExtension.endsWith('csv') || fileExtension.endsWith('xlsx')
                    || fileExtension.endsWith('pdf') || fileExtension.endsWith('msg')) {
                    validUploadFile = true;
                }
            }
        }

        return (
            <Container fluid={true}>
                <div>
                    <Button size={'mini'} color={'black'} data-index={cellInfo.index} onClick={this.inputClick}>
                        <Icon name={"upload"}/>{validUploadFile ? this.state.uploadFiles![cellInfo.index].name : 'Add File'}
                    </Button>
                    <input id={`uploadInput${cellInfo.index}`} style={{display:'none'}} data-index={cellInfo.index}
                           accept={'.pdf,.msg,.csv,.xlsx'} type={'file'} color={'black'}
                            onChange={this.fileUploaded}/>
                </div>
                <div>
                    <span style={{visibility: validUploadFile ? 'visible' : 'hidden'}}
                          className={'link-button'} data-name={'edit'} data-index={cellInfo.index}
                          data-cell={JSON.stringify(cellInfo)} onClick={this.uploadFile}>Upload</span>
                </div>
            </Container>
        )
    };

    uploadFile = (event:any) => {
        let cellInfo = JSON.parse(event.currentTarget.dataset.cell);
        if (cellInfo.original.comments === undefined) {
            cellInfo.original.comments = '';
        }
        let formData = new FormData();
        formData.append('file', this.state.uploadFiles[cellInfo.index]);
        formData.set('chgSId', cellInfo.original.chgSId);
        formData.set('comments',cellInfo.original.comments);

        axios.post(`/api/auth/addAttachment`, formData,{headers: {'Content-Type': 'multipart/form-data'}})
            .then(resp => {
                if (resp.status === 200) {
                    toast.success(`File uploaded successfully`);
                    this.fetchData();
                    this.setState({loading:false})
                }
            }).catch(error => {
                toast.error(`Error uploading file - ${error.message}`);
                this.setState({loading:false});
        });
        this.setState({loading:true})
    };

    addAttachment = () => {
        let data: any[] = [];
        if (this.state.data) {
            data = [...this.state.data]
        }
        if (!some(data,{fileNm:null})) {
            data.push({
                chgSId: this.props.currentChangeset.number,
                creBy: this.props.currentUser,
                modBy: this.props.currentUser,
                fileNm: null,
                id: data.length
            });
            let editing = [...this.state.editing];
            editing.push({index:data.length - 1,value:true});
            this.setState({data,editing});
        }
    };

    columns = [{
            Header: <Button content={'Add Attachment'} size={'mini'} onClick={this.addAttachment}/>,
            accessor: 'addAttachment',
            Cell: this.getActionButtons,
            Filter: () => (
                <Button color={'black'} size={'mini'} style={{visibility:this.getFiltered}} onClick={this.clearFilterOnClick}>Clear Filters</Button>
            ),
            sortable:false
        },{
            Header:'Changeset Id',
            accessor:'chgSId'
        },{
            Header:'File Name',
            accessor:'fileNm',
            Cell: this.downloadAttachment
        },{
            Header:'Created By',
            accessor:'creBy'
        },{
            Header:'Created Date',
            accessor:'creDt'
        },{
            Header:'Modified By',
            accessor:'modBy'
        },{
            Header:'Modified Date',
            accessor:'modDt'
        },{
            Header:'Comments',
            accessor:'comments',
            Cell: this.styleEditableCells
        },{
            Header:'Upload',
            filterable: false,
            Cell: this.uploadAttachment,
            minWidth: 150
        }
    ];

    downloadFile = (fileName: string) => {
        let data = new FormData();
        data.set('changesetId', this.props.currentChangeset.number.toString())
        data.set('fileName', fileName);
        axios.post('/api/auth/downloadAttachment', data, {responseType: 'arraybuffer'})
            .then(resp => {
                fileDownload(resp.data, fileName);
            })
            .catch(error => {
                toast.error(`Error downloading file ${fileName} - ${error.message}`)
            });
    };

    fetchData = () => {
        axios.get(`/api/auth/attachment`, {params:
                {changesetId: this.props.currentChangeset.number}
            }).then(resp => {
            if (resp.data) {
                this.setState({data:resp.data, status: resp.status, loading: false});
            } else {
                this.setState({loading:false,data:[]})
            }
        }).catch(resp => {
            this.setState({status: resp.status, loading: false})
        });
        this.setState({currentChangesetNumber:this.props.currentChangeset.number,loading:true,editing:[]})
    };

    componentDidUpdate() {
        if (this.props.currentChangeset.number !== this.state.currentChangesetNumber) {
            this.fetchData();
        }
    }

    public render() {
        const {data} = this.state;
        return (
            <Container fluid={true} style={{paddingLeft:'50px', paddingRight:'50px'}}>
                <ReactTable
                    data={data}
                    columns={this.columns}
                    defaultPageSize={10}
                    filterable={true}
                    filtered={this.state.filtered}
                    style={{height: '500px', width: '100%'}}
                    className='-highlight'
                    defaultFilterMethod={GridUtil.filterMethod}
                    onFilteredChange={filtered => this.setState({filtered})}
                    getTheadProps={() => {
                        return {
                            style: {
                                background: 'black',
                                color: 'white',
                                borderRadius: '5px'
                            }
                        };
                    }}
                />
                <Loading active={this.state.loading}/>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentChangeset: state.changeSetReducer.currentChangeset,
        currentUser: state.authReducer.currentUser
    }
};

export default connect(mapStateToProps)(AttachmentGrid)
