import * as React from 'react';
import {Button, Container, Form, Grid, Header, Message} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {Redirect} from "react-router";
import GeneralService from "../../services/general-services";

interface ForgotPasswordProps {
    location: any
}

interface ForgotPasswordState {
    email:string,
    loading: boolean,
    messageHeader: string,
    messageBody: string,
    forgotMistake: boolean
}

const generalService = new GeneralService();
class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
    constructor(props: ForgotPasswordProps, state: ForgotPasswordState) {
        super(props, state);
        this.state = {
            email: '',
            loading: false,
            messageHeader: '',
            messageBody: '',
            forgotMistake: false
        };
    }

    handleChange = (e: any, value: any) => {
        this.setState({[e.target.name.toString()]: value.value, } as any);
        if(this.state.forgotMistake) {
            this.setState({forgotMistake: false});
        }
    };

    requestPasswordChange = () => {
        generalService.sendRequestToUpdatePassword(this.state.email)
            .then((resp: any) => {
                if (resp) {
                    this.setState({
                        email: '',
                        forgotMistake: false,
                        messageHeader: 'Success',
                        messageBody: 'Please check your email to reset your password.'
                    });
                }
            })
            .catch(() => {
                this.setState({
                    forgotMistake: true,
                    messageHeader: 'Forgot Password Error',
                    messageBody: 'Error submitting request for password reset. Please try again.'
                });
            })
            .finally(() => {
                this.setState({loading: false});
            });

        this.setState({loading: true});
    };

    handleForgotMistakeDismiss = () => {
        this.setState({
            forgotMistake: false,
            messageHeader: '',
            messageBody: ''
        });
    };

    render() {
        if(generalService.loggedIn()){
            return <Redirect to={'/'} />;
        }

        return (
            <Container fluid={true}>
                <div className={'loginDiv'}>
                    <div className={'loginForm'}>
                        <Grid textAlign='center' className={'loginInput'}>
                            <Grid.Row>
                                <Header content={'Forgot Password'} size={'large'}/>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column style={{maxWidth: 450}}>
                                    <Form warning={true} autoComplete={'off'} onSubmit={this.requestPasswordChange}>
                                        <Form.Input type={'text'} required={true} fluid={true} icon='mail' name={'email'}
                                                    iconPosition='left' placeholder={'Email'}
                                                    value={this.state.email} onChange={this.handleChange} />
                                        <Button type={'submit'} color={'red'} content={'Email Link'}
                                                loading={this.state.loading}/>
                                        {this.state.messageBody &&
                                            <Message
                                                warning={this.state.messageHeader === 'Forgot Password Error'}
                                                header={this.state.messageHeader}
                                                content={this.state.messageBody}
                                                onDismiss={this.handleForgotMistakeDismiss}
                                            />
                                        }
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <NavLink to={'/'} className={'signUpLink'}>
                                    Return to Login
                                </NavLink>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </Container>
        );
    }
}

export default ForgotPassword;