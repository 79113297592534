import * as React from 'react';
import {Button, Container, Form, Grid, Header, Label, Message} from "semantic-ui-react";
import {toast} from 'react-toastify';
import GeneralService from "../services/general-services";
import history from '../history';

interface ChangePasswordProps {
    location: any
}

interface ChangePasswordState {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    token: string,
    status: string,
    message: string,
    loading: boolean,
    retypeError: boolean,
    passwordErrors: string[],
    oldMatchError: boolean,
    showMessage: boolean,
}

const generalService = new GeneralService();
class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
    constructor(props: ChangePasswordProps) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            token: '',
            status: '',
            message: '',
            loading: false,
            retypeError: false,
            passwordErrors: [],
            oldMatchError: false,
            showMessage: false,
        };
    }

    handleFieldChange = (e: any, data: any) => {
        let fieldName: string = e.target.name.toString();
        this.setState({[fieldName]: data.value} as any);
        
        if (this.state.status.length > 0) {
            this.setState({
                status: '',
                message: '',
                showMessage: false
            });
        }

        if (this.state.retypeError 
            && (
                (fieldName === 'newPassword' && this.state.confirmPassword === data.value) 
                || (fieldName === 'confirmPassword' && this.state.newPassword === data.value)
            )
        ) {
            this.setState({retypeError: false});
        } else if (this.state.oldMatchError
            && (
                (fieldName === 'oldPassword' && this.state.newPassword !== data.value)
                || (fieldName === 'newPassword' && this.state.oldPassword !== data.value)
            )
        ) {
            this.setState({oldMatchError: false});
        }
    };

    validatePassword = () => {
        let validMatching = true;

        if (this.state.newPassword !== this.state.confirmPassword) {
            validMatching = false;
            this.setState({retypeError: true});
        }
        if (this.state.oldPassword === this.state.newPassword) {
            validMatching = false;
            this.setState({oldMatchError: true});
        }
        
        let errors: string[] = generalService.validatePassword(this.state.newPassword);
        
        if (errors.length > 0) {
            this.setState({
                passwordErrors: errors,
                status: 'validation',
                showMessage: true
            });
        }

        return validMatching && errors.length === 0;
    };

    handleSubmit = () => {
        if (this.validatePassword()) {
            this.setState({loading: true});
            generalService.changePassword(this.state.oldPassword, this.state.newPassword)
                .then((response: any) => {
                    this.setState({
                        passwordErrors: [],
                        loading: false,
                        showMessage: true
                    });
                    if (response.message === "Success") {
                        this.setState({
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                            status: 'success',
                            message: 'Your password has been changed.'
                        });
                    } else if (response.message === 'Bad credentials') {
                        this.setState({
                            status: 'error',
                            message: 'Incorrect current password.'
                        });
                    } else if (response.message === 'Locked') {
                        generalService.logout()
                        toast.error('Account has been locked. Log in later to try again.');
                        history.push({pathname: '/login', state: {from: this.props.location.pathname}});
                        // Do not update state when using history.push.
                    } else { //(response.message === 'Error')
                        throw new Error(response.message);
                    }
                })
                .catch(() => {
                    this.setState({
                        passwordErrors: [],
                        loading: false,
                        status: 'error',
                        message: 'An error occurred. Password was not changed.'
                    });
                });
        }
    };
    
    render() {
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Header size={'large'}>Change Password</Header>
                    </Grid.Row>
                    <Grid.Row>
                        {this.state.showMessage &&
                            <Message positive={this.state.status === 'success'}
                                     warning={this.state.status === 'validation'}
                                     negative={this.state.status === 'error'}
                                     list={this.state.passwordErrors}
                                     content={this.state.message}
                            />}
                    </Grid.Row>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Input name={'oldPassword'} label={'Current password'}
                                        type={'password'} required={true}
                                        value={this.state.oldPassword} onChange={this.handleFieldChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input name={'newPassword'} label={'New password'}
                                        type={'password'} error={this.state.oldMatchError} required={true}
                                        value={this.state.newPassword} onChange={this.handleFieldChange}/>
                            {
                                this.state.oldMatchError ?
                                    <Label basic={true} color={'red'} pointing={'left'}
                                           content={`Your new password can't match your old password.`}
                                           horizontal={true}/>
                                    : null
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Input name={'confirmPassword'} label={'Confirm new password'}
                                        type={'password'} error={this.state.retypeError} required={true}
                                        value={this.state.confirmPassword} onChange={this.handleFieldChange}/>
                            {
                                this.state.retypeError ?
                                    <Label basic={true} color={'red'} pointing={'left'}
                                           content={'The new password and confirmation password do not match.'}
                                           horizontal={true}/>
                                    : null
                            }
                        </Form.Group>
                        <Button type={'submit'} size={'small'} content={'Change Password'}
                                loading={this.state.loading}/>
                    </Form>
                </Grid>
            </Container>
        );
    }
}

export default ChangePassword;