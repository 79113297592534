import * as React from 'react';
import {Button, Icon, Tab} from 'semantic-ui-react'
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";
import EntityGrid from "./EntityGrid";
import EntityModal from "./EntityModal";
import SubRespRelGrid from "./SubRespRelGrid";
import MpLreRelGrid from "./MpLreRelGrid";
import UserGrid from "./UserGrid";

interface TabGridsProps {
    entityModalActive: boolean;
    activeTab: string;
    updateEntityModalActive: (entityModalActive: boolean) => void;
    updateAddOrModifyEntity: (addOrModifyEntity: string) => void;
    updateActiveTab: (activeTab: string) => void;
    roles: any;
}

interface TabGridsState {
}

/**Defines the tabs displayed in the Entity Management screen and the actions that can be taken in each tab*/


class TabGrids extends React.Component<TabGridsProps,TabGridsState> {
    constructor(props: TabGridsProps) {
        super(props);
        this.state = {
        }
    }

    panes = [
        {
            menuItem: { key: 'entities', icon: 'user', content: 'Entities' },
            render: () => <Tab.Pane>
                <Button icon={true} labelPosition='left' onClick={this.addEntityClick} color={'green'}>
                    <Icon name='user plus'/>
                    Add Entity
                </Button>
                    <EntityGrid/>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'subRespEntRel', icon: 'group', content: 'Sub/Resp Entity Relationship' },
            render: () => <Tab.Pane>
                <SubRespRelGrid/>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'mpLreRel', icon: 'group', content: 'MP/LRE Relationship' },
            render: () => <Tab.Pane>
                <MpLreRelGrid/>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'users', icon: 'user', content: 'Users' },
            render: () => <Tab.Pane>
                <UserGrid/>
            </Tab.Pane>
        }
    ]



    addEntityClick = () =>{
        this.props.updateEntityModalActive(true);
        this.props.updateAddOrModifyEntity('Add')
    }

    tabChange = (e: any) => {
        this.props.updateActiveTab(e.target.innerText);
    }

    render(){
        return(
            <div>
                <EntityModal/>
                <Tab panes={this.panes} onTabChange={this.tabChange} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        entityModalActive: Boolean(state.entityManagementReducer.entityModalActive),
        updateAddOrModifyEntity: state.entityManagementReducer.updateAddOrModifyEntity,
        roles: state.authReducer.roles,
        activeTab: state.defaultReducer.activeTab,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateEntityModalActive: (entityModalActive: boolean) => dispatch(
            {type: actionTypes.SET_ENTITYMODALACTIVE, payload: entityModalActive}
        ),
        updateAddOrModifyEntity: (addOrModifyEntity: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYENTITY, payload: addOrModifyEntity}
        ),
        updateActiveTab: (activeTab: string) => dispatch(
            {type: actionTypes.SET_ACTIVETAB, payload: activeTab}
        )

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TabGrids);

