import { combineReducers } from 'redux'
import defaultReducer from './defaultReducer';
import authReducer from "./authReducer";
import changeSetReducer from "./changeSetReducer";
import baseCaseReducer from "./baseCaseReducer";
import contactReducer from "./contactReducer";
import entityManagementReducer from "./entityManagementReducer";
import rolloverReducer from "./rolloverReducer";



const rootReducer = combineReducers({
    defaultReducer,
    authReducer,
    changeSetReducer,
    baseCaseReducer,
    contactReducer,
    entityManagementReducer,
    rolloverReducer
})

export default rootReducer;