import * as React from "react";
import {Redirect, RouteComponentProps} from "react-router";
import {
    Button,
    CheckboxProps,
    Container,
    Grid,
    Header,
    Input,
    InputOnChangeData,
    Label,
    Message
} from "semantic-ui-react";
import GeneralService from "../../services/general-services";
import {toast} from "react-toastify";
import * as actionTypes from "../../store/actions/actionTypes";
import {connect} from "react-redux";

interface ResetPasswordState {
    displayPage: boolean,
    loading: boolean,
    email: string,
    password: string,
    confirmPassword: string,
    emailError: boolean,
    passwordError: boolean,
    confirmPasswordError: boolean,
    validationErrors: string[],
    resetSuccess: boolean,
    securityUserResponse: SecurityUserResponse,
}

interface ResetPasswordProps extends RouteComponentProps {
    setErrorMessage: (message: string) => void,
    location: any,
}

const generalService = new GeneralService();

class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
    constructor(props: ResetPasswordProps, state: ResetPasswordState) {
        super(props, state);
        this.state = {
            displayPage: true,
            loading: false,
            email: '',
            password: '',
            confirmPassword: '',
            emailError: false,
            passwordError: false,
            confirmPasswordError: false,
            validationErrors: [],
            resetSuccess: false,
            securityUserResponse: {
                email: '',
                badToken: true,
                token: '',
                pass: ''
            }
        };
    }

    componentDidMount() {
        this.getSecurityUser();
    }

    getSecurityUser = () => {
        let params = null;
        let token: any = null;
        if (this.props.location !== undefined) {
            params = new URLSearchParams(this.props.location.search);
            token = params.get('token');
        }
        generalService.getSecurityUserResponse(token)
            .then((resp: SecurityUserResponse) => {
                this.setState({
                    displayPage: !resp.badToken,
                    securityUserResponse: resp,
                });
            })
    };

    handleChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData | CheckboxProps) => {
        let value = data.value as string;
        let {confirmPasswordError, emailError} = this.state;
        if (data.name === 'email') {
            this.setState({email: value});
            if (emailError) {
                emailError = false;
                this.setState({emailError: false});
            }
        }
        else if (data.name === 'password') {
            this.setState({password: value});
        } else if (data.name === 'confirmPassword') {
            this.setState({confirmPassword: value});
        }
        
        if ((data.name === 'password' || data.name === 'confirmPassword') && (confirmPasswordError)) {
            confirmPasswordError = false;
            this.setState({confirmPasswordError: false});
        }
        if (!emailError && !confirmPasswordError) {
            this.setState({validationErrors: []});
        }
    };

    validateForm = (password: string, confirmPassword: string) => {
        let passwordError = true;
        let confirmPasswordError = true;
        let errors: string[] = [];
        if (password.length === 0) {
            errors.push('Password cannot be blank.');
        } else {
            errors = generalService.validatePassword(password);
        }

        if (errors.length === 0) {
            passwordError = false;
        }
        if (confirmPassword !== password) {
            errors.push('Confirm password does not match password.');
        } else {
            confirmPasswordError = false;
        }

        if (this.state.email.toLowerCase() !== this.state.securityUserResponse.email.toLowerCase()) {
            errors.push('Invalid email.');
        }
        
        this.setState({
            validationErrors: errors, 
            passwordError, 
            confirmPasswordError
        });

        return errors.length === 0;
    };

    resetPassword = () => {
        let securityUserResponse = {...this.state.securityUserResponse};
        let {password, confirmPassword} = this.state;
        if (password && confirmPassword) {
            let valid = this.validateForm(password, confirmPassword);

            if (valid) {
                this.setState({loading: true});
                securityUserResponse.pass = password;
                //make call to reset password
                generalService.resetUserPassword(securityUserResponse)
                    .then((resp: PasswordResetResponse) => {
                        if (resp.resetStatus) {
                            this.setState({resetSuccess: true});
                            toast.success('Successfully reset password.');
                        } else {
                            let validationErrors = [];
                            validationErrors.push(resp.resetMessage);
                            this.setState({validationErrors});
                        }
                    })
                    .finally(() => {
                        this.setState({loading: false});
                    });
            }
        }
    };

    handleResetMessageDismiss = () => {
        this.setState({validationErrors: []});
    };

    render() {
        if (generalService.loggedIn()) {
            return <Redirect to={{
                pathname: this.props.location.state === undefined ? '/' : this.props.location.state,
                state: {from: this.props.location.state === undefined ? '/' : this.props.location.state}
            }}/>;
        }

        if (this.state.resetSuccess) {
            return <Redirect to={'/login'}/>;
        }

        if (!this.state.displayPage) {
            toast.warn('Reset link has expired. Please request a new link.');
            return <Redirect to={'/ForgotPassword'}/>;
        }

        return (
            <Container>
                <Grid centered={true}>
                    <Grid.Row>
                        <Header size={'large'}>Reset your password</Header>
                    </Grid.Row>
                    <Grid.Row>
                        <Input label={<Label basic={true} content={'Email'}/>}
                               labelPosition={'left'}
                               placeholder={'Email'}
                               name={'email'}
                               value={this.state.email}
                               type={'email'}
                               error={false}
                               onChange={this.handleChange}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Input label={<Label basic={true} content={'Password'}/>}
                               labelPosition={'left'}
                               placeholder={this.state.passwordError ? 'Password is required' : 'New Password'}
                               name={'password'}
                               value={this.state.password}
                               type={'password'}
                               error={this.state.passwordError}
                               onChange={this.handleChange}
                        />
                    </Grid.Row>
                    <Grid.Row>

                        <Input label={<Label basic={true} content={'Confirm Password'}/>}
                               labelPosition={'left'}
                               placeholder={this.state.confirmPasswordError ? 'Confirm password does not match password' : 'Re-enter your password'}
                               name={'confirmPassword'}
                               value={this.state.confirmPassword}
                               type={'password'}
                               error={this.state.confirmPasswordError}
                               onChange={this.handleChange}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Button color='red' content='Reset Password'
                                type='submit'
                                loading={this.state.loading}
                                onClick={this.resetPassword}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        {this.state.validationErrors.length > 0 &&
                            <Message
                                header={'Password Reset Error'}
                                list={this.state.validationErrors}
                                onDismiss={this.handleResetMessageDismiss}
                            />
                        }
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setErrorMessage: (message: string) => dispatch(
            {type: actionTypes.SET_ERROR_MESSAGE, payload: message}
        )
    }
};

export default connect(mapDispatchToProps)(ResetPassword);