import * as React from 'react';
import {Button, Checkbox, Icon, Modal, ModalProps} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-toastify";
import {connect} from "react-redux";

interface PostingDeleteModalProps extends ModalProps{
    postingId: number,
    posting: Object,
    handleDelete(data: any): any

}

type OwnProps = PostingDeleteModalProps & ModalProps;

interface PropsFromState {
    userEntities: any[]
}

type AllProps = OwnProps & PropsFromState;

interface PostingDeleteModalState {
    open: boolean,
    entities: Array<object>,
    selectedEntity: string,
    commentText: string,
    privateCheck: boolean,
    admin: boolean,
    privacyHeading: string,
    privacyContent: string,
    loading: boolean
}

const publicHeading: string = 'Public';
const publicContent: string = 'Everyone can view this comment';

const privateHeading: string = 'Private';
const privateContent: string = 'Only your entity and SPP can view this comment';

class PostingDeleteModal extends React.Component<AllProps, PostingDeleteModalState> {
    _isMounted: boolean = false;

    constructor(props: AllProps, state: PostingDeleteModalState) {
        super(props, state);
        this.state = {
            open: false,
            entities: [],
            selectedEntity: '',
            commentText: '',
            privateCheck: true,
            admin: false,
            privacyHeading: privateHeading,
            privacyContent: privateContent,
            loading: true
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUserEntity();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleFieldChange = (e:any, value:any) => {
        this.setState({ [e.target.name.toString()] : value.value } as any);
    };

    handleSelectChange = (e: any, data: any) => {
        this.setState({selectedEntity: data.value});
    };

    togglePrivacy = () => {
        const {privateCheck} = this.state;
        if (privateCheck) { // private was previously checked: will be public now
            this.setState((prevState) => {
                return {
                    privateCheck: !prevState.privateCheck,
                    privacyHeading: publicHeading,
                    privacyContent: publicContent
                }
            })
        } else { // private was not previously checked: will be private now
            this.setState((prevState) => {
                return {
                    privateCheck: !prevState.privateCheck,
                    privacyHeading: privateHeading,
                    privacyContent: privateContent
                }
            })
        }
    };

    toggleModal = () => {
        const {admin, open, entities, selectedEntity} = this.state;
        if (open) { // toggle from open to closed
            // reset modal inputs when closing
            if (admin) {
                this.setState({
                    open: false,
                    privateCheck: false,
                    privacyHeading: publicHeading,
                    privacyContent: publicContent,
                });
            } else {
                this.setState({
                    open: false,
                    privateCheck: true,
                    privacyHeading: privateHeading,
                    privacyContent: privateContent,
                });
            }
            if (selectedEntity !== 'SPP(ISO)' && entities.length > 1) {
                this.setState({selectedEntity: ''});
            }
        } else { // toggle from closed to open
            this.setState({open: true});
        }
    };

    getUserEntity = () => {
        this.setState({loading: true});
        let dropdownItems: Array<object> = [];
        let entity = '';
        let spp: boolean = false;
        this.props.userEntities.forEach((item: any) => {
            entity = item.respEntTypeNm;
            if (entity === 'SPP(ISO)') {spp = true;}
            dropdownItems.push({key: entity, text: entity, value: entity});
        });

        if(this._isMounted) {
            if (spp) {
                this.setState({selectedEntity: 'SPP(ISO)', admin: true})
            } else if (dropdownItems.length === 1) {
                this.setState({selectedEntity: entity});
            } else if (this.props.comment.isPrivate) {
                this.setState({selectedEntity: this.props.comment.entityLock})
            } else {
                this.setState({entities: [...dropdownItems]});
            }
        }
    };

    showPrivacyCheck = () => {
        return (
            !this.state.admin ?
                <PrivacyCheckbox
                    privacy={this.state.privateCheck}
                    togglePrivacy={this.togglePrivacy}
                />
                : null
        )
    };

    handleDelete = () => {
        if(this.state.entities.length > 0 && this.state.selectedEntity === ''){
            toast.warn('You must select an entity');
            return;
        }
        const data = {
            'postingId': this.props.postingId.toString()
        };
        axios.delete('/api/auth/postings',
            { data }
        ).then((resp) => {
            this.props.handleDelete(data);
        }).catch((resp) => {
            console.log('PostingDeleteModal - handleDelete failed:',resp.data);
        });
        this.toggleModal();
    };

    render() {
        // @ts-ignore
        const {posting, postingEntityList, reminders} = this.props.posting
        return (
            <>
                <Button floated={'left'} size={'mini'} color={'black'} disabled={this.state.loading} onClick={this.toggleModal}>
                    <Icon name='delete'/>
                    Delete
                </Button>
                <Modal size={'mini'}  open={this.state.open} closeOnDimmerClick={true} closeIcon={true} onClose={this.toggleModal}>
                    <Modal.Header>Delete Post</Modal.Header>
                    <Modal.Content>
                        <p>Are you sure you want to delete this post - <b>{posting}</b> ?<br/>
                            {
                                (postingEntityList.length > 0 && reminders.length) ?
                                    <span><b>It contains Posting Entities and Reminders.</b></span>
                                    : (postingEntityList.length) ?  <span><b>It contains Posting Entities.</b></span>
                                    : (reminders.length) ?  <span><b>It contains Reminders.</b></span> : null
                            }
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button  onClick={this.handleDelete} content={'Yes'}/>
                        <Button  onClick={this.toggleModal} content={'No'}/>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}

interface PrivacyCheckboxProps {
    privacy: boolean,
    togglePrivacy: () => void
}

const PrivacyCheckbox: React.FC<PrivacyCheckboxProps> = (props: PrivacyCheckboxProps) => (
    <Checkbox label={'Private'} checked={props.privacy} onChange={props.togglePrivacy}/>
);

const mapStateToProps = (state: any) => {
    return {
        userEntities: state.authReducer.userEntities
    }
};

export default connect<PropsFromState, {}, OwnProps>(mapStateToProps, {})(PostingDeleteModal);