import {GridCellProps} from '@progress/kendo-react-grid';

export const MonthPlanYearCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem} = props;
    
    const planYearContent = (season: string, planYear: number) => {
        return season === 'Winter' 
            ? `${planYear}-${planYear + 1}`
            : planYear;
    };
    
    const value = !!dataItem['seasonNm'] && !!dataItem['planYearId'] 
        ? planYearContent(dataItem['seasonNm'], dataItem['planYearId'])
        : '';
    
    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {value}
        </td>
    );
};