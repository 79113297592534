import React from 'react';
import {AppBar, AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';

interface ActionPanelProps {
    title: string;
}

const ActionPanel: React.FC<ActionPanelProps> = ({title, children}) => {
    return (
        <AppBar>
            <AppBarSection>
                <h3>{title}</h3>
            </AppBarSection>
            <AppBarSpacer/>
            {children}
        </AppBar>
    );
};

export default ActionPanel;