import React from 'react';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {Button, ButtonGroup} from '@progress/kendo-react-buttons';

interface RemovePanelProps {
    inRemove: boolean,
    openModal: () => void,
    toggleRemove: () => void
}

const RemovePanel: React.FC<RemovePanelProps> = ({inRemove, openModal, toggleRemove}) => {

    return (
        <>
            <AppBarSection>
                {inRemove
                    ?
                    <ButtonGroup>
                        <Button onClick={openModal}>Delete</Button>
                        <Button onClick={toggleRemove}>Cancel</Button>
                    </ButtonGroup>
                    :
                    <Button onClick={toggleRemove}>Remove</Button>
                }
            </AppBarSection>
            <AppBarSpacer style={{width: 4}}/>
        </>
    );
};

export default RemovePanel;
