import * as React from 'react';
import {Button, Icon, Modal} from "semantic-ui-react";

interface PopupState {
popupOpen : boolean,
    answer : boolean,
}
interface PopupProps {
popupOpenProp : boolean,
    isResponseCheck: any
}
class PopupX extends React.Component<PopupProps, PopupState> {
    constructor(props : PopupProps, state : PopupState) {
        super(props,state);
        this.state = {
            popupOpen : this.props.popupOpenProp,
            answer : false
        }
    }

    // @ts-ignore
    toggleModal = (item) => {
        if (item.target.innerText === 'Yes') {
            this.props.isResponseCheck(true);
        } else {
        this.props.isResponseCheck(false);
        }
        const {popupOpen} = this.state;
        if (popupOpen) { // toggle from open to closed
           // reset modal inputs when closing
            this.setState({popupOpen : false})

        } else { // toggle from closed to open
            this.setState({popupOpen: true});
        }
        return false;
    };
    popup = () => {
        return (
            <>
                <Button floated={'left'} size={'mini'} color={'black'}  onClick={this.toggleModal}>
                    <Icon name='delete'/>
                    Delete
                </Button>
                <Modal size={'mini'}  open={this.state.popupOpen} closeOnDimmerClick={true} closeIcon={true} onClose={this.toggleModal}>
                    <Modal.Header>Record Removal Notification</Modal.Header>
                    <Modal.Content>
                        <p>Are you sure you want to REMOVE identified record(s) from the BaseCase Data?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                       { <Button  onClick={this.toggleModal} content={'Yes'}/>}
                        <Button  onClick={this.toggleModal} content={'No'} />
                    </Modal.Actions>
                </Modal>
            </>
        );
    }
    render() {
        return this.popup();
    }

}

export default PopupX;