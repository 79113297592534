const raChangesetLastYear = 2023;

interface HistoryTable {
    category: string,
    tabNm: string,
    visNm: string,
    firstSubYear: number,
    lastSubYear: number | null,
    season: string,
    api: string,
    parameters: Array<string>
}

export const historyTables: Array<HistoryTable> = [
    {
        category: 'MD',
        tabNm: 'Branches',
        visNm: 'Branches',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'BranchOutages',
        visNm: 'Branch Outages',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'BusDtl',
        visNm: 'Bus Details',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'BusOutages',
        visNm: 'Bus Outages',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'FixedShuntOutages',
        visNm: 'Fixed Shunt Outages',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'GeneratorOutages',
        visNm: 'Generator Outages',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'Generators',
        visNm: 'Generators',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'LoadDtl',
        visNm: 'Load Details',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'SwitchedShuntOutages',
        visNm: 'Switched Shunt Outages',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'Transactions',
        visNm: 'Transactions',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'Transformer2WindingOutages',
        visNm: 'Transformer 2 Winding Outages',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'Transformer3WindingOutages',
        visNm: 'Transformer 3 Winding Outages',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'Transformers2Winding',
        visNm: 'Transformers 2 Winding',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'Transformers3Winding',
        visNm: 'Transformers 3 Winding',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'MD',
        tabNm: 'TwoTerminalDCTies',
        visNm: 'Two Terminal DC Ties',
        firstSubYear: 2016,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'CapacityAdjustments',
        visNm: 'Capacity Adjustments',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'DemandSideManagement',
        visNm: 'Demand Side Management',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'GeneratorTestResults',
        visNm: 'Generator Test Results',
        firstSubYear: 2020,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'MnthlyNetEnergyForLoad',
        visNm: 'Monthly Net Energy For Load',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'MnthlyPeakDemand',
        visNm: 'Monthly Peak Demand',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'Plants',
        visNm: 'Plants',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'PurchasesSales',
        visNm: 'Purchases Sales',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'ResourceOwnership',
        visNm: 'Resource Ownership',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'Resources',
        visNm: 'Resources',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'YrlyNetEnergyForLoad',
        visNm: 'Yearly Net Energy For Load',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'YrlyPeakDemand',
        visNm: 'Yearly Peak Demand',
        firstSubYear: 2016,
        lastSubYear: raChangesetLastYear,
        season: 'All',
        api: '/api/auth/historical/historicalData',
        parameters: ['tabName','subYear'],
    },
    {
        category: 'RA',
        tabNm: 'CapacityAdjustmentsSummer',
        visNm: 'Capacity Adjustments',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/submissions/history/capacity/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'CapacityAdjustmentsWinter',
        visNm: 'Capacity Adjustments',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/submissions/history/capacity/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'MonthlyDemandShoulder',
        visNm: 'Monthly Demand',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Shoulder',
        api: '/api/auth/ra/submissions/history/monthly/shoulder',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'MonthlyDemandSummer',
        visNm: 'Monthly Demand',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/submissions/history/monthly/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'MonthlyDemandWinter',
        visNm: 'Monthly Demand',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/submissions/history/monthly/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'Plants',
        visNm: 'Plants',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/plants/history/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'Plants',
        visNm: 'Plants',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/plants/history/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'PurchasesSalesSummer',
        visNm: 'Purchases Sales',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/purchases-sales/history/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'PurchasesSalesWinter',
        visNm: 'Purchases Sales',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/purchases-sales/history/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'ResourceOwnershipSummer',
        visNm: 'Resource Ownership',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/resource-ownership/history/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'ResourceOwnershipWinter',
        visNm: 'Resource Ownership',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/resource-ownership/history/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'Resources',
        visNm: 'Resources',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/resources/history/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'Resources',
        visNm: 'Resources',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/resources/history/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'TestResultsOp',
        visNm: 'Operational Test Results',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/ra/test-results/history/op',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'TestResultsSummer',
        visNm: 'Capability Test Results',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/test-results/history/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'TestResultsWinter',
        visNm: 'Capability Test Results',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/test-results/history/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'YearlyDemandSummer',
        visNm: 'Yearly Demand Submissions',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/submissions/history/demand/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'YearlyDemandWinter',
        visNm: 'Yearly Demand Submissions',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/submissions/history/demand/winter',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'YearlyEnergy',
        visNm: 'Yearly Energy Submissions',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'All',
        api: '/api/auth/ra/submissions/history/energy',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'YearlyPeakSummer',
        visNm: 'Yearly Peak Demand',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/ra/submissions/history/peak/summer',
        parameters: ['subYear'],
    },
    {
        category: 'RA',
        tabNm: 'YearlyPeakWinter',
        visNm: 'Yearly Peak Demand',
        firstSubYear: raChangesetLastYear + 1,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/ra/submissions/history/peak/winter',
        parameters: ['subYear'],
    },
];


