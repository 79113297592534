import {useCallback, useEffect, useState} from 'react';
import {Button, Container, Header} from 'semantic-ui-react';
import axios from 'axios';
import {SubmitEntData} from '../../../interfaces/submissions-interface';
import {SubmittingEntitySelection} from './SubmittingEntitySelection';
import SeasonStatusGrid from './SeasonStatusGrid';
import {SeasonRollForwardModal} from './SeasonRollForwardModal';
import {CategorySeason} from '../../../interfaces/category-interface';

// This page component is used for categories that do not use changesets. 
const SeasonRolloverPage = () => {
    const [seasons, setSeasons] = useState<Array<CategorySeason>>([]);
    const [newSubmittingEntities, setNewSubmittingEntities] = useState<Array<SubmitEntData>>([]);
    const [selectedEntities, setSelectedEntities] = useState<Array<SubmitEntData>>([]);
    
    const getSeasons = useCallback(() => {
            axios.get('/api/auth/ra/seasons')
                .then((resp) => {
                    setSeasons(resp.data);
                });
        }
    , []);

    useEffect(() => {
       getSeasons();
    }, [getSeasons]);

    useEffect(() => {
        getNewSubmittingEntities();
    }, []);

    const getNewSubmittingEntities = () => {
        axios.get('/api/auth/ra/submissions/new-submitting-entities')
        .then((resp) => {
            setNewSubmittingEntities(resp.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const onSelect = (submitEnt: SubmitEntData, checked: boolean) => {
        if (checked) {
            let newSelectedEntities = [...selectedEntities, submitEnt]
            setSelectedEntities(newSelectedEntities);
        } else {
            let newSelectedEntities = selectedEntities.filter(value => value !== submitEnt)
            setSelectedEntities(newSelectedEntities);
        }
    };

    const createEntRecords = () => {
        axios.post('/api/auth/ra/submissions/initialize-submitting-entities', selectedEntities)
            .then((resp) => {
                console.log(resp.data);
                getNewSubmittingEntities();
                setSelectedEntities([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <Container fluid={true} style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
            <Header size='large'>Seasonal Rollover</Header>
            <Header size='small'>Active Seasons</Header>
            <SeasonStatusGrid seasons={seasons} getSeasons={getSeasons}/>
            <br/><br/>
            <SeasonRollForwardModal getSeasons={getSeasons}/>
            <br/><br/>
            <br/><br/>
            <Header size='large'>Submitting Entities without Submissions Records</Header>
            <ul style={{listStyle: 'none'}}>
            {
                newSubmittingEntities.length ? newSubmittingEntities.map((entity: any) => {
                        return (<SubmittingEntitySelection key={entity.submitEntId} entity={entity} onSelect={onSelect}/>)
                    }) : null
            }
            </ul>
            <br/><br/>
            <Button onClick={createEntRecords}>Create Submissions Records</Button>

        </Container>
    );
};

export default SeasonRolloverPage;