import * as React from "react";
import {connect} from "react-redux";
import {Container} from "semantic-ui-react";

interface ErrorState {

}

interface ErrorProps {
    errorMessage: string;
}

class Error extends React.Component<ErrorProps,ErrorState> {
    constructor(props: ErrorProps, state: ErrorState) {
        super(props,state);
        this.state = {

        };
    }

    componentDidMount(): void {

    }

    render() {
        if(this.props.errorMessage !== null && this.props.errorMessage !== "" && this.props.errorMessage !== undefined) {
            return (
                <Container fluid={true}>
                    <h1>Error</h1>
                    <p>{this.props.errorMessage}</p>
                </Container>
            )
        }
        else {
            return (
                <Container fluid={true} style={{paddingLeft: '20px', paddingRight: '20px'}}>
                    <h1 className={'errorContent'}>Error</h1>
                    <p className={'errorContent'}>An error occurred while processing your request.</p>
                </Container>
            )
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        errorMessage: state.defaultReducer.errorMessage
    }
};

export default connect(mapStateToProps)(Error);