import * as React from 'react';
import {
    Button,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Grid, InputOnChangeData,
    TextArea,
    TextAreaProps
} from "semantic-ui-react";
import axios from "axios";
import {connect} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import {toast} from "react-toastify";
import {Principal} from "../../auth";
import history from "../../history";

interface ChangesetState {
    options: DropdownItemProps[]
    changeset: Changeset
    dropDownLoading: boolean
    dropDownValue: string
    creatingChangeset: boolean
    changesetNameUpdated: boolean
    originalComment: string
    originalName: string
    updatingChangeset: boolean
    changesetList: any[]
}

interface ChangesetProps {
    currentUser: string
    resource:string
    propsChangeset: Changeset
    updateCurrentChangeset: (currentChangeset: Changeset) => void
    updateChangeSetPendingSave: (pendingSave: boolean) => void;
    setChangesetRowsUpdated: (rowUpdated: boolean) => void;
    setChangeSetAuthorIsMember: (isMember: boolean) => void;
    roles:Principal[],
    resAdqLockedOut?: boolean
    modDevLockedOut?: boolean
    category?: string
    sppStaff: boolean
    currentTab: string
}

const find = require('lodash/find');

class ChangesetOperations extends React.Component<ChangesetProps,ChangesetState> {
    constructor(props: ChangesetProps, state: ChangesetState) {
        super(props, state);
        this.state = {
            options:[],
            changeset: {
                status: '',
                name: '',
                number: 0,
                comments: '',
                changeSetAuthorName: ''
            },
            dropDownLoading: false,
            dropDownValue: '',
            creatingChangeset: false,
            changesetNameUpdated: false,
            originalComment: '',
            originalName: '',
            updatingChangeset: false,
            changesetList:[]
        };
    }

    componentDidMount() {
        if (this.props.resource.split('/')[2]) {
            const resourceChangesetId = this.props.resource.split('/')[2];
            if (this.props.propsChangeset.number) {
                this.setChangesetDetails(this.props.propsChangeset);
            } else { // browser refreshed and Redux was set back to initialState - must fetch changeset details again
                this.getRouteChangeset(resourceChangesetId)
                    .then((changeset: Changeset) => {
                        if (changeset.status === 'APPROVED' || changeset.status === 'REJECTED' || changeset.status === 'ABANDONED') {
                            history.push(`/${this.props.resource.split('/')[1]}`);
                        } else {
                            this.setChangesetDetails(changeset);
                        }
                    })
                    .catch(error => {
                        toast.error(error.message);
                        history.push(`/${this.props.resource.split('/')[1]}`);
                    });
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<ChangesetProps>, prevState: Readonly<ChangesetState>, snapshot?: any): void {
        if (this.props !== prevProps) {
            if (this.state.options.length > 0 && this.props.propsChangeset.number !== 0) {
                let c = this.props.propsChangeset;
                this.setState({dropDownValue: c.number.toString()});
            }

            if (this.props.propsChangeset.status === 'ABANDONED'
                || this.props.propsChangeset.status === 'APPROVED'
                || this.props.propsChangeset.status === 'REJECTED') {
                this.clearChangeset();
            }

            this.setState({changeset:this.props.propsChangeset})
        }
    }

    componentWillUnmount(): void {
        this.props.updateCurrentChangeset({
            name:'',
            number:0,
            comments:'',
            status:'',
            changeSetAuthorName: ''
        });
    }
    
    setChangesetDetails = (changeset: Changeset) => {
        this.determineIfChangeSetAuthorMember(changeset.changeSetAuthorName)
            .then((resp:any) => {
                let authorIsMember = false;
                if(resp.data === 0) {
                    authorIsMember = true;
                }

                this.props.setChangeSetAuthorIsMember(authorIsMember);
                this.props.updateCurrentChangeset(changeset);
                this.props.setChangesetRowsUpdated(false);
                this.props.updateChangeSetPendingSave(false);
                let options: DropdownItemProps[] = [];
                let changesetList:any[] = [];
                options.push({
                    key: changeset.number,
                    text:`${changeset.number},${changeset.name},${changeset.status},${changeset.comments},${changeset.changeSetAuthorName}`,
                    value: changeset.number.toString()
                });
                changesetList.push(changeset);
                this.setState({
                    changeset,
                    options: options,
                    dropDownValue: changeset.number.toString(),
                    changesetNameUpdated: false,
                    originalComment: changeset.comments,
                    originalName: changeset.name,
                    changesetList
                });
            })
            .catch(error => {
                toast.error(error.message);
            });
    };

    handleChange = (event: React.FormEvent<HTMLTextAreaElement> | React.SyntheticEvent<HTMLInputElement>, data: TextAreaProps | InputOnChangeData) => {
        let changeset = {...this.state.changeset};
        changeset[data.name] = data.value;
        if(!this.state.changesetNameUpdated) {
            this.setState({changesetNameUpdated: true});
        }
        else {
            if (changeset.comments === this.state.originalComment && changeset.name === this.state.originalName) {
                this.setState({changesetNameUpdated: false});
            }
        }
        this.setState({changeset});
    };

    createChangeset = () => {
        if(this.state.changeset.name) {
            axios.post(`/api/auth/createChangeset`,{
                    tabName: this.props.currentTab,
                    changesetName: this.state.changeset.name,
                    comments: this.state.changeset.comments
                }).then(resp => {
                if (resp.status === 200) {
                    let changeset: Changeset = {
                        status: 'DRAFT',
                        number: resp.data,
                        name: this.state.changeset.name,
                        comments: this.state.changeset.comments,
                        changeSetAuthorName: this.props.currentUser
                    };
                    this.addDropDownOption(changeset);
                    this.props.updateCurrentChangeset(changeset);
                    this.getChangesetNumbers();
                    this.setState({
                        changeset, 
                        creatingChangeset: false, 
                        changesetNameUpdated: false, 
                        originalComment: changeset.comments, 
                        originalName: changeset.name
                    });
                    toast.info('Changeset created successfully');
                }
            }).catch(resp => {
                toast.error(`Error creating changeset ${this.state.changeset.name} - ${resp.message}`);
                this.setState({creatingChangeset: false});
            });
            this.setState({creatingChangeset: true})
        }
        else {
            toast.warn('Changeset name required in order to create changeset');
        }
    };

    updateChangeset = () => {
        if(this.state.changeset.name) {
            let updateChangesetDetails: any = {
                id: this.state.changeset.number,
                nm: this.state.changeset.name,
                comments: this.state.changeset.comments,
                disp: this.state.changeset.status,
                changesetAuthorName: this.state.changeset.changeSetAuthorName
            }
            axios.put(`/api/auth/updateChangesetDetails`, updateChangesetDetails).then(resp => {
                if (resp.status === 200) {
                    let changeset: Changeset = {
                        status: this.state.changeset.status,
                        number: this.state.changeset.number,
                        name: this.state.changeset.name,
                        comments: this.state.changeset.comments,
                        changeSetAuthorName: this.state.changeset.changeSetAuthorName
                    };
                    this.props.updateCurrentChangeset(changeset);
                    this.setState({changeset, changesetNameUpdated: false, originalComment: this.state.changeset.comments, originalName: this.state.changeset.name});
                    toast.success(resp.data);
                } else{
                    toast.error(resp.data);
                }
                this.setState({updatingChangeset: false});
            }).catch(resp => {
                toast.error(`Error updating changeset - ${resp.message}`);
                this.setState({updatingChangeset: false});
            });
            this.setState({updatingChangeset: true})
        }
        else {
            toast.warn('Changeset name required in order to update changeset');
        }
    };

    clearChangeset = () => {
        let changeset:Changeset = {
            status: '',
            number: 0,
            name: '',
            comments: '',
            changeSetAuthorName: ''
        };
        this.setState({
            changeset,
            dropDownValue:'',
            changesetNameUpdated: false,
            originalComment: '',
            originalName: ''
        });
        this.props.updateCurrentChangeset(changeset);
        toast.info('Changeset cleared successfully');
    };

    getChangesetNumbers = () => {
        let options: DropdownItemProps[] = [];
        axios.get(`/api/auth/changeset`,
            {params:
                    {tabName:this.props.resource.split('/')[1]}
            },
            ).then( resp => {
                let changesetList = resp.data.map((c:any) =>{
                    options.push({
                        key: c.id,
                        text:`${c.id},${c.nm},${c.disp},${c.comments},${c.changeSetAuthorName}`,
                        value: (c.id as number).toString()
                    });
                    return {    //Returning this as a new object to account for the id field in the database being renamed as "number" elsewhere in this component
                        status: c.disp,
                        number: c.id,
                        name: c.nm,
                        comments: c.comments,
                        changeSetAuthorName: c.changeSetAuthorName
                    };
                });
                this.setState({
                    options,
                    dropDownLoading:false,
                    changesetList
                });
            }).catch(resp => {
                toast.error(`Error retrieving changeset numbers - ${resp.message}`);
        });
        this.setState({dropDownLoading:true})
    };

    /**
     * Only called when user came to page via Changeset Details link on home page and then refreshed browser.
     * Refreshing the browser loses Redux data. This method fetches details again for changesetId in route path parameter.
     * @param changesetId
     */
    getRouteChangeset = (changesetId: string) => {
        return new Promise<Changeset>((resolve, reject) => {
            axios.get(`/api/auth/changeset/${changesetId}`)
                .then((response) => {
                    let changeset: Changeset = {
                        number: response.data.id,
                        name: response.data.nm,
                        status: response.data.disp,
                        comments: response.data.comments,
                        changeSetAuthorName: response.data.creBy
                    };
                    resolve(changeset);
                })
                .catch(() => {
                    reject(new Error('Error retrieving changeset details. Please select changeset again.'));
                });
        });
    };

    addDropDownOption = (changeset:Changeset) => {
        let options = [...this.state.options];
        options.push({
            key: changeset.number,
            text:`${changeset.number},${changeset.name},${changeset.status},${changeset.comments},${changeset.changeSetAuthorName}`,
            value: changeset.number.toString()
        });
        let changesetList = [...this.state.changesetList];
        changesetList.push(changeset);
        this.setState({
            options,
            changesetList, 
            dropDownValue: changeset.number.toString()
        });
    };

    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (data.value) {
            let changeset = find(this.state.changesetList, {number: parseInt(data.value as string)});
            this.determineIfChangeSetAuthorMember(changeset.changeSetAuthorName)
                .then((resp:any) => {
                    let authorIsMember = false;
                    if(resp.data === 0) {
                        authorIsMember = true;
                    }

                    this.props.setChangeSetAuthorIsMember(authorIsMember);
                    this.props.updateCurrentChangeset(changeset);
                    this.props.setChangesetRowsUpdated(false);
                    this.props.updateChangeSetPendingSave(false);
                    this.setState({
                        changeset,
                        dropDownValue: data.value as string,
                        changesetNameUpdated: false,
                        originalComment: changeset.comments,
                        originalName: changeset.name
                    });
                }).catch(err => {
                toast.error(err);
            });
        }
    };

    determineIfChangeSetAuthorMember = (changesetAuthor: string) => {
        return new Promise((resolve, reject) => {
            axios.get('/api/auth/checkSppUser', {params:{
                userName: changesetAuthor
            }}).then((resp: any) => {
                resolve(resp);
            }).catch(() => {
                reject(new Error('Error determining if original changeset author is SppStaff.'));
            });
        });
    };

    userHasPermissionsToUpdate = () => {
        let userDoesNotHavePermission = false;
        if (!this.props.sppStaff && this.state.changeset.changeSetAuthorName) {
            if (this.props.currentUser.toUpperCase() !== this.state.changeset.changeSetAuthorName.toUpperCase()) {
                userDoesNotHavePermission = true;
            }
        }

        return userDoesNotHavePermission;
    };

    render () {
        return (
            <Grid columns={3}>
                <Grid.Column>
                    <Grid.Row>
                        <Form>
                            <Form.Group widths={'equal'}>
                                <Form.Field>
                                    <label>Changeset Name:</label>
                                    <Form.Input 
                                        type={'text'} 
                                        placeholder={'Enter Changeset name'} 
                                        readOnly={(((this.props.category === 'ResourceAdequacy' && this.props.resAdqLockedOut) || (this.props.category === 'ModelingDevelopment' && this.props.modDevLockedOut)) && !this.props.sppStaff) || this.userHasPermissionsToUpdate()} 
                                        value={this.state.changeset.name} 
                                        name={'name'} 
                                        onChange={this.handleChange} 
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Comments</label>
                                    <TextArea 
                                        placeholder={'Enter comments'} 
                                        readOnly={(((this.props.category === 'ResourceAdequacy' && this.props.resAdqLockedOut) || (this.props.category === 'ModelingDevelopment' && this.props.modDevLockedOut)) && !this.props.sppStaff) || this.userHasPermissionsToUpdate()} 
                                        value={this.state.changeset.comments} 
                                        name={'comments'} 
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Row>
                    <Grid.Row>
                        <label><b>Open existing Changeset:</b></label>
                    </Grid.Row>
                    <Grid.Row>
                        <Dropdown fluid={true} placeholder={'Changesets'}
                                  search={true} selection={true} onClick={this.getChangesetNumbers}
                                  value={this.state.dropDownValue}
                                  loading={this.state.dropDownLoading}
                                  options={this.state.options} onChange={this.dropDownChange}/>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row >
                        {(this.state.changeset.number === 0 || !this.state.changesetNameUpdated || this.state.changeset.status !== 'DRAFT') 
                            ? 
                            <Button size={'mini'} color={'black'} onClick={this.createChangeset} loading={this.state.creatingChangeset}
                                    content={'Create Changeset'}
                                    disabled={(((this.props.category === 'ResourceAdequacy' && this.props.resAdqLockedOut) 
                                            || (this.props.category === 'ModelingDevelopment' && this.props.modDevLockedOut)) && !this.props.sppStaff) 
                                        || this.state.changeset.number !== 0}
                            />
                            :
                            <Button size={'mini'} 
                                      color={'black'} 
                                      onClick={this.updateChangeset} 
                                      loading={this.state.updatingChangeset}
                                      content={'Update Changeset'} 
                                      disabled={((((this.props.category === 'ResourceAdequacy' && this.props.resAdqLockedOut) || (this.props.category === 'ModelingDevelopment' && this.props.modDevLockedOut)) && !this.props.sppStaff) 
                                              || this.userHasPermissionsToUpdate()) 
                                          && !this.state.changesetNameUpdated}
                            />
                        }
                        <Button size={'mini'} color={"black"} onClick={this.clearChangeset}>Clear Changeset</Button>
                    </Grid.Row>
                    <Grid.Row/>
                    <Grid.Row/>
                    <Grid.Row>
                        <Form>
                            <Form.Field>
                                <label>Changeset Status:</label>
                                <input placeholder={'Changeset Status'} readOnly={true} value={this.state.changeset.status}/>
                            </Form.Field>
                        </Form>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Changeset Number:</label>
                            <input placeholder={'Changeset Status'} value={this.state.changeset.number.toString()} readOnly={true}/>
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToProps = (state: any) => {

    return {
        propsChangeset: state.changeSetReducer.currentChangeset,
        currentUser: state.authReducer.currentUser,
        roles: state.authReducer.roles,
        currentTab: state.defaultReducer.currentTab
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentChangeset: (currentChangeset: Changeset) => dispatch(
            {type: actionTypes.UPDATE_CURRENT_CHANGESET, payload: currentChangeset}),
        updateChangeSetPendingSave: (pendingSave: boolean) => dispatch (
            {type: actionTypes.UPDATE_CHANGESET_PENDING_SAVE, payload: pendingSave}),
        setChangesetRowsUpdated: (rowUpdated: boolean) => dispatch (
            {type: actionTypes.SET_CHANGESET_ROW_UPDATED, payload: rowUpdated}),
        setChangeSetAuthorIsMember: (isMember: boolean) => dispatch (
            {type: actionTypes.SET_CHANGESET_AUTHOR_IS_MEMBER, payload: isMember})
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ChangesetOperations);