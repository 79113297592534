import * as actionTypes from '../actions/actionTypes';
import initialState from "./initialState";

export default function rolloverReducer(state = initialState.rolloverState, action:any){
    switch(action.type) {
        case actionTypes.SET_ROLLOVERMODALACTIVE:
            return {
                ...state,
                rolloverModalActive: action.payload
            };
    }
    return state;
};


