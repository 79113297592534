import * as React from 'react';
import {
    Button,
    Checkbox,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Grid,
    InputOnChangeData,
    Modal,
    ModalProps
} from "semantic-ui-react";
import {Reminder} from "./Reminder";
import axios from "axios";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, {Moment} from 'moment';
import {connect} from 'react-redux';

interface PostingEntity {
    entityId: number
    entityNameType: string
}

interface PostingModalProps {
    startDate?: string,
    endDate?: string,
    currentUser: string,
    toggle(posting: any): any,
}

type OwnProps = PostingModalProps & ModalProps;

interface PropsFromState {
    currentCategory: string,
    userEntities: any[],
    roles: Array<Roles>
}

type AllProps = OwnProps & PropsFromState;

interface PostingModalState {
    reminders: any,
    startMoment: Moment | null,
    endMoment: Moment | null,
    postingTitle: string,
    action: string,
    titleError: boolean,
    startError: boolean,
    endError: boolean,
    catError: boolean,
    entityError: boolean,
    entities: DropdownItemProps[],
    entityCheck: boolean,
    selectedEntities: string[],
    postingEntities: PostingEntity[]
    inputValues: number[]
    reminderDurations: string[],
    milestoneFlag: boolean,
    isStaff: boolean
}

const find = require('lodash/find');
class PostingModal extends React.Component<AllProps, PostingModalState> {
    constructor(props: AllProps, state: PostingModalState) {
        super(props, state);
        this.state = {
            reminders: [],
            startMoment: this.props.startDate ? moment(this.props.startDate, 'MM/DD/YYYY h:mm:ss') : null,
            endMoment: this.props.endDate ? moment(this.props.endDate, 'MM/DD/YYYY h:mm:ss') : null,
            postingTitle: '',
            action: '',
            titleError: false,
            startError: false,
            endError: false,
            catError: false,
            entityError: false,
            entities: [],
            entityCheck: false,
            selectedEntities: [],
            postingEntities: [],
            inputValues: [],
            reminderDurations: [],
            milestoneFlag: false,
            isStaff: this.getStaffRole()
        };
    }

    componentDidMount() {
        // category can only be 'RA' or 'MDWG'
        setTimeout(() => { this.getCategoryEntities(this.props.currentCategory); },0);
        if(this.props.post){
            this.setState({
                postingTitle: this.props.post.posting,
                action: this.props.post.action,
                startMoment: this.props.post.startDate ? moment(this.props.post.startDate, 'MM/DD/YYYY h:mm:ss') : null,
                endMoment: this.props.post.endDate ? moment(this.props.post.endDate, 'MM/DD/YYYY h:mm:ss') : null,
                entityCheck:  this.props.post.selectEntity,
                milestoneFlag: this.props.post.milestoneFlag
            });
          //  selectedEntities: this.props.post.postingEntityList,
            let existsEntities: string[] = [];
            // @ts-ignore
            this.props.post.postingEntityList.forEach(entity =>{
                existsEntities.push(entity.entityNameType)
            })
            this.setState({selectedEntities: existsEntities})

            let reminders = [...this.state.reminders];
            let reminderDurations = [...this.state.reminderDurations];
            let inputValues = [...this.state.inputValues];
            // @ts-ignore
            this.props.post.reminders.forEach((reminder, index) => {
                reminders.push(
                    <Reminder
                        endDate={this.momentToString(this.state.endMoment)}
                        inputChange={this.inputChange}
                        index={index}
                        dropDownChange={this.handleReminderDropDown}
                        defaultDurationSelection = {reminder.duration}
                        defaultDurationTypeSelection = {reminder.durationType}
                        key={index}
                    />
                );
                reminderDurations[index] = reminder.durationType;
                inputValues[index] = +reminder.duration;
             })
            this.setState({reminders: reminders})
            this.setState({reminderDurations: reminderDurations})
            this.setState({inputValues: inputValues})
        }
    }

    componentDidUpdate(prevProps: Readonly<AllProps>) {
        if (prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate) {
            this.setState({
                startMoment: this.props.startDate ? moment(this.props.startDate, 'MM/DD/YYYY h:mm:ss') : null,
                endMoment: this.props.endDate ? moment(this.props.endDate, 'MM/DD/YYYY h:mm:ss') : null,
            });
        }

        if (prevProps.open && !this.props.open && !this.props.editPosting) {
            this.clearForm();
        }

        if (this.props.currentCategory !== prevProps.currentCategory) {
            this.getCategoryEntities(this.props.currentCategory);
        }
    }

    createReminders = () => {
        let reminders = [...this.state.reminders];
        reminders.push(
            <Reminder
                endDate={this.momentToString(this.state.endMoment)}
                inputChange={this.inputChange}
                index={reminders.length}
                dropDownChange={this.handleReminderDropDown}
            />
        );
        this.setState({reminders})
    };

    handleFieldChange = (e: any, value: any) => {
        if (e.target.name.toString() === 'postingTitle') {
            this.setState({
                postingTitle: value.value,
                titleError: false,
            });
        }
        this.setState({[e.target.name.toString()]: value.value} as any);
    };

    handleDateChange = (name: string, date: Date) => {
        if (name === 'startMoment') {
            this.setState({
                startMoment: moment(date),
                startError: false,
            });
        } else if (name === 'endMoment') {
            this.setState({
                endMoment: moment(date),
                endError: false,
            })
        }
    };

    handleReminderDropDown = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let reminderDurations = [...this.state.reminderDurations];
        reminderDurations[data.index] = data.value as string;
        this.setState({reminderDurations})
    };

    inputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        let inputValues = [...this.state.inputValues];
        inputValues[data.index] = +data.value;
        this.setState({inputValues})
    };

    momentToString = (m: Moment | null) => {
        let format: string = 'MM/DD/YYYY hh:mm a';
        return m ? m.format(format) : '';
    };

    momentToDate = (m: Moment | null) => {
        return m ? m.toDate() : null;
    };

    getCategoryEntities = (category: string) => {
        let entities: DropdownItemProps[] = [];
        if (category === 'RA') {
            entities = this.props.userEntities
                .filter((entity: any) => (['GEN', 'PSE', 'LRE', 'OC', 'SE', 'MP'].includes(entity.entTypNm)))
                .map((entity: any) => ({
                    key: entity.id,
                    text: entity.respEntTypeNm,
                    value: entity.respEntTypeNm
                }));
        } else if (category === 'MDWG') {
            entities = this.props.userEntities
                .filter((entity: any) => (['GEN', 'PSE', 'LRE', 'OC', 'SE', 'MP'].includes(entity.entTypNm) && !entity.respEntNm.includes('-')))
                .map((entity: any) => ({
                    key: entity.id,
                    text: entity.respEntTypeNm,
                    value: entity.respEntTypeNm
                }));
        }
        this.setState({entities});
    };

    validatePosting = () => {
        let title: boolean = this.state.postingTitle === '';
        let start: boolean = (this.state.startMoment === null);
        let end: boolean = (this.state.endMoment === null);
        let entity: boolean = this.state.entityCheck && this.state.selectedEntities.length === 0;

        if (title || start || end || entity) {
            this.setState({
                titleError: title,
                startError: start,
                endError: end,
                entityError: entity,
            });
        } else { /* if all entities are selected, this the same as not selecting any entities */
            if (this.state.selectedEntities.length === this.state.entities.length) {
                this.setState({selectedEntities: []});
            }
        }

        return (title || start || end || entity);
    };

    clearForm = () => {
        this.setState({
            postingTitle: '',
            action: '',
            //category: this.props.category,
            startMoment: this.props.startDate ? moment(this.props.startDate, 'MM/DD/YYYY h:mm:ss') : null,
            endMoment: this.props.endDate ? moment(this.props.endDate, 'MM/DD/YYYY h:mm:ss') : null,
            entityCheck: false,
            selectedEntities: [],
            reminders: [],
            milestoneFlag: false
        });
    };

    submitPostings = () => {
        if (this.validatePosting()) {
            return;
        }

        let reminders:ReminderInterface[] = this.state.reminders.map((value:any,index:number) => ({
            duration:this.state.inputValues[index],
            durationType:this.state.reminderDurations[index]
        }));

        let posting = {
            posting: this.state.postingTitle,
            action: this.state.action,
            category: this.props.currentCategory,
            startDate: this.momentToString(this.state.startMoment),
            endDate: this.momentToString(this.state.endMoment),
            postingEntityList: this.state.postingEntities,
            createdId: this.props.currentUser,
            lastUpdateId: this.props.currentUser,
            reminders: reminders,
            milestoneFlag: this.state.milestoneFlag
        };

        this.clearForm();

        let formData = new FormData();
        formData.set('posting', JSON.stringify(posting));
        axios.post('/api/auth/postings', formData).then(response => {
            this.props.toggle(response.data);
        }).catch(response => {
            toast.error(`Error occurred adding a new posting - ${response.message}`)
        });
    };

    updatePostings = () => {
        if (this.validatePosting()) {
            return;
        }

        let reminders:ReminderInterface[] = this.state.reminders.map((value:any,index:number) => ({
            duration:this.state.inputValues[index],
            durationType:this.state.reminderDurations[index]
        }));
        //todo: this code is awful
            this.props.post.posting = this.state.postingTitle
            this.props.post.action = this.state.action
            this.props.post.startDate = this.momentToString(this.state.startMoment)
            this.props.post.endDate = this.momentToString(this.state.endMoment)
            this.props.post.postingEntityList = this.state.postingEntities
            this.props.post.lastUpdateId = this.props.currentUser
            this.props.post.reminders = reminders
            this.props.post.selectEntity =  this.state.entityCheck
            this.props.post.milestoneFlag = this.state.milestoneFlag

        let formData = new FormData();
        formData.set('posting', JSON.stringify(this.props.post));
        axios.put('/api/auth/postings', formData)
            .then(response => {
                this.props.post.reminders = response.data.reminders;
                this.props.toggle(response.data);
                this.setState({reminders: []});
                let reminders = [...this.state.reminders];
                let reminderDurations = [...this.state.reminderDurations];
                let inputValues = [...this.state.inputValues];

                this.props.post.reminders.forEach((reminder: any, index: number) => {
                    reminders.push(
                        <Reminder
                            endDate={this.momentToString(this.state.endMoment)}
                            inputChange={this.inputChange}
                            index={index}
                            dropDownChange={this.handleReminderDropDown}
                            defaultDurationSelection={reminder.duration}
                            defaultDurationTypeSelection={reminder.durationType}
                        />
                    );
                    reminderDurations[index] = reminder.durationType;
                    inputValues[index] = +reminder.duration;
                });
                this.setState({
                    reminders: reminders,
                    reminderDurations: reminderDurations,
                    inputValues: inputValues
                });
            })
            .catch(response => {
                toast.error(`Error occurred adding a new posting - ${response.message}`)
            });
    };

    toggleCheckBox = () => {
        this.setState({entityCheck: !this.state.entityCheck});
    };

    getStaffRole = () => {
        return !!this.props.roles.find((role) => {
            return role.sppStaff === true;
        });
    };

    toggleMilestoneCheckBox = () => {
        this.setState({milestoneFlag: !this.state.milestoneFlag})
    }

    showEntitySelect = () => {
        return (
            this.state.entityCheck ?
                <Dropdown
                    multiple={true}
                    selection={true}
                    search={true}
                    name={'entity'}
                    upward={false}
                    options={this.state.entities}
                    onChange={this.entityDropDownChange}
                    error={this.state.entityError}
                    value={this.state.selectedEntities}
                />
                : null
        )
    };

    entityDropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (data.value) {
            let dataMap = data.value as string[];
            let postingEntities: PostingEntity[] = dataMap.map(v => {
                let postingEntity = find(this.state.entities,{value:v});
                return ({entityId:postingEntity.key,entityNameType:v})
            });
            this.setState({selectedEntities: data.value as string[],postingEntities})
        }
    };

    render() {
        let prettyCategory = '';
        if (this.props.currentCategory === 'MDWG') {
            prettyCategory = 'Modeling Development';
        }
        if (this.props.currentCategory === 'RA') {
            prettyCategory = 'Resource Adequacy';
        }

        return (
            <Modal open={this.props.open} closeOnDimmerClick={true} closeIcon={true}
                   onClose={this.props.onClose}>
                <Modal.Header>{ (this.props.editPosting) ? 'Edit' : 'Create new'} posting for {prettyCategory}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input name={'postingTitle'} label={'Posting Title'} error={this.state.titleError}
                                    value={this.state.postingTitle} required={true}
                                    labelPosition={'left'} onChange={this.handleFieldChange}/>
                        <Grid>
                            <Grid.Column stretched={true} width={8}>
                                    <Form.Group>
                            <Form.Field label={'Start Date'} control={DatePicker} error={this.state.startError}
                                        name={'startMoment'} required={true} minDate={moment().toDate()}
                                        maxDate={this.momentToDate(this.state.endMoment)}
                                        onChange={(date: Date) => this.handleDateChange('startMoment', date)}
                                        selected={this.momentToDate(this.state.startMoment)}
                                        dateFormat={'MM/dd/yyyy'}/>

                            <Form.Field label={'End Date'} control={DatePicker} error={this.state.endError}
                                        name={'startMoment'} required={true}
                                        minDate={this.state.startMoment !== null ?
                                            this.momentToDate(this.state.startMoment)
                                            : moment().toDate()}
                                        onChange={(date: Date) => this.handleDateChange('endMoment', date)}
                                        selected={this.momentToDate(this.state.endMoment)}
                                        dateFormat={'MM/dd/yyyy'}/>
                                    </Form.Group>
                            </Grid.Column>
                            <Grid.Column verticalAlign={"middle"} width={8}>
                            <Form.Checkbox label={'Milestone Flag'} onChange={this.toggleMilestoneCheckBox} defaultChecked={this.state.milestoneFlag}/>
                            </Grid.Column>

                        </Grid>
                        {(this.props.currentCategory === 'RA' ? !this.state.isStaff : true) && <Checkbox label={'Select Entities'} onChange={this.toggleCheckBox}/>}
                        <br/>
                        {(this.props.currentCategory === 'RA' ? !this.state.isStaff : true) && this.showEntitySelect()}
                        <Form.Input name={'action'} label={'Action'} labelPosition={'left'}
                                    value={this.state.action} onChange={this.handleFieldChange}/>
                        <div/>
                        <Button size={'mini'} color={'black'} onClick={this.createReminders}>Add reminders</Button>
                        <div style={{paddingBottom: '10px'}}/>
                        {this.state.reminders}
                    </Form>
                </Modal.Content>
                <Modal.Actions>

                    {
                        ( this.props.editPosting) ?
                            <Button size={'mini'} color={'black'} onClick={this.updatePostings}>Update</Button>
                            : <Button size={'mini'} color={'black'} onClick={this.submitPostings}>Submit</Button>
                    }
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory,
        userEntities: state.authReducer.userEntities,
        roles: state.authReducer.roles
    }
};

export default connect<PropsFromState, {}, OwnProps>(mapStateToProps, {})(PostingModal);