import * as React from 'react';
import {Button, Icon, Menu, Segment, Table} from 'semantic-ui-react';
import axios from "axios";
import PostingModal from "./PostingModal";
import {CommentsContainer} from "./CommentsContainer";
import {toast} from "react-toastify";
import moment from 'moment';
import {connect} from 'react-redux';
import CategoryDropDown from "../common/CategoryDropDown";

interface PostingsProps {
    currentUser: string,
    sppStaff: boolean,
    userEntities: string[],
    currentCategory: string
    hideDropDownOnHome?: boolean
}

interface PostingsState {
    data: any,
    creatingPosting: boolean,
    loading: boolean,
    grabbedPostings: boolean,
    recordTypes: string[],
    currentRecordTab: string,
    currentPage: number,
    pageSize: number
}

const orderBy = require('lodash/orderBy');
class Postings extends React.Component<PostingsProps, PostingsState> {
    constructor(props: PostingsProps, state: PostingsState) {
        super(props, state);
        this.state = {
            data: [],
            creatingPosting: false,
            loading: false,
            grabbedPostings: false,
            recordTypes: [],
            currentRecordTab: 'Capacity Adjustments',
            currentPage: 1,
            pageSize: 5
        };
    }

    componentDidMount(): void {
        // The slight delay of wrapping in 0 MS setTimeout is enough for this.props.currentCategory to load.
        //  Otherwise, when the page first loads, no category will be available and no postings will be retrieved.
        setTimeout(this.getPostings, 0);
    }

    componentDidUpdate(prevProps: Readonly<PostingsProps>, prevState: Readonly<PostingsState>, snapshot?: any): void {

        if (prevProps.currentCategory !== this.props.currentCategory && !this.state.loading) {
            // if loading is true, componentDidMount is already running this code
                this.getPostings();
        }

        if (prevProps.currentCategory !== this.props.currentCategory) {
            this.setState({currentPage: 1});
        }
    }

    togglePostingModal = () => {
        this.setState({creatingPosting: !this.state.creatingPosting})
    };

    getLastPage = (posts: number, pageSize: number) => {
        let fullPages = Math.trunc(posts / pageSize);
        let partialPage = posts % pageSize;

        if (partialPage !== 0) {
            fullPages++;
        }
        return fullPages;
    };

    addPosting = (posting: any) => {
        if ((moment(posting.startDate, 'MM/DD/YYYY hh:mm a').isSameOrBefore(moment(), 'day'))
            && (moment(posting.endDate, 'MM/DD/YYYY hh:mm a').isSameOrAfter(moment(), 'day'))) {
            this.setState((prevState) => {
                return {
                    creatingPosting: false,
                    currentPage: this.getLastPage(prevState.data.length + 1, this.state.pageSize),
                    data: [...prevState.data, posting],
                }
            });
        } else {
            if(posting.id){
                this.setState((prevState) => {
                    return {
                        creatingPosting: false,
                        currentPage: this.getLastPage(prevState.data.length + 1, this.state.pageSize),
                        data: [...prevState.data, posting],
                    }
                });
            } else{
                 this.setState({creatingPosting: false});
            }
        }
    };

    getPostings = () => {
        if (!this.props.currentCategory) {
            return;
        }
        axios.get(`/api/auth/postingsByCategory/${this.props.currentCategory}`).then((response) => {
            if (response.data.length > 0) {
                this.setState({data: response.data});
            }
            this.setState({loading: false});
        }).catch((error) => {
            if (error.message) {
                if(error.message === 'Request failed with status code 500') {
                    toast.error('Connection failure - Postings');
                } else {
                    toast.error(`Error retrieving postings from the server - ${error.message}`)
                }
            }
            this.setState({loading: false});
        });
        this.setState({loading: true});
    };

    sort = (event:any) => {
        let data = [...this.state.data];
        data = orderBy(data,[event.currentTarget.dataset.name],['asc']);
        this.setState({data});
    };

    displayPostings = () => {
        if ((this.state.data.length === 0)) {
            return;
        }
        let startIndex = (this.state.currentPage - 1) * this.state.pageSize;
        return (
            this.state.data.slice(startIndex,startIndex + this.state.pageSize).map((posting: any) =>
                <React.Fragment key={posting.id}>
                    <Table.Row style={{backgroundColor: posting.milestoneFlag? 'rgba(250,158,0,0.5)': null}}>
                        <Table.Cell>{posting.posting}</Table.Cell>
                        <Table.Cell>{posting.action}</Table.Cell>
                        <Table.Cell>{moment(posting.startDate, 'MM/DD/YYYY hh:mm a').format('MM/DD/YYYY')}</Table.Cell>
                        <Table.Cell>{moment(posting.endDate, 'MM/DD/YYYY hh:mm a').format('MM/DD/YYYY')}</Table.Cell>
                    </Table.Row>
                    <CommentsContainer posting={posting} postingId={posting.id}  currentUser={this.props.currentUser}
                                       onPostEdit={this.updatePost} onDeletePost={this.removePost} isAdmin={this.props.sppStaff}/>
                </React.Fragment>
            )
        )
    };

    removePost = (data: any) =>{
        // @ts-ignore
        let index = this.state.data.findIndex(p => p.id === data.postingId);
        let stateData = this.state.data;
        if (index > -1) {
            stateData.splice(index, 1);
        }
        this.setState({data: stateData});
    };

    updatePost = (updatedPost: any) => {
        // @ts-ignore
        let index = this.state.data.findIndex(p => p.id === updatedPost.id);
         let data = this.state.data;
        data[index] = updatedPost;
        this.setState({data: data});
    };

    updateRecordTypes = (recordTypes: string[]) => {
        this.setState({recordTypes: recordTypes});
    };

    updateCurrentRecordTab = (currentRecordTab: string) => {
        this.setState({currentRecordTab: currentRecordTab});
    };

    incrementPage = () => {
      this.setState((prevState) => {
          return ({currentPage: prevState.currentPage+1})
      });
    };

    decrementPage = () => {
        this.setState((prevState) => {
            return ({currentPage: prevState.currentPage-1})
        });
    };

    updateCurrentPage = (event:any) => {
        this.setState({currentPage:+event.currentTarget.text});
    };

    buildPager = () => {
        let numberOfPages = this.state.data.length / this.state.pageSize;
        let pager = [];
        for (let i = 0; i < numberOfPages; i++) {
            pager.push(
                <Menu.Item as='a' color={'grey'}
                           active={this.state.currentPage === (i + 1)}
                           onClick={this.updateCurrentPage}
                           key={'menu_item_page_' + (i+1)}
                >
                    {i + 1}
                </Menu.Item>)
        }
        return pager;
    };

    public render() {

        return (
            <Segment loading={this.state.loading} style={{paddingTop:'50px'}}>
                <PostingModal
                    open={this.state.creatingPosting}
                    onClose={this.togglePostingModal}
                    currentUser={this.props.currentUser}
                    toggle={this.addPosting}
                />
                {!this.props.hideDropDownOnHome &&
                    <div style={{float: 'right', marginBottom: '10px'}}>
                        <CategoryDropDown
                            recordTypes={this.state.recordTypes}
                            updateRecordTypes={this.updateRecordTypes}
                            currentRecordTab={this.state.currentRecordTab}
                            updateCurrentRecordTab={this.updateCurrentRecordTab}
                        />
                    </div>
                }
                {
                    (this.props.sppStaff) ?
                        <Button floated={"left"} size={'mini'} color={'black'} content={'Add New'}
                                onClick={this.togglePostingModal}/>
                        : null
                }
                <Table celled={false} striped={true} collapsing={false}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Posting
                            </Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                            <Table.HeaderCell>Start Date</Table.HeaderCell>
                            <Table.HeaderCell>End Date</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.displayPostings()}
                    </Table.Body>

                    <Table.Footer fullWidth={true}>
                        <Table.Row textAlign={"center"}>
                            <Table.HeaderCell colSpan={'4'}>
                                <Menu pagination={true}>
                                    <Menu.Item
                                        as='a'
                                        icon={true}
                                        disabled={this.state.currentPage === 1}
                                        onClick={this.decrementPage}
                                        key={'menu_item_left'}
                                    >
                                        <Icon name='chevron left' />
                                    </Menu.Item>
                                    {this.buildPager()}
                                    <Menu.Item
                                        as='a'
                                        icon={true}
                                        onClick={this.incrementPage}
                                        disabled={this.state.currentPage >= (this.state.data.length / this.state.pageSize)}
                                        key={'menu_item_right'}
                                    >
                                        <Icon name='chevron right'/>
                                    </Menu.Item>
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Segment>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        sppStaff: state.authReducer.userRolesEntities.sppStaff,
        userEntities: state.defaultReducer.userEntities,
        currentCategory: state.defaultReducer.currentCategory
    }
};

export default connect(mapStateToProps)(Postings);