import {Checkbox, CheckboxProps} from 'semantic-ui-react';
import {SubmitEntData} from '../../../interfaces/submissions-interface';

interface SubmittingEntitySelectionProps {
    entity: SubmitEntData,
    onSelect: (submitEnt: SubmitEntData, checked: boolean) => void
}

export const SubmittingEntitySelection = (props: SubmittingEntitySelectionProps) => {
    const onChange = (e: any, data: CheckboxProps) => {
        if (data.checked !== undefined) {
            props.onSelect(data.data as SubmitEntData, data.checked);
        }
    };

    return (
        <li>
            <Checkbox onChange={onChange} data={props.entity} label={<label>{props.entity.submitEntNm}</label>}/>
        </li>
    );
};