import * as React from 'react';
import {Button, Dimmer, Form, Header, Icon, Loader, Modal, ModalProps, Segment, Select} from "semantic-ui-react";
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";

interface ContactModalProps extends ModalProps {
}

interface ContactModalState {
    cid: number
    id: number
    name: string
    stakeholderNm: any
    phone: string
    email: string
    contactType: string
    extension: string
    categoryName: string
    stakeholderDropdownVals: any[]
    contactDropdownVals: any[]
    categoryDropdownVals: any[]
    contactData: any[]
    existingContact: boolean
    existingRoles: any[]
    nameError: boolean,
    stakeholderId: number,
    categoryId: number
    submitButtonDisabled: boolean
    loading: boolean
    areaCode: string,
    prefix: string,
    lineNumber: string


}

const contactTypeDropdowns = [
    { key: 'Primary', text: 'Primary', value: 'Primary'},
    { key: 'Secondary', text: 'Secondary', value: 'Secondary'},
    { key: 'Tertiary', text: 'Tertiary', value: 'Tertiary'},
    { key: 'Alternate', text: 'Alternate', value: 'Alternate'}
];

const contactCategoryDropdowns = [
    { key: 1, text: 'Compliance', value: 'Compliance'},
    { key: 2, text: 'Dynamics', value: 'Dynamics'},
    { key: 3, text: 'GMD', value: 'GMD'},
    { key: 4, text: 'MOD33', value: 'MOD33'},
    { key: 5, text: 'Powerflow', value: 'Powerflow'},
    { key: 6, text: 'Short Circuit', value: 'Short Circuit'}
];

const initialState = {
                        cid: 0,
                        id: 0,
                        name: '',
                        stakeholderNm: [],
                        phone: '',
                        email: '',
                        contactType: '',
                        extension: '',
                        categoryName: '',
                        stakeholderDropdownVals: [],
                        contactDropdownVals: [],
                        categoryDropdownVals: [],
                        contactData: [],
                        contactNames: [],
                        existingContact: false,
                        existingRoles: [],
                        nameError: false,
                        stakeholderId: 0,
                        categoryId: 0,
                        submitButtonDisabled: true,
                        loading: false,
                        areaCode: '',
                        prefix: '',
                        lineNumber: ''

                    }

class ContactModal extends React.Component<ContactModalProps,ContactModalState> {
    constructor(props: ContactModalProps) {
        super(props);
        this.state = initialState
    }

    formatDropdownOptions = (data:any) =>{
        let dropDownItems: any = []
        data.forEach((s:any) => {
            dropDownItems.push({key: s.stakeholderId, text: s.stakeholderNm, value: s.stakeholderNm})
        });
        return dropDownItems;
    }

    formatCustomerDropdownOptions = (data:any) =>{
        let dropDownItems: any = []
        data.forEach((c:any) => {
            if(c.name) {
                dropDownItems.push({key: c.customerId, text: c.name, value: c.name})
            }
        });
        return dropDownItems;
    }

    getStakeholderDropdownOptions = () =>{
        axios.get('/api/auth/contact/stakeholder/dropdown')
            .then(resp => {
                if (resp.data) {
                    let stakeholderVals: any[] = this.formatDropdownOptions(resp.data);
                    // return stakeholderVals;
                    this.setState({stakeholderDropdownVals: stakeholderVals})
                }
            })
            .catch(() => {
                toast.error('Error loading stakeholder options.');
            });
    }

    getContactDropdownOptions = () =>{
        axios.get('/api/auth/contact/contacts').then(resp => {
            if (resp.data) {
                let contactVals: any[] = this.formatCustomerDropdownOptions(resp.data);
                this.setState({contactDropdownVals: contactVals, contactData: resp.data});
            }
        }).catch(() => {
            toast.error('Error loading contacts.');
        });
    }

    handleSubmitClick = () => {
        if(this.props.addOrModifyContact === 'Modify') {
            this.modifyContactInfo();
        } else {
            this.addContact();
        }
    }

    handleCancelClick = () => {
        this.closeModalCleanup();
    }

    closeModalCleanup = () => {
        //set state back to empty so that a blank form is opened next time they click Add Contact
        this.setState(initialState)
        //reset redux store values to the initial
        this.props.updateContactModalActive(false);
        this.props.updateAddOrModifyContact('');
        this.props.updateCurrentRow({});

    }

    modifyContactInfo = () => {
        let formData = new FormData();
        formData.set('cid', this.state.cid.toString());
        formData.set('name', this.state.name);
        formData.set('phone', `${this.state.areaCode}-${this.state.prefix}-${this.state.lineNumber}`);
        formData.set('email', this.state.email);
        formData.set('extension', this.state.extension);
        axios.post('/api/auth/contact/contact/update', formData)
            .then(response => {
                if (response.status === 200) {
                    toast.success('Contact information updated successfully!')
                    this.setState({submitButtonDisabled: true})
                }
            })
            .catch(error => {
                toast.error(`Error while updating contact info - ${error.message}`)
            });
    }

    addContact = () => {
        let formData = new FormData();
        formData.set('name', this.state.name);
        formData.set('phone', `${this.state.areaCode}-${this.state.prefix}-${this.state.lineNumber}`);
        formData.set('email', this.state.email);
        formData.set('extension', this.state.extension);
        axios.post('/api/auth/contact/contact/add', formData)
            .then(response => {
                let responseArray: any[] = response.data.split(' ');
                let cid: number = parseInt(responseArray[responseArray.length - 1]);
                this.setState({cid: cid})
                toast.success('Contact information updated successfully!');
            })
            .catch(error => {
                toast.error(`Error occurred adding a new contact - ${error.message}`)
                this.closeModalCleanup();
            });
    }

    handleFormChange = (e: any, { value, name}: any) => {
        // @ts-ignore
        this.setState({[name]: value});
        if (name === 'name') {
            if(value) {
                // If it is an existing contact, set state values with their info so it gets displayed in modal
                // this.state.contactData.map((c: any) => {
                for(let i = 0; i < this.state.contactData.length; i++){
                    if (this.state.contactData[i].name === value) {
                        this.setState({phone: this.state.contactData[i].phone, email: this.state.contactData[i].email, id: this.state.contactData[i].contactId, cid: this.state.contactData[i].contactId, existingContact: true});
                        break;
                    }else {
                        // If contact doesn't exist, set state values to their default, empty value
                        this.setState({phone: '', email: '', areaCode:'', prefix: '', lineNumber: '', id: 0, cid: 0, existingContact: false});
                    }
                }
            } else {
                // If contact doesn't exist, set state values to their default, empty value
                this.setState({phone: '', email: '', areaCode:'', prefix: '', lineNumber: '', id: 0, cid: 0, existingContact: false});
            }
            //Updates state values when role information is updated so the ID can be sent to API
        } else if(name === 'stakeholderNm') {
            this.state.stakeholderDropdownVals.forEach((s:any) => {
                if(s.value === value){
                    this.setState({stakeholderId: s.key});
                }
            });
            //Updates state values when category name is updated so the ID can be sent to the API
        } else if(name === 'categoryName'){
            contactCategoryDropdowns.forEach((c:any) => {
                if(c.value === value){
                    this.setState({categoryId: c.key});
                }
            });
            //Update state value when type name is updated so name can be sent to the API
        } else if(name === 'contactType'){
            this.setState({contactType: value});
        }
    }


    componentDidMount(): void {
        this.getStakeholderDropdownOptions();
        this.getContactDropdownOptions();
    }

    formatPhoneComponents = () =>{
        if(this.state.phone) {
            let splitPhone = this.state.phone.split("-")
            this.setState({areaCode: splitPhone[0], prefix: splitPhone[1], lineNumber: splitPhone[2]})
        }
    }

    componentWillReceiveProps(nextProps: any, nextState:any) {
        // this will run every time the props change - and possibly in addition to this, so we need to check for prop changes
        if (this.props.currentRow !== nextProps.currentRow) {
            this.setState(nextProps.currentRow);
            //We have to do this because componentDidMount doesn't run on every display of modal
            this.getStakeholderDropdownOptions();
            this.getContactDropdownOptions();
        }

    }

    componentDidUpdate(prevProps: Readonly<ContactModalProps>, prevState: Readonly<ContactModalState>, snapshot?: any): void {
        if(prevState.cid !== this.state.cid && this.state.cid !== 0){
            //If we changed the selected contact we need to get the roles, phone components etc, and set them in state so their
            //information gets displayed in the modal
            this.getExistingRoles();
            this.formatPhoneComponents();
            this.setState({existingContact: true, stakeholderNm: [], contactType: '', categoryName: ''})
        } else {
            //If any of the data has changed and all required fields are completed, activate the submit button
            if(prevState.name !== this.state.name || prevState.areaCode !== this.state.areaCode || prevState.prefix !== this.state.prefix || prevState.lineNumber !== this.state.lineNumber || prevState.extension !== this.state.extension || prevState.email !== this.state.email){
                if(this.state.name && this.state.areaCode && this.state.prefix && this.state.lineNumber && this.state.email) {
                    this.setState({submitButtonDisabled: false});
                }
            }
        }

    }

    handleAddition = (e:any, { value }:any) => {
        //This handles adding a new value to the Contact Name dropdown
        this.setState((prevState) => ({
            contactDropdownVals: [{ text: value, value }, ...prevState.contactDropdownVals],
        }))
    }

    addRole = () => {
        let formData = new FormData();
        formData.set('cid', this.state.cid.toString());
        formData.set('sid', this.state.stakeholderId.toString());
        formData.set('contactType', this.state.contactType);
        formData.set('category', this.state.categoryId.toString());
        axios.post('/api/auth/contact/category/add', formData)
            .then(response => {
                this.getExistingRoles();
                this.setState({stakeholderNm: '', contactType: '', categoryName: ''})
                if (response.data.toString().includes('already has this role:')) {
                    toast.error(`User role already exists.`);
                }
            })
            .catch(error => {
                toast.error(`Error occurred adding a new role - ${error.message}`)
                this.closeModalCleanup();
            });
    };

    getExistingRoles = () =>{
        //Gets all existing roles populates JSX and pushes to an array that is used to display all roles in the modal
        this.setState({loading: true});
        axios.get(`/api/auth/contact/category/contact/${this.state.cid}`)
            .then(response => {
                if (response.status === 200) {
                    let element: any[] = []
                    response.data.forEach((r: any) => {
                        element.push(this.createRole(r.stakeholderNm, r.contactType, r.categoryName, r.id))
                    });
                    this.setState({existingRoles: element});
                }
            })
            .catch(response => {
                toast.error(`Error occurred adding a new posting - ${response.message}`)
                this.closeModalCleanup();
            })
            .finally(() => {
                this.setState({loading: false});
            });
    }

    createRole = (stakeholderNm: string, contactType: string, categoryName: string, roleId: number ) =>{
        //JSX for each role. Gets called for each role and added to an array to display multiple roles in the modal
        return(
        <Form.Group key={roleId}>

                <Form.Input fluid={true}
                    // label={{children: 'Stakeholder Name', htmlFor: 'form-select-control-type'}}
                            placeholder='Stakeholder Name'
                            searchInput={{id: 'form-select-control-type'}}
                            value={stakeholderNm}
                            name={'stakeholderNm'}
                            width={12}
                            readOnly={true}
                />
                <Form.Input fluid={true}
                            placeholder='Type'
                            searchInput={{id: 'form-select-control-type'}}
                            value={contactType}
                            name={'contactType'}
                            width={4}
                            readOnly={true}
                />
                <Form.Input fluid={true}
                            placeholder='Category'
                            searchInput={{id: 'form-select-control-type'}}
                            value={categoryName}
                            name={'categoryName'}
                            width={6}
                            readOnly={true}
                />
            <Form.Button icon={true} color={'red'} onClick={this.deleteRole} index={roleId}>

                <Icon name='delete'/>

            </Form.Button>
        </Form.Group>

        )
    }

    deleteRole = (e: any, {index}: any) =>{
        axios.delete(`/api/auth/contact/category/${index}`)
            .then(response => {
                if (response.status === 200) {
                    this.getExistingRoles();
                }
            })
            .catch(error => {
                toast.error(`Error occurred adding a new posting - ${error.message}`)
                this.closeModalCleanup();
            });

    }

    public render(){
        return (
            <Modal size={"large"} open={this.props.contactModalActive}>
                <Modal.Header>
                    {this.props.addOrModifyContact === 'Add' ? 'Add Contact/Manage Roles' : 'Modify Contact/Manage Roles'}
                </Modal.Header>
                <Modal.Content>
                    <div className={'addContactDiv'}>

                        <Form>
                            {this.state.loading?
                                <div>
                                    <Dimmer inverted={true} active={true}>
                                        <Loader inverted={true} content='Loading' />
                                    </Dimmer>
                                </div>: null }

                            <Segment>
                                <Header>Contact Info</Header>
                            {this.props.addOrModifyContact === 'Modify' ?
                                <Form.Input fluid={true} selection={true} label='Contact Name' entryType={'string'}
                                            className={'projectNameInput'} placeholder={'Contact Name'}
                                            onChange={this.handleFormChange}
                                            clearable={true}
                                            name={'name'} value={this.state.name}
                                            searchInput={{id: 'form-select-control-type'}}
                                            id={'newName'}
                                            error={this.state.nameError}

                                /> :
                                <Form.Dropdown required={true} fluid={true} selection={true}
                                    // control={Dropdown}
                                               options={this.state.contactDropdownVals}
                                               label={{children: 'Contact Name', htmlFor: 'form-select-control-type'}}
                                               placeholder='Select or add contact name...'
                                               searchInput={{id: 'form-select-control-type'}}
                                               search={true}
                                               allowAdditions={true}
                                               onAddItem={this.handleAddition}
                                               onChange={this.handleFormChange}
                                               value={this.state.name}
                                               name={'name'}
                                               entryType={'select'}
                                               clearable={true}
                                />
                            }

                            <Form.Group>

                                <Form.Input fluid={true} label='Area Code' entryType={'string'} className={'phone'}
                                            placeholder={'501'}
                                            onChange={this.handleFormChange}
                                            name={'areaCode'}
                                            value={this.state.areaCode}
                                            width={4}
                                            readOnly={this.props.addOrModifyContact === 'Add' && this.state.existingContact}
                                />

                                <Form.Input fluid={true} label='Prefix' entryType={'string'} className={'phone'}
                                            placeholder={'867'}
                                            onChange={this.handleFormChange}
                                            name={'prefix'}
                                            value={this.state.prefix}
                                            width={4}
                                            readOnly={this.props.addOrModifyContact === 'Add' && this.state.existingContact}
                                />
                            <Form.Input fluid={true} label='Line Number' entryType={'string'} className={'phone'}
                                        placeholder={'5309'}
                                        onChange={this.handleFormChange}
                                        name={'lineNumber'}
                                        value={this.state.lineNumber}
                                        width={8}
                                        readOnly={this.props.addOrModifyContact === 'Add' && this.state.existingContact}
                            />
                            <Form.Input fluid={true} label='Ext.' entryType={'string'} className={'extension'}
                                        placeholder={'Extension'}
                                        onChange={this.handleFormChange}
                                        name={'extension'}
                                        value={this.state.extension}
                                        width={4}
                                        readOnly={this.props.addOrModifyContact === 'Add' && this.state.existingContact}
                            />
                            </Form.Group>

                            <Form.Input fluid={true} label='Email Address' entryType={'string'} className={'email'}
                                        placeholder={'Email Address'}
                                        onChange={this.handleFormChange}
                                        name={'email'}
                                        value={this.state.email}
                                        readOnly={this.props.addOrModifyContact === 'Add' && this.state.existingContact}
                            />
                                <Modal.Actions>
                                    {this.props.addOrModifyContact === 'Add' && !this.state.existingContact ?
                                        <div>
                                            < Button content={'Add Contact'} color={'blue'} onClick={this.handleSubmitClick}
                                                     disabled={this.state.submitButtonDisabled}/>
                                            <Button content={'Cancel'} color={'red'} onClick={this.handleCancelClick}/>
                                        </div>: null
                                    }
                                    {this.props.addOrModifyContact === 'Modify' ?
                                        <div>
                                            < Button content={'Submit Changes'} color={'blue'} onClick={this.handleSubmitClick}
                                                     disabled={this.state.submitButtonDisabled}/>
                                            <Button content={'Cancel'} color={'red'} onClick={this.handleCancelClick} disabled={this.state.submitButtonDisabled}/>
                                        </div>: null
                                    }

                                </Modal.Actions>

                            </Segment>

                            {this.state.existingContact?
                                <Segment>
                                    <Header>Contact Roles</Header>
                                    <Segment>
                                        <Header as={'h4'}>Add Role</Header>
                            <Form.Group>


                                    <Form.Input required={true} fluid={true} selection={true}
                                                control={Select}
                                                options={this.state.stakeholderDropdownVals}
                                                // label={{children: 'Stakeholder Name', htmlFor: 'form-select-control-type'}}
                                                placeholder='Stakeholder Name'
                                                searchInput={{id: 'form-select-control-type'}}
                                                onChange={this.handleFormChange}
                                                value={this.state.stakeholderNm}
                                                name={'stakeholderNm'}
                                                width={12}
                                                search={true}
                                    />
                                            <Form.Input required={true} fluid={true} selection={true}
                                                        control={Select}
                                                        options={contactTypeDropdowns}
                                                        // label={{children: 'Contact Type', htmlFor: 'form-select-control-type'}}
                                                        placeholder='Type'
                                                        searchInput={{id: 'form-select-control-type'}}
                                                        onChange={this.handleFormChange}
                                                        value={this.state.contactType}
                                                        name={'contactType'}
                                                        width={4}
                                            />
                                    <Form.Input required={true} fluid={true} selection={true}
                                                control={Select}
                                                options={contactCategoryDropdowns}
                                                // label={{children: 'Category', htmlFor: 'form-select-control-type'}}
                                                placeholder='Category'
                                                searchInput={{id: 'form-select-control-type'}}
                                                onChange={this.handleFormChange}
                                                value={this.state.categoryName}
                                                name={'categoryName'}
                                                width={6}
                                    />

                                <Form.Button icon={true} color={'green'} onClick={this.addRole} disabled={!this.state.stakeholderId || !this.state.categoryId || !this.state.contactType}>

                                            <Icon name='add' />

                                </Form.Button>
                            </Form.Group>
                                    </Segment>
                                    {this.state.existingRoles.length?
                                        <div>
                                    <Segment>
                                        <Header as={'h4'}>Delete Roles</Header>
                                    {this.state.existingRoles}
                                    </Segment>
                                        </div>: null}
                                </Segment>

                                : null}

                        </Form>

                    </div>
                </Modal.Content>
                    <Modal.Actions>
                        <Button content={'Done'} color={'blue'} onClick={this.handleCancelClick}/>
                    </Modal.Actions>
            </Modal>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        contactModalActive: Boolean(state.contactReducer.contactModalActive),
        addOrModifyContact: String(state.contactReducer.addOrModifyContact),
        currentRow: state.defaultReducer.currentRow

    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateContactModalActive: (contactModalActive: boolean) => dispatch(
            {type: actionTypes.SET_CONTACTMODALACTIVE, payload: contactModalActive}
        ),
        updateAddOrModifyContact: (addOrModifyContact: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYCONTACT, payload: addOrModifyContact}
        ),
        updateCurrentRow: (currentRow: boolean) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}
        ),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);