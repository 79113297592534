import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';

// Disabled prop should be true if grid is in edit or verify mode.
interface ValidatePanelProps {
    disabled: boolean;
    validate: () => void;
    loading?: boolean;
}

const ValidatePanel: React.FC<ValidatePanelProps> = ({ disabled, validate, loading = false }) => {
    return (
        <>
            <AppBarSection>
                <Button onClick={validate} disabled={disabled}>
                    Validate
                    {loading && <Loader size="small" type="pulsing" />}
                </Button>
            </AppBarSection>
            <AppBarSpacer style={{ width: 4 }} />
        </>
    );
};

export default ValidatePanel;
