import axios from "axios";
import {toast} from "react-toastify";

export default class ResadqService {

    getReFromSe = (submittingEntity:string) => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/entity/getRespEntNames`, {params:{
                submitEntNm: submittingEntity
            }}).then(({status, data}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error('Error retrieving Submitting Entities');
                }
            })
        })
    };

    getSubmittingEntities = (resource:string) => {
        let url = `/api/auth/reports/submittingentities/${resource}`;
        if (resource === 'CapacityAdjustments' || resource === 'DemandAndEnergy') {
            url = '/api/auth/dropdown/ResAdqSubmitEntity';
        }
        return new Promise<any[]>((resolve, reject) => {
            axios.get(url).then( ({status,statusText,data}) => {
                if (status === 200) {
                    resolve(data)
                } else {
                    toast.error('Error retrieving Submitting Entities');
                }
            })
        })
    };

    getSubmissionsData = (submittingEntity:string,changesetOrBasecase:string,tab:string,descriptor:string) => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/submissions/getData`, {params: {
                submitEntityName: submittingEntity,
                actionDesc: changesetOrBasecase,
                tabName: tab,
                desc: descriptor
            }}).then(({status, data,statusText}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error retrieving data for ${descriptor} - ${statusText}`);
                }
            }).catch(({message}) => {
                toast.error(`Error retrieving data for ${descriptor} - ${message}`);
            })
        })
    };

    saveSubmissionsData = (records:any[], resource:string) => {
        return new Promise((resolve) => {
            axios.post('/api/auth/saveChangesetData', {
                changesetData: records,
                tabNm: resource
            }).then(response => {
                if (response.data === 'Success') {
                    toast.success(`Changeset was saved successfully`);
                    resolve(response.data);
                } else {
                    throw new Error(response.data);
                }
            }).catch((error) => {
                toast.error(`Error saving changeset for ${resource} - ${error.message}`);
            })
        })
    };

    getSubmittalYears = () => {
        return new Promise<any[]>((resolve, reject) => {
            axios.get(`/api/auth/reports/subYears`).then(({status,statusText,data}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error('Error retrieving Submittal Year');
                }
            })
        })
    };

    getReportData = (groupName:string,subGroupName:string, submitEntityName:string, subYearId:number, season:string) => {
        return new Promise<any>((resolve, reject) => {
            let reportDataParams: any = {
                groupName: groupName,
                subGroupName: subGroupName,
                submitEntityName: submitEntityName,
                subYearId: subYearId,
                season: season
            }
            axios.get(`/api/auth/reports/reportData`, {params: reportDataParams}).then(({status, statusText, data}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error retrieving submittal year for ${subGroupName}- ${statusText}`);
                }
            });
        });
    }

};