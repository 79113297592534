import React, {useEffect, useState} from 'react';
import { Container, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import PurchasesSalesGrid from './PurchasesSalesGrid';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';

const PurchasesSalesPage: React.FC<GridPageProps> = ({ roles }) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);

    useEffect(() => {
        setPageRole(getCompressedPageRole('PurchasesSales', roles));
    }, [roles]);

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size="large">Purchases & Sales</Header>
            <AccordionWrapper accordionHeader={'Purchases & Sales Summer'}>
                <PurchasesSalesGrid
                    {...pageRole}
                    title='Purchases & Sales Summer'
                    dataApi='/api/auth/ra/purchases-sales/summer'
                    season='Summer'
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Purchases & Sales Winter'}>
                <PurchasesSalesGrid
                    {...pageRole}
                    title='Purchases & Sales Winter'
                    dataApi='/api/auth/ra/purchases-sales/winter'
                    season='Winter'
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(PurchasesSalesPage);
