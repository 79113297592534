import axios from "axios";
import {toast} from "react-toastify";

let fileDownload = require('js-file-download');
export default class ChangesetService {

    getUserChangesets = () => {
        return new Promise<any[]>((resolve, reject) => {
            axios.get(`/api/auth/userChangesets`).then(({status,data,statusText}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error retrieving user changeset data - Status = ${status}, Status Text = ${statusText}`);
                }
            }).catch(({message}) => {
                if(message) {
                    if (message === 'Request failed with status code 500') {
                        reject(toast.error('Connection failure - Changeset Details'));
                    } else {
                        reject(toast.error(`Error retrieving user changeset data - ${message}`));
                    }
                }
            })
        })
    };

    actOnChangeset = (changesetNumber:number,changesetStatus:string,resource:string) => {
        return new Promise((resolve) => {
            axios.post(`/api/auth/actOnChangeset`, {
                changesetNumber: changesetNumber,
                status: changesetStatus,
                resource: resource
            }).then(({status,data}) => {
                // data in this case will be the response from the spChangesetWorkflow proc
                if (status === 200 && data === '') {
                    toast.success(`Changeset ${changesetNumber} was successfully updated to ${changesetStatus}`);
                } else {
                    toast.error(`Error updating changeset - ${data}`);
                }
                resolve(data);
            })
        });
    };

    downloadExcel = () => {
        axios.get(`/api/auth/changeset/historyExport`, {responseType: 'arraybuffer'})
            .then(resp => {
                fileDownload(resp.data, 'ChangesetHistory.xlsx');
            })
            .catch(resp => {
                toast.error(`Error exporting table data - ${resp.message}`);
            });
    };

    getChangesetByNumber = (changesetNumber:number) => {
        return new Promise((resolve) => {
            axios.get(`/api/auth/changeset/${changesetNumber}`,{
                withCredentials:true
            }).then(({status,data,statusText}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error retrieving changeset - ${statusText}`)
                }
            })
        })
    };

    checkNoChange(finalData: any[], finalBaseCaseData: any[], metaData: any[], resource: string): Promise<any> {
        /** This method is used check for any changes made to a record in the transactions changeset. This will allow the
         * No Change indicator to display appropriately in the changeset grid. Using a promise to make sure we don't move
         * on to populating the changeset grid and No Change indicators prior to completing this check. Checking if
         * resource is transactions, and if not, returning finalData as the response.*/

        return new Promise((resolve) => {
            let updatedFinalData: any[] = [];
            if(resource === 'Transactions' && finalData.length) {
                finalData.forEach(row => {
                    row['noChange'] = true;
                    let compareRow = finalBaseCaseData.filter(e => {
                        return e.pK === row.pK && parseInt(e.mirrorRec) === row.mirrorRec
                    });
                    let compareKeys = Object.keys(row);
                    if (compareRow.length) {
                        for (let i = 0; i < compareKeys.length; i++) {
                            if (compareKeys[i] !== 'confAckInd' && compareKeys[i] !== 'respAckInd' && compareKeys[i] !== 'mirrorRec' && compareKeys[i] !== 'action' && compareKeys[i] !== 'disp') {
                                let editInd = metaData.filter(r => {
                                    return r.nm.toUpperCase() === compareKeys[i].toUpperCase()
                                });

                                //Was bombing out when editInd was empty. This check resolves that.
                                if (editInd.length) {
                                    if ((editInd[0]['editInd'] !== undefined && parseInt(editInd[0]['editInd']) === 1) ||(editInd[0]['staffEditInd'] !== undefined && editInd[0]['staffEditInd'])) {
                                        let rowVal = row[compareKeys[i]];
                                        let compareRowVal = compareRow[0][compareKeys[i]];

                                        //Set all empty strings, undefined, and 0 values to null for the comparison
                                        if (rowVal === '' || rowVal === undefined || rowVal === 0) {
                                            rowVal = null;
                                        }
                                        if (compareRowVal === '' || compareRowVal === undefined || compareRowVal === 0) {
                                            compareRowVal = null;
                                        }
                                        if(rowVal !== compareRowVal){
                                            row['noChange'] = false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    updatedFinalData.push(row);
                });
            } else {
                updatedFinalData = finalData;
            }
            resolve(updatedFinalData);
        })
    }

}
