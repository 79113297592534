import axios from "axios";
import {toast} from "react-toastify";

export default class DropDownService {

    buildSearchParams = (path:string,cellInfo:any) => {
        let pathArray = path.split('{');
        const params = new URLSearchParams();

        if (path.includes('=')) {
            pathArray.forEach((p,index) => {
                if (index !== 0) {
                    let key = p.split('=')[0];
                    let accessorValue = p.split('=')[1].replace('}','');
                    let cellValue = cellInfo[accessorValue] === '' ? '0' : cellInfo[accessorValue];
                    params.append(key,cellValue);
                    }
            });
        } else {
            pathArray.forEach((p,index) => {
                if (index !== 0) {
                    let accessorValue = p.replace('}','');
                    let cellValue = cellInfo[accessorValue] === '' ? '0' : cellInfo[accessorValue];
                    params.append(accessorValue,cellValue);
                    }
            });
        }

        return params;

    };

    getDropDownValues = (path: string,cellInfo: any) => {
        let pathArray = path.split('{');
        const params = this.buildSearchParams(path,cellInfo);

        return new Promise<any[]>((resolve, reject) => {
            axios.get(`${pathArray[0]}`,  { params,
                withCredentials: true
            }).then(({status, data, statusText}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error retrieving drop-down values, Status = ${status}, Status Text = ${statusText}`);
                }
            }).catch(({message}) => {
                reject(toast.error(`Error retrieving user changeset data - ${message}`));
            })
        })
    };
}