import * as actionTypes from '../actions/actionTypes';
import initialState from "./initialState";

export default function changeSetReducer(state = initialState.changeSetState, action:any){
    switch(action.type) {
        case actionTypes.UPDATE_CURRENT_CHANGESET:
            return {
                ...state,
                currentChangeset: action.payload
            };
        case actionTypes.UPDATE_SUBMITTING_ENTITY:
            return {
                ...state,
                submittingEntity: action.payload
            };
        case actionTypes.UPDATE_CHANGESET_OR_BASECASE:
            return {
                ...state,
                changesetOrBasecase: action.payload
            };
        case actionTypes.ADD_ROWS_TO_CHANGESET:
            return {
                ...state,
                rowsToAddToChangeset: action.payload
            };
        case actionTypes.REMOVE_ROW_FROM_CHANGESET:
            return {
                ...state,
                rowToRemoveFromChangeset: action.payload
            };
        case actionTypes.UPDATE_CHANGESET_PENDING_SAVE:
            return {
                ...state,
                changeSetPendingSave: action.payload
            };
        case actionTypes.SET_CHANGESET_ROW_UPDATED:
            return {
                ...state,
                changeSetRowUpdated: action.payload
            };
        case actionTypes.SET_USER_IN_AUTHOR_ENTITY_GROUP:
            return {
                ...state,
                userInAuthorEntityGroup: action.payload
            };
        case actionTypes.SET_CHANGESET_AUTHOR_IS_MEMBER:
            return {
                ...state,
                changeSetAuthorIsMember: action.payload
            };
        case actionTypes.INCREMENT_CHANGESET_VERSION:
            return {
                ...state,
                changesetVersion: state.changesetVersion + 1
            };
        case actionTypes.RESET_CHANGESET_VERSION:
            return {
                ...state,
                changesetVersion: 1
            };
    }
    return state;
};


