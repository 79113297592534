import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import PlantsGrid from './PlantsGrid';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';

const PlantsPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    
    useEffect(() => {
        setPageRole(getCompressedPageRole('Plants', roles));
    }, [roles]);
    
    return (
        <Container fluid={true} style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '50px'}}>
            <Header size={'large'}>Plants</Header>
            <PlantsGrid {...pageRole}/>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(PlantsPage);