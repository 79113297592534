import {ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent} from '@progress/kendo-react-dropdowns';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Grid} from 'semantic-ui-react';

export interface DropDownOption {
    text: string,
    id: string | number
}

interface SubmittingEntityDropdownProps {
    submitEntId?: number,
    setSubmitEntId: (submitEntId: number | undefined) => void
}

interface SubmitEntData {
    submitEntId: number,
    submitEntNm: string
}

export const SubmittingEntityDropdown: React.FC<SubmittingEntityDropdownProps> = ({submitEntId, setSubmitEntId}) => {
    const [submittingEntityList, setSubmittingEntityList] = useState<Array<DropDownOption>>([]);
    const [dataValue, setDataValue] = useState<DropDownOption | undefined>();
    const [responsibleEntityList, setResponsibleEntityList] = useState<Array<string>>([]);
    const [filteredEntityList, setFilteredEntityList] = useState<Array<DropDownOption>>([]);

    useEffect(() => {
        axios
            .get('/api/auth/ra/submissions/dropdown/submitting-entities')
            .then((resp) => {
                const entities = resp.data.map((item: SubmitEntData) => ({
                    id: item.submitEntId,
                    text: item.submitEntNm,
                }));
                setSubmittingEntityList([{id: '', text: 'Select Submitting Entity...'}, ...entities]);
                setFilteredEntityList([{id: '', text: 'Select Submitting Entity...'}, ...entities]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        let newDataValue = submittingEntityList.find((option) => option.id === submitEntId)
        if (newDataValue) {
            setDataValue(newDataValue);
        } else {
            setDataValue({id: '', text: ''});
        }
        if (submitEntId) {
            const params = new URLSearchParams();
            params.set('submitEntId', submitEntId.toString());
            axios.get('/api/auth/ra/submissions/responsible-entities', {params}).then((resp) => {
                setResponsibleEntityList(resp.data);
            });
        }
    }, [submittingEntityList, submitEntId]);

    const filterChange = (event: ComboBoxFilterChangeEvent) => {
        const value = event.filter.value;
        const filteredOptions = value === ''
            ? submittingEntityList
            : submittingEntityList.filter((option) =>
                option.text.toLowerCase().includes(value.toLowerCase())
            );
        setFilteredEntityList(filteredOptions);
    };

    const onChange = (event: ComboBoxChangeEvent) => {
        const { value } = event.target;
        if (value === null || value === undefined) {
            setSubmitEntId(undefined);
            setDataValue(undefined);
        } else {
            const selectedValue = value.id;
            setDataValue(value);
            setSubmitEntId(selectedValue);
        }
    };

    return (
        <>
            <ComboBox
                data={filteredEntityList}
                textField="text"
                dataItemKey="id"
                value={dataValue}
                placeholder='Search or Select Submitting Entity...'
                filterable={true}
                onFilterChange={filterChange}
                onChange={onChange}
                style={{height: 'auto', width: '400px', maxHeight: '200px'}}
            />
            <br />
            <br />
            {submitEntId && (
                <>
                    <label><b>Responsible Entities:</b></label>
                    <Grid padded={true} columns={5}>
                        {responsibleEntityList.map((entity, index) => (
                            <Grid.Column key={index}>
                                <span>{entity}</span>
                            </Grid.Column>
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};





