const initialState = {
    defaultState: {
        currentTab: '',
        currentCategory: '',
        errorMessage: '',
        userType:'',
        subYear: 0,
        notificationCount: 0,
        activeMenuItem: ''
    },
    authState: {
        currentUser: localStorage.getItem("userName"),
        userToken: '',
        roles: localStorage.getItem("roles") !== null
            ? JSON.parse(localStorage.getItem("roles")!.toString())
            : [],
        userEntities:  localStorage.getItem("userEntities") !== null
            ? JSON.parse(localStorage.getItem("userEntities")!.toString()): [],
        userRolesEntities: localStorage.getItem("userRolesEntities") !== null
            ? JSON.parse(localStorage.getItem("userRolesEntities")!.toString()): {}
    },
    changeSetState: {
        currentChangeset: {
            status: '',
            name: '',
            number: 0,
            comments: ''
        },
        submittingEntity: '',
        changesetOrBasecase: '',
        rowsToAddToChangeset: [],
        rowToRemoveFromChangeset: 0,
        changeSetPendingSave: false,
        changeSetRowUpdated: false,
        userInAuthorEntityGroup: false,
        changeSetAuthorIsMember: false,
        changesetVersion: 1,
    },
    baseCaseState:{
        changeSetSubYearId: 0,
        basecaseVersion: 1
    },
    contactState:{
        contactModalActive: false,
        addOrDeleteContact: '',
        stakeholderModalActive: false,
        deleteModalActive: false,
        currentRow: {},
        stakeholderCurrentRow: {},
        deleteScreen: ''
    },
    entityManagementState:{
        entityModalActive: false,
        currentEntityRow: {},
        addOrModifyEntity: '',
        allEntities: []
    },
    rolloverState:{
        rolloverModalActive: false,
    }
};

export default initialState;