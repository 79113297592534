import * as React from 'react';
import {Button,Modal, ModalProps} from "semantic-ui-react";
import * as actionTypes from "../../store/actions/actionTypes";
import {connect} from "react-redux";
// import axios from "axios";

interface DeleteModalProps extends ModalProps {
}

interface DeleteModalState {
    deleteRecord: any

}

const initialState = {
                        deleteRecord: {}

                    };

class DeleteModal extends React.Component<DeleteModalProps,DeleteModalState> {
    constructor(props: DeleteModalProps) {
        super(props);
        this.state = initialState
    }
/*
if(this.props.addOrModifyContact === 'Modify') {
            this.modifyContactInfo();
 */
    handleSubmitClick = () =>{
        //TODO: Add an admin service that will check the delete screen and run the approriate delete action
        console.log('submit was clicked')
    };

    handleCancelClick = () => {
        //set state back to empty so that a blank form is opened next time they click Add Contact
        this.setState(initialState);
        this.props.updateDeleteModalActive(false);
        this.props.updateDeleteScreen('');
    };

    deleteMessage = () => {
            if(this.props.deleteScreen === 'Contact') {
                return (<div>Are you sure you want to delete <b>{this.props.currentRow.contactName}</b> as
                    the <b>{this.props.currentRow.contactType}</b> contact
                    for <b>{this.props.currentRow.category}</b> at <b>{this.props.currentRow.stakeholderNm}</b>?</div>
                )
            } else if (this.props.deleteScreen === 'SubRespRel'){
                return (<div>Are you sure you want to remove <b>{this.props.currentRow.submitEntLongName}</b> as
                        the submitting entity for <b>{this.props.currentRow.respEntLongName}</b>?</div>
                )
            } else if (this.props.deleteScree === 'Stakeholder') {


                return (
                    <div>Are you sure you want to delete <b>{this.props.currentRow.stakeholderNm}</b> from the
                        stakeholder table?</div>

                )
            }
            return

    };

    public render(){
        return (
            <Modal size={"large"} open={this.props.deleteModalActive}>
                <Modal.Header>
                    Confirm Delete
                </Modal.Header>
                <Modal.Content>
                    <div className={'addContactDiv'}>
                        {this.deleteMessage()}
                    </div>
                </Modal.Content>
                <Modal.Actions>

                    <Button content={'Yes'} color={'blue'} onClick={this.handleSubmitClick}/>

                    <Button content={'No'} color={'red'} onClick={this.handleCancelClick}/>

                </Modal.Actions>
            </Modal>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        deleteModalActive: Boolean(state.defaultReducer.deleteModalActive),
        currentRow: state.defaultReducer.currentRow,
        deleteScreen: state.defaultReducer.deleteScreen,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDeleteModalActive: (deleteModalActive: boolean) => dispatch(
            {type: actionTypes.SET_DELETEMODALACTIVE, payload: deleteModalActive}
        ),
        updateCurrentRow: (currentRow: boolean) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}
        ),
        updateDeleteScreen: (deleteScreen: boolean) => dispatch(
            {type: actionTypes.SET_DELETESCREEN, payload: deleteScreen}
        ),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);