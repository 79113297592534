import React from 'react';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {Button, ButtonGroup} from '@progress/kendo-react-buttons';

// Disabled is optional prop that defaults to false. Should be enabled if grid is in verify mode.
// Edit mode and verify mode are mutually exclusive.
interface SavePanelProps {
    inEdit: boolean,
    disabled?: boolean,
    save: () => void,
    toggleEdit: () => void
}

const SavePanel: React.FC<SavePanelProps> = (props: SavePanelProps) => {
    const disabled = !!props.disabled;
    
    return (
        <>
            <AppBarSection>
                {props.inEdit ? (
                    <ButtonGroup>
                        <Button onClick={props.save}>Save</Button>
                        <Button onClick={props.toggleEdit}>Cancel</Button>
                    </ButtonGroup>
                ) : (
                    <Button onClick={props.toggleEdit} disabled={disabled}>Edit</Button>
                )}
            </AppBarSection>
            <AppBarSpacer style={{width: 4}} />
        </>
    );
};

export default SavePanel;
