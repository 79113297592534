import * as React from 'react';
import {Container, Header, Segment} from "semantic-ui-react";

interface AccessDeniedProps {

}

interface AccessDeniedState {

}

class AccessDenied extends React.Component<AccessDeniedProps, AccessDeniedState> {
    constructor(props: AccessDeniedProps, state: AccessDeniedState) {
        super(props, state);
        this.state = {
        };
    }

    public render() {

        return (
            <Container fluid={true}>
                <Segment textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
                    <Header as={'h1'} content={'Access Denied'} color={'red'} />
                    <div color={'red'}>
                            It appears you do not have appropriate permissions to view this page.  Please submit an RMS ticket if you believe this message to be in error.
                    </div>
                </Segment>
            </Container>
        )
    }
}

export default AccessDenied;