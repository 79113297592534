import * as React from 'react';
import {Container, Grid} from 'semantic-ui-react';
import Postings from "../Comment/Postings";
import {connect} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import {toast} from "react-toastify";
import GeneralService from "../../services/general-services";
import MdAndRaHomeWrapper from "./MdAndRaHomeWrapper";

interface HomeState {
    notificationCount: number
}

interface HomeProps {
    notificationCount: number,
    updateNotificationCount: (notificationCount: number) => void,
    updateActiveMenuItem: (activeMenuItem: string) => void,
}
const generalService = new GeneralService();

class Home extends React.Component<HomeProps, HomeState>{
    constructor(props: HomeProps, state: HomeState) {
        super(props, state);
        this.state = {
            notificationCount: 0
        }
    }

    componentDidMount(): void {
        this.getUnreadNotificationCount();
        this.props.updateActiveMenuItem('home');
    }

    getUnreadNotificationCount = () => {
        generalService.getNotificationCountRequest()
            .then((resp: any) => {
                this.props.updateNotificationCount(resp);
                this.setState({notificationCount: resp});
            }).catch(resp => {
            toast.error(`Error retrieving unread notification count - ${resp.message}`);
        })
    }

    public render() {
        return (
            <Container fluid={true} textAlign={"center"} style={{paddingTop:'50px'}}>
                <Grid columns={"equal"}>
                    <Grid.Row style={{paddingLeft:'20px',paddingRight:'20px'}}>
                        <Grid.Column>
                            <Postings hideDropDownOnHome={true} />
                        </Grid.Column>
                        <Grid.Column>
                            <MdAndRaHomeWrapper/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        notificationCount: state.defaultReducer.notificationCount,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateNotificationCount: (notificationCount: number) => dispatch(
            {type: actionTypes.UPDATE_NOTIFICATION_COUNT, payload: notificationCount}),
        updateActiveMenuItem: (activeMenuItem: string) => dispatch(
            {type: actionTypes.UPDATE_ACTIVE_MENU_ITEM, payload: activeMenuItem}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
