import * as React from 'react';
import {Icon, List, Segment} from "semantic-ui-react";
import axios from "axios";
import GeneralService from "../../services/general-services";
import {toast} from "react-toastify";
const fileDownload = require('js-file-download');

interface FooterProps {
}

const client = new GeneralService();

class Footer extends React.Component<FooterProps> {
    constructor(props: FooterProps) {
        super(props);
        this.setLoginStatus = this.setLoginStatus.bind(this);
        this.getUserManual = this.getUserManual.bind(this);
        this.showInformation = this.showInformation.bind(this);
    }

    setLoginStatus = (loginStatus: string | boolean | null) => {
        if (loginStatus === null) {
            return false;
        } else if (typeof loginStatus === 'string') {
            return (loginStatus === 'true');
        } else
            return loginStatus;
    };

    getUserManual = () => {
        axios.post('/api/auth/referenceDocument/downloadRefDoc',null, { params : {filename:'EDST User Guide'},
            withCredentials: true,
            responseType: 'arraybuffer'
        })
            .then(resp => {
                let extension = null;
                if(resp.headers['content-type'] === 'application/pdf' || resp.headers['content-type'] === 'application/octet-stream'){
                    extension = '.pdf';
                }
                else if (resp.headers['content-type'] === 'application/doc' || resp.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                {
                    extension = '.docx';
                }
                fileDownload(resp.data, 'EDST User Guide'+extension)
            }).catch(resp => {
            toast.error(`Error retrieving File from server - ${resp.message}`);
        })
    };

    showInformation = () => {
        const segStyle = {border: 'none'};
        const linkStyle = {
            background: 'none', 
            border: 'none', 
            color: '#4183c4', 
            cursor: 'pointer',
            margin: '0px',
            padding: '0px'
        };
        return (
            <Segment textAlign={'center'} style={segStyle}>
                <List divided={true} horizontal={true} relaxed={'very'}>
                    <List.Item><Icon name={'mail'}/><a href={'mailto:EDSTSupportRA@spp.org'}>Resource Adequacy</a></List.Item>
                    <List.Item><Icon name={'mail'}/><a href={'mailto:EDSTSupportMDWG@spp.org'}>Model Development</a></List.Item>
                    <List.Item><Icon name={'comments'}/><a href='https://spprms.issuetrak.com' target={'blank'}>SPP RMS</a></List.Item>
                    <List.Item><Icon name={'book'}/><button style={linkStyle} onClick={this.getUserManual}>User Manual</button></List.Item>
                </List>
            </Segment>

        );
    };

    render() {
        const segStyle = {border: 'none'};
        return (
            <Segment.Group style={segStyle}>
                {
                    client.loggedIn(true) ? this.showInformation() : null
                }
                <Segment textAlign={'center'} style={segStyle}>
                    <p style={{textAlign:'center'}}><Icon name={'copyright'}/>2019 Southwest Power Pool, Inc. All rights reserved</p>
                    <p style={{textAlign:'center'}}>Confidential Information Authorized Access Only</p>
                </Segment>
            </Segment.Group>
        );
    }
}
export default Footer;