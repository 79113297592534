import React from 'react';
import {Button, Modal, Table} from 'semantic-ui-react';

interface BulkUploadValidationMessageProps {
    modalActive: boolean,
    openModal: () => void,
    closeModal: () => void,
    validationErrors: {
       dataSet: string,
       changesetId: number,
       recordKey: number,
       validationName: string,
       validationMessage: string 
    }[],
    confirmAction: () => void,
    uploadPending: boolean
}

const BulkUploadValidationMessage: React.FC<BulkUploadValidationMessageProps> = (props): React.ReactElement => {
    let closeButtonText = props.uploadPending ? 'Cancel Upload' : 'Close';
    let triggerButtonText = props.uploadPending ? 'Complete Pending Upload' : 'Display Upload Validations';
    
    return (
        <Modal 
            open={props.modalActive} 
            onClose={props.closeModal} 
            trigger={<Button onClick={props.openModal} size={'mini'} content={triggerButtonText} color={props.uploadPending ? 'red' : 'orange'}/>}
        >
            <Modal.Header>Upload Validation Messages</Modal.Header>
            <Modal.Content>
                {props.uploadPending 
                    ? <p>The upload changes have not yet been applied to the changeset. Click <strong>Continue Upload</strong> to finish the upload.</p> 
                    : <p>Upload is complete and resulted in the following validations. Save changeset to keep changes.</p>
                }
                
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Changeset ID</Table.HeaderCell>
                            <Table.HeaderCell>Record Key</Table.HeaderCell>
                            <Table.HeaderCell>Validation Message</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {props.validationErrors.map(v => (
                        <Table.Row>
                            <Table.Cell>{v.changesetId}</Table.Cell>
                            <Table.Cell>{v.recordKey}</Table.Cell>
                            <Table.Cell>{v.validationMessage}</Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                {props.uploadPending 
                    ? <Button onClick={props.confirmAction} content={"Continue Upload"} positive={true}/> 
                    : null
                }
                <Button onClick={props.closeModal} content={closeButtonText} positive={!props.uploadPending} negative={props.uploadPending}/>
            </Modal.Actions>
        </Modal>
    );
};

export default BulkUploadValidationMessage;