import * as React from "react";
import {Principal} from "../../auth";
import {/*Dropdown, */DropdownItemProps, DropdownProps} from "semantic-ui-react";
import * as actionTypes from "../../store/actions/actionTypes";
import {connect} from "react-redux";

interface CategoryDropDownProps {
    updateCurrentCategory: (category: string) => void,
    currentCategory: string,
    currentUser: string,
    roles: Principal[],
    recordTypes?: string[],
    currentRecordTab?: string,
    updateRecordTypes: (recordTypes: string[]) => void,
    updateCurrentRecordTab: (currentRecordTab: string) => void,
}

interface CategoryDropDownState {
    categoryOptions:DropdownItemProps[]
    currentCategory:string
}

const find = require('lodash/find');
const includes = require('lodash/includes');
class CategoryDropDown extends React.Component<CategoryDropDownProps,CategoryDropDownState> {
    constructor(props: CategoryDropDownProps, state: CategoryDropDownState) {
        super(props, state);
        this.state = {
            categoryOptions:[],
            currentCategory:'',
        };
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories = () => {
        let categoryOptions: DropdownItemProps[] = [];
        this.props.roles.forEach(role => {
            if (role.cat === 'ModDev' && find(categoryOptions,{key:'Modeling Development'}) === undefined) {
                categoryOptions.push({key:'Modeling Development',text:'Modeling Development',value:'Modeling Development'})
            } else if (role.cat === 'ResAdq' && find(categoryOptions,{key:'Resource Adequacy'}) === undefined) {
                categoryOptions.push({key: 'Resource Adequacy', text: 'Resource Adequacy', value: 'Resource Adequacy'})
            }
        });
        this.setState({categoryOptions});
        if(categoryOptions.length === 0) { // no categories, reset the category to empty string
            this.setRecordTypes('');
        } else if (categoryOptions.length > 1 && this.props.currentCategory) { // remember last selected category
            if (this.props.currentCategory === 'MDWG') {
                this.setRecordTypes('Modeling Development');
            } else if (this.props.currentCategory === 'RA') {
                this.setRecordTypes('Resource Adequacy');
            }
        } else { // select the only option
            this.setRecordTypes(categoryOptions[0].value as string);
        }
    };

    categoryChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setRecordTypes(data.value as string);
    };

    setRecordTypes = (currentCategory: string) => {
        this.setState({currentCategory});
        if (currentCategory === '') {
            this.props.updateCurrentCategory('');
            return;
        }
        // Use Set then create array from set to sort. That takes care of duplicates.
        let recordSet = new Set<string>();
        this.props.roles.forEach(role => {
            if (currentCategory === 'Modeling Development' && (role.cat === 'ModDev' || (role.cat === 'Ref' && includes(['Bus Details', 'Load Details'], role.visNm)))) {
                recordSet.add(role.visNm);
                this.props.updateCurrentCategory('MDWG');
            } else if (currentCategory === 'Resource Adequacy' && role.cat === 'ResAdq'
                    && includes(['Capacity Adjustments','Demand and Energy','Purchases & Sales','Plants','Resources','Resource Ownership','Generator Test Results'],role.visNm)) {
                if (role.visNm === 'Demand and Energy') {
                    recordSet.add('Demand Side Management Submissions');
                } else {
                    recordSet.add(role.visNm);
                }
                this.props.updateCurrentCategory('RA');
            }
        });
        if (recordSet.size > 0) {
            let recordTypes = Array.from(recordSet).sort();
            this.props.updateRecordTypes(recordTypes);
            this.props.updateCurrentRecordTab(recordTypes[0]);
        }
    };

    public render() {
        return (
            null
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory,
        currentUser: state.authReducer.currentUser,
        roles: state.authReducer.roles
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentTab: (currentTab: string) => dispatch (
            {type: actionTypes.UPDATE_CURRENT_TAB, payload: currentTab}
        ),
        updateCurrentCategory: (category: string) => dispatch(
            {type: actionTypes.UPDATE_EDST_CATEGORY, payload: category})
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(CategoryDropDown);