import * as React from 'react';
import {Button, Dropdown, DropdownItemProps, DropdownProps, Grid, Header, Icon} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import axios from "axios";
import {AccordionSegment} from "../common/AccordionSegment";
import {toast} from "react-toastify";
import {getReactTable, setPercentageTypes} from "../common/ReactTableUtil";
import ResadqService from "../../services/resadq-services";
import GridUtil from "../common/GridUtil";
import ReactTableHOC from "../common/EdstGrid";
import format from 'date-fns/format';
import {connect} from 'react-redux';
import {getRaActiveSummerSubyear, getRaCurrentSeasonSubyear} from "../../services/ra-report-service";

interface DeliverabilityStudyProps {
    sppStaff: boolean
}

interface DeliverabilityStudyState {
    availabilitySummaryActive: boolean,
    resourceAvailabilityActive: boolean,
    submittingEntities: any,
    seDropDownLoading: boolean,
    submittalYears: any,
    submittalYearsLoading: boolean
    entity: string
    submittalYear: number
    seasonOptions: any
    season: string
    disabledSeason: boolean
    creatingReport: boolean
    DeliverableCapacity: any[]
    DeliverableCapacityMetaData: any[]
    DeliverableCapacityLoading: boolean
    DeliverabilityStudyDetails: any[]
    DeliverabilityStudyDetailsMetaData: any[]
    DeliverabilityStudyDetailsLoading: boolean
    DeliverabilityStudyResults: any[]
    DeliverabilityStudyResultsMetaData: any[]
    DeliverabilityStudyResultsLoading: boolean
    hideContent: boolean
    exporting: boolean
    missingSubmittalYear: boolean
    missingEntity: boolean
    missingSeasonType: boolean
    toastSubmittingEntityId:number
    toastSubmittalYear:number
    toastReportTypeId:number
    toastseason: number
    currentSubYear: number
    batchReportProgress: any
    batchReportProgressLoading: boolean
    intervalId: any
    hasBatchReportFailed: boolean
    hasCheckedForBatchReportProgress: boolean
    currentSeason: string
    reportGenerated: boolean
}

const client = new ResadqService();
let fileDownload = require('js-file-download');
class DeliverabilityStudy extends React.Component<RouteComponentProps<{}> & DeliverabilityStudyProps,DeliverabilityStudyState> {
    constructor(props: RouteComponentProps<{}> & DeliverabilityStudyProps, state: DeliverabilityStudyState) {
        super(props, state);
        this.state = {
            availabilitySummaryActive: true,
            resourceAvailabilityActive: true,
            submittingEntities: [],
            seDropDownLoading: true,
            submittalYears: [],
            submittalYearsLoading: true,
            entity:'',
            submittalYear:0,
            season: '',
            seasonOptions: [
                { key: 'Summer', text: 'Summer', value: 'Summer' },
                { key: 'Winter', text: 'Winter', value: 'Winter' },
            ],
            disabledSeason: false,
            creatingReport: false,
            DeliverableCapacity: [],
            DeliverableCapacityMetaData: [],
            DeliverableCapacityLoading: false,
            DeliverabilityStudyDetails: [],
            DeliverabilityStudyDetailsMetaData: [],
            DeliverabilityStudyDetailsLoading: false,
            DeliverabilityStudyResults: [],
            DeliverabilityStudyResultsMetaData: [],
            DeliverabilityStudyResultsLoading: false,
            hideContent: true,
            exporting: false,
            missingEntity:false,
            missingSubmittalYear:false,
            missingSeasonType: false,
            toastSubmittalYear:0,
            toastSubmittingEntityId:0,
            toastReportTypeId:0,
            currentSubYear: 0,
            toastseason:0,
            batchReportProgress: {},
            batchReportProgressLoading: false,
            intervalId: null,
            hasBatchReportFailed: false,
            hasCheckedForBatchReportProgress: false,
            currentSeason: '',
            reportGenerated: false
        };
    }

    componentDidMount(): void {
        this.getSubmittingEntities();
        this.getSubmittalYear();
        this.getCurrentYearSeason();
        this.getBatchReportProgress();
    }

    componentWillUnmount(): void {
        if (this.state.intervalId != null) {
            clearInterval(this.state.intervalId);
        }
    }

    getCurrentYearSeason = () => {
        getRaCurrentSeasonSubyear()
            .then((resp) => {
                this.setState({currentSubYear: resp.data});
                getRaActiveSummerSubyear()
                    .then((response) => {
                        let currentSeason = resp.data === response.data ? 'Summer' : 'Winter';
                        this.setState({currentSubYear: resp.data, currentSeason});
                    });
            })
            .catch((error) => {
                console.log(error);
            })
    };

    getDeliverabilityStudyTariff = () => {
        let subGroupName = 'DeliverabilityStudyResults';
        axios.get(`/api/auth/reports/deliverabilityStudyTariff/${this.state.entity}/${this.state.submittalYear}/${this.state.season}`)
            .then(response => {
                this.setState({
                    [subGroupName]: response.data.data,
                    [`${subGroupName}MetaData`]: setPercentageTypes(response.data.columnMetaDataList),
                    [`${subGroupName}Loading`]: false
                } as any);
            })
            .catch(error => {
                this.setState({
                    [`${subGroupName}Loading`]: false
                } as any);
                toast.error(`Error retrieving Deliverability Study data for DeliverabilityStudyTariff - ${error.message}`);
            });
        this.setState({DeliverabilityStudyResultsLoading: true});
    };

    getDeliverabilityStudyData = (subGroupName: string) => {
        let reportDetails = {
            groupName: 'DeliverabilityStudyResults',
            subGroupName: subGroupName,
            submitEntityName: this.state.entity,
            subYearId: this.state.submittalYear,
            season: this.state.season
        }
        axios.get(`/api/auth/reports/reportDataDsr`, {params:reportDetails}).then(resp => {
            this.setState({
                [subGroupName]: resp.data.data,
                [`${subGroupName}MetaData`]: setPercentageTypes(resp.data.columnMetaDataList),
                [`${subGroupName}Loading`]: false
            } as any);
        }).catch(resp => {
            this.setState({
                [`${subGroupName}Loading`]:false
            } as any);
            toast.error(`Error retrieving Deliverability Study data for ${subGroupName} - ${resp.message}`);
        });
        this.setState({
            [`${subGroupName}Loading`]:true
        } as any);
    };

    getSubmittingEntities = () => {
        client.getSubmittingEntities('DeliverabilityStudy')
            .then((data:any[]) => {
                let submittingEntities: DropdownItemProps[] = data.map((se:any) => ({key:se,text:se,value:se}));
                this.setState({submittingEntities,seDropDownLoading:false});
            });
    };

    getSubmittalYear = () => {
        client.getSubmittalYears()
            .then((data:any[]) => {
                let submittalYears: DropdownItemProps[] = data.map((se:any) => ({key:se, text:se, value:parseInt(se)}));
                this.setState({submittalYears,submittalYearsLoading:false});
            });
        this.setState({submittalYearsLoading: true});
    };

    entityChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        await this.setState({entity:data.value as string, reportGenerated: false});
        this.clearToastMessages();
    };

    yearChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let submittalYear: number = data.value as number;
        let {season} = this.state;
        let disabledSeason: boolean;
        
        if (submittalYear <= 2022 || submittalYear > this.state.currentSubYear) {
            season = 'Summer';
            disabledSeason = true;
        } else {
            disabledSeason = false;
        }

        await this.setState({submittalYear, season, disabledSeason, reportGenerated: false});
        this.clearToastMessages();
    };

    //note below, current subyear and current season come from the api, the subyearid and season are the users selection
    isButtonDisabled = () => {
        if (this.state.entity === '') {
            return {create: true, show: true}
        }
        if (this.state.submittalYear === 0) {
            return {create: true, show: true}
        }
        if (this.state.season === '' ) {
            return {create: true, show: true}
        }
        //need to cover all possible combos of submittal year and season type
        //this will cover when the year is older than the current, includes all season combos
        if (this.state.currentSubYear > this.state.submittalYear) {
            return {create: true, show: false};
            //this will cover when the year is the same
        } else if (this.state.currentSubYear === this.state.submittalYear) {
            //winter&winter or summer&summer
            if (this.state.currentSeason === this.state.season) {
                return {create: false, show: true}
                //summer&winter
            } else if (this.state.currentSeason === 'Summer' && this.state.season === 'Winter') {
                return {create: false, show: true};
                //only thing left at this point is winter&summer
            } else {
                return {create: true, show: false};
            }
            //this is the case for year current year is less than user selection, it will handle all season combos
        } else if (this.state.currentSubYear < this.state.submittalYear) {
            return {create: false, show: true};
            //this is the 'default' branch incase there was something that caused all the prior branches to be missed
        } else {
            return {create: true, show: true};
        }
    }

    seasondropDownChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        await this.setState({
            season: data.value as string,
            reportGenerated: false
        });
        this.clearToastMessages();
    };
    
    resetGrids = () => {
        this.setState({DeliverabilityStudyResults: [], DeliverableCapacity: [], DeliverabilityStudyDetails: []});
    };

    showReport = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            this.getDeliverabilityStudyTariff();
            this.getDeliverabilityStudyData('DeliverableCapacity');
            this.getDeliverabilityStudyData('DeliverabilityStudyDetails');
            this.setState({hideContent:false});
            toast.success("Display of Report data Completed");
        }
    };

    createReport = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            this.resetGrids();
            let data = new FormData();
            data.set('submitEntName', this.state.entity);
            data.set('season', this.state.season);
            axios.post('/api/auth/reports/create-dsr-report', data)
                .then(resp => {
                    if (resp.status === 200) {
                        toast.success("Report Data Created");
                        this.showReport();
                        this.setState({reportGenerated: true});
                    }
                })
                .catch(error => {
                    toast.error(`Error creating report - ${error.message}`);
                })
                .finally(() => {
                    this.setState({creatingReport: false});
                });
            this.setState({creatingReport: true})
        }
    };

    clearToastMessages = () => {
        if (this.state.toastSubmittalYear && this.state.submittalYear) {
            toast.dismiss(this.state.toastSubmittalYear);
            this.setState({toastSubmittalYear:0})
        }

        if (this.state.toastSubmittingEntityId && this.state.entity) {
            toast.dismiss(this.state.toastSubmittingEntityId);
            this.setState({toastSubmittingEntityId:0})
        }
        if (this.state.toastseason && this.state.season) {
            toast.dismiss(this.state.toastseason);
            this.setState({toastseason:0})
        }
    };

    validateDropDowns = () => {
        if (!this.state.submittalYear && !this.state.toastSubmittalYear) {
            let toastSubmittalYear = toast.info('Please select a Submittal Year',{autoClose:false});
            this.setState({missingSubmittalYear:true,toastSubmittalYear})
        }
        if (!this.state.entity && !this.state.toastSubmittingEntityId) {
            let toastSubmittingEntityId = toast.info('Please select an RA Entity',{autoClose:false});
            this.setState({missingEntity:true,toastSubmittingEntityId})
        }
        if (!this.state.season && !this.state.toastseason) {
            let toastseason = toast.info('Please select a Season Type',{autoClose:false});
            this.setState({missingSeasonType:true,toastseason})
        }
        return this.state.submittalYear && this.state.entity && this.state.season
    };

    export = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            let dateString: string = format(new Date(),'MM-dd-yyyy');
            let reportName: string = 'Deliverability_' + this.state.entity + '_' 
                + this.state.season + '_' + this.state.submittalYear.toString() + '_'
                + dateString + '.xlsx';
            let exportParams: any = {
                submitEntityName: this.state.entity,
                subYear: this.state.submittalYear,
                season: this.state.season
            };
            axios.post('/api/auth/reports/export/dsr', exportParams, {responseType:'arraybuffer'}).then(response => {
                this.setState({exporting: false});
                fileDownload(response.data, reportName);
            }).catch(error => {
                this.setState({exporting: false});
                toast.error(`Error exporting data to excel - ${error.message}`);
            });
            this.setState({exporting: true});
        }
    };

    createBatchReport = () => {
        if (!this.state.season && !this.state.toastseason) {
            let toastseason = toast.info('Please select a Season Type',{autoClose:false});
            this.setState({missingSeasonType:true,toastseason})
            return;
        }
        this.clearToastMessages();
        let params = new URLSearchParams();
        params.set('season', this.state.season);
        axios.get('/api/auth/reports/createbatchreport',{params}).then(resp => {
            if (resp.status === 200) {
                toast.success("Batch Report Job Complete"); // This promise doesn't resolve until the entire procedure completes.
            }
            this.setState({creatingReport: false});
        }).catch(resp => {
            toast.error(`Error creating batch report - ${resp.message}`);
            this.setState({creatingReport: false});
        });
        this.setState({creatingReport: true});
        if (this.state.intervalId == null) {
            let intervalId: any = setInterval(this.getBatchReportProgress, 15000);
            this.setState({intervalId: intervalId});
        }
        toast.success("Batch Report Job Started, Please Wait");
    };

    waitForBatchReportProgress = async () => {
        this.clearToastMessages();
        toast.info("Checking for actively running batch report jobs, Create Report options will be available in 30 seconds");
        await setTimeout(this.unlockBatchReportProgressButton, 30000);
    }

    unlockBatchReportProgressButton = () => {
        this.setState({hasCheckedForBatchReportProgress: true});
        this.getBatchReportProgress();
        // this.clearToastMessages();
        toast.success("Check for batch report jobs complete");
    }

    getBatchReportProgress = () => {
        if (!this.props.sppStaff) {
            return;
        }
        this.clearToastMessages();
        axios.get('/api/auth/factor/getbatchreportprogress').then(resp => {
            let batchReportProgress = resp.data;
            this.setState({batchReportProgress, batchReportProgressLoading: false});
            this.hasBatchReportFailed();
            if ((!this.state.hasCheckedForBatchReportProgress) || (batchReportProgress.val === 0 && batchReportProgress.comments === 'COMPLETE')) {
                this.setState({hasCheckedForBatchReportProgress: true});
                if (this.state.intervalId !== null) {
                    clearInterval(this.state.intervalId);
                    this.setState({intervalId: null});
                }
            }
            else if (this.state.intervalId === null && batchReportProgress.val !== 0 && batchReportProgress.comments === "IN PROGRESS" && !this.state.hasBatchReportFailed) {
                toast.info("There is a currently running batch report job. If you need to create a report, please try again later")
                let intervalId: any = setInterval(this.getBatchReportProgress, 15000);
                this.setState({intervalId: intervalId});
                this.setState({hasCheckedForBatchReportProgress: true})
            }
            // if the batch report is not running, disable creation for 30 seconds to make sure it wasn't running but hasn't posted any results yet
            else if (!this.state.hasCheckedForBatchReportProgress && this.props.sppStaff) {
                this.waitForBatchReportProgress();
            }
        }).catch(resp => {
            toast.error(`Error updating batch report progress - ${resp.message}`);
            this.setState({batchReportProgressLoading: false});
        });
        this.setState({batchReportProgressLoading: true});
    }

    // if the batch report is still running but hasn't completed an entity in 15 minutes, we can assume that the report
    // has failed and can be run again
    hasBatchReportFailed = () => {
        let hasBatchReportFailed: boolean = false;
        let currentDate: Date = new Date();
        let lastEntityCompletedDate: Date = new Date(Date.parse(this.state.batchReportProgress.modDt));
        let minutesSinceLastUpdate: number = 0;
        if (this.state.batchReportProgress.val !== 0 && this.state.batchReportProgress.comments === "IN PROGRESS") {
            minutesSinceLastUpdate = Math.floor(((currentDate.getTime() - lastEntityCompletedDate.getTime())/1000)/60);
            if (minutesSinceLastUpdate >= 15) {
                hasBatchReportFailed = true;
            }
        }

        this.setState({hasBatchReportFailed: hasBatchReportFailed});
    }

    public render() {
        return (
            <div style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
                <Header size={'large'}>Deliverability Study Resource Availability</Header>
                <Grid>
                    <Grid.Row columns={7}>
                        <Grid.Column>
                            <p><b>Resource Adequacy Entity:</b></p>
                            <Dropdown placeholder={'RA Entities'}
                                      search={true}
                                      selection={true}
                                      loading={this.state.seDropDownLoading}
                                      options={this.state.submittingEntities}
                                      onChange={this.entityChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <p><b>Submittal Year:</b></p>
                            <Dropdown placeholder={'Select Submittal Year'}
                                      search={true}
                                      selection={true}
                                      loading={this.state.submittalYearsLoading}
                                      options={this.state.submittalYears}
                                      onChange={this.yearChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <p><b>Season Type:</b></p>
                            <Dropdown placeholder={'Select Season'}
                                      search={true}
                                      selection={true}
                                      disabled={this.state.disabledSeason}
                                      options={this.state.seasonOptions}
                                      onChange={this.seasondropDownChange}
                                      value={this.state.season}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row columns={4}/>
                                <Grid.Row columns={4}>
                                    <Grid.Column>
                                        <Button
                                            content={'Create Report'}
                                            color={'black'}
                                            size={'mini'}
                                            disabled={this.isButtonDisabled().create}
                                            onClick={this.createReport} loading={this.state.creatingReport}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            content={'Show Report'}
                                            color={'black'}
                                            size={'mini'}
                                            disabled={this.isButtonDisabled().show}
                                            onClick={this.showReport}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            size={'mini'}
                                            color={'black'}
                                            icon={'download'}
                                            onClick={this.export}
                                            loading={this.state.exporting}
                                            disabled={!(!this.isButtonDisabled().show || (!this.isButtonDisabled().create && this.state.reportGenerated))}
                                        >
                                            <Icon name={'download'}/>Export
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        {this.props.sppStaff
                                            ? <Button
                                                content={'Create Batch Report'}
                                                size={'mini'}
                                                color={'black'}
                                                disabled={!this.props.sppStaff
                                                    || ((this.state.batchReportProgress.comments != null && this.state.batchReportProgress.comments === "IN PROGRESS") && !this.state.hasBatchReportFailed)
                                                    || !this.state.hasCheckedForBatchReportProgress
                                                }
                                                onClick={this.createBatchReport}
                                                loading={this.state.creatingReport}
                                            />
                                            : null
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column>
                            {this.props.sppStaff
                                ? <div>
                                    { this.state.batchReportProgress.comments === "IN PROGRESS"
                                        ? <div style={{textAlign: 'center'}}>
                                            <p><b>Batch Report Progress:</b></p>
                                            <div>
                                                <progress value={this.state.batchReportProgress.val * 200} max="100"/>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    { this.state.batchReportProgress.val > .5 && !this.state.hasBatchReportFailed
                                        ? <div style={{textAlign: 'center'}}>Finishing up...</div>
                                        : null
                                    }
                                    { this.state.hasBatchReportFailed
                                        ?
                                        <div style={{color: 'red', textAlign: 'center'}}>Batch report job has failed. Please try again.</div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row/>
                </Grid>
                <AccordionSegment segmentHeader={'Deliverability Study Results'} contentHidden={this.state.hideContent} size={'mini'}>
                    {getReactTable(
                        this.state.DeliverabilityStudyResults,
                        this.state.DeliverabilityStudyResultsMetaData,
                        this.state.DeliverabilityStudyResultsLoading,
                        "DelStudyResourceAvail",
                        100,
                        false
                    )}
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Submitting Entity Deliverability Summary'} contentHidden={this.state.hideContent} size={'mini'}>
                    <ReactTableHOC
                        data={this.state.DeliverableCapacity}
                        columnMetaData={this.state.DeliverableCapacityMetaData}
                        showPagination={false}
                        defaultPageSize={1}
                        filterable={false}
                        className={'-striped DelStudyAvailSummary'}
                        tableClassName={"DelStudyAvailSummary"}
                        defaultFilterMethod={GridUtil.filterMethod}
                        loading={this.state.DeliverableCapacityLoading}
                        size={'mini'}
                        color={'black'}
                        tableStyle={{height:'100px'}}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: "black",
                                    color: "white",
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth}})}
                        getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                    />
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Resource Ownership Deliverability Summary'} contentHidden={this.state.hideContent} size={'mini'}>
                    {getReactTable(
                        this.state.DeliverabilityStudyDetails,
                        this.state.DeliverabilityStudyDetailsMetaData,
                        this.state.DeliverabilityStudyDetailsLoading,
                        "DelStudyResourceAvail",
                        100,
                        false
                    )}
                </AccordionSegment>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        sppStaff: state.authReducer.userRolesEntities.sppStaff
    }
};

export default connect(mapStateToProps)(DeliverabilityStudy);