import * as React from 'react';
import {Button, Container, Grid, Icon} from "semantic-ui-react";
import axios from 'axios';
import '../../../CSS/react-table-custom-orig.css';
import GridUtil from "../../common/GridUtil";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import ReactTable from "react-table";
import * as actionTypes from "../../../store/actions/actionTypes";
import GeneralService from "../../../services/general-services";
import ContactDateModal from "./ContactDateModal";



interface ContactGridState {
    data: any;
    columns: any;
    metaData: any;
    filtered: any;
    openModal: boolean,
    status: number,
    loading: boolean,
    deleteModalVisible: boolean
}

interface ContactGridProps {
    currentUser: string
    currentRow: any
    activeTab: any
    updateDeleteModalActive: (deleteModalActive: boolean) => void;
    updateCurrentRow: (currentRow: any) => void;
    updateDeleteScreen: (deleteScreen: any) => void;
    updateAddOrModifyContact: (addOrModifyContact: string) => void;
    contactModalActive: boolean;
    updateContactModalActive: (contactModalActive: boolean) => void;
    updateActiveTab: (activeTab: string) => void;
    sppStaff: boolean
}

const generalClient = new GeneralService();
class ContactGrid extends React.Component<ContactGridProps, ContactGridState> {
    constructor(props: ContactGridProps, state: ContactGridState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [
                {
                    Header:'Action',
                    accessor:'action',
                    width: 80
                },{
                    Header:'Stakeholder Name',
                    accessor:'stakeholderNm',
                    width: 300
                },{
                    Header:'Nerc ID',
                    accessor:'nercId',
                    width: 100
                },{
                    Header:'Contact Type',
                    accessor:'contactType',
                    width: 100
                },{
                    Header:'Name',
                    accessor:'name',
                    width: 250
                },{
                    Header:'Phone',
                    accessor:'phone',
                    width: 100
                },{
                    Header:'Extension',
                    accessor:'extension',
                    width: 75
                },{
                    Header:'Email',
                    accessor:'email'

                },{
                    Header:'Category',
                    accessor:'categoryName',
                    width: 100
                }
            ],
            metaData: [],
            filtered: [],
            openModal: false,
            status: 200,
            loading: true,
            deleteModalVisible: false,
        };
    }

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[] });
    };

    getFiltered = () => {
        if (this.state.filtered) {
            return 'visible'
        }
        return 'hidden'
    };

    componentDidMount(): void {
        this.fetchData();
    }

    componentWillReceiveProps(nextProps: any) {
        // this will run every time the props change - and possibly in addition to this, so we need to check for prop changes
        if (this.props.activeTab === 'Contacts' && this.props.currentRow !== nextProps.currentRow) {
            this.fetchData();
        }
    }

    getActions = (key: number) =>{
        //For now, we removed the delete row action to prevent unintended deletes from users. Leaving JSX and logic to handle
        //a click for now until we decide how to handle long term
        return(
            <div>
                <Icon id={key} color={'blue'} name={'edit'} onClick={this.takeAction} disabled={false} style={{cursor: 'pointer'}}/>
                {/*<Icon id={key} color={'red'} name={'delete'} onClick={this.takeAction} disabled={false} style={{cursor: 'pointer'}}/>*/}
            </div>
        )
    }

    formatPhoneComponents = (currentRow: any) =>{
        //Adds areaCode, prefix and lineNumber elements to current row so they can be checked for state changes in contact modal.
        if(currentRow.phone) {
            let splitPhone = currentRow.phone.split("-")
            currentRow['areaCode'] = splitPhone[0];
            currentRow['prefix'] = splitPhone[1];
            currentRow['lineNumber'] = splitPhone[2]

        }
        return currentRow;
    }

    takeAction = (e:any) => {
        let currentRow:any = this.state.data.find((obj:any) => obj.id === parseInt(e.target.id))
        let actionType: any = e.target.className;
        this.props.updateCurrentRow(this.formatPhoneComponents(currentRow));
        //We clicked edit row
        if(actionType.includes('edit')) {
            this.props.updateAddOrModifyContact('Modify');
            this.props.updateContactModalActive(true);
            //We clicked delete row
        } else {
            this.props.updateDeleteScreen('Contact');
            this.props.updateDeleteModalActive(true);
        }

    }

    fetchData = () => {
        axios.get(`/api/auth/contact/default`)
            .then(resp => {
            if (resp.data) {
                let finalData: any = []
                resp.data.forEach((c:any) => {
                    c.action = this.getActions(c.id);
                    finalData.push(c);
                })
                this.setState({data:finalData, status: 200, loading: false});
            } else {
                this.setState({loading:false,data:[]})
            }
        }).catch(resp => {
            this.setState({status: resp.status, loading: false})
        });
    };

    public render() {
        const {data} = this.state;
        return (
            <div>

                <Container fluid={true} style={{paddingTop:'10px', paddingBottom: '10px'}}>
                    <ReactTable
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={100}
                        filterable={true}
                        filtered={this.state.filtered}
                        style={{height: '500px', width: '100%'}}
                        className='-highlight'
                        defaultFilterMethod={GridUtil.filterMethod}
                        onFilteredChange={filtered => this.setState({filtered})}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: 'black',
                                    color: 'white',
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth, textAlign: "center"}})}
                    />
                    <Loading active={this.state.loading}/>
                </Container>
                <Grid>
                    <Grid.Column width={2}>
                        <Button size={'mini'} icon={true} labelPosition='left' onClick={generalClient.downloadExcel} name={'contactcategory'} color={'black'}>
                            <Icon name='download'/>
                            Download
                        </Button>
                    </Grid.Column>
                    {this.props.sppStaff
                    ? <Grid.Column width={2}>
                            <ContactDateModal/>
                        </Grid.Column>
                    : null}
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        deleteModalActive: Boolean(state.defaultReducer.deleteModalActive),
        currentRow: state.defaultReducer.currentRow,
        deleteScreen: state.contactReducer.deleteScreen,
        addOrModifyContact: state.contactReducer.addOrModifyContact,
        contactModalActive: Boolean(state.contactReducer.contactModalActive),
        activeTab: state.defaultReducer.activeTab,
        sppStaff: state.authReducer.userRolesEntities.sppStaff,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDeleteModalActive: (deleteModalActive: boolean) => dispatch(
            {type: actionTypes.SET_DELETEMODALACTIVE, payload: deleteModalActive}
        ),
        updateCurrentRow: (currentRow: boolean) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}
        ),
        updateDeleteScreen: (deleteScreen: boolean) => dispatch(
            {type: actionTypes.SET_DELETESCREEN, payload: deleteScreen}
        ),
        updateAddOrModifyContact: (addOrModifyContact: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYCONTACT, payload: addOrModifyContact}
        ),
        updateContactModalActive: (contactModalActive: boolean) => dispatch(
            {type: actionTypes.SET_CONTACTMODALACTIVE, payload: contactModalActive}
        ),
        updateActiveTab: (activeTab: string) => dispatch(
            {type: actionTypes.SET_ACTIVETAB, payload: activeTab}
        ),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ContactGrid)
