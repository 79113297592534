import * as React from 'react';
import {Button, Container, Icon} from "semantic-ui-react";
// import axios from 'axios';
import '../../../CSS/react-table-custom-orig.css';
import GridUtil from "../../common/GridUtil";
import AdminService from "../../../services/admin-services";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import ReactTable from "react-table";
import * as actionTypes from "../../../store/actions/actionTypes";
import GeneralService from "../../../services/general-services";



interface SubRespRelGridState {
    data: any;
    columns: any;
    metaData: any;
    filtered: any;
    openModal: boolean,
    status: number,
    loading: boolean
}

interface SubRespRelGridProps {
    currentUser: string
    currentRow: any
    updateCurrentRow: (currentRow: any) => void;
    activeTab: string;
    subYear: number;
}

/**Populate the SubRespRel Grid*/
const generalClient = new GeneralService();
const adminClient = new AdminService();
class SubRespRelGrid extends React.Component<SubRespRelGridProps, SubRespRelGridState> {
    constructor(props: SubRespRelGridProps, state: SubRespRelGridState) {
        super(props,state);
        this.state = {
            data: [],
            //TODO: Pull columnMeta from database
            columns: [
                {
                    Header:'Submitting Entity ID',
                    accessor:'submitEntId',
                    width: 80
                },
                {
                    Header:'Submitting Entity Name',
                    accessor:'submitEntNm'
                },
                {
                    Header:'Submitting Entity Long Name',
                    accessor:'submitEntLongNm'
                },{
                    Header:'Submitting Entity Type',
                    accessor:'submitEntType'
                },{
                    Header:'Responsible Entity ID',
                    accessor:'respEntId',
                    width: 80
                },
                {
                    Header:'Responsible Entity Name',
                    accessor:'respEntNm'
                },
                {
                    Header:'Responsible Entity Long Name',
                    accessor:'respEntLongNm'
                },{
                    Header:'Responsible Entity Type',
                    accessor:'respEntType'
                },
            ],
            metaData: [],
            filtered: [],
            openModal: false,
            status: 200,
            loading: true
        };
    }

    getGridData = () => {
        /**Calls adminClient which makes an axios request to backend for all of the subRespEntRel data to be displayed in the
         * subRespRel Grid.*/
        adminClient.getSubRespRelGridData(this.props.subYear).then( (response:any) => {
            this.setState({data: response.data, status: response.status, loading: false});
        });
    }

    componentDidMount(): void {
        /**Get all data when the SubRespEntRel Grid component mounts*/
        this.getGridData()
    }

    componentWillReceiveProps(nextProps: any) {
        /**Refresh grid data when the modal has been activated, and then closed. This will ensure data in the grid updates
         * to reflect any changes made by the user in the Entity Modal*/
        if (this.props.activeTab === 'Sub/Resp Entity Relationship' && this.props.currentRow !== nextProps.currentRow) {
            this.getGridData()
        }
    }

    public render() {
        /**JSX for displaying the grid using ReactTable module*/
        const {data} = this.state;
        return (
            <div>

                <Container fluid={true} style={{paddingTop:'10px', paddingBottom: '10px'}}>
                    <ReactTable
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={100}
                        filterable={true}
                        filtered={this.state.filtered}
                        style={{height: '500px', width: '100%'}}
                        className='-highlight'
                        defaultFilterMethod={GridUtil.filterMethod}
                        onFilteredChange={filtered => this.setState({filtered})}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: 'black',
                                    color: 'white',
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth, textAlign: "center"}})}
                    />
                    <Loading active={this.state.loading}/>
                </Container>
                <Button size={'mini'} icon={true} labelPosition='left' onClick={generalClient.downloadExcel} name={'submitentrespentrel'} color={'black'}>
                    <Icon name='download'/>
                    Download
                </Button>
            </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        currentRow: state.defaultReducer.currentRow,
        activeTab: state.defaultReducer.activeTab,
        subYear: state.defaultReducer.subYear,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentRow: (currentRow: boolean) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}
        ),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(SubRespRelGrid)
