import React from 'react';
import {Tooltip} from '@progress/kendo-react-tooltip';

const TooltipContentTemplate = (props: any) => (
    <div>
        <strong>Original Value</strong>
        <br/>
        {props.title}
    </div>
);

const TooltipContainer: React.FC<{}> = ({children}) => (
    <Tooltip position='top' anchorElement='target' parentTitle={true} content={(props) => <TooltipContentTemplate title={props.title}/>}>
        {children}
    </Tooltip>
);

export default TooltipContainer;