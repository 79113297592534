import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {useState} from 'react';
import {DropDownOption} from './DropDownCell';
import {isDifferent, processDropDownListOptions} from '../../../services/data-grid-service';
import {PurchasesSalesResourceOptions} from '../../../interfaces/purchases-sales-interface';

interface FuelTypeDropDownCellProps extends GridCellProps {
    resourceOwnerOptions: Array<PurchasesSalesResourceOptions>
}

export const FuelTypeDropDownCell = (props: FuelTypeDropDownCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData, plantNm, resourceNm, contractType, ownershipCompanyNm} = dataItem;
    const dataValue: string = field && contractType && dataItem[field] ? dataItem[field] : '';
    const dataOption: DropDownOption = {text: dataValue, value: dataValue};
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    const disabled = !contractType || (contractType === 'Plant Specific' && !plantNm) || (contractType === 'Resource Specific' && !resourceNm);

    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value.value
            });
        }
    };

    const [options, setOptions] = useState<Array<DropDownOption>>([dataOption]);

    const onOpen = () => {
        let fuelTypeOptions: Array<string>;
        if (contractType === 'Fleet') {
            fuelTypeOptions = ['Conventional Based', 'Hydro Based'];
        } else if (contractType === 'Plant Specific' && plantNm) {
            if (ownershipCompanyNm) {
                fuelTypeOptions = Array.from(new Set(props.resourceOwnerOptions
                    .filter((item) => item.plantNm === plantNm && item.ownershipCompanyNm === ownershipCompanyNm)
                    .map((item) => item.fuelType)
                ));
            } else {
                fuelTypeOptions = Array.from(new Set(props.resourceOwnerOptions
                    .filter((item) => item.plantNm === plantNm)
                    .map((item) => item.fuelType)
                ));
            }
        } else if (contractType === 'Resource Specific' && plantNm && resourceNm) {
            fuelTypeOptions = Array.from(new Set(props.resourceOwnerOptions
                .filter((item) => item.plantNm === plantNm && item.resourceNm === resourceNm)
                .map((item) => item.fuelType)
            ));
        } else {
            fuelTypeOptions = [''];
        }
        setOptions(processDropDownListOptions(fuelTypeOptions));
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit ?
                isChanged ?
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        disabled={disabled}
                        title={originalValue}
                    />
                    :
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        disabled={disabled}
                    />
                :
                dataValue
            }
        </td>
    );
};

export const getFuelTypeDropDownCell = (resourceOwnerOptions: Array<PurchasesSalesResourceOptions>) => {
    return (props: GridCellProps) => (
        <FuelTypeDropDownCell {...props} resourceOwnerOptions={resourceOwnerOptions}/>
    );
};