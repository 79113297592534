import * as React from 'react';
import {Button, Form, Grid, Icon, Modal} from "semantic-ui-react";
import GeneralService from "../../../services/general-services";
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import parse from 'date-fns/parse';
import DatePicker from "react-datepicker";

interface ContactDateModalState {
    openModal: boolean,
    loading: boolean,
    modalActive: boolean,
    startDate: string,
    endDate: string,
    startError: boolean,
    endError: boolean,
    downloadDisabled: boolean,
    errorMessage: string,
    errorLabel: string
}

const dateFormat = 'yyyy-MM-dd HH:mm:ss';
const generalClient = new GeneralService();
class ContactDateModal extends React.Component<{}, ContactDateModalState> {
    constructor(props: {}, state: ContactDateModalState) {
        super(props, state);
        this.state = {
            openModal: false,
            loading: false,
            modalActive: false,
            startDate: format(new Date(),dateFormat),
            endDate: format(new Date(),dateFormat),
            startError: false,
            endError: false,
            downloadDisabled: false,
            errorMessage: 'error message',
            errorLabel: ''
        };
    }

    componentDidMount(): void {

    }

    handleDateChange = (name: string, date: Date) => {
        let start = parse(this.state.startDate, dateFormat, new Date());
        let end = parse(this.state.endDate, dateFormat, new Date());
        let {startError, endError} = this.state;
        if (name === 'startDate') {
            if (isAfter(date, end)) {
                startError = true;
                //this.state.errorMessage = "Date is Incorrect"
                //this.setState({errorMessage: 'error message'})
                this.setState({errorLabel: 'Invalid Date - Start Date must be earlier then End Date'})
            } else {
                startError = false;
                this.setState({errorLabel: ''})
            }
            this.setState({
                startDate: format(date, dateFormat),
                startError,
                //add code to check dates are valid - put error
            });
        } else if (name === 'endDate') {
            if (isAfter(date, start)) {
                endError = true;
                this.setState({errorLabel: 'Invalid Date - End Date must be later then Start Date'})
            } else {
                endError = false;
                this.setState({errorLabel: ''})
            }
            this.setState({
                endDate: format(date, dateFormat),
                endError,
            })
        }
        this.setState({downloadDisabled: startError || endError})
    };

    openModal = () => {
        this.setState({modalActive: true});
    };

    download = () => {
        //loader start true
        this.setState({loading: true});
        generalClient.downloadContactDateExcel(this.state.startDate, this.state.endDate)
            .finally(() => {
                //loader stop false
                this.setState({loading: false, modalActive: false});
            })
    }

    cancelModal = () => {
        this.setState({modalActive: false});
    };

    render() {
        return (
            <div>
                <Modal open={this.state.modalActive}
                       onClose={this.cancelModal}
                       size={"large"}
                       trigger={
                           <Button size={'mini'} icon={true} labelPosition='left' onClick={this.openModal}
                                   color={'black'}>
                               <Icon name='download'/>
                               Download by Date Range
                           </Button>}
                >
                    <Modal.Header>Contact Added List By Date Range</Modal.Header>
                    {
                        <Modal.Content>
                            Please enter a date range for pulling the contacts.
                            The Start Date is the oldest date and the End Date is the newest date.
                        </Modal.Content>
                    }
                    <Modal.Actions>
                        <Grid>
                            <Grid.Column stretched={true} width={8} textAlign={"left"}>
                                <Form.Group>
                                    <Form.Field label={'Start Date'} control={DatePicker} error={this.state.startError}
                                                name={'startDate'} required={true}
                                                onChange={(date: Date) => this.handleDateChange('startDate', date)}
                                                selected={parse(this.state.startDate, dateFormat, new Date())}
                                                dateFormat={'MM/dd/yyyy'}/>

                                    <Form.Field label={'End Date'} control={DatePicker} error={this.state.endError}
                                                name={'endDate'} required={true}
                                                onChange={(date: Date) => this.handleDateChange('endDate', date)}
                                                selected={parse(this.state.endDate, dateFormat, new Date())}
                                                dateFormat={'MM/dd/yyyy'}/>
                                </Form.Group>
                                {/*<label htmlFor="message">Message</label>*/}
                                <label> { this.state.errorLabel } </label>
                            </Grid.Column>
                        </Grid>
                        <Button size={'mini'} icon={true} labelPosition='left' onClick={this.download}
                                name={'contactcategorybydaterange'} color={'black'} disabled={this.state.downloadDisabled}>
                            <Icon name='download'/>
                            Download by Date Range
                        </Button>
                        <Button size={'mini'} 
                                onClick={this.cancelModal}
                                negative={true}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default ContactDateModal;



