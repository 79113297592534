import React from 'react';
import {Button, Modal} from 'semantic-ui-react';

interface RemoveModalProps {
    closeModal: () => void,
    confirmRemove: () => void,
    open: boolean
}

const RemoveModal: React.FC<RemoveModalProps> = ({closeModal, confirmRemove, open}) => {
    return (
        <Modal open={open} onClose={closeModal}>
            <Modal.Header>Confirm Removal</Modal.Header>
            <Modal.Content>
                <p>Marked records will be permanently deleted. Please confirm.</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={confirmRemove}>Confirm</Button>
                <Button onClick={closeModal}>Cancel</Button>
            </Modal.Actions>
        </Modal>
)
    ;
};

export default RemoveModal;

/*
Usage in parent component:

State and methods needed:
const [inRemove, setInRemove] = useState<boolean>(false);
const [modalOpen, setModalOpen] = useState<boolean>(false);

const remove = () => setInRemove(false); // more functionality here for actual removal processing
const toggleRemove = () => setInRemove(!inRemove);

const openModal = () => setModalOpen(true);
const closeModal = () => setModalOpen(false);

const confirmRemove = () => {
    setModalOpen(false);
    remove();
};

In return/render:
<ActionPanel title={title}>
	<SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>
	<RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>
</ActionPanel>
{modalOpen && <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>}
<Grid data={data} onItemChange={handleChange} editField="inEdit">
	map columnMeta to create GridColumn components or fill with empty column <GridColumn/>
</Grid>
 */