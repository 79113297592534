import * as React from 'react';
import {Button, Dropdown, DropdownItemProps, DropdownProps, Grid, Header, Icon} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import {AccordionSegment} from "../common/AccordionSegment";
import {
    Chart,
    ChartTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    exportVisual,
    Charts
} from '@progress/kendo-react-charts';
import 'hammerjs';
import {exportImage} from '@progress/kendo-drawing';
import {getReactTable} from "../common/ReactTableUtil";
import ResadqService from "../../services/resadq-services";
import {toast} from "react-toastify";
import axios from "axios";
import format from 'date-fns/format';
import {getRaActiveSummerSubyear, getRaCurrentSeasonSubyear} from '../../services/ra-report-service';

interface ResourceAdequacyRequirementState {
    submittingEntities: any
    seDropDownLoading: boolean
    submittalYears: any
    submittalYearsLoading: boolean
    submitEntityName: string
    subYearId: number
    season: string
    seasonOptions: any
    disabledSeason: boolean
    creatingReport: boolean
    firmCapacityBarData: any
    firmCapWaterfallData: any
    peakDemandBarData: any
    peakDemandWaterfallData: any
    firmCapacityLoading: boolean
    peakDemandLoading: boolean
    FirmCapacitySummary: any[]
    FirmCapacitySummaryMetaData: any[]
    FirmCapacitySummaryLoading: boolean
    PeakDemandSummary: any[]
    PeakDemandSummaryMetaData: any[]
    PeakDemandSummaryLoading: boolean
    ResourceAdequacySummary: any[]
    ResourceAdequacySummaryMetaData: any[]
    ResourceAdequacySummaryLoading: boolean
    hideContent: boolean
    missingSubmittalYear: boolean
    missingEntity: boolean
    missingSeasonType: boolean
    toastSubmittingEntityId: number
    toastSubmittalYear: number
    toastseason: number
    exporting: boolean
    currentSubYear: number
    currentSeason: string
    reportGenerated: boolean
    seasonCheck: string
    readOnlyReport: boolean
}

let fileDownload = require('js-file-download');
const client = new ResadqService();

export class ResourceAdequacyRequirement extends React.Component<RouteComponentProps<{}>, ResourceAdequacyRequirementState> {
    constructor(props: RouteComponentProps<{}>, state: ResourceAdequacyRequirementState) {
        super(props, state);
        this.state = {
            submittingEntities: [],
            seDropDownLoading: true,
            submittalYears: [],
            submittalYearsLoading: true,
            submitEntityName: '',
            subYearId: 0,
            season: '',
            seasonOptions: [
                { key: 'Summer', text: 'Summer', value: 'Summer' },
                { key: 'Winter', text: 'Winter', value: 'Winter' },
            ],
            disabledSeason: false,
            creatingReport: false,
            firmCapacityBarData: [],
            firmCapWaterfallData: [],
            peakDemandBarData: [],
            peakDemandWaterfallData: [],
            firmCapacityLoading: false,
            peakDemandLoading: false,
            FirmCapacitySummary: [],
            FirmCapacitySummaryMetaData: [],
            FirmCapacitySummaryLoading: false,
            PeakDemandSummary: [],
            PeakDemandSummaryMetaData: [],
            PeakDemandSummaryLoading: false,
            ResourceAdequacySummary: [],
            ResourceAdequacySummaryMetaData: [],
            ResourceAdequacySummaryLoading: false,
            hideContent: true,
            missingEntity: false,
            missingSeasonType: false,
            missingSubmittalYear: false,
            toastSubmittalYear: 0,
            toastSubmittingEntityId: 0,
            toastseason:0,
            exporting: false,
            currentSubYear: 0,
            currentSeason: 'Summer',
            reportGenerated: false,
            seasonCheck:'',
            readOnlyReport: true
        };
    }

    // refs
    _firmCapChart: any;
    _peakDemandChart: any;

    componentDidMount(): void {
        this.getSubmittingEntities();
        this.getSubmittalYear();
        this.getCurrentYearSeason();
    }
    
    getCurrentYearSeason = () => {
        getRaCurrentSeasonSubyear()
            .then((resp) => {
                this.setState({currentSubYear: resp.data});
                getRaActiveSummerSubyear()
                    .then((response) => {
                        let currentSeason = resp.data === response.data ? 'Summer' : 'Winter';
                        this.setState({currentSubYear: resp.data, currentSeason});
                    });
            })
            .catch((error) => {
                console.log(error);
            })
    };

    export = async () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            let dateString: string = format(new Date(), 'yyyy-MM-dd');
            let reportName: string = 'RAR_' + this.state.submitEntityName + '_' +
                this.state.subYearId.toString() + '_' + this.state.season + '_' +
                dateString + '.xlsx';

            axios.post('/api/auth/reports/export/rar', await this.getExportParams(), {responseType: 'arraybuffer'})
                .then(resp => {
                    fileDownload(resp.data, reportName);
                    toast.success('Export completed successfully');
                })
                .catch(resp => {
                    toast.error(`Error creating export - ${resp.message}`);
                })
                .finally(() => {
                    this.setState({exporting: false});
                });
            this.setState({exporting: true})
        }
    };

    exportChartImage = async (chartRef: Charts, width: number, height: number) => {
        const chartVisual = await exportVisual(chartRef, {width: width, height: height});
        let imgUri = "";

        if (chartVisual) {
            imgUri = await exportImage(chartVisual, {width: width, height: height})
            return imgUri;
        }

        return imgUri;
    }

    getExportParams = async () => {
        const [firmCapImageStream, netPeakImageStream] = await Promise.all([
            this.exportChartImage(this._firmCapChart, 600, 400),
            this.exportChartImage(this._peakDemandChart, 800, 400)
        ]);

        let exportParams: any = {
            submitEntityName: this.state.submitEntityName,
            subYear: this.state.subYearId,
            season: this.state.season,
            firmCapImageStream: firmCapImageStream,
            netPeakImageStream: netPeakImageStream
        }

        return exportParams
    }

    getRarData = (subGroupName: string) => {
        let {submitEntityName,subYearId,season} = this.state;
        client.getReportData('ResourceAdequacyRequirement',subGroupName,submitEntityName,subYearId,season)
            .then((data) => {
                this.setState({
                    [subGroupName]:data.data,
                    [`${subGroupName}MetaData`]:data.columnMetaDataList,
                    [`${subGroupName}Loading`]:false
                } as any);
            });
        this.setState({
            [`${subGroupName}Loading`]:true
        } as any);
    };

    getSubmittingEntities = () => {
        client.getSubmittingEntities('ResourceAdequacyRequirement')
            .then((data:any[]) => {
                let submittingEntities: DropdownItemProps[] = data.map((se:any) => ({key:se,text:se,value:se}));
                this.setState({submittingEntities,seDropDownLoading:false});
            });
    };

    clearToastMessages = () => {
        if (this.state.toastSubmittalYear && this.state.subYearId) {
            toast.dismiss(this.state.toastSubmittalYear);
            this.setState({toastSubmittalYear:0})
        }
        if (this.state.toastSubmittingEntityId && this.state.submitEntityName) {
            toast.dismiss(this.state.toastSubmittingEntityId);
            this.setState({toastSubmittingEntityId:0})
        }
        if (this.state.toastseason && this.state.season) {
            toast.dismiss(this.state.toastseason);
            this.setState({toastseason:0})
        }
    };

    validateDropDowns = () => {
        if (!this.state.subYearId && !this.state.toastSubmittalYear) {
            let toastSubmittalYear = toast.info('Please select a Submittal Year',{autoClose:false});
            this.setState({missingSubmittalYear:true,toastSubmittalYear})
        }
        if (!this.state.submitEntityName && !this.state.toastSubmittingEntityId) {
            let toastSubmittingEntityId = toast.info('Please select an RA Entity',{autoClose:false});
            this.setState({missingEntity:true,toastSubmittingEntityId})
        }
        if (!this.state.season && !this.state.toastseason) {
            let toastseason = toast.info('Please select a season',{autoClose:false});
            this.setState({missingEntity:true,toastseason})
        }
        return this.state.subYearId && this.state.submitEntityName && this.state.season
    };

    getSubmittalYear = () => {
        client.getSubmittalYears()
            .then((data) => {
                let submittalYears: DropdownItemProps[] = data.map((se:any) => ({key:se, text:se, value:parseInt(se)}));
                this.setState({submittalYears,submittalYearsLoading:false});
            });
        this.setState({submittalYearsLoading:true});
    };

    pointColor = (point: any) => {
        let summary = point.dataItem.summary;

        if (summary) {
            return '#377eb8';
        }

        if (point.value > 0) {
            return 'green';
        } else {
            return 'red';
        }
    };

    getNextWaterfallValue = (last: any, low: any, high: any) => {
        if (last === low) {
            return high - low;
        } else {
            return low - high;
        }
    };
    
    getFirmCapacityBarGraph = () => {
        let barChartParams: any =
            {
                submitEntName: this.state.submitEntityName,
                graphName: 'StackedFirmCapacitySummary',
                subYear: this.state.subYearId,
                season: this.state.season
            }
        axios.get('/api/auth/reports/rangebarchart', {params: barChartParams})
            .then(resp => {
                let firmCapWaterfallData = [
                    {
                        label: (resp.data[0].vRowNm).trim().replaceAll(' ','\n'),
                        amount: resp.data[0].High
                    },
                    {
                        label: (resp.data[1].vRowNm).trim().replaceAll(' ','\n'),
                        amount: this.getNextWaterfallValue(resp.data[0].High, resp.data[1].Low, resp.data[1].High)
                    },
                    {
                        label: (resp.data[2].vRowNm).trim().replaceAll(' ','\n'),
                        amount: this.getNextWaterfallValue(resp.data[1].High, resp.data[2].Low, resp.data[2].High)
                    },
                    {
                        label: (resp.data[3].vRowNm).trim().replaceAll(' ','\n'),
                        amount: this.getNextWaterfallValue(resp.data[2].High, resp.data[3].Low, resp.data[3].High)
                    },
                    {
                        label: (resp.data[4].vRowNm).trim().replaceAll(' ','\n'),
                        summary: "total"
                    },
                ];
                this.setState({firmCapacityBarData: resp.data, firmCapWaterfallData});
            })
            .catch(error => {
                if (error.message) {
                    toast.error(`Error retrieving Capacity Summary data - ${error.message}`);
                }
            })
            .finally(() => {
                this.setState({firmCapacityLoading: false});
            });
        this.setState({firmCapacityLoading: true})
    };

    getPeakDemandBarGraph = () => {
        let rangeBarChartData: any = {
            submitEntName: this.state.submitEntityName,
            graphName: 'StackedPeakDemandSummary',
            subYear: this.state.subYearId,
            season: this.state.season
        }
        axios.get('/api/auth/reports/rangebarchart', {params: rangeBarChartData})
            .then(resp => {
                let peakDemandWaterfallData = [
                    {
                        label: (resp.data[0].vRowNm).trim().replaceAll(' ', '\n'),
                        amount: resp.data[0].High
                    },
                    {
                        label: (resp.data[1].vRowNm).trim().replaceAll(' ', '\n'),
                        amount: this.getNextWaterfallValue(resp.data[0].High, resp.data[1].Low, resp.data[1].High)
                    },
                    {
                        label: (resp.data[2].vRowNm).trim().replaceAll(' ', '\n'),
                        amount: this.getNextWaterfallValue(resp.data[1].High, resp.data[2].Low, resp.data[2].High)
                    },
                    {
                        label: (resp.data[3].vRowNm).trim().replaceAll(' ', '\n'),
                        amount: this.getNextWaterfallValue(resp.data[2].High, resp.data[3].Low, resp.data[3].High)
                    },
                    {
                        label: (resp.data[4].vRowNm).trim().replaceAll(' ', '\n'),
                        summary: "total"
                    },
                ];
                this.setState({peakDemandBarData: resp.data, peakDemandWaterfallData});
            })
            .catch(error => {
                if (error.message) {
                    toast.error(`Error retrieving Net Peak Demand Summary data - ${error.message}`);
                }
            })
            .finally(() => {
                this.setState({peakDemandLoading: false});
            });
        this.setState({peakDemandLoading: true})
    };

    sedropDownChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        await this.setState({submitEntityName:data.value as string, reportGenerated: false});
        this.clearToastMessages();
    };

    //note below, current subyear and current season come from the api, the subyearid and season are the users selection
    isButtonDisabled = () => {
        if (this.state.submitEntityName === '') {
            return {create: true, show: true}
        }
        if (this.state.subYearId === 0) {
            return {create: true, show: true}
        }
        if (this.state.season === '' ) {
            return {create: true, show: true}
        }
        //need to cover all possible combos of submittal year and season type
        //this will cover when the year is older than the current, includes all season combos
        if (this.state.currentSubYear > this.state.subYearId) {
            return {create: true, show: false};
        //this will cover when the year is the same
        } else if (this.state.currentSubYear === this.state.subYearId) {
            //winter&winter or summer&summer
            if (this.state.currentSeason === this.state.season) {
                return {create: false, show: true}
                //summer&winter
            } else if (this.state.currentSeason === 'Summer' && this.state.season === 'Winter') {
                return {create: false, show: true};
                //only thing left at this point is winter&summer
            } else {
                return {create: true, show: false};
            }
        //this is the case for year current year is less than user selection, it will handle all season combos
        } else if (this.state.currentSubYear < this.state.subYearId) {
            return {create: false, show: true};
        //this is the 'default' branch incase there was something that caused all the prior branches to be missed
        } else {
            return {create: true, show: true};
        }
    }

    sydropDownChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let subYearId: number = data.value as number;
        let {season, disabledSeason} = this.state;

        if (subYearId <= 2023 || subYearId > this.state.currentSubYear) {
            season = 'Summer';
            disabledSeason = true;
        } else {
            disabledSeason = false;
        }

        await this.setState({subYearId, season, disabledSeason, reportGenerated: false});
        this.clearToastMessages();
    };

    seasondropDownChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        await this.setState({season: data.value as string, reportGenerated: false});
        this.clearToastMessages();
    };

    showReport = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            this.getFirmCapacityBarGraph();
            this.getPeakDemandBarGraph();
            this.getRarData('FirmCapacitySummary');
            this.getRarData('PeakDemandSummary');
            this.getRarData('ResourceAdequacySummary');
            this.setState({hideContent: false, reportGenerated: true});
            toast.success("Display of Report data Completed");
            this.setState({seasonCheck: this.state.season});
        }
    };

    createReport = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            let data = new FormData();
            data.set('submitEntName', this.state.submitEntityName);
            data.set('season', this.state.season);
            axios.post('/api/auth/reports/create-report', data)
                .then(resp => {
                    if (resp.status === 200) {
                        toast.success("Report Data Created");
                        this.showReport();
                    }
                })
                .catch(error => {
                    toast.error(`Error creating report - ${error.message}`);
                })
                .finally(() => {
                    this.setState({creatingReport: false});
                });
            this.setState({creatingReport: true})
        }
    };

    public render() {
        return (
            <div style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
                <Header size={'large'}>Resource Adequacy Requirement</Header>
                <Grid>
                    <Grid.Row columns={7}>
                        <Grid.Column>
                            <p><b>Submitting Entity:</b></p>
                            <Dropdown placeholder={'Submitting Entities'}
                                      search={true}
                                      selection={true}
                                      loading={this.state.seDropDownLoading}
                                      options={this.state.submittingEntities}
                                      onChange={this.sedropDownChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <p><b>Submittal Year:</b></p>
                            <Dropdown placeholder={'Select Submittal Year'}
                                      search={true}
                                      selection={true}
                                      loading={this.state.submittalYearsLoading}
                                      options={this.state.submittalYears}
                                      onChange={this.sydropDownChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <p><b>Season Type:</b></p>
                            <Dropdown placeholder={'Select Season'}
                                      search={true}
                                      selection={true}
                                      disabled={this.state.disabledSeason}
                                      options={this.state.seasonOptions}
                                      onChange={this.seasondropDownChange}
                                      value={this.state.season}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row columns={3}/>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Button
                                            content={'Create Report'}
                                            size={'mini'}
                                            color={'black'}
                                            disabled={this.isButtonDisabled().create}
                                            onClick={this.createReport}
                                            loading={this.state.creatingReport}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            content={'Show Report'}
                                            size={'mini'}
                                            color={'black'}
                                            disabled={this.isButtonDisabled().show}
                                            onClick={this.showReport}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            size={'mini'}
                                            color={'black'}
                                            onClick={this.export}
                                            loading={this.state.exporting}
                                            disabled={!this.state.reportGenerated}
                                        >
                                            <Icon name={'download'}/>Export
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Capacity Summary'} centered={true}
                                              contentHidden={this.state.hideContent}
                                              loading={this.state.firmCapacityLoading} >
                                <Chart 
                                    ref={(cmp) => this._firmCapChart = cmp}
                                    style={{height: 400, width: 600, paddingLeft: '20px', paddingRight: '20px'}}
                                    transitions={false}
                                >

                                    {this.state.seasonCheck === "Summer" ? (
                                        <ChartTitle text='Summer Capacity Summary'/>
                                        ) : ( <ChartTitle text='Winter Capacity Summary'/>
                                    )}

                                    <ChartSeries>
                                        <ChartSeriesItem
                                            type='waterfall'
                                            data={this.state.firmCapWaterfallData}
                                            color={this.pointColor}
                                            field='amount'
                                            categoryField='label'
                                            summaryField='summary'
                                        >
                                            <ChartSeriesLabels format='n3' position='center'/>
                                        </ChartSeriesItem>
                                    </ChartSeries>
                                    <ChartValueAxis>
                                        <ChartValueAxisItem title={{text: 'Capacity Amount (MW)'}}/>
                                    </ChartValueAxis>
                                </Chart>
                            </AccordionSegment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Net Peak Demand Summary'} centered={true}
                                              contentHidden={this.state.hideContent} loading={this.state.peakDemandLoading}>
                                <Chart
                                    ref={(cmp) => this._peakDemandChart = cmp}
                                    style={{height: 400, width: 800, paddingLeft: '20px', paddingRight: '20px'}}
                                    transitions={false}
                                >
                                    {this.state.seasonCheck === "Summer" ? (
                                        <ChartTitle text='Summer Net Peak Demand Summary'/>
                                    ) : ( <ChartTitle text='Winter Net Peak Demand Summary'/>
                                    )}
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            type='waterfall'
                                            data={this.state.peakDemandWaterfallData}
                                            color={this.pointColor}
                                            field='amount'
                                            categoryField='label'
                                            summaryField='summary'
                                        >
                                            <ChartSeriesLabels format='n3' position='center'/>
                                        </ChartSeriesItem>
                                    </ChartSeries>
                                    <ChartValueAxis>
                                        <ChartValueAxisItem title={{text: 'Demand Amount (MW)'}}/>
                                    </ChartValueAxis>
                                </Chart>
                            </AccordionSegment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Capacity Summary'} contentHidden={this.state.hideContent}>
                                {getReactTable(this.state.FirmCapacitySummary,this.state.FirmCapacitySummaryMetaData,this.state.FirmCapacitySummaryLoading, "FirmCapacitySummaryGrid",0,false,{showPagination:false})}
                            </AccordionSegment>
                        </Grid.Column>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Peak Demand Summary'} contentHidden={this.state.hideContent}>
                                {getReactTable(this.state.PeakDemandSummary,this.state.PeakDemandSummaryMetaData,this.state.PeakDemandSummaryLoading, "PeakDemandSummaryGrid",0,false,{showPagination:false})}
                            </AccordionSegment>
                        </Grid.Column>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Requirements Summary'} contentHidden={this.state.hideContent}>
                                {getReactTable(this.state.ResourceAdequacySummary,this.state.ResourceAdequacySummaryMetaData,this.state.ResourceAdequacySummaryLoading, "ReqSummaryGrid",0,false,{showPagination:false})}
                            </AccordionSegment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}