import * as React from 'react';
import {Button, Container, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {Redirect} from 'react-router';
import history from '../../history';
import {connect} from "react-redux";
import {Principal} from "../../auth";
import * as actionTypes from "../../store/actions/actionTypes";
import GeneralService from "../../services/general-services";
import {Link} from "react-router-dom";

interface LoginState {
    user: string,
    pass: string,
    token: string,
    roles: Principal[],
    forgotPassword: boolean,
    loggingIn: boolean,
    loginMistake: boolean,
    loginMessage: string,
}

interface LoginProps {
    updateCurrentUser: (currentUser: string) => void
    updateUserEntities: (entities: string[]) => void
    updateUserPrincipal: (roles: Principal[]) => void
    userRolesEntities: (userRolesAndEntities: any) => void
    location: any;
}

require('formdata-polyfill');
const client = new GeneralService();

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps, state: LoginState) {
        super(props, state);
        this.state = {
            user: '',
            pass: '',
            token: '',
            roles: [],
            forgotPassword: false,
            loggingIn: false,
            loginMistake: false,
            loginMessage: '',
        };
    }

    componentDidMount(): void {
        if (!client.loggedIn(true)) {
            if (sessionStorage.getItem('uuid') !== null) {
                sessionStorage.removeItem('uuid');
            }
            if (localStorage.getItem('edst_token') !== null) {
                localStorage.removeItem('edst_token');
            }
            if (localStorage.getItem('userName') !== null) {
                localStorage.removeItem('userName');
            }
        } else {
            history.push({
                pathname: this.props.location.state === undefined ? '/' : this.props.location.state.from,
                state: {from: this.props.location.pathname}
            });
        }
    }

    handleChange = (e: any, value: any) => {
        this.setState({[e.target.name.toString()]: value.value} as any);
    };

    login = async () => {
        if (sessionStorage.getItem('uuid') !== null) {
            sessionStorage.removeItem('uuid');
        }
        if (localStorage.getItem('edst_token') !== null) {
            localStorage.removeItem('edst_token');
        }
        if (localStorage.getItem('userName') !== null) {
            localStorage.removeItem('userName');
        }
        client.login(this.state.user, this.state.pass)
            .then((resp: any) => {
                let retry = false;
                if (resp.message === 'Success') {
                    localStorage.setItem('userName', resp.userName);
                    sessionStorage.setItem('uuid', resp.uuid);
                    if (resp.twoFactorEnabled) {
                        history.push({
                            pathname: '/VerifyCode',
                            state: {from: this.props.location.pathname}
                        });
                    } else {
                        localStorage.setItem('edst_token', resp.token);
                        this.props.updateUserPrincipal(resp.userAuthResponse.roles);
                        this.props.updateUserEntities(resp.userAuthResponse.respEntities);
                        this.props.userRolesEntities(resp.userAuthResponse.userRolesAndEntitiesDTO);
                        history.push({
                            pathname: this.props.location.state === undefined ? '/' : this.props.location.state.from,
                            state: {from: this.props.location.pathname}
                        });
                    }
                } else {
                    retry = true;
                    this.setState({
                        loginMistake: true,
                        loggingIn: false
                    });
                    if (resp.message === 'Bad credentials') {
                        this.setState({loginMessage: 'Invalid login attempt'});
                    } else if (resp.message === 'Disabled') {
                        this.setState({loginMessage: 'User account is disabled.'});
                    } else if (resp.message === 'Locked') {
                        this.setState({loginMessage: 'Account has been locked, please try again later'});
                    } else {
                        this.setState({loginMessage: resp.message + '. Try again.'});
                    }
                }
                this.props.updateCurrentUser(this.state.user.trim());
                if (retry) {
                    this.setState({loggingIn: false});
                }
            })
            .catch(() => {
                this.setState({loggingIn: false})
            });
        this.setState({loggingIn: true});
    };

    handleLoginMessageDismiss = () => {
        this.setState({loginMistake: false});
    };

    public render() {
        if (client.loggedIn(true)) {
            return (
                <Redirect to={'/'}/>
            )
        }
        
        return (
            <Container fluid={true}>
                <Grid textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column style={{maxWidth: 450}}>
                            <Header as='h2' color='red' textAlign='center'/>
                            <Form size='large' onSubmit={this.login}>
                                <Segment stacked={true}>
                                    <Form.Input autoFocus={true} required={true} fluid={true} icon='user' name={'user'}
                                                iconPosition='left' placeholder='Username' value={this.state.user}
                                                onChange={this.handleChange}/>
                                    <Form.Input required={true} fluid={true} icon='lock' name={'pass'}
                                                iconPosition='left' placeholder='Password' type='password'
                                                value={this.state.pass} onChange={this.handleChange}/>

                                    <Button color='red' fluid={true} size='large' loading={this.state.loggingIn}>
                                        Login
                                    </Button>
                                </Segment>
                            </Form>
                            {this.state.loginMistake &&
                                <Message
                                    warning={true}
                                    header={'Login Failed'}
                                    content={this.state.loginMessage}
                                    onDismiss={this.handleLoginMessageDismiss}
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Link to='/ForgotPassword' style={{textDecoration: 'underline'}}>
                            Forgot your password?
                        </Link>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.authReducer.currentUser,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentUser: (currentUser: string) => dispatch(
            {type: actionTypes.UPDATE_CURRENT_USER, payload: currentUser}),
        updateUserPrincipal: (roles: Principal[]) => dispatch(
            {type: actionTypes.UPDATE_USER_PRINCIPAL, payload: roles}),
        updateUserEntities: (userEntities: string[]) => dispatch(
            {type: actionTypes.UPDATE_USER_ENTITIES, payload: userEntities}),
        userRolesEntities: (userRolesAndEntities: string[]) => dispatch(
            {type: actionTypes.UPDATE_USER_ROLES_ENTITIES, payload: userRolesAndEntities})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);