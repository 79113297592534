import * as React from 'react';
import {ReactElement} from 'react';
import ReactTable, {Column, ReactTableFunction, TableProps} from "react-table";
import NumberFormat from "react-number-format";
import '../../CSS/react-table-custom.css';
import {
    Container,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Icon,
    Input,
    Popup,
    Segment,
    SegmentProps
} from "semantic-ui-react";
import ReactDatePicker from "react-datepicker";
import {getColumnProps} from "./ReactTableUtil";
import {Principal} from "../../auth";
import moment from "moment";
import {EdstDropDown} from "./EdstDropDown";
import DropDownService from "../../services/drop-down-service";
import {
    determineInputType,
    getColumnMetaByHeaderName,
    getColumnWidth,
    isRequiredAndEmpty,
    keepRequiredInd
} from "./GridUtil";


export interface WithHOCProps {
    columnMetaData?: any[]
    data: any[]
    segmentStyle?: any
    tableStyle?: any
    isChangeSet?: boolean
    updatedRows?: (updatedRows: any[]) => void
    clearFilters?: () => void
    onCellChange?: (e:any, gridNum?:number, cellType?: string) => void
    onFilterChanged?: (e:any) => void
    utilityColumn?: any[]
    changeSetGridInEditMode?: (InEditMode: boolean, gridIndex:number) => void
    errors?:{}
    resource?:string
    tableClassName?: any
    gridInEditMode?: boolean
    editModeGridIndex?:number
    originalData?: any[]
    roles?:Principal[]
    changeSetStatus?: string
    multiFilterSelection?:boolean
    renderCell?: (cellInfo: any) => void
    rowHeight?:number
    inputKey?: any
    inputFilterKey?: any
    filtered?:any
    lockedOut?: boolean
    previousData?: any[]
    entities?: string[]
    sppStaff?: boolean
    isChangeSetAuthor?: boolean
    isAssignedToChangeSetAuthorEntity?: boolean
    ref?:any
    updateModalActive?: () => void
    updateRowId?: (rowId: number) => void
}

interface WithHOCState {
    pageSize:number
    cellBlurred: boolean
    previousCellValue: any
    inputKey: any
    selected:any
}

const dropDownClient = new DropDownService();
const some = require('lodash/some');
const includes = require('lodash/includes');
export const withHOC = <P extends Partial<TableProps>>(Component: React.ComponentType<P>) =>
    class WithHOC extends React.Component<P & WithHOCProps & SegmentProps,WithHOCState> {
        //private renderGlobal: boolean;
        constructor(props: P & WithHOCProps & SegmentProps,state: WithHOCState) {
            super(props,state);
            this.state = {
                pageSize:20,
                cellBlurred: false,
                previousCellValue: null,
                inputKey: null,
                selected:null,
            };

            //this.renderGlobal = true;
        }

        componentDidUpdate(prevProps: Readonly<P & WithHOCProps & SegmentProps>, prevState: Readonly<WithHOCState>, snapshot?: any): void {
            if (prevProps.inputKey !== this.props.inputKey) {
                this.setState({inputKey: this.props.inputKey});
            }

            if (this.props.isChangeSet && !this.props.lockedOut && (this.props.changeSetStatus === 'DRAFT' || (this.props.changeSetStatus === 'PENDING' && (this.props.resource === 'Transactions' || this.props.resource === 'PurchasesSales')))) {
                if (this.props.columnMetaData !== undefined && this.props.columnMetaData.length > 0) {
                    for (let i = 0; i < this.props.columnMetaData.length; i++) {
                        for (let j = 0; j < this.props.data.length; j++) {
                            let column = this.props.columnMetaData[i].nm.charAt(0).toLowerCase() + this.props.columnMetaData[i].nm.substring(1);
                            let cellData = this.props.data[j][column];
                            if (cellData === undefined) {
                                if (this.props.columnMetaData[i]['requiredInd'] === true
                                        && keepRequiredInd(this.props.resource!, this.props.data[j], column)
                                        && this.props.columnMetaData[i]['visInd'] === '1'
                                        && this.props.columnMetaData[i]['editInd'] === '1') {
                                    if(this.props.columnMetaData[i] != null) {
                                        this.setRequiredBackground(column, j);
                                    }
                                }
                            } else {
                                if (isRequiredAndEmpty(this.props.columnMetaData[i], cellData)
                                        && keepRequiredInd(this.props.resource!, this.props.data[j], column)) {
                                    if(column != null) {
                                        this.setRequiredBackground(column, j);
                                    }
                                } else {
                                    if(this.props.columnMetaData[i]['visInd'] === '1') {
                                        let previousDataHolder = this.props.previousData;
                                        let previousRow = this.highlightBinarySearch(this.props.previousData, this.props.data[j]);
                                        if (this.props.previousData !== undefined && previousDataHolder != null && previousDataHolder.length > 0) {
                                            if(previousRow != null) {
                                                if (previousRow.hasOwnProperty(column)) {
                                                    if (this.props.data[j][column] !== previousRow[column]) {
                                                        this.setPreviousChangeBackground(column, j);
                                                    }
                                                }
                                                else {
                                                    if (column === 'action') {
                                                        if (this.props.data[j][column] !== 'MODIFY') {
                                                            this.setPreviousChangeBackground(column, j);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            if (this.props.onCellChange !== undefined) {
                data["textContent"] = data.value;
                this.props.onCellChange(data, this.getGridIndex(), 'dropdown');

                if (data.nullonchange !== undefined && data.nullonchange !== null) {
                    let nullOnChangeColumns = data.nullonchange.split(',');
                    nullOnChangeColumns.forEach((column: any) => {
                        if (column.toLowerCase() === 'tareanm' && data.value === 'NET') {
                            data.value = 'NET';
                        } else {
                            data.value = 'null';
                        }
                        data.id = column;
                        if (this.props.onCellChange !== undefined) {
                            this.props.onCellChange(data, this.getGridIndex(), 'edstDropDown');
                        }
                    });
                }
            }

            this.callEditModeCallBack();
            this.setState({cellBlurred: true});
        };

        inputFocus = (event: any, cellInfo:any) => {
            this.checkActivateSppVerifyModal(cellInfo);
            if(this.props.changeSetStatus === 'DRAFT' || (this.props.changeSetStatus === 'PENDING' && (this.props.resource === 'Transactions' || this.props.resource === 'PurchasesSales')) || ((this.props.changeSetStatus === 'QUEUED' || this.props.changeSetStatus === 'WARN') && cellInfo.column.id === 'sppVerified')) {
                let data = this.props.data;
                if(this.state.previousCellValue === null) {
                    //this.renderGlobal = false;
                    this.setState({previousCellValue:data[cellInfo.index][cellInfo.column.id]});
                }
            }
        };

        handleBlurChange = (e:any, cellInfo:any) => {
            const value = e.target.value;
            if(this.state.previousCellValue !== null && this.state.previousCellValue.toString() !== value) {
                if (this.props.onCellChange !== undefined) {
                    if(e.target.type.toUpperCase() === 'DOUBLE') {
                        e.target.value = parseFloat(value).toFixed(2);
                    }
                    e.target.accKey = cellInfo.index;
                    this.props.onCellChange(e.target, this.getGridIndex(), 'input');
                    this.setState({previousCellValue: null});
                }
            }
            if (this.props.originalData && this.props.originalData[cellInfo.index][cellInfo.column.id].toString() !== value) {
                this.callEditModeCallBack();
                this.setState({cellBlurred: true});
            }
        };

        callEditModeCallBack = () => {
            if (this.props.changeSetGridInEditMode !== undefined && this.props.gridInEditMode === false) {
                this.props.changeSetGridInEditMode(true, this.getGridIndex());
            }
        };
        
        getGridIndex = () => {
            return this.props.editModeGridIndex ? this.props.editModeGridIndex : 0;
        };

        /** Formats the output on the table for percentages and currency*/
        format = (type: any, returnVal: any, previousVal: any, overrideHighlighting: boolean, columnId: string = '') => {
            switch(type) {
                case 'percentage':
                    return (
                        <Popup
                            content={(previousVal === "" || previousVal == null) ? "null" : previousVal} header={'Original Value'} position={'top center'} disabled={!(returnVal !== previousVal && previousVal != null && !overrideHighlighting)}
                            trigger={
                            <div style={{textAlign:'center', verticalAlign:'middle', backgroundColor: (returnVal !== previousVal && previousVal != null && !overrideHighlighting) ? '#5cd65c' : 'transparent'}}>
                                <NumberFormat value={returnVal * 100} displayType={'text'} suffix={'%'} decimalScale={2}
                                              fixedDecimalScale={true}/>
                            </div>}
                        />
                    );
                case 'currency':
                    if (returnVal >= 0) {
                        return (
                            <Popup
                                content={(previousVal === "" || previousVal == null) ? "null" : previousVal} header={'Original Value'} position={'top center'} disabled={!(returnVal !== previousVal && previousVal != null && !overrideHighlighting)}
                                trigger={
                                <div style={{float: 'right', backgroundColor: (returnVal !== previousVal && previousVal != null && !overrideHighlighting) ? '#5cd65c' : 'transparent'}}>
                                    <NumberFormat value={returnVal} displayType={'text'} thousandSeparator={','} prefix={'$'}
                                                  decimalScale={2} fixedDecimalScale={true}/>
                                </div>}
                            />
                        )
                    } else {
                        returnVal = -returnVal;
                        return (
                            <Popup
                                content={(previousVal === "" || previousVal == null) ? "null" : previousVal} header={'Original Value'} position={'top center'} disabled={!(returnVal !== previousVal && previousVal != null && !overrideHighlighting)}
                                trigger={
                                <div style={{float: 'right', backgroundColor: (returnVal !== previousVal && previousVal != null && !overrideHighlighting) ? '#5cd65c' : 'transparent'}}>
                                    <NumberFormat value={returnVal} displayType={'text'} thousandSeparator={','} prefix={'($'}
                                                  suffix={')'} decimalScale={2} fixedDecimalScale={true}/>
                                </div>}
                            />
                        )
                    }
                case 'number':
                    return (
                        <Popup content={(previousVal === "" || previousVal == null) ? "null" : previousVal} header={'Original Value'} position={'top center'} disabled={!(returnVal !== previousVal && previousVal != null && !overrideHighlighting)}
                               trigger={<div style={{float: 'right', backgroundColor: (returnVal !== previousVal && previousVal != null && !overrideHighlighting) ? '#5cd65c' : 'transparent'}}>
                                   {returnVal}
                               </div>}
                        />

                    );
                default:
                    return (
                        <Popup
                            content={(previousVal === "" || previousVal == null) ? "null" : previousVal} header={'Original Value'} position={'top center'} disabled={!(returnVal !== previousVal && previousVal != null && !overrideHighlighting)}
                            trigger={
                                <div style={{
                                    textAlign:'center',
                                    verticalAlign:'middle',
                                    backgroundColor: (returnVal !== previousVal && previousVal != null && !overrideHighlighting) ? '#5cd65c' : 'transparent',
                                    color: (columnId === 'sppVerified' && returnVal === 'YES'? 'green': columnId === 'sppVerified' && returnVal === 'NO'? 'red': ''),
                                    fontWeight: (columnId === 'sppVerified'? "bold": "normal")
                                }}>
                                    {returnVal}
                                </div>}
                        />
                    );
                    // eslint-disable-next-line no-unreachable
                    return returnVal;
            }
        };

        setPreviousChangeBackground = (columnId: string, rowIndex: number) => {
            let color = [] as any;
            if (this.props.columnMetaData !== undefined) {
                let columnMetaData = getColumnMetaByHeaderName(this.props.columnMetaData,columnId);
                if (columnMetaData.type === 'Timestamp') {
                    color = document.querySelectorAll(".react-datepicker__input-container>input#" + columnId + "-" + rowIndex);
                }
                else {
                    if (this.props.data[rowIndex][columnId] !== "") {
                        if ((columnMetaData.dropDownValues !== undefined && columnMetaData.dropDownValues !== null) || columnMetaData.nm.toLowerCase() === 'action') {
                            color = document.querySelectorAll("#" + columnId + "-" + rowIndex + ".ui.fluid.search.selection.dropdown.inputClass" + this.getGridIndex());
                        }
                        else {
                            if (columnMetaData.callbackRoute !== undefined && columnMetaData.callbackRoute !== null) {
                                color = document.querySelectorAll("#" + columnId + "-" + rowIndex + ".ui.fluid.search.selection.dropdown.inputClass" + this.getGridIndex());
                            }
                            else
                            {
                                color = document.querySelectorAll(".inputClass" + this.getGridIndex() + ".ui.input>input#" + columnId + "-" + rowIndex);
                            }
                        }
                    }
                }
                if (color.length > 0) {
                    (color.item(0) as HTMLElement).style.backgroundColor = '#5cd65c';
                }
            }
        };

        removeSuccessBackground = (cellInfo: any) => {
            if (this.props.gridInEditMode && this.state.cellBlurred) {
                if (this.props.originalData && this.props.originalData[cellInfo.index] !== undefined) {
                    if (cellInfo.value !== this.props.originalData[cellInfo.index][cellInfo.column.id]) {
                        let color = [] as any;
                        let typeSeen = '';
                        if (this.props.columnMetaData !== undefined) {
                            let columnMetaData = getColumnMetaByHeaderName(this.props.columnMetaData,cellInfo.column.id);
                            if (columnMetaData.type === 'Timestamp') {
                                color = document.querySelectorAll(".react-datepicker__input-container>input#" + cellInfo.column.id + "-" + cellInfo.index);
                            }
                            else {
                                if ((columnMetaData.dropDownValues !== undefined && columnMetaData.dropDownValues !== null) || columnMetaData.nm.toLowerCase() === 'action') {
                                    color = document.querySelectorAll("#" + cellInfo.column.id + "-" + cellInfo.index + ".ui.fluid.search.selection.dropdown.inputClass" + this.getGridIndex());
                                    color.item(0).classList.remove('active');
                                    color.item(0).classList.remove('visible');
                                    color.item(0).setAttribute("aria-expanded", "false");
                                }
                                else {
                                    if (columnMetaData.callbackRoute !== undefined && columnMetaData.callbackRoute !== null) {
                                        typeSeen = 'edstDropDown';
                                        color = document.querySelectorAll("#" + cellInfo.column.id + "-" + cellInfo.index + ".ui.fluid.search.selection.dropdown.inputClass" + this.getGridIndex());
                                        color.item(0).classList.remove('active');
                                        color.item(0).classList.remove('visible');
                                        color.item(0).setAttribute("aria-expanded", "false");
                                    }
                                    else
                                    {
                                        color = document.querySelectorAll(".inputClass" + this.getGridIndex() + ".ui.input>input#" + cellInfo.column.id + "-" + cellInfo.index);
                                    }
                                }
                            }
                            if (color.length > 0) {
                                if(typeSeen === 'edstDropDown') {
                                    (color.item(0) as HTMLElement).style.backgroundColor = '#fff6f6';
                                }
                                else {
                                    (color.item(0) as HTMLElement).style.backgroundColor = 'white';
                                }
                            }
                        }
                        this.setState({cellBlurred: false});
                    }
                }
            }
        };

        setRequiredBackground = (columnId:string, rowIndex:number) => {
            let color = [] as any;
            if (this.props.columnMetaData !== undefined) {
                let columnMetaData = getColumnMetaByHeaderName(this.props.columnMetaData,columnId);
                if (columnMetaData.type === 'Timestamp') {
                    color = document.querySelectorAll(".react-datepicker__input-container>input#" + columnId + "-" + rowIndex);
                }
                else {
                    if (this.props.data[rowIndex][columnId] !== "") {
                        if ((columnMetaData.dropDownValues !== undefined && columnMetaData.dropDownValues !== null) || columnMetaData.nm.toLowerCase() === 'action') {
                            color = document.querySelectorAll("#" + columnId + "-" + rowIndex + ".ui.fluid.search.selection.dropdown.inputClass" + this.getGridIndex());
                        }
                        else {
                            if (columnMetaData.callbackRoute !== undefined && columnMetaData.callbackRoute !== null) {
                                color = document.querySelectorAll("#" + columnId + "-" + rowIndex + ".ui.fluid.search.selection.dropdown.inputClass" + this.getGridIndex());
                            }
                            else
                            {
                                color = document.querySelectorAll(".inputClass" + this.getGridIndex() + ".ui.input>input#" + columnId + "-" + rowIndex);
                            }
                        }
                    }
                }

                if (color.length > 0) {
                    (color.item(0) as HTMLElement).style.backgroundColor = '#fff6f6';
                    (color.item(0) as HTMLElement).style.borderColor = '#e0b4b4';
                }
            }
        };

        // Iterative function to implement Binary Search
        highlightBinarySearch = (previousData: any, element: any): any => {
            if(element['pK'] === -1 || element['pK'] == null || element['pK'].toString().trim() === '') {
                return null;
            }

            let start=0, end=previousData.length-1, finalPreviousData = previousData;

            if (this.props.resource === 'Transactions') {
                finalPreviousData = previousData.filter((e: any) => {
                    return e.mirrorRec === "0"
                });
            }

            // Iterate while start not meets end
            while (start<=end){

                // Find the mid index
                let mid=Math.floor((start + end)/2);

                if(this.props.resource === 'PurchasesSales') {
                    // If element is present at mid, return True
                    if (finalPreviousData[mid]['pK'] === element['pK']) {
                        return finalPreviousData[mid];
                    }
                    // Else look in left or right half accordingly
                    else if(finalPreviousData[mid]['pK'] < element['pK']) {
                        start = mid + 1;
                    }
                    else {
                        end = mid - 1;
                    }
                }
                else {
                    if (finalPreviousData[mid]['pK'] === element['pK']) {
                        return finalPreviousData[mid];
                    }
                    // Else look in left or right half accordingly
                    else if(finalPreviousData[mid]['pK'] < element['pK']) {
                        start = mid + 1;
                    }
                    else {
                        end = mid - 1;
                    }
                }
            }

            return null;
        };

        handleSpecialStringsLookingLikeNumbers = (columnMetaData:any): string => {
            let colName = columnMetaData.nm.charAt(0).toLowerCase() + columnMetaData.nm.substring(1);
            switch(this.props.resource) {
                case 'Generators':
                    if(colName.startsWith('auxLoad')) {
                        return '0';
                    }
                    else {
                        return '';
                    }
                default:
                    return '';
            }
        };

        handleSpecialCasesForNumbers = (columnMetaData: any): any => {
            let colName = columnMetaData.nm.charAt(0).toLowerCase() + columnMetaData.nm.substring(1);
            switch(this.props.resource) {
                case 'BusDtl':
                    if(colName === 'id') {
                        return null;
                    } else {
                        return 0;
                    }
                case 'Resources':
                    return '';
                case 'GeneratorTestResults':
                    return '';
                default:
                    return 0;
            }
        };

        setDefaultValueBasedOnType = (columnMetaData:any): any => {
            let type = columnMetaData.type as string;
            if (type !== undefined && type !== null) {
                type = type.toUpperCase();
                if (type === 'LONG' || type === 'INTEGER' || type === 'DOUBLE') {
                    return this.handleSpecialCasesForNumbers(columnMetaData);
                }
                else {
                    return this.handleSpecialStringsLookingLikeNumbers(columnMetaData);
                }
            }
            return '';
        };

        allowAdditionsToDropDown = (columnMetaData: any): any => {
            let colName = columnMetaData.nm.charAt(0).toLowerCase() + columnMetaData.nm.substring(1);
            return ((this.props.resource === 'Resources' && colName === 'id')
                || (this.props.resource === 'BusDtl' && colName === 'id'));
        };

        checkActivateSppVerifyModal = (cellInfo: any) =>{
            /** Checks row to see if modal should be shown informing user that row has already been verified by SPP staff
             *  If row has been verified the rowId prop is updated and modal activated.*/
            if(this.props.data[cellInfo.index]['sppVerified'] && (this.props.changeSetStatus === 'DRAFT'|| (this.props.changeSetStatus === 'PENDING' && this.props.resource === 'PurchasesSales'))){
                if(this.props.data[cellInfo.index]['sppVerified'] === 'YES'){
                    //have to check updateModalActive and updateRowId exists first because they are optional props
                    if(this.props.updateModalActive && this.props.updateRowId) {
                        this.props.updateRowId(cellInfo.index);
                        this.props.updateModalActive();
                    }
                }

            }
        }

        getCellDiv = (cellInfo:any,data:any,currentCellError:any[]) => {
            if (this.props.columnMetaData !== undefined) { // todo: combine this into a function that render cell can use too
                let columnMetaData = getColumnMetaByHeaderName(this.props.columnMetaData,cellInfo.column.id);
                let cellValue = data[cellInfo.index][cellInfo.column.id];
                if(cellValue == null) {
                    cellValue = this.setDefaultValueBasedOnType(columnMetaData);
                    data[cellInfo.index][cellInfo.column.id] = this.setDefaultValueBasedOnType(columnMetaData);
                }
                let dropDownAddValue = this.allowAdditionsToDropDown(columnMetaData);
                if (columnMetaData.type === 'Timestamp') {
                    cellValue = cellValue === undefined || cellValue === '' ? null : moment(cellValue, 'MM/DD/YYYY hh:mm a').toDate();
                    return (
                        <div key={this.state.inputKey}>
                            <ReactDatePicker
                                className={'datepickerinput dateInputCell inputClass' + this.getGridIndex()}
                                id={cellInfo.column.id + "-" + cellInfo.index}
                                onChange={(event: any, c: any) => this.onDateChange(event, c, cellInfo)}
                                onBlur={(event: any) => this.onDateBlur(event, cellInfo)}
                                selected={moment(cellValue).isValid() ? cellValue : null}
                                placeholderText={'Select Date'} autoComplete={'off'}
                                isClearable={true}
                                todayButton={'Today'}
                                dateFormat={'MM/dd/yyyy hh:mm a'}
                                peekNextMonth={true}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                onFocus={() =>{this.checkActivateSppVerifyModal(cellInfo)}}
                            />
                        </div>
                    )
                }else if (columnMetaData.callbackRoute !== undefined && columnMetaData.callbackRoute !== null) {
                    return (
                        <div key={this.state.inputKey}>
                            <EdstDropDown upward={false} selection={true} cellInfo={cellInfo} editModeGridIndex={this.getGridIndex()}
                                          onChange={this.dropDownChange} search={true} fluid={true} allowAdditions={dropDownAddValue}
                                          error={isRequiredAndEmpty(columnMetaData, data[cellInfo.index][cellInfo.column.id])
                                                && keepRequiredInd(this.props.resource!,data[cellInfo.index],cellInfo.column.id)}
                                          clearable={true} compact={true} columnMetaData={columnMetaData} value={cellValue}                                          
                                          onClick={this.checkActivateSppVerifyModal}
                            />
                        </div>
                    )
                } else if (columnMetaData.nm === 'NoChange'){
                    if(data[cellInfo.index].noChange === false || data[cellInfo.index].noChange === 'False'){
                        return (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Icon name='delete' color={'red'}/>
                            </div>
                        )
                    } else{
                        return(
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Icon name='check' color={'green'}/>
                            </div>
                        )
                    }

                }

                else if ((columnMetaData.dropDownValues !== undefined && columnMetaData.dropDownValues !== null) || columnMetaData.nm.toLowerCase() === 'action') {
                    let options:DropdownItemProps[] = [];
                    if (columnMetaData.nm.toLowerCase() === 'action') {
                        let {roles} = this.props;
                        if (roles !== undefined) {
                            if (some(roles,{tabNm:this.props.resource,addRole:true})) {
                                options.push({key: 'ADD', value: 'ADD', text: 'ADD'});
                            }
                            if (some(roles,{tabNm:this.props.resource,modifyRole:true})) {
                                options.push({key: 'MODIFY', value: 'MODIFY', text: 'MODIFY'});
                            }
                            if (some(roles,{tabNm:this.props.resource,removeRole:true})) {
                                options.push({key: 'REMOVE', value: 'REMOVE', text: 'REMOVE'});
                            }
                        }
                    } else {
                        options = columnMetaData.dropDownValues.map((ddv: any) => {
                            return {key: ddv, value: ddv, text: ddv}
                        });
                        if (columnMetaData.nm.toLowerCase() === 'tareaid') {
                            options.unshift({key: 'NET', value: 'NET', text: 'NET'})
                        }
                    }
                    return (
                        <div key={this.state.inputKey}>
                            <Dropdown upward={false} options={options} selection={true} nullonchange={columnMetaData.nullOnChange}
                                      error={isRequiredAndEmpty(columnMetaData, data[cellInfo.index][cellInfo.column.id])
                                            && keepRequiredInd(this.props.resource!,data[cellInfo.index],cellInfo.column.id)}
                                      onChange={this.dropDownChange} accKey={cellInfo.index}
                                      className={'inputClass' + this.getGridIndex()} allowAdditions={dropDownAddValue}
                                      id={cellInfo.column.id + "-" + cellInfo.index} search={true} fluid={true}
                                      clearable={true} defaultValue={cellValue != null ? cellValue.toString() : cellValue}
                                      onClick={() => this.checkActivateSppVerifyModal(cellInfo)}

                            />
                        </div>
                    )
                }

                return (
                    <div key={this.state.inputKey}>
                        <Input
                            className={'inputClass' + this.getGridIndex()}
                            onBlur={(e:any) => this.handleBlurChange(e, cellInfo)}
                            onFocus={(e:any) => this.inputFocus(e, cellInfo)}
                            fluid={true}
                            type={determineInputType(columnMetaData)}
                            id={cellInfo.column.id + "-" + cellInfo.index}
                            error={(isRequiredAndEmpty(columnMetaData, cellValue)
                                    && keepRequiredInd(this.props.resource!,data[cellInfo.index],cellInfo.column.id))
                                || (currentCellError !== undefined ? currentCellError.length > 0 : false)}
                            defaultValue={cellValue != null ? cellValue.toString() : cellValue}

                        />
                    </div>
                )
            }

            return null;
        };

        // For transactions, set the appropriate acknowledge indicator to editable,
        // depending on user's assigned entities
        getAcknowledgeIndicator = (cellInfo: any, currentColumn: any) => {
            if (this.props.resource === 'PurchasesSales') {
                if (cellInfo.column.id === 'confAckInd') {
                    if (!this.props.isChangeSetAuthor && this.props.changeSetStatus !== 'Pending') {
                        currentColumn.editInd =  "0";
                    }
                }
            } else {
                // For transactions, set the appropriate acknowledge indicator to editable,
                // depending on user's assigned entities
                if (cellInfo.column.id === 'confAckInd') {
                    // @ts-ignore
                    if (this.props.entities.includes(cellInfo.row.confNm)) {
                        currentColumn.editInd =  "1";
                    } else {
                        currentColumn.editInd =  "0";
                    }
                }
                if (cellInfo.column.id === 'respAckInd') {
                    // @ts-ignore
                    if (this.props.entities.includes(cellInfo.row.respNm)) {
                        currentColumn.editInd =  "1";
                    } else {
                        currentColumn.editInd =  "0";
                    }
                }
            }
        };


        /*
        * Makes a cell editable or a drill down if specified, otherwise returns just the data to display
        */
        renderCell = (cellInfo: any) => {
            const {data, previousData, columnMetaData, errors} = this.props;
            if (columnMetaData) {
                let currentColumn = getColumnMetaByHeaderName(columnMetaData, cellInfo.column.id);

                let currentCellError: any[] = [];
                if (errors !== undefined && errors[cellInfo.index]) {
                    currentCellError = errors[cellInfo.index].filter((value: any) => {
                        return value.nm === currentColumn.nm
                    });
                }

                let overrideHighlighting = false;
                let finalPreviousVal = null;
                if (previousData !== undefined && previousData != null && previousData.length > 0) {
                    let previousRow = this.highlightBinarySearch(previousData, data[cellInfo.index]);
                    //if(previousData[cellInfo.index] !== undefined && previousData[cellInfo.index] != null) {
                    if (previousRow != null && previousRow[cellInfo.column.id] !== undefined) {
                        finalPreviousVal = previousRow[cellInfo.column.id];
                        if (finalPreviousVal == null) {
                            if (previousRow.hasOwnProperty(cellInfo.column.id)) {
                                if (determineInputType(currentColumn) !== 'text') {
                                    finalPreviousVal = 0;
                                } else {
                                    finalPreviousVal = "";
                                }
                            } else {
                                if (cellInfo.column.id === 'action') {
                                    finalPreviousVal = 'MODIFY';
                                }
                            }
                        }
                    }

                    if (cellInfo.column.id === 'creBy' || cellInfo.column.id === 'creDt' || cellInfo.column.id === 'modBy' || cellInfo.column.id === 'modDt') {
                        overrideHighlighting = true;
                    }
                }

                if(cellInfo.column.id === 'sppVerified'){
                    if ((this.props.changeSetStatus === 'QUEUED' || this.props.changeSetStatus === 'WARN') && this.props.sppStaff) {
                        currentColumn.editInd = "1"
                    }
                }

                if ((this.props.resource === 'Transactions' || this.props.resource === 'PurchasesSales')) {
                    if (this.props.changeSetStatus === 'PENDING') {
                        if (!cellInfo.column.id.endsWith('AckInd') && !cellInfo.column.id.endsWith('Cap') && !cellInfo.column.id.endsWith('Capacity')) {
                            currentColumn.editInd = "0";
                        }

                        if (!this.props.sppStaff) {
                            if (cellInfo.column.id === 'confAckInd' || cellInfo.column.id === 'respAckInd') {
                                this.getAcknowledgeIndicator(cellInfo, currentColumn);
                            }
                        }
                    }

                    if (this.props.changeSetStatus === 'DRAFT') {
                        this.getAcknowledgeIndicator(cellInfo, currentColumn);

                        //Disable editing on changeset if not sppStaff, or not assigned to entity of original changeset author
                        if (!this.props.sppStaff && !this.props.isAssignedToChangeSetAuthorEntity) {
                            currentColumn.editInd = "0";
                        }

                        //Enable editing on changeset for sppStaff if StaffEditInd is set to 1
                        if (this.props.resource === 'Transactions') {
                            if (this.props.sppStaff && currentColumn.staffEditInd) {
                                currentColumn.editInd = "1";
                            }
                        }
                    }
                }


                if ((currentColumn.editInd === "1" && this.props.isChangeSet && !this.props.lockedOut
                    && (this.props.changeSetStatus === 'DRAFT' || (this.props.changeSetStatus === 'PENDING' && (this.props.resource === 'Transactions' || this.props.resource === 'PurchasesSales'))|| (currentColumn.nm === 'SppVerified' && (this.props.changeSetStatus === 'QUEUED' || this.props.changeSetStatus === 'WARN')) )) || currentColumn.nm === 'NoChange') {
                    if (currentCellError.length > 0) {
                        return (
                            <Popup content={currentCellError[0] ? currentCellError[0].errorMessage : ''}
                                   trigger={this.getCellDiv(cellInfo, data, currentCellError)}/>
                        )
                    } else {
                        if (isRequiredAndEmpty(currentColumn, data[cellInfo.index][cellInfo.column.id]) && keepRequiredInd(this.props.resource!, data[cellInfo.index], cellInfo.column.id)) {
                            this.removeSuccessBackground(cellInfo);
                            return (
                                <Popup style={{backgroundColor: '#fff6f6'}}
                                       content={currentColumn.vnm + " Is Required Field"}
                                       trigger={this.getCellDiv(cellInfo, data, currentCellError)}/>
                            )
                        } else {
                            if (determineInputType(currentColumn) !== 'text' && cellInfo.value == null) {
                                cellInfo.value = 0;
                                this.props.data[cellInfo.index][cellInfo.column.id] = 0;
                            } else {
                                if (cellInfo.value == null) {
                                    cellInfo.value = "";
                                    this.props.data[cellInfo.index][cellInfo.column.id] = '';
                                }
                            }
                            //Special case for BusNumber field in Bus Details, it is only editable when action is ADD and PK has no value
                            if (this.props.resource === 'BusDtl') {
                                if (cellInfo.column.id === 'id') {
                                    if ((data[cellInfo.index]['pK'] > 0) || data[cellInfo.index]['action'] !== 'ADD') {
                                        return (this.format(currentColumn.type, data[cellInfo.index][cellInfo.column.id], finalPreviousVal, overrideHighlighting));
                                    }
                                }
                            }
                            if (cellInfo.value !== finalPreviousVal) {
                                return (
                                    <Popup
                                        header={'Original Value'}
                                        content={(finalPreviousVal === "" || finalPreviousVal == null) ? "null" : finalPreviousVal}
                                        trigger={this.getCellDiv(cellInfo, data, currentCellError)}
                                    />
                                )
                            }
                            return this.getCellDiv(cellInfo, data, currentCellError);
                        }
                    }
                } else if (currentColumn.callbackRoute !== null && currentColumn.callbackRoute !== '' && data[cellInfo.index][cellInfo.column.id] === 'null') {
                    dropDownClient.getDropDownValues(currentColumn.callbackRoute, cellInfo.original)
                        .then((value: string[]) => {
                            let data = {
                                id: cellInfo.column.id + "-" + cellInfo.index,
                                accKey: cellInfo.index,
                                textContent: value instanceof Array ? null : value,
                                value: value instanceof Array ? null : value
                            };
                            if (this.props.onCellChange !== undefined) {
                                this.props.onCellChange(data, this.getGridIndex(), 'edstDropDown');
                            }
                        });
                    return (this.format(currentColumn.type, data[cellInfo.index][cellInfo.column.id], finalPreviousVal, overrideHighlighting));
                } else {
                    return (this.format(currentColumn.type, data[cellInfo.index][cellInfo.column.id], finalPreviousVal, overrideHighlighting, cellInfo.column.id));
                }
            }
        };

        createFilter = (cellInfo:any, filter:any) => {
            let {multiFilterSelection} = this.props;
            if (this.props.columnMetaData !== undefined) {
                let columnMetaData = getColumnMetaByHeaderName(this.props.columnMetaData,cellInfo.column.id);
                if ((columnMetaData.dropDownValues !== undefined && columnMetaData.dropDownValues !== null) || columnMetaData.nm.toLowerCase() === 'action') {
                    let options:DropdownItemProps[] = [];
                    if (columnMetaData.nm.toLowerCase() === 'action') {
                        let {roles} = this.props;
                        if (roles !== undefined) {
                            if (some(roles,{TabNm:this.props.resource,AddRole:true})) {
                                options.push({key: 'ADD', value: 'ADD', text: 'ADD'});
                            }
                            if (some(roles,{TabNm:this.props.resource,ModifyRole:true})) {
                                options.push({key: 'MODIFY', value: 'MODIFY', text: 'MODIFY'});
                            }
                            if (some(roles,{TabNm:this.props.resource,RemoveRole:true})) {
                                options.push({key: 'REMOVE', value: 'REMOVE', text: 'REMOVE'});
                            }
                        }
                    } else {
                        options = columnMetaData.dropDownValues.map((ddv: any) => {
                            return {key: ddv, value: ddv, text: ddv}
                        });
                        if (columnMetaData.nm.toLowerCase() === 'tareaid') {
                            options.unshift({key: 'NET', value: 'NET', text: 'NET'});
                        }
                    }

                    let filteredValue = cellInfo.filter === undefined ? '' : cellInfo.filter.value;
                    return (
                        <Dropdown options={options} selection={true}
                                  multiple={multiFilterSelection !== undefined ? multiFilterSelection : false}
                                  search={true} fluid={true} clearable={true} upward={false}
                                  onChange={(event, data) => {
                                      this.updateFilter(cellInfo, data.value as string)
                                  }}
                                  value={filteredValue}
                        />
                    )
                } else if (columnMetaData.type === 'Timestamp' || columnMetaData.type === 'Date') {
                    let filteredValue = cellInfo.filter === undefined ? undefined : moment(cellInfo.filter.value, 'MM/DD/YYYY hh:mm a').toDate();
                    return (
                        <ReactDatePicker
                            className={'datepickerinput'}
                            onChange={(event: any, c: any) => this.onFilterDateChange(event, c, cellInfo)}
                            selected={moment(filteredValue).isValid() ? filteredValue : null}
                            placeholderText={'Select Date'} autoComplete={'off'} isClearable={true}
                            todayButton={'Today'}
                            peekNextMonth={true}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            dropdownMode="select"
                        />
                    )
                } else {
                    return (
                        <div key={this.props.inputFilterKey}>
                            <Input
                                fluid={true}
                                onChange={event => {
                                    this.updateFilter(cellInfo, event.target.value);
                                }}
                                type={determineInputType(columnMetaData)}
                                defaultValue={''}
                            />
                        </div>
                    );
                }
            }
            return null;
        };


        onFilterDateChange = (e:any, c:any, cellInfo:any) => {
            if (c.target.value) {
                this.updateFilter(cellInfo,c.target.value);
            } else {
                this.updateFilter(cellInfo, e);
            }
        };

        onDateBlur = (e:any, cellInfo:any) => {
            const date = new Date(e.target.value);
            if (moment(date).isValid()) {
                let data = {
                    id: cellInfo.column.id + "-" + cellInfo.index,
                    accKey: cellInfo.index,
                    textContent: moment(e.target.value).format('MM/DD/YYYY hh:mm a'),
                    value: moment(e.target.value).format('MM/DD/YYYY hh:mm a')
                };

                if (this.props.onCellChange !== undefined) {
                    this.props.onCellChange(data, this.getGridIndex(), 'timestamp');
                }
            } else {
                console.log("Invalid date entered: %s", date);
            }

            //this.renderGlobal = true;
        };

        onDateChange = (e:any,c:any,cellInfo:any) => {
            if (c.target.value) {
                //When user typing date, we will handle this onblur in end
                //this.renderGlobal = false;
            } else {
                let data = {
                    id: cellInfo.column.id + "-" + cellInfo.index,
                    accKey: cellInfo.index,
                    textContent: e,
                    value: e
                };
                if (this.props.onCellChange !== undefined) {
                    this.props.onCellChange(data, this.getGridIndex(), 'timestamp');
                }
            }

            this.callEditModeCallBack();
        };

        updateFilter = (cellInfo:any,value:any) => {
            let filtered: any = [];
            if (this.props.filtered) {
                filtered = [...this.props.filtered];
            }
            filtered = filtered.filter((value: any) => {
                return value.id !== cellInfo.column.id
            });
            if ((value instanceof Array && value.length > 0) || !(value !instanceof Array)) {
                filtered.push({id:cellInfo.column.id,value:value});
            }

            if (this.props.onFilterChanged !== undefined) {
                this.props.onFilterChanged(filtered);
            }
        };

        generateColumnsFromMetaData = () => {
            let {columnMetaData,resource} = this.props;
            const columns: any[] = [];
            if ((columnMetaData !== null) && (typeof columnMetaData !== "undefined")) {
                columnMetaData.sort(function (a: any, b: any) {
                    return a.sortId - b.sortId;
                });

                if (this.props.utilityColumn !== undefined) {
                    this.props.utilityColumn.forEach((column:Column) => {
                        columns.push(column);
                    });
                }

                columnMetaData.forEach((c: any) => {
                    if (c.visInd === "1") {
                        let tempColumn = {
                            Header: c.vnm,
                            accessor: c.nm.charAt(0).toLowerCase() + c.nm.substring(1),
                            Cell: this.props.renderCell !== undefined ? this.props.renderCell : this.renderCell,
                            getProps: (state:any,rowInfo:any,column:any) => getColumnProps(state,rowInfo,column,resource === undefined ? '' : resource as string),
                            Filter: (column:Column,filter:any,onChange:ReactTableFunction) => {
                                return (
                                    this.createFilter(column,filter)
                                )},
                            headerClassName: 'datePickerFilter',
                            width: getColumnWidth(this.props.data,c.nm.charAt(0).toLowerCase() + c.nm.substring(1),c.vnm,c.dropDownValues)

                        };
                        columns.push(tempColumn);
                    }

                });
            }
            return columns;
        };

        pageSizeChanged = (pageSize:number) => {
            localStorage.setItem(`${this.props.resource}BaseCasePageSize`,pageSize.toString());
        };

        pageChanged = () => {
            this.setState({inputKey: Date.now()});
        };

        filterMethod = (filter: any, row: any) => {
            if (filter.value instanceof Array) {
                return includes(filter.value,String(row[filter.id]))
            }
            if(filter.value instanceof Date) {
                filter.value = moment(filter.value).format("MM/DD/YYYY");
            }

            if(filter.value == null) {
                filter.value = '';
            }
            return String(row[filter.id]).toUpperCase().includes(filter.value.toString().toUpperCase());
        };

        determineRowHeight = () => {
            if (this.props.gridInEditMode) {
                return '60px'
            } else if (!this.props.rowHeight) {
                return '25px'
            }
            return `${this.props.rowHeight}px`
        };

        render () : ReactElement {
            return (
                <div>
                <Container fluid={true}>
                    <Segment {...this.props} style={this.props.segmentStyle}>
                        <Component style={this.props.tableStyle}
                                   {...this.props}
                                   filtered={this.props.filtered}
                                   defaultFilterMethod={this.filterMethod}
                                   data={this.props.data}
                                   minRows={this.props.data.length < this.state.pageSize ? this.props.data.length : this.state.pageSize}
                                   columns={this.generateColumnsFromMetaData()}
                                   children={this.props.children}
                                   onPageSizeChange={this.pageSizeChanged}
                                   getTrProps={(state:any, rowInfo:any) => {
                                       if (rowInfo && rowInfo.row) {
                                           return {
                                               onClick: (e:any) => {
                                                   this.setState({
                                                       selected: rowInfo.index
                                                   })
                                               },
                                               style: {
                                                   background: rowInfo.index === this.state.selected ? 'rgba(41,41,41,0.13)' : 'white'
                                               }
                                           }
                                       }else{
                                           return {
                                               style: {
                                                   minWidth: state.rowMinWidth,
                                                   height: this.determineRowHeight()
                                               }}
                                           }
                                       }
                                   }
                                   getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                                   getTheadFilterTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                                   getTdProps={(state:any,rowInfo:any,column:any,instance:any) => {
                                       let columnMeta = getColumnMetaByHeaderName(this.props.columnMetaData,column.id);
                                       if (columnMeta !== undefined && columnMeta !== null) {
                                           if ((columnMeta.dropDownValues !== undefined && columnMeta.dropDownValues !== null) || columnMeta.nm.toLowerCase() === 'action' || (columnMeta.callbackRoute !== undefined && columnMeta.callbackRoute !== null)) {
                                               return {
                                                   style: {
                                                       overflow:'visible'
                                                   }
                                               }
                                           }
                                       }

                                       return {}
                                   }}
                                   onPageChange={this.pageChanged}
                        />
                    </Segment>
                </Container>

                </div>
            )
        }
    };

export default withHOC(ReactTable);