import * as React from 'react';
import {RouteComponentProps} from "react-router";
import {
    Button,
    ButtonProps,
    Checkbox,
    CheckboxProps,
    Container,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Grid,
    Header,
    Input,
    InputOnChangeData,
    Segment,
    TextArea,
    TextAreaProps
} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {Principal} from "../../auth";

interface UserManagementState {
    userId: number,
    selectedUser: string,
    name: string,
    longName: string,
    phoneNumber: string,
    email: string,
    comments: string,
    activeInd: boolean,
    competitiveInd: boolean,
    nonCompetitiveInd: boolean,
    changesetNotificationInd: boolean,
    reminderNotificationInd: boolean,
    existingUsers: any,
    dropDownLoading: boolean,
    roles: any,
    entities: DropdownItemProps[],
    checkedRoles: any,
    checkedEntities: string[],
    originalEntities: string[],
    originalRoles: string[]
    rolesUpdating: boolean
    entitiesUpdating: boolean
}

interface UserManagementProps {
    roles: Principal[]
    currentUser: string,
    userRolesEntities: any,
}

const some = require('lodash/some');
const find = require('lodash/find');
export class UserManagement extends React.Component<RouteComponentProps<{}> & UserManagementProps, UserManagementState> {
    constructor(props: RouteComponentProps<{}> & UserManagementProps, state: UserManagementState) {
        super(props, state);
        this.state = {
            userId: -1,
            selectedUser: '',
            name: '',
            longName: '',
            phoneNumber: '',
            email: '',
            comments: '',
            activeInd: false,
            competitiveInd: false,
            nonCompetitiveInd: false,
            changesetNotificationInd: false,
            reminderNotificationInd: false,
            existingUsers: [],
            dropDownLoading: true,
            roles: [],
            entities: [],
            checkedRoles: [],
            checkedEntities: [],
            originalEntities: [],
            originalRoles: [],
            rolesUpdating: false,
            entitiesUpdating: false
        };
    }

    componentDidMount(): void {
        this.getUsers();
        //TODO: is there another way to analyze this without sending table/role combos to UI?
        if (some(this.props.roles,{tabNm:'Users',sppStaff:true})) {
            this.setInitialState();
        }

    }

    setInitialState = () =>{
        this.setState({
            userId: -1,
            name: '',
            longName: '',
            phoneNumber: '',
            email: '',
            comments: '',
            activeInd: false,
            competitiveInd: false,
            nonCompetitiveInd: false,
            changesetNotificationInd: false,
            reminderNotificationInd: false,
            existingUsers: [],
            roles: [],
            entities: [],
            checkedRoles: [],
            checkedEntities: [],
            originalEntities: [],
            originalRoles: []
        });
    }

    getUsers = () => {
        let existingUsers: DropdownItemProps[] = [];
        axios.get(`/api/auth/user/getExistingUsers`).then( resp => {
            resp.data.forEach((r:any) =>{
                existingUsers.push({key:r,text:r,value:r});
            });
            this.setState({existingUsers,dropDownLoading:false});
        }).catch(resp => {
            toast.error(`Error retrieving user list - ${resp.message}`)
        });
    };

    getUserManagementDetails = (userName: string) => {
        axios.get(`/api/auth/user/getUserManagementDetails`,
            {params:{userName: userName}}).then( resp => {
                this.setState({roles:resp.data.roleList,
                    userId: resp.data.id,
                    name: resp.data.nm,
                    longName: resp.data.longNm,
                    phoneNumber: resp.data.phone,
                    email: resp.data.email,
                    comments: resp.data.comments == null ? '' : resp.data.comments,
                    activeInd: resp.data.activeInd,
                    competitiveInd: resp.data.ndaCompDutyInd,
                    nonCompetitiveInd: resp.data.ndaNonCompDutyInd,
                    changesetNotificationInd: resp.data.chgSNotificationInd,
                    reminderNotificationInd: resp.data.reminderNotificationInd,
                    checkedRoles:resp.data.checkedRoleList,
                    originalRoles:resp.data.checkedRoleList,
                    selectedUser: userName})
            let checkedEntities:any = [];
            let entities:DropdownItemProps[] = [];
            resp.data.checkedEntityList.forEach((entity:any) => {
                checkedEntities.push(entity.respEntTypeNm);
            })
            resp.data.respEntityList.forEach((entity:any) => {
                entities.push({key:entity.id,text:entity.respEntTypeNm,value:entity.respEntTypeNm});
            });
            if (this.state.entities.length === 0) {
                this.setState({entities})
            }
            this.setState({checkedEntities,originalEntities:checkedEntities})

        }).catch(resp => {
            toast.error(`Error retrieving roles - ${resp.message}`)
        });
    };

    handleChange = (event: React.SyntheticEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>, data: InputOnChangeData | TextAreaProps) => {
        this.setState({ [data.name.toString()] : data.value } as any);
    };

    checkBoxChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        this.setState({ [data.name as string] : data.checked } as any);
    };

    clearButtonClick = () => {
        this.setState({
            selectedUser: '',
            name: '',
            longName: '',
            phoneNumber: '',
            email: '',
            comments: '',
            activeInd: false,
            competitiveInd: false,
            nonCompetitiveInd: false,
            checkedRoles: [],
            checkedEntities: []
        });

        if (!some(this.props.roles,{tabNm:'Users',sppStaff:true})) {
            this.setState({roles:[],entities:[]})
        }
    };

    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.getUserManagementDetails(data.value as string)
    };

    roleCheckboxChanged = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let checkedRoles = [...this.state.checkedRoles];
        if (data.checked) {
            checkedRoles.push(data.label);
        } else {
            checkedRoles = checkedRoles.filter(value => {
                return value !== data.label
            });
        }
        this.setState({checkedRoles})
    };

    entityCheckboxChanged = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let checkedEntities = [...this.state.checkedEntities];
        if (data.checked) {
            checkedEntities.push(data.label as string);
        } else {
            checkedEntities = checkedEntities.filter(value => {
                return value !== data.label
            });
        }
        this.setState({checkedEntities})
    };

    entityDropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({checkedEntities:data.value as string[]})
    };

    getEntityIds = (entityNames: any[]) =>{
        return entityNames.map((value:string) => {
            return find(this.state.entities, {text:value}).key;
        });
    }

    addResponsibleEntities = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {

        let addedResponsibleEntityNames = this.state.checkedEntities.filter((value:string) => {
            return !this.state.originalEntities.includes(value);
        });

        let deletedResponsibleEntityNames = this.state.originalEntities.filter((value:string) => {
            return !this.state.checkedEntities.includes(value);
        });

        let modifyEntities:any = {
            userId: this.state.userId,
            addResponsibleEntities: this.getEntityIds(addedResponsibleEntityNames),
            deleteResponsibleEntities: this.getEntityIds(deletedResponsibleEntityNames)
        }

        axios.put('/api/auth/user/modifyEntities',modifyEntities).then(resp => {
            toast.success(resp.data);
            this.setState({entitiesUpdating:false})
        }).catch(resp => {
            toast.error(`Error updating responsible entities for ${this.state.name}`);
            this.setState({entitiesUpdating:false})
        });
        this.setState({entitiesUpdating:true});
    };

    addRoles = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        let addedRoles = this.state.checkedRoles.filter((value:string) => {
            return !this.state.originalRoles.includes(value);
        });

        let deletedRoles = this.state.originalRoles.filter((value:string) => {
            return !this.state.checkedRoles.includes(value);
        });

        let modifyRoles:any = {
            userNm: this.state.name,
            addRoles: addedRoles,
            deleteRoles: deletedRoles
        }
        axios.put('/api/auth/user/modifyRoles',modifyRoles).then(resp => {
            toast.success(resp.data);
            this.setState({rolesUpdating:false});
        }).catch(resp => {
            toast.error(`Error updating roles for ${this.state.name}`);
            this.setState({rolesUpdating:false});
        });
        this.setState({rolesUpdating:true});
    };

    modifyUser = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        let modifiedUser: any = {
            nm: this.state.name,
            longNm: this.state.longName.trim(),
            phone: this.state.phoneNumber,
            email: this.state.email.trim(),
            comments: this.state.comments,
            activeInd: this.state.activeInd,
            ndaCompDutyInd: this.state.competitiveInd,
            ndaNonCompDutyInd: this.state.nonCompetitiveInd,
            chgSNotificationInd: this.state.changesetNotificationInd,
            reminderNotificationInd: this.state.reminderNotificationInd,
        }

        axios.put('/api/auth/user/modifyUser', modifiedUser)
            .then(resp => {
                toast.success(resp.data);
            })
            .catch(() => {
                toast.error(`Error updating user details for ${this.state.name}`);
            });
    };

    render() {
        let {name,longName,
            phoneNumber,
            email,
            comments,
            activeInd,
            competitiveInd,
            nonCompetitiveInd,
            changesetNotificationInd,
            reminderNotificationInd
        } = this.state;
        return (
            <Container fluid={true} style={{paddingLeft:'50px', paddingRight:'50px', paddingTop: '50px'}}>
                <div/>
                <Header size={'huge'}>User Management</Header>
                <Segment>
                    <Header>User Details</Header>
                    <Grid>
                        <Grid.Row style={{display:'block',paddingLeft:'20px'}}>
                            <p><b>Select User:</b></p>
                            <Dropdown selection={true} search={true}
                                      placeholder={'User Names'} loading={this.state.dropDownLoading}
                                      value={this.state.selectedUser} options={this.state.existingUsers}
                                      onChange={this.dropDownChange}/>
                        </Grid.Row>
                        <Grid.Row columns={8}>
                            <Grid.Column>
                                <p><b>Name:</b></p>
                                <Input disabled={true} value={name}
                                       size={"mini"} type={'text'}
                                       placeholder={'User Name'} name={'roleName'}
                                       required={true} onChange={this.handleChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Long Name:</b></p>
                                <Input value={longName} size={"mini"}
                                       type={'text'} placeholder={'Long Name'}
                                       name={'longName'} required={true}
                                       onChange={this.handleChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Phone No:</b></p>
                                <Input value={phoneNumber} size={"mini"}
                                       type={'text'} name={'phoneNumber'}
                                       placeholder={'User Phone No'} onChange={this.handleChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Email:</b></p>
                                <Input value={email} size={"mini"}
                                       type={'text'} name={'email'}
                                       placeholder={'User Email'} onChange={this.handleChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Comments:</b></p>
                                <TextArea value={comments} placeholder={'Enter Comments here:'}
                                          name={'comments'} onChange={this.handleChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>ActiveInd</b></p>
                                <Checkbox checked={activeInd} name={'activeInd'} onChange={this.checkBoxChange} readOnly={!this.props.userRolesEntities.sppStaff}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Competitive Indicator</b></p>
                                <Checkbox checked={competitiveInd} name={'competitiveInd'} onChange={this.checkBoxChange} readOnly={!this.props.userRolesEntities.sppStaff}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>NonCompetitive Indicator</b></p>
                                <Checkbox checked={nonCompetitiveInd} name={'nonCompetitiveInd'} onChange={this.checkBoxChange} readOnly={!this.props.userRolesEntities.sppStaff}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingLeft:'20px'}}>
                            <p><b>Email Notifications:</b></p>
                        </Grid.Row>
                        <Grid.Row columns={6}>
                            <Grid.Column>
                                <Checkbox label={'Changeset Emails'} checked={changesetNotificationInd} name={'changesetNotificationInd'} onChange={this.checkBoxChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Checkbox label={'Posting Reminder Emails'} checked={reminderNotificationInd} name={'reminderNotificationInd'} onChange={this.checkBoxChange}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingLeft:'20px'}}>
                            <Button size={'mini'} color={'black'} onClick={this.modifyUser} disabled={!this.state.selectedUser}>Modify</Button>
                            <Button size={'mini'} color={'black'} onClick={this.clearButtonClick}>Clear</Button>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header>Roles</Header>
                    <Grid padded={true} columns={5} >
                        {this.state.roles.map((role:any) => {
                            return (
                                <Grid.Column key={role}>
                                    <Checkbox size={'mini'}
                                              label={role} disabled={!some(this.props.roles,{tabNm:'Users',sppStaff:true})}
                                              checked={this.state.checkedRoles.includes(role)}
                                              onChange={this.roleCheckboxChanged}/>
                                </Grid.Column>
                            )
                        })}
                        <Grid.Row>
                            <Button size={'mini'} color={'black'}
                                    content={'Assign'} disabled={!some(this.props.roles,{tabNm:'Users',sppStaff:true})}
                                    loading={this.state.rolesUpdating} onClick={this.addRoles}/>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header>Assigned Entities</Header>
                    <Grid padded={true}>
                        <Grid.Row>
                            <Dropdown multiple={true} selection={true} clearable={true}
                                      search={true} options={this.state.entities} disabled={!some(this.props.roles,{tabNm:'Users',sppStaff:true})}
                                      onChange={this.entityDropDownChange} value={this.state.checkedEntities}/>
                        </Grid.Row>
                        <Grid.Row>
                            <Button size={'mini'} color={'black'}
                                    content={'Assign'} disabled={!some(this.props.roles,{tabNm:'Users',sppStaff:true})}
                                    loading={this.state.entitiesUpdating} onClick={this.addResponsibleEntities}/>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        userRoles: state.authReducer.roles,
        userRolesEntities: state.authReducer.userRolesEntities,
    }
};

export default connect(mapStateToProps)(UserManagement);