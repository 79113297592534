import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {useState} from 'react';
import {DropDownOption} from './DropDownCell';
import {isDifferent, processDropDownListOptions} from '../../../services/data-grid-service';
import {PurchasesSalesResourceOptions} from '../../../interfaces/purchases-sales-interface';

interface OwnershipCompanyDropDownCellProps extends GridCellProps {
    resourceOwnerOptions: Array<PurchasesSalesResourceOptions>
}

export const OwnershipCompanyDropDownCell = (props: OwnershipCompanyDropDownCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData, plantNm, resourceNm} = dataItem;
    const dataValue: string = field && plantNm && dataItem[field] ? dataItem[field] : '';
    const dataOption: DropDownOption = {text: dataValue, value: dataValue};
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);

    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value.value
            });
        }
    };

    const [options, setOptions] = useState<Array<DropDownOption>>([dataOption]);

    const onOpen = () => {
        let ownershipCompanyOptions: Array<string>;
        if (plantNm && resourceNm) {
            ownershipCompanyOptions = Array.from(new Set(props.resourceOwnerOptions
                .filter((item) => item.plantNm === plantNm && item.resourceNm === resourceNm)
                .map((item) => item.ownershipCompanyNm)
            ));
        } else if (plantNm && !resourceNm) {
            ownershipCompanyOptions = Array.from(new Set(props.resourceOwnerOptions
                .filter((item) => item.plantNm === plantNm)
                .map((item) => item.ownershipCompanyNm)
            ));
        } else {
            ownershipCompanyOptions = [''];
        }
        setOptions(processDropDownListOptions(ownershipCompanyOptions));
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit ?
                isChanged ?
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        disabled={!plantNm}
                        title={originalValue}
                    />
                    :
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        disabled={!plantNm}
                    />
                :
                dataValue
            }
        </td>
    );
};

export const getOwnershipCompanyDropDownCell = (resourceOwnerOptions: Array<PurchasesSalesResourceOptions>) => {
    return (props: GridCellProps) => (
        <OwnershipCompanyDropDownCell {...props} resourceOwnerOptions={resourceOwnerOptions}/>
    );
};