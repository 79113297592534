import * as React from 'react';
import {Button, Icon, Tab} from 'semantic-ui-react'
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";
import ContactModal from "./ContactModal";
import ContactGrid from "./ContactGrid";
import StakeholderModal from "./StakeholderModal";
import DeleteModal from "../../common/DeleteModal";
import StakeholderGrid from "./StakeholderGrid";

interface TabGridsProps {
    contactModalActive: boolean,
    activeTab: string,
    updateContactModalActive: (contactModalActive: boolean) => void,
    updateStakeholderModalActive: (stakeholderModalActive: boolean) => void,
    updateAddOrModifyContact: (addOrModifyContact: string) => void,
    sppStaff: boolean,
    updateActiveTab: (activeTab: string) => void
}

interface TabGridsState {
    contactModalLoaded: boolean
}

class TabGrids extends React.Component<TabGridsProps,TabGridsState> {
    constructor(props: TabGridsProps) {
        super(props);
        this.state = {
            contactModalLoaded: false,
        }
    }

    panes = [
        {
            menuItem: { key: 'contacts', icon: 'user', content: 'Contacts' },
            render: () => <Tab.Pane>
                <Button icon={true} labelPosition='left' onClick={this.addContactClick} color={'green'}>
                    <Icon name='user plus'/>
                    Add Contact/Role
                </Button>
                    <ContactGrid/>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'stakeholders', icon: 'group', content: 'Stakeholders' },
            render: () => <Tab.Pane>
                <Button icon={true} labelPosition='left' onClick={this.addStakeholderClick} color={'green'} disabled={!this.props.sppStaff}>
                    <Icon name='group'/>
                    Add Stakeholder
                </Button>
                <StakeholderGrid />
            </Tab.Pane>
        }
    ];

    addContactClick = () =>{
        this.props.updateContactModalActive(true);
        this.props.updateAddOrModifyContact('Add')
    }

    addStakeholderClick = () =>{
        this.props.updateStakeholderModalActive(true);
        this.props.updateAddOrModifyContact('Add')
    }

    tabChange = (e: any) => {
        this.props.updateActiveTab(e.target.innerText);
    }

    render(){
        return(
            <div>
                <ContactModal/>
                <StakeholderModal/>
                <DeleteModal/>
                <Tab panes={this.panes} onTabChange={this.tabChange} />
            </div>
        )
    }
}




const mapStateToProps = (state: any) => {
    return {
        contactModalActive: Boolean(state.contactReducer.contactModalActive),
        addStakeholderModalActive: Boolean(state.contactReducer.addStakeholderModalActive),
        updateAddOrModifyContact: state.contactReducer.addOrModifyContact,
        sppStaff: state.authReducer.userRolesEntities.sppStaff,
        activeTab: state.defaultReducer.activeTab,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateContactModalActive: (contactModalActive: boolean) => dispatch(
            {type: actionTypes.SET_CONTACTMODALACTIVE, payload: contactModalActive}
        ),
        updateStakeholderModalActive: (stakeholderModalActive: boolean) => dispatch(
            {type: actionTypes.SET_STAKEHOLDERMODALACTIVE, payload: stakeholderModalActive}
        ),
        updateAddOrModifyContact: (addOrModifyContact: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYCONTACT, payload: addOrModifyContact}
        ),
        updateActiveTab: (activeTab: string) => dispatch(
            {type: actionTypes.SET_ACTIVETAB, payload: activeTab}
        ),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TabGrids);

