import {Input} from '@progress/kendo-react-inputs';
import {GridCellProps} from '@progress/kendo-react-grid';
import {isDifferent} from '../../../services/data-grid-service';

interface MaxNumberCellProps extends GridCellProps {
    maxDigits: number
}

export const MaxNumberCell = (props: MaxNumberCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value = field && dataItem[field] ? dataItem[field] : '';
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    const onChange = (e: any) => {
        if (props.onChange && (/^\d+$/.test(e.target.value) || e.target.value === '')) { // only change if all characters are numbers
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: +e.target.value,
            });
        }
    };
    
    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center', backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit 
                ? isChanged 
                    ? <Input value={value} maxLength={props.maxDigits} onChange={onChange} title={originalValue}/>
                    : <Input value={value} maxLength={props.maxDigits} onChange={onChange}/>
                : value 
            }
        </td>
    );
};

export const getMaxNumberCell = (maxDigits: number) => {
    return ((props: GridCellProps) => 
            <MaxNumberCell {...props} maxDigits={maxDigits}/>
    );
};