import React from 'react';
import {Container, Header} from 'semantic-ui-react';
import PrmGrid from './PrmGrid';


const RaDataManagement: React.FC = () => {
    return (
        <Container fluid={true} style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '50px'}}>
            <Header size={'large'}>RA Data Management</Header>
            <PrmGrid
                title='Manage PRM Values'
                dataApi='/api/auth/data-manage/prm/resource-type'
                columnApi='/api/auth/data-manage/prm/columns/resource-type'
            />
            <br/>
            <PrmGrid
                title='LRE PRM Identification'
                dataApi='/api/auth/data-manage/prm/identification'
                columnApi='/api/auth/data-manage/prm/columns/identification'
            />
        </Container>
    );
};

export default RaDataManagement;