import * as React from 'react';
import {
    Button,
    Container,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Grid,
    Header,
    Icon,
} from "semantic-ui-react";
import axios from 'axios';
import {toast} from "react-toastify";
import {Principal} from "../../auth";
import {connect} from "react-redux";
import GeneralService from "../../services/general-services";

interface ReferenceDocumentsState {
    titles: DropdownItemProps[]
    dropDownLoading: boolean
    exporting: boolean
    downloadEnabled: boolean
    downloadFileName : string
    updateEnabled: boolean
    updateFileName : string
    updateFileEnabled : boolean
    updateFile: any
    uploadFile : any
    uploadFileName : string
    uploadFileNameUpdated: boolean
    uploadEnabled : boolean
    userTypeId : number
}

interface ReferenceDocumentsProps{
    currentUser: string,
    roles:Principal[],
    userType: string,
    updateUserType: (userType: string) => void,
    userRolesEntities : any
}

const acceptedTypes: string[] = [
    '.xlsx',
    '.pdf',
    '.csv',
    '.docx'
];


const generalService = new GeneralService();
class ReferenceDocuments extends React.Component<ReferenceDocumentsProps, ReferenceDocumentsState> {
    constructor(props: ReferenceDocumentsProps, state: ReferenceDocumentsState) {
        super(props, state);
        this.state = {
            titles: [],
            dropDownLoading: true,
            exporting: false,
            downloadEnabled: false,
            downloadFileName:'',
            uploadFileName: '',
            uploadFileNameUpdated: false,
            userTypeId : 3,
            updateFile: '',
            uploadFile: '',
            updateEnabled: false,
            updateFileName : '',
            uploadEnabled : false,
            updateFileEnabled : false
        };
    }

    componentDidMount(): void {
        let userTypeId: number = this.getUserInfo();
        this.getReferenceDocTitles(userTypeId)
    }

    componentDidUpdate():void{

    }

    getUserInfo = (): number =>{
        let { userTypeId } = this.state;
        let modDevUser = this.props.userRolesEntities.md;
        let rAUser = this.props.userRolesEntities.ra;
        if(rAUser && modDevUser){
            userTypeId = 2;
        }
        else if(rAUser){
            userTypeId = 1;
        }else if(modDevUser) {
            userTypeId = 0;
        }
        this.setState({userTypeId:userTypeId});
        return userTypeId;
    }

    getReferenceDocTitles = (teamTypeId:number) => {
        this.setState({titles: []})
        axios.get(`/api/auth/referenceDocument/documentsTitles`,
            {
                params:
                    {
                        TeamTypeId: teamTypeId
                    }
            }).then(resp => {
            let titles = [...this.state.titles];
            resp.data.forEach((value:any) => {
                titles.push({key:value,text:value,value:value})
            });
            this.setState({titles,dropDownLoading:false})
        }).catch(resp => {
            toast.error(`Error retrieving Titles for drop-down - ${resp.message}`);
            this.setState({dropDownLoading:false})
        })
    };

    isValidFileType = (fileType: string): boolean => {
        return acceptedTypes.includes(fileType.substring(fileType.indexOf('.')));
    };

    removeFile = () => {
        let removeCheck: boolean = true;
        if (this.state.downloadFileName === 'RA Contact List' || this.state.downloadFileName === 'EDST User Guide') {
            removeCheck = false;
            toast.info(this.state.downloadFileName + " cannot be deleted, only updated")
            this.setState({
                downloadEnabled: false,
                downloadFileName: '',
                uploadFileName: '',
                uploadFileNameUpdated: false,
                updateFile: '',
                uploadFile: '',
                updateEnabled: false,
                updateFileName: '',
                uploadEnabled: false,
                updateFileEnabled: false
            })
            this.getReferenceDocTitles(this.state.userTypeId);
        }
        if (removeCheck) {
            axios.post('/api/auth/referenceDocument/deleteRefDoc', null, {
                params: {filename: this.state.downloadFileName},
                responseType: 'arraybuffer'
            })
                .then(() => {
                    toast.success("Successfully deleted " + this.state.downloadFileName);
                    this.setState({
                        downloadEnabled: false,
                        downloadFileName: '',
                        uploadFileName: '',
                        uploadFileNameUpdated: false,
                        updateFile: '',
                        uploadFile: '',
                        updateEnabled: false,
                        updateFileName: '',
                        uploadEnabled: false,
                        updateFileEnabled: false
                    });
                    this.getReferenceDocTitles(this.state.userTypeId);
                })
                .catch(error => {
                    toast.error(`Error deleting File from server - ${error.message}`);
                });
        }
    };


    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({
            downloadFileName: data.value as string,
            downloadEnabled: true,
            [data.name]: data.value as string,
            updateFileName: data.value as string,
            updateEnabled: true
        } as any);
    };

    fileUploaded = (e: any) => {
        if (e.target.files.length > 0) {
            let fileName = e.target.files[0].name.toLowerCase();
            if (fileName.includes('..')) {
                toast.error('Invalid file name contains ..');
                return;
            }

            if (this.isValidFileType(fileName)) {
                this.setState({
                    uploadFile: e.target.files[0],
                    uploadEnabled: true
                });
            } else {
                toast.error('Only the following formats are allowed (xlsx, csv, pdf, docx)');
            }
        }
    };

    fileUpdated = (e: any) => {
        if (e.target.files.length > 0) {
            let fileName = e.target.files[0].name.toLowerCase();
            if (fileName.includes('..')) {
                toast.error('Invalid file name contains ..');
                return;
            }

            if (this.isValidFileType(fileName)) {
                this.setState({
                    updateFile: e.target.files[0],
                    updateFileEnabled: true
                });
            } else {
                toast.error('Only the following formats are allowed (xlsx, csv, pdf, docx)');
            }
        }
    };

    uploadFile = () => {
        let formData = new FormData();
        formData.append('file', this.state.uploadFile);
        formData.set('teamTypeId', this.state.userTypeId.toString());
        formData.set('fileShortName',this.state.uploadFileName);
        axios.post(`/api/auth/referenceDocument/UploadRefDoc`, formData)
            .then(resp => {
                if (resp.data.toString().includes('Already exists')) {
                    toast.error(resp.data);
                } else
                    toast.success(resp.data);
                this.setState({downloadEnabled: false,
                    downloadFileName:'',
                    uploadFileName: '',
                    uploadFileNameUpdated: false,
                    updateFile: '',
                    uploadFile: '',
                    updateEnabled: false,
                    updateFileName : '',
                    uploadEnabled : false,
                    updateFileEnabled : false});
                this.getReferenceDocTitles(this.state.userTypeId);
            })
            .catch(error => {
                toast.error(`Error uploading file - ${error.message}`);
            });
    };

    updateFile = () => {
        let formData = new FormData();
        formData.append('file', this.state.updateFile);
        formData.set('teamTypeId', this.state.userTypeId.toString());
        formData.set('fileShortName', this.state.updateFileName);
        axios.post(`/api/auth/referenceDocument/UpdateRefDoc`, formData)
            .then(resp => {
                toast.success(resp.data);
                this.setState({
                    downloadEnabled: false,
                    downloadFileName: '',
                    uploadFileName: '',
                    uploadFileNameUpdated: false,
                    updateFile: '',
                    uploadFile: '',
                    updateEnabled: false,
                    updateFileName: '',
                    uploadEnabled: false,
                    updateFileEnabled: false
                })
                this.getReferenceDocTitles(this.state.userTypeId);
            })
            .catch(error => {
                toast.error(`Error updating file - ${error.message}`);
            });
    };

    handleOnChange = (event: any) => {
        this.setState({
            uploadFileName: event.target.value,
            uploadFileNameUpdated: true
        });
    };

    inputClick = () => {
        document.getElementById('uploadInput')!.click();
    };
    
    updateClick = () =>{
        document.getElementById('updateInput')!.click();
    }

    render() {
        return (
            <Container fluid={true} style={{paddingLeft: '50px', paddingRight: '50px', paddingTop: '50px'}}>
                <Grid>
                    <Grid.Row columns={2}>
                        <Header>Reference Documents</Header>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <p><b>Select Document To Download:</b></p>
                            <Dropdown
                                search={true}
                                selection={true}
                                placeholder={'Choose File'}
                                options={this.state.titles}
                                loading={this.state.dropDownLoading}
                                onChange={this.dropDownChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4}>
                        <Grid.Column>
                            <Button
                                size={'mini'}
                                color={'black'}
                                onClick={generalService.downloadFile}
                                docname={this.state.downloadFileName}
                                disabled={!this.state.downloadEnabled}
                                content={'Download'}
                            />
                            <Button
                                size={'mini'}
                                color={'blue'}
                                disabled={!this.state.updateEnabled}
                                onClick={this.updateClick}
                            >
                                <Icon name={"upload"}/>
                                {this.state.updateFile!.name ? this.state.updateFile!.name : 'Update Selected File'}
                            </Button>
                            <input
                                id={'updateInput'}
                                accept={'xlsx,pdf,csv,docx'}
                                type={'file'}
                                color={'blue'}
                                style={{display: 'none'}}
                                onChange={this.fileUpdated}
                            />
                            <Button
                                size={'mini'} color={'blue'}
                                onClick={this.updateFile}
                                disabled={!this.state.updateFileEnabled}
                                style={{visibility: this.state.updateFile!.name ? 'visible' : 'hidden' && this.state.updateEnabled}}
                            >
                                Update
                            </Button>
                            <Button
                                size={'mini'}
                                color={'black'}
                                onClick={this.removeFile}
                                disabled={!this.state.downloadEnabled}
                                content={'Delete'}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <p><b>Upload:</b></p>
                    </Grid.Row>
                    <Grid.Row columns={4}>
                        <Grid.Column>
                            <Form.Field>
                                <Form.Input
                                    type={'text'}
                                    placeholder={'New Document Name'}
                                    onChange={this.handleOnChange}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4}>
                        <Grid.Column>
                            <Button
                                size={'mini'}
                                color={'black'}
                                onClick={this.inputClick}
                                disabled={!this.state.uploadFileNameUpdated}
                            >
                                <Icon name={"upload"}/>
                                {this.state.uploadFile!.name ? this.state.uploadFile!.name : 'Upload New File'}
                            </Button>
                            <input
                                id={'uploadInput'}
                                accept={'xlsx,pdf,csv,docx'}
                                type={'file'}
                                color={'black'}
                                style={{display: 'none'}}
                                onChange={this.fileUploaded}
                            />
                            <Button
                                size={'mini'} color={'black'}
                                onClick={this.uploadFile}
                                disabled={!this.state.uploadEnabled}
                                style={{visibility: this.state.uploadFile!.name ? 'visible' : 'hidden' && this.state.uploadFileNameUpdated}}
                            >
                                Upload
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        roles: state.authReducer.roles,
        userType: state.defaultReducer.userType,
        userRolesEntities : state.authReducer.userRolesEntities
    }
};


export default connect(mapStateToProps)(ReferenceDocuments);