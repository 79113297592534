import React, {useCallback} from 'react';
import axios from 'axios';
import {Button} from '@progress/kendo-react-buttons';
import {Table} from 'semantic-ui-react';
import {CategorySeason} from '../../../interfaces/category-interface';

export interface SeasonStatusProps {
    seasons: Array<CategorySeason>,
    getSeasons: () => void
}

const SeasonStatusGrid: React.FC<SeasonStatusProps> = ({seasons, getSeasons}) => {

    const toggleSeason = useCallback((season: string, open: boolean) => {
        console.log('season',season,'open',open);
        const data = {
            season: season,
            open: open
        };
        axios.post('/api/auth/ra/season-window', data)
            .then(() => {
                getSeasons();
            })
            .catch((error) => {
                console.log(error);
            });
    }, [getSeasons]);

    return (
        <Table celled={true}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>Submittal Year</Table.HeaderCell>
                    <Table.HeaderCell>Season</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Window Open</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            
            <Table.Body>
                {seasons.map((season: CategorySeason, index) => (
                    <Table.Row key={index}>
                        <Table.Cell>{season.category.nm}</Table.Cell>
                        <Table.Cell>{season.subYearId}</Table.Cell>
                        <Table.Cell>{season.season.nm}</Table.Cell>
                        <Table.Cell>{season.status.nm}</Table.Cell>
                        <Table.Cell>{season.windowOpen ? 'Open' : 'Closed'} <Button onClick={() => {toggleSeason(season.season.nm, !season.windowOpen)}}>{season.windowOpen ? 'Close' : 'Open'} Window</Button></Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

export default SeasonStatusGrid;