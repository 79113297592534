import React from 'react';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';
import {toast} from 'react-toastify';

interface ClearFilterPanelProps {
    clearFilter: () => void
}

const ClearFilterPanel: React.FC<ClearFilterPanelProps> = ({clearFilter}) => {

    const handleClear = () => {
        clearFilter();
        toast.info('Filters cleared.');
    }
    
    return (
        <>
            <AppBarSection>
                <Button onClick={handleClear}>Clear Filters</Button>
            </AppBarSection>
            <AppBarSpacer style={{width: 4}}/>
        </>
    );
};

export default ClearFilterPanel;