import * as actionTypes from '../actions/actionTypes';
import initialState from "./initialState";

export default function entityManagementReducer(state = initialState.entityManagementState, action:any){
    switch(action.type) {
        case actionTypes.SET_ENTITYMODALACTIVE:
            return {
                ...state,
                entityModalActive: action.payload
            };
        case actionTypes.SET_CURRENTENTITYROW:
            return {
                ...state,
                currentEntityRow: action.payload
            }
        case actionTypes.SET_ADDORMODIFYENTITY:
            return {
                ...state,
                addOrModifyEntity: action.payload
            };
        case actionTypes.SET_ALLENTITIES:
            return {
                ...state,
                allEntities: action.payload
            };
    }
    return state;
};


