import * as actionTypes from '../actions/actionTypes';
import initialState from "./initialState";

export default function contactReducer(state = initialState.contactState, action:any){
    switch(action.type) {
        case actionTypes.SET_CONTACTMODALACTIVE:
            return {
                ...state,
                contactModalActive: action.payload
            };
        case actionTypes.SET_STAKEHOLDERMODALACTIVE:
            return {
                ...state,
                stakeholderModalActive: action.payload
            };

        case actionTypes.SET_STAKEHOLDERCURRENTROW:
            return {
                ...state,
                stakeholderCurrentRow: action.payload
            };
        case actionTypes.SET_ADDORMODIFYCONTACT:
            return {
                ...state,
                addOrModifyContact: action.payload
            };
    }
    return state;
};


