import * as React from 'react';
import {Button, Dropdown, DropdownItemProps, DropdownProps, Grid, Header, Icon} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import axios from "axios";
import {AccordionSegment} from "../common/AccordionSegment";
import {Bar} from '@nivo/bar'
import {Pie} from '@nivo/pie'
import {toast} from "react-toastify";
import {getReactTable} from "../common/ReactTableUtil";
import ResadqService from "../../services/resadq-services";
import format from 'date-fns/format';
import {getRaActiveSummerSubyear, getRaCurrentSeasonSubyear} from "../../services/ra-report-service";

interface TenYearForecastState {
    submittingEntities: any
    seDropDownLoading: boolean
    submittalYears: any
    submittalYearsLoading: boolean
    submitEntityName: string
    subYearId: number
    season: string
    seasonOptions: any
    disabledSeason: boolean
    reserveMarginTrendData: any
    pieChart: any
    pieChartPlusFive: any
    reserveMarginTrendLoading:boolean
    pieChartLoading: boolean
    pieChartPlusFiveLoading: boolean
    creatingReport: boolean
    YrlyCapacitySummary:any[]
    YrlyCapacitySummaryMetaData:any[]
    YrlyCapacitySummaryLoading:boolean
    YrlyDemandSummary:any[]
    YrlyDemandSummaryMetaData:any[]
    YrlyDemandSummaryLoading:boolean
    YrlyResAdqRequirementSummary:any[]
    YrlyResAdqRequirementSummaryMetaData:any[]
    YrlyResAdqRequirementSummaryLoading:boolean
    hideContent:boolean
    missingSubmittalYear: boolean
    missingEntity: boolean
    missingSeasonType: boolean
    toastSubmittingEntityId:number
    toastSubmittalYear:number
    toastseason: number
    exporting: boolean
    currentSubYear: number
    createReportFlag : boolean
    seasonCheck: string
    yearCheck: number
    currentSeason: string
    reportGenerated: boolean
}

interface TenYearForecastProps {
    userType: any
    roles: any
}

let fileDownload = require('js-file-download');
const client = new ResadqService();
class TenYearForecast extends React.Component<RouteComponentProps<{}> & TenYearForecastProps,TenYearForecastState> {
    constructor(props: RouteComponentProps<{}> & TenYearForecastProps, state: TenYearForecastState) {
        super(props, state);
        this.state = {
            submittingEntities: [],
            seDropDownLoading: true,
            submittalYears: [],
            submittalYearsLoading: true,
            submitEntityName:'',
            subYearId: 0,
            season: '',
            seasonOptions: [
                { key: 'Summer', text: 'Summer', value: 'Summer' },
                { key: 'Winter', text: 'Winter', value: 'Winter' },
            ],
            disabledSeason: false,
            reserveMarginTrendData: [],
            pieChart: [],
            pieChartPlusFive: [],
            reserveMarginTrendLoading: false,
            pieChartLoading: false,
            pieChartPlusFiveLoading: false,
            creatingReport: false,
            YrlyCapacitySummary:[],
            YrlyCapacitySummaryMetaData:[],
            YrlyCapacitySummaryLoading:false,
            YrlyDemandSummary:[],
            YrlyDemandSummaryMetaData:[],
            YrlyDemandSummaryLoading:false,
            YrlyResAdqRequirementSummary:[],
            YrlyResAdqRequirementSummaryMetaData:[],
            YrlyResAdqRequirementSummaryLoading:false,
            hideContent: true,
            missingEntity:false,
            missingSeasonType: false,
            missingSubmittalYear:false,
            toastSubmittalYear:0,
            toastSubmittingEntityId:0,
            toastseason:0,
            exporting:false,
            currentSubYear: 0,
            createReportFlag:false,
            seasonCheck:'',
            yearCheck:0,
            currentSeason: '',
            reportGenerated: false
        };
    }

    componentDidMount(): void {
        this.getSubmittingEntities();
        this.getSubmittalYear();
        this.getCurrentYearSeason();
    }

    getSubmittingEntities = () => {
        client.getSubmittingEntities('TenYearForecastOverview')
            .then((data) => {
                let submittingEntities: DropdownItemProps[] = data.map((se:any) => ({key:se, text:se, value:se}));
                this.setState({submittingEntities,seDropDownLoading:false});
            });
        this.setState({seDropDownLoading:true})
    };

    getTenYearGridData = (subGroupName: string) => {
        let {submitEntityName,subYearId,season} = this.state;
        client.getReportData('TenYearForecastOverview',subGroupName,submitEntityName,subYearId,season)
            .then((data) => {
                this.setState({
                    [subGroupName]:data.data,
                    [`${subGroupName}MetaData`]:data.columnMetaDataList,
                    [`${subGroupName}Loading`]:false
                } as any);
            });

        this.setState({[`${subGroupName}Loading`]:true} as any);
    };

    clearToastMessages = () => {
        if (this.state.toastSubmittalYear && this.state.subYearId) {
            toast.dismiss(this.state.toastSubmittalYear);
            this.setState({toastSubmittalYear:0})
        }
        if (this.state.toastSubmittingEntityId && this.state.submitEntityName) {
            toast.dismiss(this.state.toastSubmittingEntityId);
            this.setState({toastSubmittingEntityId:0})
        }
        if (this.state.toastseason && this.state.season) {
            toast.dismiss(this.state.toastseason);
            this.setState({toastseason:0})
        }
    };

    validateDropDowns = () => {
        if (!this.state.subYearId && !this.state.toastSubmittalYear) {
            let toastSubmittalYear = toast.info('Please select a Submittal Year',{autoClose:false});
            this.setState({missingSubmittalYear:true,toastSubmittalYear})
        }
        if (!this.state.submitEntityName && !this.state.toastSubmittingEntityId) {
            let toastSubmittingEntityId = toast.info('Please select an RA Entity',{autoClose:false});
            this.setState({missingEntity:true,toastSubmittingEntityId})
        }
        if (!this.state.season && !this.state.toastseason) {
            let toastseason = toast.info('Please select a Season Type',{autoClose:false});
            this.setState({missingSeasonType:true,toastseason})
        }
        return this.state.subYearId && this.state.submitEntityName && this.state.season
    };

    getCurrentYearSeason = () => {
        getRaCurrentSeasonSubyear()
            .then((resp) => {
                this.setState({currentSubYear: resp.data});
                getRaActiveSummerSubyear()
                    .then((response) => {
                        let currentSeason = resp.data === response.data ? 'Summer' : 'Winter';
                        this.setState({currentSubYear: resp.data, currentSeason});
                    });
            })
            .catch((error) => {
                console.log(error);
            })
    };

    getSubmittalYear = () => {
        client.getSubmittalYears()
            .then((data) => {
                let submittalYears: DropdownItemProps[] = data.map((subyear: any) => ({key: subyear, text: subyear.toString(), value: subyear}));
                this.setState({submittalYears, submittalYearsLoading: false});
            });
        this.setState({submittalYearsLoading:true})
    };

    //note below, current subyear and current season come from the api, the subyearid and season are the users selection
    isButtonDisabled = () => {
        if (this.state.submitEntityName === '') {
            return {create: true, show: true}
        }
        if (this.state.subYearId === 0) {
            return {create: true, show: true}
        }
        if (this.state.season === '' ) {
            return {create: true, show: true}
        }
        //need to cover all possible combos of submittal year and season type
        //this will cover when the year is older than the current, includes all season combos
        if (this.state.currentSubYear > this.state.subYearId) {
            return {create: true, show: false};
            //this will cover when the year is the same
        } else if (this.state.currentSubYear === this.state.subYearId) {
            //winter&winter or summer&summer
            if (this.state.currentSeason === this.state.season) {
                return {create: false, show: true}
                //summer&winter
            } else if (this.state.currentSeason === 'Summer' && this.state.season === 'Winter') {
                return {create: false, show: true};
                //only thing left at this point is winter&summer
            } else {
                return {create: true, show: false};
            }
            //this is the case for year current year is less than user selection, it will handle all season combos
        } else if (this.state.currentSubYear < this.state.subYearId) {
            return {create: false, show: true};
            //this is the 'default' branch incase there was something that caused all the prior branches to be missed
        } else {
            return {create: true, show: true};
        }
    }

    submitEntityDropDownChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        await this.setState({submitEntityName:data.value as string, reportGenerated: false});
        this.clearToastMessages();
    };

    subyearDropDownChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let subYearId: number = data.value as number;
        let season = this.state.season;
        let disabledSeason: boolean;
        
        if (subYearId <= 2023 || subYearId > this.state.currentSubYear) {
            season = 'Summer';
            disabledSeason = true;
        } else {
            disabledSeason = false;
        }

        await this.setState({subYearId, season, disabledSeason, reportGenerated: false});
        this.clearToastMessages();
    };

    seasondropDownChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        await this.setState({season: data.value as string, reportGenerated: false});
        this.clearToastMessages();
    };

    getBarChartData = () => {
        let reserveMarginhChartInputs:any = {
            reportName: 'TenYearForecastOverview',
            submitEntName: this.state.submitEntityName,
            subYear: this.state.subYearId,
            season: this.state.season,
        }
        axios.get('/api/auth/reports/reservemarginchart',{params: reserveMarginhChartInputs}).then( resp => {
            const trendData = resp.data.map((item: any) => ({
                PlanYearId: item.PlanYearId,
                'Entity Reserve Margin': item.PlanningReserveMargin,
                SPPPlanningReserveMargin: item.SPPPlanningReserveMargin
            }));
            this.setState({reserveMarginTrendData: trendData, reserveMarginTrendLoading:false})

        }).catch( error => {
            if (error.message) {
                toast.error(`Error retrieving bar chart data - ${error.message}`);
            }
            this.setState({reserveMarginTrendLoading:false})
        });
        this.setState({reserveMarginTrendLoading:true})
    };

    getPieChartData = (yearsToAdd:number) => {
        let planYear = Number(this.state.subYearId) + Number(yearsToAdd);
        let pieChartInputs: any = {
            submitEntName: this.state.submitEntityName,
            subYear: this.state.subYearId,
            planYear: planYear,
            season: this.state.season
        }
        axios.get('/api/auth/reports/piechart',{params: pieChartInputs}).then( resp => {
            let pieChart:any = [];
            let max = 0;
            resp.data.forEach((value:any) => {
                max += value.totalCap
            });
            resp.data.forEach((value:any) => {
                let percentage = ((value.totalCap/max) * 100).toFixed(2);
                pieChart.push({id:value.longNm,label:value.longNm,value:percentage})
            });
            if (yearsToAdd > 0) {
                this.setState({pieChartPlusFive:pieChart,pieChartPlusFiveLoading:false});
            } else {
                this.setState({pieChart,pieChartLoading:false});
            }
        }).catch( error => {
            if (error.message) {
                toast.error(`Error retrieving pie chart data - ${error.message}`);
            }
            if (yearsToAdd > 0) {
                this.setState({pieChartPlusFiveLoading:false});
            } else {
                this.setState({pieChartLoading:false});
            }
        });

        if (yearsToAdd > 0) {
            this.setState({pieChartPlusFiveLoading:true});
        } else {
            this.setState({pieChartLoading:true});
        }
    };

    showReport = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            this.getBarChartData();
            this.getPieChartData(0);
            this.getPieChartData(5);
            this.getTenYearGridData('YrlyCapacitySummary');
            this.getTenYearGridData('YrlyDemandSummary');
            this.getTenYearGridData('YrlyResAdqRequirementSummary');
            this.setState({hideContent: false, reportGenerated: true});
            toast.success("Display of Report data Completed");
            this.setState({yearCheck: this.state.subYearId});
            this.setState({seasonCheck: this.state.season});
        }
    };

    createReport = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            let data = new FormData();
            data.set('submitEntName', this.state.submitEntityName);
            data.set('season', this.state.season);
            axios.post('/api/auth/reports/create-report', data)
                .then(resp => {
                    if (resp.status === 200) {
                        toast.success("Report Data Created");
                        this.showReport();
                    }
                })
                .catch(error => {
                    toast.error(`Error creating report - ${error.message}`);
                })
                .finally(() => {
                    this.setState({creatingReport: false});
                });
            this.setState({creatingReport: true})
            this.setState({createReportFlag:true})
        }
    };

    export = () => {
        if (this.validateDropDowns()) {
            this.clearToastMessages();
            let dateString: string = format(new Date(),'yyyy-MM-dd');
            let reportName: string = 'TenYear_' + this.state.submitEntityName + '_' +
                this.state.subYearId.toString() + '_' + this.state.season + '_' +
                dateString + '.xlsx';
            let exportParams: any = {
                submitEntityName: this.state.submitEntityName,
                subYear: this.state.subYearId,
                season: this.state.season
            }
            axios.post('/api/auth/reports/export/tyfo', exportParams, {responseType:'arraybuffer'}).then(resp => {
                if (resp.status === 200) {
                    fileDownload(resp.data,reportName);
                    toast.success('Export completed successfully');
                    this.setState({exporting:false})
                }
            }).catch(resp => {
                toast.error(`Error creating export - ${resp.message}`);
                this.setState({exporting:false});
            });
            this.setState({exporting:true});
        }
    };

    public render() {
        return (
            <div style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
                <Header size={'large'}>Ten Year Forecast Overview</Header>
                <Grid>
                    <Grid.Row columns={7}>
                        <Grid.Column>
                            <p><b>Submitting Entity:</b></p>
                            <Dropdown placeholder={'Submitting Entities'}
                                      search={true}
                                      selection={true}
                                      loading={this.state.seDropDownLoading}
                                      options={this.state.submittingEntities}
                                      onChange={this.submitEntityDropDownChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <p><b>Submittal Year:</b></p>
                            <Dropdown placeholder={'Select Submittal Year'}
                                      search={true}
                                      selection={true}
                                      loading={this.state.submittalYearsLoading}
                                      options={this.state.submittalYears}
                                      onChange={this.subyearDropDownChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <p><b>Season Type:</b></p>
                            <Dropdown placeholder={'Select Season'}
                                      search={true}
                                      selection={true}
                                      disabled={this.state.disabledSeason}
                                      options={this.state.seasonOptions}
                                      onChange={this.seasondropDownChange}
                                      value={this.state.season}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row columns={3}/>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Button
                                            content={'Create Report'}
                                            size={'mini'}
                                            color={'black'}
                                            disabled={this.isButtonDisabled().create}
                                            onClick={this.createReport}
                                            loading={this.state.creatingReport}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            content={'Show Report'}
                                            size={'mini'}
                                            color={'black'}
                                            disabled={this.isButtonDisabled().show}
                                            onClick={this.showReport}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            size={'mini'}
                                            loading={this.state.exporting}
                                            color={'black'}
                                            onClick={this.export}
                                            disabled={!this.state.reportGenerated}
                                        >
                                            <Icon name={'download'}/>Export
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            {((!this.state.hideContent || this.state.createReportFlag)) ? (
                            <AccordionSegment segmentHeader={`${this.state.yearCheck} ${this.state.yearCheck >= 2024 ? `${this.state.seasonCheck} ` : ''}Fuel Type Resource Summary`} centered={true} loading={this.state.pieChartLoading} contentHidden={this.state.hideContent}>
                                <Pie height={400} width={400} data={this.state.pieChart}
                                     sliceLabel={(d) => `${d.id}:${d.value}%`}
                                     enableRadialLabels={false}
                                />
                            </AccordionSegment> ) : (
                            <AccordionSegment segmentHeader={'Submittal Year Fuel Type Resource Summary'} centered={true} loading={this.state.pieChartLoading} contentHidden={this.state.hideContent}>
                                <Pie height={400} width={400} data={this.state.pieChart}
                                     sliceLabel={(d) => `${d.id}:${d.value}%`}
                                     enableRadialLabels={false}
                                />
                            </AccordionSegment>
                            )}
                        </Grid.Column>
                        <Grid.Column>
                            {(!this.state.hideContent || this.state.createReportFlag) ? (
                            <AccordionSegment segmentHeader={`${this.state.yearCheck + 5} ${this.state.yearCheck >= 2024 ? `${this.state.seasonCheck} ` : ''}Fuel Type Resource Summary`} centered={true} contentHidden={this.state.hideContent}>
                                <Pie height={400} width={400} data={this.state.pieChartPlusFive}
                                     sliceLabel={(d) => `${d.id}:${d.value}%`}
                                     enableRadialLabels={false}
                                />
                            </AccordionSegment> ) : (
                            <AccordionSegment segmentHeader={'Planning Year Fuel Type Resource Summary'} centered={true} contentHidden={this.state.hideContent}>
                                <Pie height={400} width={400} data={this.state.pieChartPlusFive}
                                     sliceLabel={(d) => `${d.id}:${d.value}%`}
                                     enableRadialLabels={false}
                                />
                            </AccordionSegment>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            {(!this.state.hideContent || this.state.createReportFlag) ? (
                            <AccordionSegment segmentHeader={`${this.state.yearCheck >= 2024 ? `${this.state.seasonCheck} ` : ''}Reserve Margin Trend`} centered={true} loading={this.state.reserveMarginTrendLoading} contentHidden={this.state.hideContent}>
                                <Bar height={400} width={800} data={this.state.reserveMarginTrendData}
                                     keys={['Entity Reserve Margin']} groupMode={'grouped'}
                                     margin={{
                                         'top':20,
                                         'bottom':120,
                                         'right':200,
                                         'left':50
                                     }}
                                     colors={'set1'}
                                     padding={.5}
                                     indexBy={'PlanYearId'}
                                     axisLeft={{legend:'Year',legendOffset:-40,legendPosition:'start'}}
                                     legends={[
                                         {
                                             "dataFrom": "keys",
                                             "anchor": "bottom-right",
                                             "direction": "column",
                                             "justify": false,
                                             "translateX": 120,
                                             "translateY": 0,
                                             "itemsSpacing": 2,
                                             "itemWidth": 100,
                                             "itemHeight": 20,
                                             "itemDirection": "left-to-right",
                                             "itemOpacity": 0.85,
                                             "symbolSize": 20,
                                             "effects": [
                                                 {
                                                     "on": "hover",
                                                     "style": {
                                                         "itemOpacity": 1
                                                     }
                                                 }
                                             ],
                                         }
                                     ]}
                                />
                            </AccordionSegment> ) : (
                            <AccordionSegment segmentHeader={'Reserve Margin Trend'} centered={true} loading={this.state.reserveMarginTrendLoading} contentHidden={this.state.hideContent}>
                                <Bar height={400} width={800} data={this.state.reserveMarginTrendData}
                                     keys={['Entity Reserve Margin']} groupMode={'grouped'}
                                     margin={{
                                         'top':20,
                                         'bottom':120,
                                         'right':200,
                                         'left':50
                                     }}
                                     colors={'set1'}
                                     padding={.5}
                                     indexBy={'PlanYearId'}
                                     axisLeft={{legend:'Year',legendOffset:-40,legendPosition:'start'}}
                                     legends={[
                                         {
                                             "dataFrom": "keys",
                                             "anchor": "bottom-right",
                                             "direction": "column",
                                             "justify": false,
                                             "translateX": 120,
                                             "translateY": 0,
                                             "itemsSpacing": 2,
                                             "itemWidth": 100,
                                             "itemHeight": 20,
                                             "itemDirection": "left-to-right",
                                             "itemOpacity": 0.85,
                                             "symbolSize": 20,
                                             "effects": [
                                                 {
                                                     "on": "hover",
                                                     "style": {
                                                         "itemOpacity": 1
                                                     }
                                                 }
                                             ]
                                         }
                                     ]}
                                />
                            </AccordionSegment>
                        )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Capacity Summary'} contentHidden={this.state.hideContent} size={'tiny'}>
                                {getReactTable(this.state.YrlyCapacitySummary,this.state.YrlyCapacitySummaryMetaData,this.state.YrlyCapacitySummaryLoading, "CapacitySummaryGrid")}
                            </AccordionSegment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Demand Summary'} contentHidden={this.state.hideContent} size={'tiny'}>
                                {getReactTable(this.state.YrlyDemandSummary,this.state.YrlyDemandSummaryMetaData,this.state.YrlyDemandSummaryLoading, "DemandSummaryGrid")}
                            </AccordionSegment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <AccordionSegment segmentHeader={'Requirements Summary'} contentHidden={this.state.hideContent} size={'tiny'}>
                                {getReactTable(this.state.YrlyResAdqRequirementSummary,this.state.YrlyResAdqRequirementSummaryMetaData,this.state.YrlyResAdqRequirementSummaryLoading, "ReqsSummaryGrid")}
                            </AccordionSegment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}
export default TenYearForecast;