import * as React from 'react';
import {Button, Container, Icon} from "semantic-ui-react";
import axios from 'axios';
import '../../../CSS/react-table-custom-orig.css';
import GridUtil from "../../common/GridUtil";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import ReactTable from "react-table";
import * as actionTypes from "../../../store/actions/actionTypes";
import GeneralService from "../../../services/general-services";


interface StakeholderGridState {
    data: any;
    columns: any;
    metaData: any;
    filtered: any;
    openModal: boolean,
    status: number,
    loading: boolean,
    deleteModalVisible: boolean,
    originalData: any[]
}

interface StakeholderGridProps {
    currentUser: string
    updateDeleteModalActive: (deleteModalActive: boolean) => void;
    updateStakeholderCurrentRow: (stakeholderCurrentRow: any) => void;
    updateDeleteScreen: (deleteScreen: any) => void;
    updateAddOrModifyContact: (addOrModifyContact: string) => void;
    stakeholderModalActive: boolean;
    updateStakeholderModalActive: (contactModalActive: boolean) => void;
    stakeholderCurrentRow: any;
    sppStaff: boolean;
}

const generalService = new GeneralService();
class StakeholderGrid extends React.Component<StakeholderGridProps, StakeholderGridState> {
    constructor(props: StakeholderGridProps, state: StakeholderGridState) {
        super(props,state);
        this.state = {
            data: [],
            //TODO: Pull columnMeta from database
            columns: [
                {
                    Header:'Action',
                    accessor:'action',
                    width: 80
                },{
                    Header:'Stakeholder Name',
                    accessor:'stakeholderNm'
                },{
                    Header:'Nerc ID',
                    accessor:'nercId',
                    width: 100
                },{
                    Header:'Stakeholder Type',
                    accessor:'stakeholderType',
                    width: 150
                },{
                    Header:'BA',
                    accessor:'ba',
                    width: 60
                },{
                    Header:'DP',
                    accessor:'dp',
                    width: 60
                },{
                    Header:'GO',
                    accessor:'go',
                    width: 60
                },{
                    Header:'GOP',
                    accessor:'gop',
                    width: 60
                },{
                    Header:'PA_PC',
                    accessor:'pa_PC',
                    width: 60
                },{
                    Header:'RC',
                    accessor:'rc',
                    width: 60
                },{
                    Header:'RP',
                    accessor:'rp',
                    width: 60
                },{
                    Header:'RSG',
                    accessor:'rsg',
                    width: 60
                },{
                    Header:'FRSG',
                    accessor:'frsg',
                    width: 60

                },{
                    Header:'RRSG',
                    accessor:'rrsg',
                    width: 60

                },{
                    Header:'TO',
                    accessor:'to',
                    width: 60
                },{
                    Header:'TOP',
                    accessor:'top',
                    width: 60
                },{
                    Header:'TP',
                    accessor:'tp',
                    width: 60
                },{
                    Header:'TSP',
                    accessor:'tsp',
                    width: 60
                }
            ],
            metaData: [],
            filtered: [],
            openModal: false,
            status: 200,
            loading: true,
            deleteModalVisible: false,
            originalData: []
        };
    }

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[] });
    };

    getFiltered = () => {
        if (this.state.filtered) {
            return 'visible'
        }
        return 'hidden'
    };

    componentDidMount(): void {
        this.fetchData();
    }

    getActions = (key: number) =>{
        return(
            <div>
                <Icon id={key} color={'blue'} name={'edit'} onClick={this.takeAction} disabled={!this.props.sppStaff} style={{cursor: 'pointer'}}/>
                {/*<Icon id={key} color={'red'} name={'delete'} onClick={this.takeAction} disabled={false} style={{cursor: 'pointer'}}/>*/}
            </div>
        )
    }

    takeAction = (e:any) => {
        let originalData = this.state.originalData;
        //Get the row we are taking action on so that it can be passed into the modal
        let currentRow:any = originalData.find((obj:any) => obj.stakeholderId === parseInt(e.target.id))
        let actionType: any = e.target.className;
        this.props.updateStakeholderCurrentRow(currentRow);
        
        if(actionType.includes('edit')) { //We clicked edit row
            this.props.updateAddOrModifyContact('Modify');
            this.props.updateStakeholderModalActive(true);
            
        } else { //We clicked delete row
            this.props.updateDeleteScreen('Stakeholder');
            this.props.updateDeleteModalActive(true);
        }
    }

    fetchData = () => {
        axios.get('/api/auth/contact/stakeholders').then(resp => {
            if (resp.data) {
                //deep cloning and storing the original state of data so it can be used to get values for current row
                let tempData: any = JSON.parse(JSON.stringify(resp.data));
                this.setState({originalData: resp.data});

                tempData.forEach((s:any) => {
                    for(let x in s){
                        if(s[x]=== true){
                            s[x] = <div><Icon color={'green'} name={'check square outline'}/></div>
                        } else if(s[x] === false) {
                            s[x] = <div><Icon color={'red'} name={'minus square outline'}/></div>
                        }
                    }
                    s.action = this.getActions(s.stakeholderId);
                })

                this.setState({data:tempData, status: resp.status, loading: false});
            } else {
                this.setState({loading:false,data:[]})
            }
        }).catch(resp => {
            this.setState({status: resp.status, loading: false})
        });
    };

    componentWillReceiveProps(nextProps: any) {
        // this will run every time the props change - and possibly in addition to this, so we need to check for prop changes
        if (this.props.stakeholderCurrentRow !== nextProps.stakeholderCurrentRow) {
            this.fetchData();
        }
    }

    public render() {
        const {data} = this.state;
        return (
            <div>

                <Container fluid={true} style={{paddingTop:'10px', paddingBottom: '10px'}}>
                    <ReactTable
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={100}
                        filterable={true}
                        filtered={this.state.filtered}
                        style={{height: '500px', width: '100%'}}
                        className='-highlight'
                        defaultFilterMethod={GridUtil.filterMethod}
                        onFilteredChange={filtered => this.setState({filtered})}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: 'black',
                                    color: 'white',
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth, textAlign: "center"}})}
                    />
                    <Loading active={this.state.loading}/>
                </Container>
                <Button size={'mini'} icon={true} labelPosition='left' onClick={generalService.downloadExcel} name={'stakeholder'} color={'black'}>
                    <Icon name='download'/>
                    Download
                </Button>

            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        sppStaff: state.authReducer.userRolesEntities.sppStaff,
        deleteModalActive: Boolean(state.contactReducer.deleteModalActive),
        stakeholderCurrentRow: state.contactReducer.stakeholderCurrentRow,
        deleteScreen: state.contactReducer.deleteScreen,
        addOrModifyContact: state.contactReducer.addOrModifyContact,
        stakeholderModalActive: Boolean(state.contactReducer.stakeholderModalActive),
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDeleteModalActive: (deleteModalActive: boolean) => dispatch(
            {type: actionTypes.SET_DELETEMODALACTIVE, payload: deleteModalActive}
        ),
        updateStakeholderCurrentRow: (stakeholderCurrentRow: boolean) => dispatch(
            {type: actionTypes.SET_STAKEHOLDERCURRENTROW, payload: stakeholderCurrentRow}
        ),
        updateDeleteScreen: (deleteScreen: boolean) => dispatch(
            {type: actionTypes.SET_DELETESCREEN, payload: deleteScreen}
        ),
        updateAddOrModifyContact: (addOrModifyContact: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYCONTACT, payload: addOrModifyContact}
        ),
        updateStakeholderModalActive: (stakeholderModalActive: boolean) => dispatch(
            {type: actionTypes.SET_STAKEHOLDERMODALACTIVE, payload: stakeholderModalActive}
        ),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(StakeholderGrid)
