import React, {useEffect, useState} from 'react';
import { Container, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import TestResultsOpGrid from './TestResultsOpGrid';
import TestResultsWinterGrid from './TestResultsWinterGrid';
import TestResultsSummerGrid from './TestResultsSummerGrid';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';

const TestResultsPage: React.FC<GridPageProps> = ({ roles }) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);

    useEffect(() => {
        setPageRole(getCompressedPageRole('GeneratorTestResults', roles));
    }, [roles]);

    const grids = [TestResultsOpGrid, TestResultsSummerGrid, TestResultsWinterGrid];

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size="large">Generator Test Results</Header>
            {grids.map((Grid, index) => (
                <AccordionWrapper accordionHeader={'Generator Test Results'}>
                    <>
                        <Grid key={index} {...pageRole} />
                        <br/>
                    </>
                </AccordionWrapper>
            ))}
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(TestResultsPage);
