import {Button, ButtonGroup} from '@progress/kendo-react-buttons';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import React from 'react';

// Disabled prop should be true if grid is in edit mode.
// Edit mode and verify mode are mutually exclusive.
interface VerifyPanelProps {
    inVerify: boolean,
    disabled: boolean,
    verify: () => void,
    toggleVerify: () => void,
    selectAll: () => void
}

const VerifyPanel: React.FC<VerifyPanelProps> = ({inVerify, disabled, selectAll, verify, toggleVerify}) => {
    return (
        <>
            <AppBarSection>
                {inVerify ? (
                    <ButtonGroup>
                        <Button onClick={selectAll}>Select All</Button>
                        <Button onClick={verify}>Confirm Verification</Button>
                        <Button onClick={toggleVerify}>Cancel</Button>
                    </ButtonGroup>
                ) : (
                    <Button onClick={toggleVerify} disabled={disabled}>Verify</Button>
                )}
            </AppBarSection>
            <AppBarSpacer style={{width: 4}}/>
        </>
    );
};

export default VerifyPanel;