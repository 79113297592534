import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {Dropdown, Icon, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import * as actionTypes from "../store/actions/actionTypes";
import GeneralService from "../services/general-services";
import NotificationIcon from "./NotificationIcon";

interface MenuItemComponentProps {
    currentUser: string,
    activeMenuItem: string,
    notificationCount: number,
    updateActiveMenuItem: (activeMenuItem: string) => void,
}

interface MenuItemComponentState {
    menuItems: MenuItem[],
    upgradesOpen: boolean,
    subMenus: Map<string, boolean>,
}

const generalService = new GeneralService();

class MenuItemComponent extends React.Component<MenuItemComponentProps, MenuItemComponentState> {
    constructor(props: MenuItemComponentProps, state: MenuItemComponentState) {
        super(props, state);
        this.state = {
            menuItems: [],
            upgradesOpen: false,
            subMenus: new Map(),
        };
    }

    componentDidMount(): void {
        this.getMenuItems();
    }

    getMenuItems = () => {
        generalService.getMenuItems()
            .then((response: MenuItem[]) => {
                let localMap = new Map();
                for (let i = 0; i < response.length; i++) {
                    if (response[i].subMenuItems.length > 0) {
                        localMap.set(response[i].menuName, false);
                    }
                }
                this.setState({menuItems: response});
            })
    };

    handleItemClick = (e: any, {name}: any) => {
        this.props.updateActiveMenuItem(name);
    };

    /* Recursive method for creating SideNav SubMenus */
    recursivelyGetSubMenus = (item: MenuItem) => {
        return (
            <Dropdown item={true} text={item.menuDisplayName} closeOnChange={true} key={item.menuName}>
                <Dropdown.Menu>
                    {item.subMenuItems.map((subItem: MenuItem) => {
                        if (subItem.menuName === 'RAContactList') {
                            return (<Dropdown.Item onClick={generalService.downloadFile} docname={'RA Contact List'}
                                                   key={subItem.menuName}>{subItem.menuDisplayName}</Dropdown.Item>)
                        } else {
                            return (
                                <Dropdown.Item 
                                    as={NavLink} 
                                    exact={true} 
                                    to={subItem.menuLink}
                                    key={subItem.menuName}
                                    name={subItem.menuName}
                                    onClick={this.handleItemClick}
                                >
                                    {subItem.menuDisplayName}
                                </Dropdown.Item>
                            )
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    };


    displayMenu = () => {
        let menu;
        if (this.state.menuItems?.length > 0) {
            menu = this.state.menuItems.map((item: MenuItem) => {
                if (item.subMenuItems.length === 0) {

                    if (item.menuName === 'notifications') {
                        return (
                            <Menu.Item
                                className={'navMenu'}
                                name={item.menuName}
                                active={this.props.activeMenuItem === item.menuName}
                                as={NavLink}
                                to={item.menuLink}
                                exact={true}
                                key={item.menuName}
                                onClick={this.handleItemClick}
                            >
                                <NotificationIcon notificationCount={this.props.notificationCount}
                                                  menuIcon={item.menuIcon}/>
                            </Menu.Item>
                        )
                    }
                    return (
                        <Menu.Item
                            className={'navMenu'}
                            name={item.menuName}
                            active={this.props.activeMenuItem === item.menuName}
                            as={NavLink}
                            to={item.menuLink}
                            exact={true}
                            key={item.menuName}
                            onClick={this.handleItemClick}
                        >
                            {item.menuIcon ? <Icon className={item.menuIcon}/> : null}
                            {item.menuDisplayName}
                        </Menu.Item>
                    )
                } else {
                    return (
                        this.recursivelyGetSubMenus(item)
                    )
                }
            })
        }

        return menu;
    };

    public render() {
        return (
            <Menu inverted={true} className={'navMenu'}>
                {this.props.currentUser &&
                    this.displayMenu()
                }
            </Menu>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        activeMenuItem: state.defaultReducer.activeMenuItem,
        notificationCount: state.defaultReducer.notificationCount,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActiveMenuItem: (activeMenuItem: string) => dispatch(
            {type: actionTypes.UPDATE_ACTIVE_MENU_ITEM, payload: activeMenuItem}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemComponent);