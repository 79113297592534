//import * as React from "react";
import {Button, Container, Form, Grid, Modal, Radio} from "semantic-ui-react";
import {useState} from "react";
import AdminService from "../../../services/admin-services";

const adminService = new AdminService();

function TemplateModal({visible, closeModal, setCreateRaSubRespEnt, subEntId, templateExists, setTemplateExists}: any){
    /** Main modal component to allow RA users to determine if they would like a new template created for the selected
     * submitting entity. The return is a jsx object, but the modal also updates state values in the parent component
     * to direct behavior once template modal closes.
     *
     * @param {boolean} visible - defines if the modal is open.
     * @param {function} closeModal - sets the parent visible state value to control modal visibility.
     * @param {function} setCreateRaSubRespEnt - lets parent component know when it needs to create a relationship for RA.
     * @param {number} subEntId - Id of submitting entity for which a template can be generated.
     *
     * @return {jsx} jsx object.*/

    const [createTemplate, setCreateTemplate] = useState(false);

    const modalCleanup = () =>{
        closeModal();
        setCreateTemplate(false);
    }

    const createNewTemplate = () =>{
        if(createTemplate) {
            adminService.createSubRespEntTemplate(subEntId).then((response: any) => {
                setCreateRaSubRespEnt(true);
                setTemplateExists(true);
                modalCleanup();
            })
        } else {
            setCreateRaSubRespEnt(true);
            modalCleanup();
        }
    }

    return(
        <div>

        {visible ?
        <Modal open={visible} size={"large"}>
            <Modal.Header>Submitting Entity Template</Modal.Header>
            {templateExists ? <ExistingTemplateContent/>:
                <CreateNewTemplate setCreateTemplateVal={setCreateTemplate}/>}
            <Modal.Actions>
                <Button onClick={() => {createNewTemplate();}}
                        positive={true}>
                    Ok
                </Button>
                <Button onClick={() => {setCreateRaSubRespEnt(false); modalCleanup();}} negative={true}>Cancel</Button>
            </Modal.Actions>
        </Modal>
            : null}
        </div>
    )
}

function ExistingTemplateContent(){
    /** Content to disiplay if a template already exists for the submitting entity
     * @return {jsx} - jsx object. */

    return(
        <Modal.Content>RA D&E and CA submissions template already exists for this submitting entity. Click Ok to
            add relationship or Cancel to stop creating relationship.</Modal.Content>
    )
}

function CreateNewTemplate({setCreateTemplateVal}: any){
    /** Content to display if a template does not exists, including radio buttons to control if a new template should be
     * created. Radio buttons set parent components state value.
     *
     * @param {function} setCreateTemplateVal - set state function from parent component.
     *
     * @return {jsx} - jsx object.*/


    const [state, setState] = useState('No')
    const changeEvent = (e: any, { value }: any) =>{
        setState(value);
        if(value === 'Yes'){
            setCreateTemplateVal(true);
        } else {
            setCreateTemplateVal(false);
        }
    }
    return(
            <Modal.Content>
                <Grid centered={true}>
                    <Grid.Row>
                        <Container text={true}>
                        <p>
                        Would you like to add a RA D&E and CA submissions template when creating this relationship?
                        </p>
                        </Container>
                    </Grid.Row>
                <Grid.Row>
                        <Form>
                            <Form.Group >
                                <Form.Field>
                                    <Radio
                                        label='Yes'
                                        name='radioGroup'
                                        value='Yes'
                                        checked={state === 'Yes'}
                                        onChange={changeEvent}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label='No'
                                        name='radioGroup'
                                        value='No'
                                        checked={state === 'No'}
                                        onChange={changeEvent}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                </Grid.Row>
                    </Grid>
            </Modal.Content>
    )
}

export default TemplateModal;