import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';

interface DropDownCellProps {
    options: Array<string>,
    dispatch: (category: string) => void,
    currentValue: string
}

export const HomePageDropdown = (props: DropDownCellProps) => {
    const onChange = (event: DropDownListChangeEvent) => {
        props.dispatch(event.value === 'Resource Adequacy' ? 'RA' : 'MDWG' );
    };

    return (
        <div style={{display: 'flex', justifyContent: 'right'}}>
            <DropDownList
                onChange={onChange}
                data={props.options}
                value={props.currentValue === 'RA' ? 'Resource Adequacy' : 'Modeling Development'}
                title={'Select Function'}
                style={{width: 250}}
            />
        </div>
    );
};



export default HomePageDropdown;