import GridUtil from "./GridUtil";
//import * as React from "react";
import ReactTableHOC from "./EdstGrid";
import {TableProps} from "react-table";

export const filterMethod = (filter: any, row: any) => {
    return String(row[filter.id]).toUpperCase().startsWith(filter.value.toString().toUpperCase());
};

export const getUpdatedRows = (updatedRows: any[]) => {
    return updatedRows;
};

export const setPercentageTypes = (columnMetaDataList: any[]) => {
    return columnMetaDataList.map((c: any) => {
        let type = c.type;
        if (c.vnm.toLowerCase().includes('percent')) {
            type = 'percentage';
        }
        return {...c, type};
    });
};

export const getReactTable = (data: any[], metaData: any[], loading: boolean, className?: string, pageSize?: number, filterable?: boolean, tableProps?: Partial<TableProps>) => {
    return (
        <ReactTableHOC
            {...tableProps}
            data={data}
            columnMetaData={metaData}
            defaultPageSize={pageSize === 0 ? 50 : pageSize}
            filterable={filterable !== false}
            className={'-striped ' + className}
            tableClassName={className}
            defaultFilterMethod={GridUtil.filterMethod}
            loading={loading}
            size={'mini'}
            color={'black'}
            tableStyle={{height:'500px'}}
            updatedRows={getUpdatedRows}
            getTheadProps={() => {
                return {
                    style: {
                        background: "black",
                        color: "white",
                        borderRadius: '5px'
                    }
                };
            }}
            getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth}})}
            getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
        />
    )
};

export const getReactEditableTable = (data: any[], metaData: any[], loading: boolean, editing: boolean, className?: string, gridIndexOnPage?:number, gridInEditMode?: boolean, changeSetStatus?: string, originalDataArray?:any[], editModeCallBack?:any, handleCellChange?:any, edstGridInputKey?:any) => {
    return (
        <ReactTableHOC
            data={data}
            columnMetaData={metaData}
            defaultPageSize={50}
            filterable={true}
            editing={editing}
            className={'-striped ' + className}
            tableClassName={className}
            defaultFilterMethod={GridUtil.filterMethod}
            loading={loading}
            inputKey={edstGridInputKey}
            size={'mini'}
            color={'black'}
            updatedRows={getUpdatedRows}
            onCellChange={handleCellChange}
            tableStyle={{height:'500px'}}
            changeSetGridInEditMode={editModeCallBack}
            gridInEditMode={gridInEditMode}
            editModeGridIndex={gridIndexOnPage}
            originalData={originalDataArray}
            changeSetStatus={changeSetStatus}
            getTheadProps={() => {
                return {
                    style: {
                        background: "black",
                        color: "white",
                        borderRadius: '5px'
                    }
                };
            }}
            getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth}})}
            getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
        />
    )
};

const includes = require('lodash/includes');
export const getColumnProps = (state:any,rowInfo:any,column:any,resource:string) => {
    if (resource === 'PurchasesSales') {
         if (includes(['pK','respEntNm','fromSubmitEntNm','confEntNm','changesetStatus',
                         'toSubmitEntNm'],column.id)) {
             return {
                 style: {
                     background: 'green',
                     color:'white'
                 }
             };
         } else if (includes(['attribute1','attribute2','respAckInd','confAckInd','mbrComments','internalTransaction','purchaseOrSale',
                     'resourceSpecific','plantNm','resourceId','ownershipCoNm','fuelTyp','deliverabilityStudyContract','oasisReservationNumber',
                     'seasonTyp','startDate','serviceAgreementNbr','transactionDescription','transactionType','sourceNameIdentifier','comments','id','endDate'],column.id)) {
             return {
                 style: {
                     background: 'lightblue',
                 }
             };
         } else if (includes(['contractedCapacity'],column.id) || column.id.endsWith('Cap')) {
             return {
                 style: {
                     background: 'orange',
                 }
             };
         } else if (includes(['subYearId','pvsChgSId','creBy','creDt','modBy','modDt'],column.id)) {
             return {
                 style: {
                     background: 'gray',
                 }
             };
         }
    } else if (resource === 'ResourceOwnership') {
        if (includes(['pK','plantName','resourceId','attribute1','changesetStatus'],column.id)) {
            return {
                style: {
                    background: 'green',
                    color:'white'
                }
            };
        } else if (includes(['plantCode','submitEntNm','respEntNm','status','ownershipCoNm','ownershipPercent','firmCap','comments','serviceAgreementNbr'],column.id)) {
            return {
                style: {
                    background: 'lightblue',
                }
            };
        } else if (includes(['ownerSummerCap','ownerWinterCap'],column.id) || column.id.endsWith('Cap')) {
            return {
                style: {
                    background: 'orange',
                }
            };
        } else if (includes(['metered','marketRegistration','initialOperationDt','plannedRetirementDt','retirementDt','capacityTestDt',
                            'testCapacity','namePlateCapacity','resourceSummerCapacity','resourceWinterCapacity','state','county','primeMover',
                            'energySource','fuelTyp','subYearId','pvsChgsId','creBy','creDt','modBy','modDt'],column.id)) {
            return {
                style: {
                    background: 'gray',
                }
            };
        }
    } else if (resource === 'Transformers2Winding') {
        if (includes(['submitRegionNm','respEntNm','inServiceDt','outServiceDt','nm'],column.id)) {
            return {
                style: {
                    background: 'gray',
                    color:'white'
                }
            };
        } else if (includes(['fromRegionNm','fromAreaId','fromAreaNm','fromBusId','fromBusNm','fromBuskV','owner1Id','fraction1','owner3Id','fraction3'],column.id)) {
            return {
                style: {
                    background: 'orange',
                }
            };
        } else if (includes(['toRegionNm','toAreaId','toAreaNm','toBusId','toBusNm','toBuskV','owner2Id','fraction2','owner4Id','fraction4'],column.id)) {
            return {
                style: {
                    background: 'lightblue',
                }
            };
        } else if (includes(['summerRatingA1','summerRatingB1','summerRatingC1','winterRatingA1','winterRatingB1','winterRatingC1','springRatingA1',
            'springRatingB1','springRatingC1','fallRatingA1','fallRatingB1','fallRatingC1'],column.id)) {
            return {
                style: {
                    background: 'lightyellow',
                }
            };
        }
    } else if (resource === 'Transformers3Winding') {
        if (includes(['respEntNm','inServiceDt','outServiceDt','nm'],column.id)) {
            return {
                style: {
                    background: 'gray',
                    color:'white'
                }
            };
        } else if (includes(['winding1RegionNm','winding1AreaId','winding1AreaNm','winding1BusId','winding1BusNm','winding1BuskV','owner1Id','fraction1','owner3Id','fraction3'],column.id)) {
            return {
                style: {
                    background: 'orange',
                }
            };
        } else if (includes(['winding2RegionNm','winding2AreaId','winding2AreaNm','winding2BusId','winding2BusNm','winding2BuskV','owner2Id','fraction2','owner4Id','fraction4'],column.id)) {
            return {
                style: {
                    background: 'lightblue',
                }
            };
        } else if (includes(['winding3RegionNm','winding3AreaId','winding3AreaNm','winding3BusId','winding3BusNm','winding3BuskV'],column.id)) {
            return {
                style: {
                    background: 'lightgreen',
                }
            };
        } else if (includes(['summerRatingA1','summerRatingB1','summerRatingC1','winterRatingA1','winterRatingB1','winterRatingC1',
            'springRatingA1','springRatingB1','springRatingC1','fallRatingA1','fallRatingB1','fallRatingC1',
            'summerRatingA2','summerRatingB2','summerRatingC2','winterRatingA2','winterRatingB2','winterRatingC2',
            'springRatingA2','springRatingB2','springRatingC2','fallRatingA2','fallRatingB2','fallRatingC2','windV3','nomV3','ang3',
            'summerRatingA3','summerRatingB3','summerRatingC3','winterRatingA3','winterRatingB3','winterRatingC3',
            'springRatingA3','springRatingB3','springRatingC3','fallRatingA3','fallRatingB3','fallRatingC3'],column.id)) {
            return {
                style: {
                    background: 'lightyellow',
                }
            };
        }
    } else if (resource === 'Branches') {
        if (includes(['submitRegionNm','inServiceDt','outServiceDt'],column.id)) {
            return {
                style: {
                    background: 'gray',
                    color:'white'
                }
            };
        } else if (includes(['fromRespEntNm','fromRegionNm','fromAreaId','fromAreaNm','fromBusId','fromBusNm','fromBuskV','owner1Id','fraction1','owner3Id','fraction3'],column.id)) {
            return {
                style: {
                    background: 'orange',
                }
            };
        } else if (includes(['toRespEntNm','toRegionNm','toAreaId','toAreaNm','toBusId','toBusNm','toBuskV','owner2Id','fraction2','owner4Id','fraction4','lENmi'],column.id)) {
            return {
                style: {
                    background: 'lightblue',
                }
            };
        } else if (includes(['r','x','b'],column.id)) {
            return {
                style: {
                    background: 'lightgreen',
                }
            };
        } else if (includes(['summerRatingA','summerRatingB','summerRatingC','winterRatingA','winterRatingB','winterRatingC',
            'springRatingA','springRatingB','springRatingC','fallRatingA','fallRatingB','fallRatingC'],column.id)) {
            return {
                style: {
                    background: 'lightyellow',
                }
            };
        }
    }
    return {}
};