import * as actionTypes from '../actions/actionTypes';
import initialState from "./initialState";

export default function baseCaseReducer(state = initialState.baseCaseState, action:any){
    switch(action.type) {
        case actionTypes.SET_SUBYEAR_ID:
            return {
                ...state,
                changeSetSubYearId: action.payload
            };
        case actionTypes.INCREMENT_BASECASE_VERSION:
            return {
                ...state,
                basecaseVersion: state.basecaseVersion + 1
            };
        case actionTypes.RESET_BASECASE_VERSION:
            return {
                ...state,
                basecaseVersion: 1
            };
    }
    return state;
};


