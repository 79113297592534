import * as React from 'react';
import {Container} from "semantic-ui-react";
import ReactTable from 'react-table';
import axios from 'axios';
import '../../../CSS/react-table-custom.css';
import GridUtil from "../../common/GridUtil";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import {toast} from "react-toastify";
import {Principal} from "../../../auth";

interface AttachmentChangesetsState {
    data: any
    columns: any
    edit: boolean
    updatedData: any
    currentRow: number
    redirect: boolean
    filtered: any
    openModal: boolean
    selectedRowColor: string
    loading: boolean
    showAddRowsButton: boolean
    rowsToAddToChangeset: any
    currentChangesetNumber: string
    currentAction: string
    cellInfo:any
    actingOnChangeset:boolean
}

interface AttachmentChangesetsProps {
    currentChangeset: Changeset
    currentUser: string
    updateChangesetState: (changeset: any) => void
    updateCurrentChangeset: (changeset: Changeset) => void
    roles: Principal[]
}

class AttachmentChangesetsGrid extends React.Component<AttachmentChangesetsProps, AttachmentChangesetsState> {
    constructor(props: AttachmentChangesetsProps, state: AttachmentChangesetsState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [],
            edit: false,
            updatedData:[],
            currentRow: -1,
            redirect:false,
            filtered: [],
            openModal: false,
            selectedRowColor: '',
            loading: true,
            showAddRowsButton: false,
            rowsToAddToChangeset: [],
            currentChangesetNumber: '',
            currentAction: '',
            cellInfo:{},
            actingOnChangeset:false
        };
    }

    renderCell = (cellInfo: any) => {
        const {data, currentChangesetNumber} = this.state;
        if (cellInfo.column.id === "id") {
            return (
                <div
                    style={{
                        color: 'blue',
                        textDecoration: currentChangesetNumber === data[cellInfo.index] ? 'underline' : 'none',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        let currentChangeset: Changeset = {
                            number: data[cellInfo.index].id,
                            status: '',
                            name: '',
                            comments: data[cellInfo.index].tabNm,  // reusing comments for the tab name
                            changeSetAuthorName: ''
                        };
                        this.props.updateCurrentChangeset(currentChangeset);
                        this.props.updateChangesetState(currentChangeset);
                        this.setState({currentChangesetNumber: data[cellInfo.index]})
                    }}
                >{data[cellInfo.index][cellInfo.column.id]}</div>
            )
        }
        return <div>{data[cellInfo.index][cellInfo.column.id]}</div>;
    };

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[] });
    };

    componentDidUpdate(prevProps: Readonly<AttachmentChangesetsProps>, prevState: Readonly<AttachmentChangesetsState>, snapshot?: any): void {
        let theads = document.querySelectorAll(".attachmentChangesetGrid .rt-thead");
        let tbodies = document.querySelectorAll(".attachmentChangesetGrid .rt-tbody");
        let tbody = tbodies[0];

        if (tbody.scrollHeight > tbody.clientHeight) {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).classList.add("vertical-scrollbar-present");
            }
        }
        else {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).classList.remove("vertical-scrollbar-present");
            }
        }
    }
    
    metadata = [
        {
            header: 'ID',
            accessor: 'id'
        }, {
            header: 'Submittal Year',
            accessor: 'subYearId'
        }, {
            header: 'Table Name',
            accessor: 'tabNm'
        }, {
            header: 'Changeset Author',
            accessor: 'creBy'
        }, {
            header: 'Number of Attachments',
            accessor: 'numattach'
        }
    ];

    componentDidMount() {
        let theads = document.querySelectorAll(".attachmentChangesetGrid .rt-thead");
        let tbodies = document.querySelectorAll(".attachmentChangesetGrid .rt-tbody");
        let tbody = tbodies[0];

        tbody.addEventListener("scroll", () => {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).scrollLeft = tbody.scrollLeft;
            }
        });

        axios.get(`/api/auth/changesetsWithAttachment`)
            .then(resp => {
                const data = resp.data ? resp.data : [];
                const columns: any = this.metadata.map((c: any) => ({
                    Header: c.header,
                    accessor: c.accessor,
                    Cell: this.renderCell
                }));
                this.setState({data, columns});
            })
            .catch(resp => {
                toast.error(`Error retrieving attachment changeset data - ${resp.message}`);
            })
            .finally(() => {
                this.setState({loading: false});
            });
    }

    pageSizeChanged = (pageSize:number) => {
        localStorage.setItem(`AttachmentChangeset`,pageSize.toString());
    };

    render() {
        let pageSize = localStorage.getItem(`AttachmentChangeset`);

        return (
            <Container fluid={true} style={{paddingLeft: '50px', paddingRight: '50px'}}>
                <ReactTable
                    data={this.state.data}
                    className='-highlight attachmentChangesetGrid'
                    columns={this.state.columns}
                    defaultPageSize={pageSize ? +pageSize : 20}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100, 200]}
                    filterable={true}
                    filtered={this.state.filtered}
                    style={{height: '500px', width: '100%'}}
                    onPageSizeChange={this.pageSizeChanged}
                    loadingText={'Loading...'}
                    loading={this.state.loading}
                    defaultFilterMethod={GridUtil.filterMethod}
                    onFilteredChange={filtered => this.setState({filtered})}
                    getTheadProps={() => {
                        return {
                            style: {
                                background: "black",
                                color: "white",
                                borderRadius: '5px'
                            }
                        };
                    }}
                    getTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth, textAlign: "center"}})}
                    getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                    getTheadFilterTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                />
                <Loading active={this.state.loading}/>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentChangeset: state.changeSetReducer.currentChangeset,
        roles: state.authReducer.roles,
        currentUser: state.authReducer.currentUser
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentChangeset: (changeset: Changeset) => dispatch (
            {type: actionTypes.UPDATE_CURRENT_CHANGESET, payload: changeset}
        )
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AttachmentChangesetsGrid)
