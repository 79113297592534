import * as React from 'react';
import {Container, Grid} from 'semantic-ui-react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import NavMenu from './NavMenu';
import Footer from './common/Footer';

export interface LayoutProps {
    children?: React.ReactNode;
    location: any;
}

export class Layout extends React.Component<LayoutProps, {}> {
    constructor(props:LayoutProps,state: {}) {
        super(props,state);
        this.state = {};
    }


    public render() {
        return (
            <Container fluid={true} >
                <ToastContainer />
                <Grid columns={'equal'}>
                    <Grid.Row centered={true}>
                        <NavMenu location={this.props.location} />
                    </Grid.Row>
                    <Grid.Row/>
                    <Grid.Row/>
                </Grid>
                    {this.props.children}
                <br/>
                <br/>
                <Footer />
            </Container>
        );
    }
}
