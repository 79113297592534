import * as React from 'react';
import {Button, Dropdown, Icon, Label, Modal, ModalProps} from "semantic-ui-react";
import axios from "axios";
import {CommentData} from "./Comment";
import {toast} from "react-toastify";
import {connect} from "react-redux";

interface CommentDeleteModalProps {
    comment: CommentData,
    handleCommentDelete(newComment: any, commentResponse : any ): any,
}

type OwnProps = CommentDeleteModalProps & ModalProps;

interface PropsFromState {
    userEntities: any[]
}

type AllProps = OwnProps & PropsFromState;

interface CommentDeleteModalState {
    open: boolean,
    entities: Array<object>,
    selectedEntity: string,
    postingId: number,
    commentId: number,
    privateCheck: boolean,
    commentText: string,
    admin: boolean,
    entityLock: string,
    loading: boolean
}

class CommentDeleteModal extends React.Component<AllProps, CommentDeleteModalState> {
    _isMounted: boolean = false;

    constructor(props: AllProps, state: CommentDeleteModalState) {
        super(props, state);
        console.log("comment Delete constructor");
        this.state = {
            open: false,
            entities: [],
            selectedEntity: '',
            postingId: this.props.comment.postingsId,
            commentId: this.props.comment.id,
            privateCheck: this.props.comment.isPrivate,
            commentText: '',
            admin: false,
            entityLock: this.props.comment.isPrivate ? this.props.comment.entityLock : '',
            loading: true
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getUserEntity();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleFieldChange = (e:any, value:any) => {
        this.setState({ [e.target.name.toString()] : value.value } as any);
    };

    toggleModal = () => {
        this.setState((prevState) => {
            return ({open: !prevState.open})
        });
    };

    handleDelete = () => {
        if(this.state.entities.length > 0 && this.state.selectedEntity === ''){
            toast.warn('You must select an entity');
            return;
        }
        const data = {
            postingId: this.state.postingId.toString(),
            commentId : this.state.commentId.toString(),
            respEntNameType: this.state.selectedEntity,
        };
        axios.delete('/api/auth/comments/comment',
            { data }
        ).then((resp) => {
            this.props.handleCommentDelete(data,resp.data);
        }).catch((resp) => {
            console.log('CommentDeleteModal - handleDelete failed:',resp.data);
        });
        this.toggleModal();
    };

    handleSelectChange = (e: any, data: any) => {
        this.setState({selectedEntity: data.value});
    };

    selectUserEntity = () => {
        if(this.state.entities.length === 0){
            return ( <Label>{this.state.selectedEntity}</Label> );
        }


        return (
            <Dropdown placeholder="Select Entity"
                      selection={true}
                      clearable={true}
                      options={this.state.entities}
                      onChange={this.handleSelectChange}/>
        )
    };

    getUserEntity = () => {
        this.setState({loading: true});
        let dropdownItems: Array<object> = [];
        let entity = '';
        let spp: boolean = false;
        this.props.userEntities.forEach((item: any) => {
            entity = item.respEntTypeNm;
            if (entity === 'SPP(ISO)') {spp = true;}
            dropdownItems.push({key: entity, text: entity, value: entity});
        });

            if(this._isMounted) {
                if (spp) {
                    this.setState({selectedEntity: 'SPP(ISO)', admin: true})
                } else if (dropdownItems.length === 1) {
                    this.setState({selectedEntity: entity});
                } else if (this.props.comment.isPrivate) {
                    this.setState({selectedEntity: this.props.comment.entityLock})
                } else {
                    this.setState({entities: [...dropdownItems]});
                }
            }
    };

    render() {
        return(
            <>
                <Button floated={'left'} size={'mini'} color={'black'} onClick={this.toggleModal}>
                    <Icon name='delete'/>
                    Delete
                </Button>
                <Modal size={'mini'}  open={this.state.open} closeOnDimmerClick={true} closeIcon={true} onClose={this.toggleModal}>
                    <Modal.Header>Delete Comment</Modal.Header>
                    <Modal.Content>
                        <p>Are you sure you want to delete this Comment?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button  onClick={this.handleDelete} content={'Yes'}/>
                        <Button  onClick={this.toggleModal} content={'No'}/>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        userEntities: state.authReducer.userEntities
    }
};

export default connect<PropsFromState, {}, OwnProps>(mapStateToProps, {})(CommentDeleteModal);