import React, {useState} from 'react';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import {Segment} from 'semantic-ui-react';
import {Button} from '@progress/kendo-react-buttons';
import axios from 'axios';
import {ApiValidations} from '../../interfaces/grid-interfaces';
import {Loader} from '@progress/kendo-react-indicators';

interface ValidationMessageAdminControlProps {
    updateMessageList: (messageList: Array<ApiValidations>) => void
}

export const ValidationMessageAdminControl: React.FC<ValidationMessageAdminControlProps> = ({updateMessageList}) => {
    const [season, setSeason] = useState<string>('All');
    const [loading, setLoading] = useState<boolean>(false);

    const handleSeasonChange = (event: DropDownListChangeEvent) => {
        setSeason(event.value);
    };
    
    const runValidations = () => {
        setLoading(true);
        axios.post(`/api/auth/ra/validations/${season}`)
            .then((resp) => {
                updateMessageList(resp.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };
    
    return (
        <Segment>
            <DropDownList
                data={['All','Summer','Winter']}
                value={season}
                onChange={handleSeasonChange}
                style={{width: '200px'}}
            />
            <Button onClick={runValidations}>Run Validations for Season {loading && <Loader size='small' type='pulsing'/>}</Button>
            <br/><br/>
            <p>Validations may take several minutes to execute. Selecting <strong>All</strong> will run validations for both current and next season.</p>
        </Segment>
    );
};