import * as React from 'react';
import {Button, Form, Modal, ModalProps, Select} from "semantic-ui-react";
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";

interface StakeholderModalProps extends ModalProps {
}

interface StakeholderModalState {
    stakeholderId: number
    stakeholderNm: string
    addStakeholderModalLoaded: boolean
    nercId: string
    stakeholderType: string
    stakeholderResponsibility: any
    submitButtonDisabled: boolean
    existingStakeholder: boolean

}

const stakeholderResponsibilityDropdown = [
    { key: 'ba ', text: 'BA', value: 'BA'},
    { key: 'dp', text: 'DP', value: 'DP'},
    { key: 'go', text: 'GO', value: 'GO'},
    { key: 'gop', text: 'GOP', value: 'GOP'},
    { key: 'pa_PC', text: 'PA_PC', value: 'PA_PC'},
    { key: 'rc', text: 'RC', value: 'RC'},
    { key: 'rp', text: 'RP', value: 'RP'},
    { key: 'rsg', text: 'RSG', value: 'RSG'},
    { key: 'frsg', text: 'FRSG', value: 'FRSG'},
    { key: 'rrsg', text: 'RRSG', value: 'RRSG'},
    { key: 'to', text: 'TO', value: 'TO'},
    { key: 'top', text: 'TOP', value: 'TOP'},
    { key: 'tp', text: 'TP', value: 'TP'},
    { key: 'tsp', text: 'TSP', value: 'TSP'}
];

const stakeholderType = [
    { key: 'Internal ', text: 'Internal', value: 'Internal'},
    { key: 'External', text: 'External', value: 'External'},
    { key: 'WECC', text: 'WECC', value: 'WECC'}
];

const initialState = {
                        stakeholderId: 0,
                        stakeholderNm: '',
                        addStakeholderModalLoaded: false,
                        nercId: '',
                        stakeholderType: '',
                        stakeholderResponsibility: [],
                        submitButtonDisabled: true,
                        existingStakeholder: false
                    }

class StakeholderModal extends React.Component<StakeholderModalProps,StakeholderModalState> {
    constructor(props: StakeholderModalProps) {
        super(props);
        this.state = initialState
    }

    handleSubmitClick = () =>{
        if(this.props.addOrModifyContact === 'Modify') {
            this.modifyStakeholder()
        } else {
            this.addStakeholder();
        }
        this.closeModalCleanup();
    }

    handleCancelClick = () => {
        this.closeModalCleanup();

    }

    handleFormChange = (e: any, { value, name}: any) =>{
        // Updates state as values in the form are changed
        let field;
        field = name
        // @ts-ignore
        this.setState({[field]: value})
    }

    closeModalCleanup = ()=>{
        //set state back to empty so that a blank form is opened next time they click Add Contact
        this.setState(initialState)
        //reset redux store values to the initial
        this.props.updateStakeholderModalActive(false);
        this.props.updateAddOrModifyContact('');
        this.props.updateStakeholderCurrentRow({});
    }

    modifyStakeholder = () => {
        //Modifies and existing stakeholder record
        let data = {
            sid: this.state.stakeholderId,
            stakeholderName: this.state.stakeholderNm.trim(),
            nercId: this.state.nercId.trim(),
            stakeholderType: this.state.stakeholderType,
            types: this.convertArrayToBooleanList()
        };
        axios.post('/api/auth/contact/stakeholder/update', data)
            .then(response => {
                if (response.status === 200) {
                    toast.success('Stakeholder information updated successfully!')
                    //making a dummy change to props so that the grid will update when the modal closes
                    this.props.updateStakeholderCurrentRow({dummy: 1});
                }
            })
            .catch(error => {
                toast.error(`Error occurred updating a stakeholder info - ${error.message}`)
            });
    }

    addStakeholder = () => {
        let data = {
            stakeholderName: this.state.stakeholderNm.trim(),
            nercId: this.state.nercId.trim(),
            stakeholderType: this.state.stakeholderType,
            types: this.convertArrayToBooleanList()
        };
        axios.post('/api/auth/contact/stakeholder/add', data)
            .then(response => {
                if (response.status === 200) {
                    //making a dummy change to props so that the grid will update when the modal closes
                    this.props.updateStakeholderCurrentRow({dummy: 1});
                    toast.success('Stakeholder information added successfully!')
                }
            })
            .catch(error => {
                toast.error(`Error occurred adding a new stakeholder - ${error.message}`)
            });
    }

    convertBooleanToArray = (currentRow: any) => {
        //The stakeholder responsibilities are stored in separate boolean fields and come across as 14 boolean values
        //To get them formatted where they will display correctly in the modal dropdown, we have to add any true values
        //to an array that manages the values in the multi-select dropdown
        let tempArray: any = []
        for(let x in currentRow){
            if(currentRow[x] === true){
                tempArray.push(x.toUpperCase());
            }
        }
        this.setState({stakeholderResponsibility: tempArray})
    }

    convertArrayToBooleanList = () => {
        //To pass the array of stakeholder responsibilites from the dropdown back to the API the list must be converted to
        // an array of boolean values
        let stakeholderResponsibilityArray: any[] = [];
        stakeholderResponsibilityDropdown.forEach((r:any) =>{
            if(this.state.stakeholderResponsibility.includes(r.value.toUpperCase())){
                stakeholderResponsibilityArray.push(true);
            } else{
                stakeholderResponsibilityArray.push(false);
            }
        })
        return stakeholderResponsibilityArray;
    }

    componentWillReceiveProps(nextProps: any) {
        // this will run every time the props change - and possibly in addition to this, so we need to check for prop changes
        if (this.props.stakeholderCurrentRow !== nextProps.stakeholderCurrentRow) {
            this.setState(nextProps.stakeholderCurrentRow);
            this.convertBooleanToArray(nextProps.stakeholderCurrentRow);
        }
    }

    componentDidUpdate(prevProps: Readonly<StakeholderModalProps>, prevState: Readonly<StakeholderModalState>, snapshot?: any): void {
        // Component did mount does not run every time modal is loaded, so we need to check for state changes to make sure
        // everything a few of our state values get updated so display on modal is correct
        if(prevState.stakeholderId !== this.state.stakeholderId && this.state.stakeholderId !== 0) {
            this.setState({existingStakeholder: true})
        } else{
            if (prevState.stakeholderNm !== this.state.stakeholderNm || prevState.nercId !== this.state.nercId || prevState.stakeholderType !== this.state.stakeholderType || prevState.stakeholderResponsibility !== this.state.stakeholderResponsibility) {
                if (this.state.stakeholderNm && this.state.stakeholderType) {
                    this.setState({submitButtonDisabled: false});
                }
            }
        }
    }

    public render(){
        return (
            <Modal size={"large"} open={this.props.stakeholderModalActive}>
                <Modal.Header>
                    {this.props.addOrModifyContact === 'Add' ? 'Add New Stakeholder': 'Modify Stakeholder'}
                </Modal.Header>
                <Modal.Content>
                    <div className={'addStakeholderDiv'}>
                        <Form>
                            <Form.Input fluid={true} label='Stakeholder Name' entryType={'string'}
                                        className={'projectNameInput'} placeholder={'Stakeholder Name'}
                                        onChange={this.handleFormChange}
                                        name={'stakeholderNm'} value={this.state.stakeholderNm}/>

                            <Form.Input fluid={true} label='NERC Id' entryType={'string'}
                                        className={'projectNameInput'} placeholder={'NERC Id'}
                                        onChange={this.handleFormChange}
                                        name={'nercId'} value={this.state.nercId}/>

                            <Form.Input required={true} fluid={true} selection={true}
                                        control={Select}
                                        options={stakeholderType}
                                        label={{children: 'Stakeholder Type', htmlFor: 'form-select-control-type'}}
                                        placeholder='Select stakeholder type...'
                                        searchInput={{id: 'form-select-control-type'}}
                                        onChange={this.handleFormChange}
                                        value={this.state.stakeholderType}
                                        name={'stakeholderType'}
                                        readOnly={false} disabled={false}
                            />

                            <Form.Input required={true} fluid={true} multiple={true} selection={true}
                                        control={Select}
                                        options={stakeholderResponsibilityDropdown}
                                        label={{children: 'Stakeholder Responsibility', htmlFor: 'form-select-control-type'}}
                                        placeholder='Select all that apply...'
                                        searchInput={{id: 'form-select-control-type'}}
                                        onChange={this.handleFormChange}
                                        value={this.state.stakeholderResponsibility}
                                        name={'stakeholderResponsibility'}
                                        entryType={'multiSelect'}
                                        readOnly={false} disabled={false}

                            />
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>

                    <Button content={'Submit'} color={'blue'} onClick={this.handleSubmitClick}
                            //allow them to submit when all fields have a value, except the NERC Id
                            disabled={this.state.submitButtonDisabled}/>

                    <Button content={'Cancel'} color={'red'} onClick={this.handleCancelClick}/>

                </Modal.Actions>
            </Modal>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        stakeholderModalActive: Boolean(state.contactReducer.stakeholderModalActive),
        addOrModifyContact: String(state.contactReducer.addOrModifyContact),
        stakeholderCurrentRow: state.contactReducer.stakeholderCurrentRow
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateStakeholderModalActive: (stakeholderModalActive: boolean) => dispatch(
            {type: actionTypes.SET_STAKEHOLDERMODALACTIVE, payload: stakeholderModalActive}
        ),
        updateAddOrModifyContact: (addOrModifyContact: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYCONTACT, payload: addOrModifyContact}
        ),
        updateStakeholderCurrentRow: (stakeholderCurrentRow: boolean) => dispatch(
            {type: actionTypes.SET_STAKEHOLDERCURRENTROW, payload: stakeholderCurrentRow}
        ),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderModal);