import {Grid, GridColumn, GridDataStateChangeEvent} from '@progress/kendo-react-grid';
import React, {useRef, useState} from 'react';
import {ApiValidations} from '../../../interfaces/grid-interfaces';
import {process} from "@progress/kendo-data-query";
import {initialDataState, replaceSpaceWithUnderscore} from "../../../services/data-grid-service";
import ClearFilterPanel from "../action-panel/ClearFilterPanel";
import ActionPanel from "../action-panel/ActionPanel";
import ExportPanel from "../action-panel/ExportPanel";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import RefreshPanel from '../action-panel/RefreshPanel';
import format from "date-fns/format";
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';

interface ValidationMessageGridProps {
    data: Array<ApiValidations>,
    showDataSet: boolean,
    refresh?: () => void,
    loading?: boolean,
    title: string
}

const ValidationMessageGrid: React.FC<ValidationMessageGridProps> = ({data, showDataSet, refresh, loading = false, title}) => {
    const globalPageSize = localStorage.getItem(`globalPageSize`)
    const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(data);
        }
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={'Validation Messages'}>
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!!refresh && <RefreshPanel disabled={false} getData={refresh} loading={loading}/>}
                <ExportPanel exportData={excelExport} disabled={false}/>
            </ActionPanel>
            <ExcelExport
                data={data}
                fileName={replaceSpaceWithUnderscore(`${title}_validation_messages_${format(new Date(), 'MM-dd-yyyy')}.xlsx`)}
                ref={_export}
            >
                <Grid
                    data={process(data, dataState)}
                    dataItemKey='id'
                    sortable={true}
                    filterable={true}
                    resizable={true}
                    style={{ height: "350px" }}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                    onPageChange={pageChange}
                >
                    {columnNames.length > 0 
                        ? columnNames.map((columnName, index) => {
                        switch (columnName) {
                            case 'seasonName':
                                return <GridColumn key={index} field={columnName} title={'Season Name'}/>
                            case 'dataSetName':
                                return showDataSet && <GridColumn key={index} field={columnName} title={'Data Set'}/>
                            case 'recordKey':
                                if (window.location.href.indexOf("DemandAndEnergy") < 1 && window.location.href.indexOf("CapacityAdjustments") < 1) {
                                    return <GridColumn key={index} field={columnName} title={'Record Key'}/>
                                }
                                else {
                                    return null;
                                }
                            case 'validationDefinitionId':
                                return <GridColumn key={index} field={columnName} title={'Message Type ID'}/>
                            case 'description':
                                return <GridColumn key={index} field={columnName} title={'Description'}/>
                            case 'entityName':
                                return <GridColumn key={index} field={columnName} title={'Entity Name'}/>
                            case 'validationLevel':
                                return <GridColumn key={index} field={columnName} title={'Validation Level'}/>
                            case 'lastRunBy':
                                return <GridColumn key={index} field={columnName} title={'Validation Run By'}/>
                            case 'lastRunDate':
                                return <GridColumn key={index} field={columnName} title={'Date Validated'}/>
                            default:
                                return null;
                        }
                    })
                        : <GridColumn/>
                    }
                </Grid>
            </ExcelExport>
        </div>
    );
};

export default ValidationMessageGrid;