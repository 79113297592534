// Common functions used by TestResultsOpGrid, TestResultsSummerGrid, and TestResultsWinterGrid.
// These only operate on the common columns of all three TestResults interfaces.

import React from 'react';
import {toast} from 'react-toastify';
import {addDataError, removeDataErrorByValue} from './data-grid-service';
import {TestResultsData} from '../interfaces/test-results-interface';
import {UiValidations} from '../interfaces/grid-interfaces';
import {ChildDropDownOption} from '../components/common/grid/ChildDropDownCell';

export const plantNmEmpty = 'Plant Name must have a value to save the record.';
export const respEntNmEmpty = 'Last Modifying Entity must have a value to save the record.';
export const ResourceNmEmpty = 'Resource ID must have a value to save the record.';
export const plantAndResourceNmDuplicate = 'Plant Name and Resource ID match an existing record, please fix the issue or edit an existing record.';


export const handleTestResultChanges =
    <T extends TestResultsData>(data: Array<T>, id: number, field: string, value: any, dataErrors: Array<UiValidations>,
                                setDataErrors: React.Dispatch<React.SetStateAction<UiValidations[]>>,
                                resourceOptions: Array<ChildDropDownOption>) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                if (field === 'plantNm') {
                    dataItem.resourceNm = '';
                    dataItem.energySource = '';
                }
                if (field === 'resourceNm') {
                    dataItem.energySource = getEnergySource(dataItem.plantNm, dataItem.resourceNm, resourceOptions) as string;
                }
                if (field === 'respEntNm' || field === 'plantNm' || field === 'resourceNm') {
                    setDataErrors(removeDataErrorByValue(id, field, dataErrors, ''));
                }
                return dataItem;
            } else {
                return item;
            }
        });
    };

export const getEnergySource = (plantNm: string, resourceNm: string, options: Array<ChildDropDownOption>) => {
    const option = options.find((option: ChildDropDownOption) => option.parentValue === plantNm && option.value === resourceNm);
    return !!option ? option.childValue : '';
};

export const processResourceOptions = (resourceData: any) => {
    return resourceData
        .map((item: any) => ({
            text: item.resourceName,
            value: item.resourceName,
            parentValue: item.plantName,
            childValue: item.energySource
        }))
        .sort((a: any, b: any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
};

export const filterEmptyRequiredColumns =
    <T extends TestResultsData>(data: Array<T>, dataErrors: Array<UiValidations>,
                                setDataErrors: React.Dispatch<React.SetStateAction<UiValidations[]>>): boolean => {
        const missingRequired = data.filter((item) => !item.respEntNm || !item.plantNm || !item.resourceNm);
        if (missingRequired.length === 0) {
            return false;
        }
        toast.error('There are columns missing required data. Please provide value to save changes.');
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {
            if (!item.respEntNm) {
                localErrors = addDataError(item.id, 'respEntNm', 'Last Modifying Entity', '',
                    respEntNmEmpty, true, localErrors);
            }
            if (!item.plantNm) {
                localErrors = addDataError(item.id, 'plantNm', 'Plant Name', '',
                    plantNmEmpty, true, localErrors);
            }
            if (!item.resourceNm) {
                localErrors = addDataError(item.id, 'resourceNm', 'Resource ID', '',
                    ResourceNmEmpty, false, localErrors);
            }
        });
        setDataErrors(localErrors);
        return true;
    };





