import {GridCellProps} from '@progress/kendo-react-grid';
import {Checkbox, CheckboxProps} from 'semantic-ui-react';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import React from "react";

// Define the custom messages for the grids to replicate True and False as YES and NO
loadMessages({
    grid: {
        filterIsTrue: 'Yes',
        filterIsFalse: 'No',
    },
}, 'myCustomMessages');


interface VerifiedCellProps extends GridCellProps {
    editable: boolean
}

export const VerifiedCell = (props: VerifiedCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const value = (field && !!dataItem[field]) as boolean;
    const tdClassName = props.editable ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const {originalData} = dataItem;
    const originalField = (originalData && originalData[field || '']) as boolean;
    const isChanged = props.editable && (value !== originalField);

    const onChange = (e: any, data: CheckboxProps) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: data.checked,
            });
        }
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center', backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {props.editable
                ? (<Checkbox checked={value} onChange={onChange}/>)
                : value ? ('YES') : ('NO')
            }
        </td>
    );
};

export const getVerifiedCell = (editable: boolean) => {
    return (props: GridCellProps) => (
        <VerifiedCell {...props} editable={editable}/>
    );
};

export const CustomLocalizationProvider: React.FC = ({ children }) => (
    <LocalizationProvider language="myCustomMessages">
        {children}
    </LocalizationProvider>
);
