import * as React from 'react';
import {Button, ButtonProps, Container, Header, Icon, Modal} from "semantic-ui-react";
import ReactTable from 'react-table';
import axios from 'axios';
//import 'react-table/react-table.css'
import '../../../CSS/react-table-custom.css';
import GridUtil from "../../common/GridUtil";
import {Loading} from "../../common/Loading";
import {connect} from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import {toast} from "react-toastify";
import {Principal} from "../../../auth";
import ChangesetService from "../../../services/changeset-services";

interface UserChangesetState {
    data: any;
    columns: any;
    edit: boolean;
    updatedData: any;
    currentRow: number;
    redirect: boolean;
    filtered: any;
    openModal: boolean,
    selectedRowColor: string,
    loading: boolean,
    showAddRowsButton: boolean,
    rowsToAddToChangeset: any,
    currentChangesetNumber: string
    currentAction: string
    cellInfo:any
    actingOnChangeset:boolean
}

interface UserChangesetsProps {
    currentChangeset: Changeset
    currentUser: string
    updateCurrentChangeset: (changeset: Changeset) => void
    roles: Principal[]
}

const demandAndEnergyTabs = [
    'DemandSideManagement',
    'MnthlyNetEnergyForLoad',
    'MnthlyPeakDemand',
    'YrlyNetEnergyForLoad',
    'YrlyPeakDemand'
];

const changesetClient = new ChangesetService();
const includes = require('lodash/includes');
const some = require('lodash/some');
class UserChangesetsGrid extends React.Component<UserChangesetsProps, UserChangesetState> {
    constructor(props: UserChangesetsProps, state: UserChangesetState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [],
            edit: false,
            updatedData:[],
            currentRow: -1,
            redirect:false,
            filtered: [],
            openModal: false,
            selectedRowColor: '',
            loading: true,
            showAddRowsButton: false,
            rowsToAddToChangeset: [],
            currentChangesetNumber: '',
            currentAction: '',
            cellInfo:{},
            actingOnChangeset:false
        };
    }

    renderCell = (cellInfo: any) => {
        const {data,currentChangesetNumber} = this.state;
        
        if (cellInfo.column.id === "id") {
            return (
                <div
                    style={{
                        color:'blue',
                        textDecoration:currentChangesetNumber === data[cellInfo.index] ? 'underline' : 'none',
                        cursor:'pointer'}}
                    onClick={() => {
                        let currentChangeset:Changeset = {
                            number: data[cellInfo.index].id,
                            status: '',
                            name: '',
                            comments: data[cellInfo.index].tabNm,  // reusing comments for the tab name
                            changeSetAuthorName:''
                        };
                        this.props.updateCurrentChangeset(currentChangeset);
                        this.setState({currentChangesetNumber:data[cellInfo.index]})
                    }}
                >{data[cellInfo.index][cellInfo.column.id]}</div>
            )
        }

        return <div>{data[cellInfo.index][cellInfo.column.id]}</div>;
    };

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[] });
    };


    getFiltered = () => {
        if (this.state.filtered) {
            return 'visible'
        }
        return 'hidden'
    };

    actOnChangeset = () => {
        let cellInfo = this.state.cellInfo;
        let tabName = cellInfo.original.tabNm;
        if (includes(demandAndEnergyTabs,tabName)) {
            tabName = 'DemandAndEnergy';
        }
        changesetClient.actOnChangeset(cellInfo.original.id,this.state.currentAction,tabName)
            .then((data:any) => {
                if (this.state.currentAction !== 'APPROVED' && data === '') {
                    let stateData = this.state.data.filter((d:any) => {
                        return d.id !== cellInfo.original.id;
                    });
                    this.setState({data:stateData,currentAction:'',cellInfo:{},actingOnChangeset:false})
                } else if (data === '') {
                    let stateData = [...this.state.data].map((sd:any) => {
                        if (sd.id === this.state.data[cellInfo.index].id) {
                            return {...sd, disp: 'APPROVED'};
                        } else {
                            return sd;
                        }
                    });

                    this.setState({data:stateData,currentAction:'',cellInfo:{},actingOnChangeset:false})
                }
                this.closeModal();
            });
        this.setState({actingOnChangeset:true})
    };

    confirmActionOnChangeset = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        let cellInfo = JSON.parse(data.cellinfo);
        this.setState({currentAction:data.name,cellInfo})
    };

    getActionButtons = (cellInfo:any) => {
        let {roles} = this.props;
        let tabName = cellInfo.original.tabNm;
        let approveDisabled = true;
        let rejectDisabled = true;
        let abandonDisabled = true;

        if (includes(demandAndEnergyTabs,tabName)) {
            tabName = 'DemandAndEnergy';
        }

        if (cellInfo.original.userNm.toUpperCase() === this.props.currentUser.toUpperCase() && cellInfo.original.disp === 'DRAFT') {
            abandonDisabled = false;
        }

        if (some(roles,{tabNm:tabName, sppStaff:true} || some(roles,{tabNm:tabName,readRole:true}))) {
            if (includes(['QUEUED', 'WARN'], cellInfo.original.disp)) {
                approveDisabled = false;
                rejectDisabled = false;
                if (includes(['DRAFT'], cellInfo.original.disp)) {
                    rejectDisabled = false;
                }
            }
        }

        let approveButton = <Button size={'mini'} color={approveDisabled ? 'grey' : "green"} basic={true} key={'APPROVED'} name={'APPROVED'} cellinfo={JSON.stringify(cellInfo)} disabled={approveDisabled} onClick={this.confirmActionOnChangeset}>Approve </Button>;
        let rejectButton = <Button size={'mini'} color={rejectDisabled ? 'grey' : "red"} basic={true} key={'REJECTED'} name={'REJECTED'} cellinfo={JSON.stringify(cellInfo)} disabled={rejectDisabled} onClick={this.confirmActionOnChangeset}>Reject </Button>;
        let abandonButton = <Button size={'mini'} color={abandonDisabled ? 'grey' : "yellow"} basic={true} key={'ABANDONED'} name={'ABANDONED'} cellinfo={JSON.stringify(cellInfo)} disabled={abandonDisabled} onClick={this.confirmActionOnChangeset}>Abandon</Button>;


        if (some(roles,{tabNm:tabName, sppStaff:true})) {
            return <Container fluid={true} textAlign={"center"}>{approveButton}{rejectButton}</Container>
        } else {
            return <Container fluid={true} textAlign={"center"}>{abandonButton}</Container>
        }

    };

    componentDidUpdate(prevProps: Readonly<UserChangesetsProps>, prevState: Readonly<UserChangesetState>, snapshot?: any): void {
        let theads = document.querySelectorAll(".userChangesetGrid .rt-thead");
        let tbodies = document.querySelectorAll(".userChangesetGrid .rt-tbody");
        let tbody = tbodies[0];

        if (tbody.scrollHeight > tbody.clientHeight) {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).classList.add("vertical-scrollbar-present");
            }
        }
        else {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).classList.remove("vertical-scrollbar-present");
            }
        }
    }
    
    metadata = [
        {
            header: 'ID',
            accessor: 'id'
        }, {
            header: 'Name',
            accessor: 'nm'
        }, {
            header: 'Submittal Year',
            accessor: 'subYearNm'
        }, {
            header: 'Changeset Author',
            accessor: 'userNm'
        }, {
            header: 'Responsible Entity',
            accessor: 'responsibleEntity'
        }, {
            header: 'Category',
            accessor: 'category'
        }, {
            header: 'Data Set',
            accessor: 'visTabNm'
        }, {
            header: 'Disposition',
            accessor: 'disp'
        }, {
            header: 'Created By',
            accessor: 'creBy'
        }, {
            header: 'Created Date',
            accessor: 'creDt'
        }, {
            header: 'Modified By',
            accessor: 'modBy'
        }, {
            header: 'Modified Date',
            accessor: 'modDt'
        }
    ];

    componentDidMount() {
        let theads = document.querySelectorAll(".userChangesetGrid .rt-thead");
        let tbodies = document.querySelectorAll(".userChangesetGrid .rt-tbody");
        let tbody = tbodies[0];

        tbody.addEventListener("scroll", () => {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).scrollLeft = tbody.scrollLeft;
            }
        });

        axios.get(`/api/auth/changesetHistory`).then( resp => {
            const data = resp.data ? resp.data : [];
            const columns: any = this.metadata.map((c:any) => ({
                    Header: c.header,
                    accessor: c.accessor,
                    Cell: this.renderCell
                }));
            // add utility column to top of the column array
            columns.unshift({
                Header: 'Available Action',
                Cell: (cellInfo:any) => { return this.getActionButtons(cellInfo)},
                Filter: <Button color={'black'} size={'mini'} style={{visibility:this.getFiltered}} onClick={this.clearFilterOnClick}>Clear Filters</Button>,
                sortable: false,
                minWidth: 150
            });
            this.setState({data, columns});
        }).catch(resp => {
            toast.error(`Error retrieving user changeset data - ${resp.message}`);
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    closeModal = () => {
        this.setState({currentAction:'',cellInfo:{}})
    };

    getConfirmationModal = () => {
        let action = '';
        if (this.state.currentAction === 'APPROVED') {
            action = 'Approve';
        } else if (this.state.currentAction === 'ABANDONED') {
            action = 'Abandon';
        } else if (this.state.currentAction === 'REJECTED') {
            action = 'Reject';
        }

        return (
            <Modal
                closeIcon={true} centered={true} size={'tiny'} open={this.state.currentAction !== ''} onClose={this.closeModal}>
                <Header content={`Confirm ${action} of Changeset`}/>
                <Modal.Content>
                    {`Are you sure you want to ${action} the changeset?`}
                </Modal.Content>
                <Modal.Actions>
                    <Button color={'green'} name={this.state.currentAction} loading={this.state.actingOnChangeset} onClick={this.actOnChangeset}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                    <Button color='red' onClick={this.closeModal}>
                        <Icon name='remove'/> No
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    pageSizeChanged = (pageSize:number) => {
        localStorage.setItem(`UserChangesets`,pageSize.toString());
    };

    public render() {
            const {data} = this.state;
            let pageSize = localStorage.getItem(`UserChangesets`);

            return (
                <Container fluid={true} style={{paddingLeft:'50px', paddingRight:'50px'}}>
                    {this.getConfirmationModal()}
                    <ReactTable
                        data={data}
                        className='-highlight userChangesetGrid'
                        columns={this.state.columns}
                        defaultPageSize={pageSize ? +pageSize : 200}
                        pageSizeOptions={[5,10,20,25,50,100,200]}
                        filterable={true}
                        filtered={this.state.filtered}
                        style={{height:'500px', width: '100%'}}
                        onPageSizeChange={this.pageSizeChanged}
                        loadingText={'Loading...'}
                        loading={this.state.loading}
                        defaultFilterMethod={GridUtil.filterMethod}
                        onFilteredChange={filtered => this.setState({filtered})}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: "black",
                                    color: "white",
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={(state:any) => ({style: {minWidth: state.rowMinWidth}})}
                        getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                        getTheadFilterTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                    />
                    <Loading active={this.state.loading}/>
                </Container>
            );
        }

}

const mapStateToProps = (state: any) => {
    return {
        currentChangeset: state.changeSetReducer.currentChangeset,
        roles: state.authReducer.roles,
        currentUser: state.authReducer.currentUser
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentChangeset: (changeset: Changeset) => dispatch (
            {type: actionTypes.UPDATE_CURRENT_CHANGESET, payload: changeset}
        )
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(UserChangesetsGrid)
