import * as React from 'react';
import {
    Container,
    DropdownItemProps, Header,
} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
// import {AccordionSegment} from "../../common/AccordionSegment";
// import ContactGrid from "./ContactGrid";
// import StakeholderGrid from "./StakeholderGrid";
import TabGrids from "./TabGrids";

interface ContactsState {
    tables: DropdownItemProps[]
    table: string
    tablesLoading: boolean
    exporting: boolean
    hideGrid: boolean
}

interface ContactsProps {
}

export class Contact extends React.Component<RouteComponentProps<{}>& ContactsProps, ContactsState> {
    constructor(props: RouteComponentProps<{}>& ContactsProps, state: ContactsState) {
        super(props, state);
        this.state = {
            tables: [],
            table: '',
            tablesLoading: true,
            exporting: false,
            hideGrid: true,
        };
    }

    componentDidMount(): void {
        this.getContacts();

    }

    getContacts = () =>{
        console.log('Getting Contact')
    }

    render() {
        return (
            <Container fluid={true} style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
                <Header size={'large'}>Contact Management</Header>
                <TabGrids/>
            </Container>
        )
    }

}