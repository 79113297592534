import * as React from "react";
import {Dropdown, DropdownProps, Grid, Input, InputOnChangeData} from "semantic-ui-react";

interface ReminderState {
    inputValue: number
}

interface ReminderProps {
    endDate: string
    inputChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
    dropDownChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void
    index: number,
    defaultDurationSelection?: number,
    defaultDurationTypeSelection?: string
}

const dropDownOptions = [
    {key:'DAYS',text:'DAYS',value:'DAYS'},
    {key:'WEEKS',text:'WEEKS',value:'WEEKS'},
    {key:'MONTHS',text:'MONTHS',value:'MONTHS'},
];


export class Reminder extends React.Component<ReminderProps,ReminderState> {
    constructor(props: ReminderProps, state: ReminderState) {
        super(props, state);
        this.state = {
            inputValue: 0
        };
    }

    render(): React.ReactNode {
        return (
            <Grid columns={3}>
                <Grid.Column>
                    <Input type={'number'} onChange={this.props.inputChange} index={this.props.index} defaultValue={this.props.defaultDurationSelection}/>
                </Grid.Column>
                <Grid.Column>
                    <Dropdown options={dropDownOptions} search={true} selection={true} style={{paddingRight: '20px'}} index={this.props.index} onChange={this.props.dropDownChange} defaultValue={this.props.defaultDurationTypeSelection}/>
                </Grid.Column>
                <Grid.Column>
                    <p>prior to end date</p>
                </Grid.Column>
            </Grid>
        )
    }
}