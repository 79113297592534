import * as React from 'react';
import {Button, Container, Grid, Header, Icon, Modal, TextArea} from "semantic-ui-react";
import ReactTableHOC from '../../common/EdstGrid';
import axios from 'axios';
import '../../../CSS/react-table-custom-orig.css';
import GridUtil from "../../common/GridUtil";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {Principal} from "../../../auth";

interface ValidationMessagesState {
    data: any;
    columns: any;
    edit: boolean;
    updatedData: any;
    metaData: any;
    filtered: any;
    openAcknowledgeModal: boolean,
    openAcknowledgeAllModal: boolean,
    status: number,
    loading: boolean,
    currentChangesetId: number,
    cellInfo: any,
    edstGridInputFilterKey: any,
    modalComment: string
}

interface ValidationMessagesProps {
    currentChangeset: Changeset,
    currentUser: string,
    roles: Principal[],
    changesetVersion: number
}

const some = require('lodash/some');
class ValidationMessages extends React.Component<ValidationMessagesProps, ValidationMessagesState> {
    constructor(props: ValidationMessagesProps, state: ValidationMessagesState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [],
            edit: false,
            updatedData:[],
            metaData: [],
            filtered: [],
            openAcknowledgeModal: false,
            openAcknowledgeAllModal: false,
            status: 200,
            loading: false,
            currentChangesetId: -1,
            cellInfo:{},
            edstGridInputFilterKey: null,
            modalComment: ""
        };
    }

    private gridUtil = new GridUtil();

    handleChange = (e:any) => {
        let data = [...this.state.data];
        let cellInfo = JSON.parse(e.currentTarget.dataset.cellinfo);
        if (cellInfo.original[cellInfo.column.id] !== e.target.textContent) {
            data[cellInfo.index][cellInfo.column.id] = e.target.textContent;
            this.setState({data});
        }
    };

    handleFilterChange = (filter:any) => {
        this.setState({filtered:filter});
    };

    /*
    * Makes a cell editable, otherwise returns just the data to display
    */
    renderCell = (cellInfo: any) => {
        const {metaData,data} = this.state;
        if (metaData) {
            let currentColumn = metaData.filter((obj:any) => {
                return cellInfo.column.id === obj.nm.charAt(0).toLowerCase() + obj.nm.substring(1);
            })[0];

            if (currentColumn.editInd === "1") {
                return (
                    <div
                        style={{
                            borderStyle: cellInfo.original.overrideInd === true || cellInfo.original.sev !== 'WARN' ? 'none' : 'inset'
                        }}
                        contentEditable={!(cellInfo.original.overrideInd === true || cellInfo.original.sev !== 'WARN')}
                        suppressContentEditableWarning={true}
                        onBlur={this.handleChange}
                        data-cellinfo={JSON.stringify(cellInfo)}
                    >{data[cellInfo.index][cellInfo.column.id]}</div>
                )
            } else {
                return (this.gridUtil.format(currentColumn.type, data[cellInfo.index][cellInfo.column.id]));
            }
        }
    };

    isAcknowledgeAllValid = () => {
        let data = [...this.state.data];
        let isValid: boolean = false;
        if (data.length > 0 && this.isSppStaff(data[0].tabNm)) {
            data.forEach((element) => {
                if (element.overrideInd === false && element.sev === "WARN") {
                    isValid = true;
                }
            });
        }

        return isValid;
    }

    acknowledgeAllValidations = () => {
        let modalComment: string = this.state.modalComment;
        axios.put('/api/auth/acknowledgeAllValidations',{
            changesetId: this.state.currentChangesetId,
            comment: modalComment,
        },{
            withCredentials:true
        }).then(resp => {
            if (resp.status === 200 && resp.data === 'Success') {
                this.getData();
                this.setState({openAcknowledgeAllModal:false});
                toast.success('Validation has been successfully overridden');
            }
        }).catch(resp => {
            toast.error(`Error acknowledging validation - ${resp.message}`)
        })
    }

    acknowledgeValidations = () => {
        let {chgSId,id,comments} = this.state.cellInfo.original;
        axios.put('/api/auth/acknowledgeValidation',{
            changesetId: chgSId,
            messageId: id,
            comment: comments,
        },{
            withCredentials:true
        }).then(resp => {
            if (resp.status === 200 && resp.data === 'Success') {
                let data = [...this.state.data];
                data[this.state.cellInfo.index].overrideInd = true;
                this.setState({data,openAcknowledgeModal:false});
                toast.success('Validation has been successfully overridden');
            }
        }).catch(resp => {
            toast.error(`Error acknowledging validation - ${resp.message}`)
        })
    };

    isSppStaff = (tabName:string) => {
        return some(this.props.roles,{tabNm:tabName, sppStaff:true})
    };

    getAcknowledgeButton = (cellInfo:any) => {
        return (
            <Container textAlign={"center"}>
                <Button size={'mini'} disabled={cellInfo.original.overrideInd === true || cellInfo.original.sev !== 'WARN' || !this.isSppStaff(cellInfo.original.tabNm)}
                        color={"black"}
                        basic={true} onClick={(e:any) => this.acknowledgeButtonClick(e, cellInfo)}
                >Acknowledge</Button>
            </Container>
        )
    };

    acknowledgeButtonClick = (event:any, cellInfo:any) => {/*event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {*/
        //let myStuff = JSON.parse(data.myStuff);
        this.setState({
            cellInfo: cellInfo,
            openAcknowledgeModal:true
        })
    };

    acknowledgeAllButtonClick = (event:any, cellInfo:any) => {
        this.setState({
            cellInfo: cellInfo,
            openAcknowledgeAllModal:true
        })
    };

    closeModal = () => {
        this.setState({openAcknowledgeModal:false, openAcknowledgeAllModal: false})
    };

    acknowledgeColumn = () => {
        return [{
            Header: 'Acknowledgement',
            Cell: (cellInfo: any) => {
                return this.getAcknowledgeButton(cellInfo)
            },
            Filter: <Button color={'black'} size={'mini'} style={{visibility:this.getFiltered}} onClick={this.clearFilterOnClick}>Clear Filters</Button>,
            sortable: false,
            minWidth: 200
        }]

    };

    acknowledgeModal = () => {
        return (
            <Modal
                closeIcon={true} centered={true} size={'tiny'} open={this.state.openAcknowledgeModal} onClose={this.closeModal}>
                <Header content={'Override Validation'}/>
                <Modal.Content>
                    <p>Are you sure you want to override this validation message?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.acknowledgeValidations}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                    <Button color='red' onClick={this.closeModal}>
                        <Icon name='remove'/> No
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    acknowledgeAllModal = () => {
        return (
            <Modal
                closeIcon={true} centered={true} size={'small'} open={this.state.openAcknowledgeAllModal} onClose={this.closeModal}>
                <Header content={'Override Validation'}/>
                <Modal.Content>
                    <p>Are you sure you want to override all validation messages?</p>
                    <div>
                        <label>(Add a comment that will apply to every row):</label><br/>
                        <TextArea onChange={this.handleModalCommentChange}/>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.acknowledgeAllValidations}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                    <Button color='red' onClick={this.closeModal}>
                        <Icon name='remove'/> No
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    getData = () => {
        if (this.props.currentChangeset.number > 0) {
            axios.get(`/api/auth/validationMessages`, {params:
                    {changesetId: this.props.currentChangeset.number}
            }).then(resp => {
                this.setState({data:resp.data.data, metaData:resp.data.columnMetaDataList, status: resp.status, loading: false, edstGridInputFilterKey: Date.now()});
            }).catch(resp => {
                toast.error(`Error retrieving validation data - ${resp.message} `);
                this.setState({status: resp.status, loading: false})
            });

            this.setState({loading: true, currentChangesetId: this.props.currentChangeset.number})
        }
    };

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[], edstGridInputFilterKey: Date.now() });
    };

    getFiltered = () => {
        if (this.state.filtered) {
            return 'visible'
        }
        return 'hidden'
    };

    handleModalCommentChange = (event: any) => {
        this.setState({modalComment: event.target.value});
    }

    componentDidMount(): void {
        this.getData();
    }

    componentDidUpdate(prevProps: ValidationMessagesProps, prevState: ValidationMessagesState) {
        if (this.props.currentChangeset.number !== this.state.currentChangesetId || this.props.changesetVersion !== prevProps.changesetVersion
            || (this.props.currentChangeset.number !== prevProps.currentChangeset.number && this.props.currentChangeset.number !== 0)
            ) {
            this.getData();
        }
    }

    public render() {
        return (
            <Container fluid={true} style={{paddingLeft:'50px', paddingRight:'50px'}}>
                {this.acknowledgeModal()}
                {this.acknowledgeAllModal()}
                <ReactTableHOC
                    tableStyle={{height:'500px'}}
                    data={this.state.data}
                    columnMetaData={this.state.metaData}
                    columns={this.state.columns}
                    renderCell={this.renderCell}
                    defaultPageSize={10}
                    utilityColumn={this.acknowledgeColumn()}
                    filterable={true}
                    filtered={this.state.filtered}
                    onFilterChanged={this.handleFilterChange}
                    inputFilterKey={this.state.edstGridInputFilterKey}
                    loading={this.state.loading}
                    tableClassName={'validationMessagesGrid'}
                    className='-striped validationMessagesGrid'
                    //onFilteredChange={filtered => this.setState({filtered})}
                    getTheadProps={() => {
                        return {
                            style: {
                                background: "black",
                                color: "white"
                            }
                        };
                    }}
                />
                <Grid style={{paddingTop:'20px'}}>
                    <Grid.Row style={{paddingLeft:'15px'}}>
                        <Button size={'mini'} color={'black'} disabled={!this.isAcknowledgeAllValid()} onClick={this.acknowledgeAllButtonClick}>
                            Acknowledge All
                        </Button>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentChangeset: state.changeSetReducer.currentChangeset,
        roles: state.authReducer.roles,
        currentUser: state.authReducer.currentUser,
        changesetVersion: state.changeSetReducer.changesetVersion
    }
};

export default connect(mapStateToProps)(ValidationMessages)