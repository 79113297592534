import * as React from 'react';
import {
    Button,
    Dimmer,
    DropdownItemProps,
    DropdownProps,
    Form,
    Loader,
    Message,
    Modal,
    ModalProps
} from "semantic-ui-react";
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";
import AdminService from "../../../services/admin-services";
import {parseInt} from "lodash";

interface RolloverModalProps extends ModalProps{
    theData: any,
    operationType: string,
}

interface RolloverModalState {
    planYearNm: string,
    seasonLbl: string,
    invalidVisible: boolean,
    planYearOptions: any[],
    seasons: any[],
    seasonTypeOptions: any[],
    seasonTypNm: string,
    monthOptions: DropdownItemProps[],
    prxyOptions: any[],
    prxyId: number | string,
    proxies: any[],
    loading: boolean,
    tableOptions: DropdownItemProps[],
    tabNm: string,
}
const adminClient = new AdminService();
const filter = require('lodash/filter');
const map = require('lodash/map');
const maxBy = require('lodash/maxBy');
const uniq = require('lodash/uniq');
class RolloverModal extends React.Component<RolloverModalProps,RolloverModalState> {
    constructor(props: RolloverModalProps) {
        super(props);
        this.state = {
            planYearNm: '',
            seasonLbl: '',
            invalidVisible: false,
            planYearOptions: [],
            seasons: [],
            seasonTypeOptions: [],
            seasonTypNm: '',
            monthOptions: this.setMonthOptions(),
            prxyOptions: [],
            prxyId: '',
            proxies: [],
            loading: false,
            tableOptions: [],
            tabNm: ''
        };
    }

    componentDidMount() {
        this.getPlanYears();
        this.getSeasons();
    }

    componentDidUpdate(prevProps: Readonly<RolloverModalProps>, prevState: Readonly<RolloverModalState>, snapshot?: any) {
        if (this.props.currentRow && this.props.rolloverModalActive) {
            if (typeof prevProps.currentRow === 'undefined' || (this.props.currentRow.id !== prevProps.currentRow.id)) {
                if (this.props.currentRow && this.props.currentRow.subYearNm && this.props.currentRow.tabNm) {
                    this.getPrxyYearData(this.props.currentRow.subYearNm, this.props.currentRow.tabNm);
                }
                this.setState({
                    planYearNm: this.props.currentRow.planYearNm,
                    seasonTypNm: this.props.currentRow.seasonTypNm,
                    seasonLbl: this.props.currentRow.seasonLbl,
                    tabNm: this.props.currentRow.tabNm,
                });
                if (this.props.currentRow.prxyId) {
                    this.setState({prxyId: this.props.currentRow.prxyId});
                }
            } else if (this.props.currentRow.subYearNm !== prevProps.currentRow.subYearNm 
                || this.props.currentRow.tabNm !== prevProps.currentRow.tabNm) {
                this.getPrxyYearData(this.props.currentRow.subYearNm, this.props.currentRow.tabNm);
            } 
            
            if (this.props.operationType === 'add' 
                && this.props.currentRow.category === 'Resource Adequacy' 
                && (!prevProps.currentRow || prevProps.currentRow.category !== 'Resource Adequacy')) {
                this.getTableOptions();
            }
        }
    }
    
    createSeasonLabel = (planYearNm: string, seasonTypNm: string, tabNm: string) => {
        switch (tabNm) {
            case 'Transactions':
                return this.createTransactionsLabel(planYearNm, seasonTypNm);
            case 'ResourceOwnership':
                return this.createResourceOwnershipLabel(planYearNm, seasonTypNm);
            case 'MnthlyNetEnergyForLoad':
            case 'MnthlyPeakDemand':
                return '';
            default:
                return planYearNm;
        }
    };
    
    createTransactionsLabel = (planYearNm: string, seasonTypNm: string) => {
        const year = planYearNm.slice(-2);
        const season = filter(this.state.seasons, {nm: seasonTypNm});
        return year + season[0].shrtNm;
    };
    
    createResourceOwnershipLabel = (planYearNm: string, seasonTypNm: string) => {
        if (seasonTypNm === 'Summer') {
            return planYearNm;
        }
        const nextYear = (parseInt(planYearNm.slice(-2)) + 1).toString();
        return planYearNm + '-' + nextYear;
    }

    getTableOptions = () => {
        const tableList = uniq(map(this.props.theData, 'tabNm')).sort();
        const tableOptions: DropdownItemProps[] = tableList.map((table: string) => ({key: table, text: table, value: table}));
        this.setState({tableOptions});
    };
    
    selectTable = (event: React.SyntheticEvent, data: DropdownProps) => {
        const row = {...this.props.currentRow};
        row.tabNm = data.value as string;
        const tableRows = filter(this.props.theData, {'tabNm': row.tabNm});
        row.id = maxBy(tableRows, 'id').id + 1;
        this.props.updateCurrentRow(row);
        this.setState({tabNm: row.tabNm});
    };
    
    isResAdq = () => {
        return (this.props.operationType === 'add' && this.props.currentRow 
            && this.props.currentRow.category 
            && this.props.currentRow.category === 'Resource Adequacy');
    };

    getPlanYears = () => {
        adminClient.getPlanYearDropdownValues()
            .then((response: any) => {
                let dropDownItems: any = response.data.map((p: any) => ({key: p.id, text: p.nm, value: p.nm}));
                this.setState({planYearOptions: dropDownItems})
            });
    };
    
    selectPlanYear = (event: React.SyntheticEvent, data: DropdownProps) => {
        const row = {...this.props.currentRow};
        row.planYearNm = data.value as string;
        if (row.seasonTypNm) {
            row.seasonLbl = this.createSeasonLabel(row.planYearNm, row.seasonTypNm, row.tabNm);
            const invalidVisible = this.isUniqueViolation(row.id, row.planYearNm, row.seasonTypNm, row.seasonLbl, row.tabNm);
            this.setState({invalidVisible});
        }
        this.props.updateCurrentRow(row);
        this.setState({planYearNm: row.planYearNm, seasonLbl: row.seasonLbl});
    };

    getSeasons = () => {
        adminClient.getSeasonTypeDropdownValues()
            .then((response: any) => {
                let seasons: any[] = [];
                let dropDownItems: any[] = [];
                response.data.forEach((s: any) => {
                    seasons.push(s);
                    dropDownItems.push({key: s.id, text: s.nm, value: s.nm});
                });
                this.setState({
                    seasons,
                    seasonTypeOptions: dropDownItems,
                })
            });
    };

    selectSeason = (event: React.SyntheticEvent, data: DropdownProps) => {
        const row = {...this.props.currentRow};
        row.seasonTypNm = data.value as string;
        if (row.planYearNm) {
            row.seasonLbl = this.createSeasonLabel(row.planYearNm, row.seasonTypNm, row.tabNm);
            const invalidVisible = this.isUniqueViolation(row.id, row.planYearNm, row.seasonTypNm, row.seasonLbl, row.tabNm);
            this.setState({invalidVisible});
        }
        this.props.updateCurrentRow(row);
        this.setState({seasonTypNm: row.seasonTypNm, seasonLbl: row.seasonLbl});
    };
    
    setMonthOptions = () => {
        const options: DropdownItemProps[] = [];
        options.push({key: 'JAN', text: 'JAN', value: 'JAN'});
        options.push({key: 'FEB', text: 'FEB', value: 'FEB'});
        options.push({key: 'MAR', text: 'MAR', value: 'MAR'});
        options.push({key: 'APR', text: 'APR', value: 'APR'});
        options.push({key: 'MAY', text: 'MAY', value: 'MAY'});
        options.push({key: 'JUN', text: 'JUN', value: 'JUN'});
        options.push({key: 'JUL', text: 'JUL', value: 'JUL'});
        options.push({key: 'AUG', text: 'AUG', value: 'AUG'});
        options.push({key: 'SEP', text: 'SEP', value: 'SEP'});
        options.push({key: 'OCT', text: 'OCT', value: 'OCT'});
        options.push({key: 'NOV', text: 'NOV', value: 'NOV'});
        options.push({key: 'DEC', text: 'DEC', value: 'DEC'});
        return options;
    };
    
    selectMonth = (event: React.SyntheticEvent, data: DropdownProps) => {
        const row = {...this.props.currentRow};
        row.seasonLbl = data.value as string;
        this.props.updateCurrentRow(row);
        const invalidVisible = this.isUniqueViolation(row.id, row.planYearNm, row.seasonTypNm, row.seasonLbl, row.tabNm);
        this.setState({invalidVisible, seasonLbl: row.seasonLbl});
    };
    
    isMonth = () => {
        return (
            this.props.currentRow 
            && this.props.currentRow.tabNm 
            && (this.props.currentRow.tabNm === 'MnthlyNetEnergyForLoad' || this.props.currentRow.tabNm === 'MnthlyPeakDemand')
        );
    };

    /** 
     * Collect all the data for the proxy year so that we can get year/season combos to add as proxy data for
     * this year's records. Calls the admin client which makes an axios call to get the data. 
     **/
    getPrxyYearData = (subYearNm: string, tabNm: string) => {
        const prevSubYearNm = (parseInt(subYearNm) - 1).toString();
        adminClient.getPrxyData(prevSubYearNm, tabNm)
            .then((data: any) => {
                let proxies: any[] = [];
                let prxyOptions: DropdownItemProps[] = [];
                data.forEach((row: any) => {
                    proxies.push(row);
                    let option = row.planYearNm + ', ' + row.seasonTypNm + ', ' + row.seasonLbl;
                    prxyOptions.push({key: row.id, text: option, value: row.id});
                });

                this.setState({proxies, prxyOptions});
            });
    };

    /**
     * Gets the value from Prxy Planning Year, Season dropdown and updates it in the currentRow.
     * @param event
     * @param data
     */
    selectPrxyYearData = (event: React.SyntheticEvent, data: DropdownProps) => {
        if (data.value) {
            const prxyId = data.value as number;
            const proxyRow = filter(this.state.proxies, {id: prxyId})[0];
            const row = {
                ...this.props.currentRow,
                prxyId,
                prxySubYearNm: proxyRow.subYearNm,
                prxyPlanYearNm: proxyRow.planYearNm,
                prxySeasonTypNm: proxyRow.seasonTypNm,
                prxySeasonLbl: proxyRow.seasonLbl
            };
            this.props.updateCurrentRow(row);
            this.setState({prxyId: data.value as number});
        } else {
            const row = {...this.props.currentRow};
            row.prxyId = null;
            row.prxySubYearNm = null;
            row.prxyPlanYearNm = null;
            row.prxySeasonTypNm = null;
            row.prxySeasonLbl = null;
            this.props.updateCurrentRow(row);
            this.setState({prxyId: ''});
        }
    };
    
    resetState = () => {
        this.setState({
            planYearNm: '',
            seasonLbl: '',
            seasonTypNm: '',
            tabNm: '',
            prxyId: '',
            invalidVisible: false,
            loading: false,
        });
    };

    closeModalCleanup = () => {
        this.resetState();
        this.props.updateRolloverModalActive(false);
        this.props.updateCurrentRow({});
    };

    isUniqueViolation = (id: number, planYearNm: string, seasonTypNm: string, seasonLbl: string, tabNm: string): boolean => {
        // Find rows with same unique key
        const uniqueRows = filter(this.props.theData, {planYearNm, seasonTypNm, seasonLbl, tabNm});
        // Make sure result is comparing to different row, not to itself
        return (uniqueRows.length > 1 || (uniqueRows.length === 1 && uniqueRows[0].id !== id));
    };

    handleSubmitClick = () => {
        if (this.props.operationType === 'modify') {
            adminClient.updateSubPlanYearSeasonRel(this.props.currentRow)
                .finally(() => {
                    this.closeModalCleanup();
                });
        } else {
            adminClient.insertSubPlanYearSeasonRel(this.props.currentRow)
                .finally(() => {
                    this.closeModalCleanup();
                });
        }
    };

    handleCancelClick = () => {
        this.closeModalCleanup();
    };

    render() {
        return (
            <Modal size={"large"} open={this.props.rolloverModalActive} loading={true}>
                <Modal.Header>
                    {this.props.operationType === 'modify'? 'Edit': 'Add'} Template Record
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        {this.state.loading?
                            <div>
                                <Dimmer inverted={true} active={true}>
                                    <Loader inverted={true} content='Loading' />
                                </Dimmer>
                            </div>: null }
                        <Form.Group>
                            {this.isResAdq() &&
                                <Form.Dropdown required={true} fluid={true} selection={true}
                                                 options={this.state.tableOptions}
                                                 placeholder='Table Name'
                                                 label='Table Name'
                                                 value={this.state.tabNm}
                                                 onChange={this.selectTable}
                                />
                            }
                            <Form.Dropdown required={true} fluid={true} selection={true}
                                           options={this.state.planYearOptions}
                                           placeholder='Plan Year'
                                           label='Plan Year'
                                           value={this.state.planYearNm}
                                           onChange={this.selectPlanYear}
                            />
                            <Form.Dropdown required={true} fluid={true} selection={true}
                                           options={this.state.seasonTypeOptions}
                                           placeholder='Season Type'
                                           label='Season Type'
                                           value={this.state.seasonTypNm}
                                           onChange={this.selectSeason}
                            />
                            {this.isMonth() 
                                ? <Form.Dropdown required={true} fluid={true} selection={true}
                                                 options={this.state.monthOptions}
                                                 placeholder='Month'
                                                 label='Season Label'
                                                 value={this.state.seasonLbl}
                                                 onChange={this.selectMonth} />
                                : <Form.Input readOnly={true} label={'Season Label'} value={this.state.seasonLbl}/>
                            }
                            <Form.Dropdown required={true} fluid={true} selection={true}
                                           options={this.state.prxyOptions}
                                           placeholder='Proxy Plan Year, Season, Season Label'
                                           label='Proxy Plan Year, Season, Season Label'
                                           value={this.state.prxyId}
                                           clearable={true}
                                           onChange={this.selectPrxyYearData}
                            />
                        </Form.Group>
                        <Message
                            visible={this.state.invalidVisible}
                            error={true}
                            header='Invalid Input'
                            content='Plan Year/Season combo already exists. Select a unique Plan Year/Season combo.'
                        />
                    </Form>
                </Modal.Content>

                <Modal.Actions>

                    <Button content={'Submit'} color={'blue'} onClick={this.handleSubmitClick} 
                            disabled={!(this.state.planYearNm && this.state.seasonTypNm && this.state.seasonLbl) || this.state.invalidVisible}
                    />
                    <Button content={'No'} color={'red'} onClick={this.handleCancelClick}/>

                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentRow: state.defaultReducer.currentRow,
        rolloverModalActive: state.rolloverReducer.rolloverModalActive
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentRow: (currentRow: any) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}
        ),
        updateRolloverModalActive: (rolloverModalActive: boolean) => dispatch(
            {type: actionTypes.SET_ROLLOVERMODALACTIVE, payload: rolloverModalActive}
        ),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RolloverModal);