import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from '../../interfaces/grid-interfaces';
import {
    TestResultsWinterData,
    TestResultsWinterEditData
} from '../../interfaces/test-results-interface';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import AddPanel from '../common/action-panel/AddPanel';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import RemovePanel from "../common/action-panel/RemovePanel";
import RemoveModal from "../common/action-panel/RemoveModal";
import {getRemoveCell} from '../common/grid/RemoveCell';
import {InputCell} from "../common/grid/InputCell";
import ExportPanel from "../common/action-panel/ExportPanel";
import ImportPanel from "../common/action-panel/ImportPanel";
import {toast} from "react-toastify";
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState,
    isSameDateTime, numberBoundValidationOnEdit, numberBoundValidationOnUpload,
    processColumnMeta,
    processDropDownListOptions, removeDataError,
    resetChildDependentOption,
    resetChildOption,
    resetOption,
    toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified, replaceSpaceWithUnderscore, toastPreSaveValidationErrors
} from '../../services/data-grid-service';
import {DropDownOption, getDropDownCell} from '../common/grid/DropDownCell';
import {CustomLocalizationProvider, getVerifiedCell} from '../common/grid/VerifiedCell';
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {DATE_TIME_FORMAT, DateCell} from "../common/grid/DateCell";
import TooltipContainer from '../common/grid/TooltipContainer';
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import {ChildDropDownOption, getChildDropDownCell} from "../common/grid/ChildDropDownCell";
import {getDecimalCell} from "../common/grid/DecimalCell";
import {
    filterEmptyRequiredColumns,
    handleTestResultChanges, plantAndResourceNmDuplicate,
    processResourceOptions
} from '../../services/test-results-grid-service';
import ValidatePanel from "../common/action-panel/ValidatePanel";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import format from 'date-fns/format';
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';

const TestResultsWinterGrid: React.FC<PageRole> = ({isStaff, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<TestResultsWinterEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<TestResultsWinterData>>([]);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [subYear, setSubYear] = useState<number>(0);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const globalPageSize = localStorage.getItem(`globalPageSize`)
    const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});
    const [entityOptions, setEntityOptions] = useState<Array<DropDownOption>>([]);
    const [plantOptions, setPlantOptions] = useState<Array<DropDownOption>>([]);
    const [resourceOptions, setResourceOptions] = useState<Array<ChildDropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);

    useEffect(() => {
        axios.get('/api/auth/ra/test-results/winter/columns')
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/ra/subyear/winter')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);

    useEffect(() => {
        axios.get('/api/auth/ra/dropdown/entities')
            .then((resp) => {
                setEntityOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/ra/resources/dropdown/plant-resource')
            .then((resp) => {
                const plantData = processDropDownListOptions(resp.data.plantData);
                const resourceData = processResourceOptions(resp.data.resourceData);
                setPlantOptions(plantData);
                setResourceOptions(resourceData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get('/api/auth/ra/test-results/winter'), axios.get('/api/auth/ra/season-window/winter')])
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp[0].data));
                setOriginalData(deepCopyGridData(resp[0].data));
                setTempSequence(-10000);
                setTempSequence(-10000);
                if (!isStaff) {
                    if (resp[1].data === false) {
                        toast.info('Resource Adequacy window is closed for the Winter season');
                    }
                    setSeasonOpen(resp[1].data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [isStaff, readRole]);

    useEffect(() => {
        getData();
    }, [getData]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const refreshData = () => {
        getData();
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const handleChange = (id: number, field: string, value: any) => {
        if ((value || value === 0) && field === 'winterCapTestDur') {
            numberBoundValidationOnEdit(value, field, setDataErrors, {id: id}, columnMeta, 999999.999, 1);
        }
        if ((value || value === 0) && (['winterCapTestDryTemp'].includes(field))) {
            numberBoundValidationOnEdit(value, field, setDataErrors, {id: id}, columnMeta, 999999.999, -999999.999);
        }
        if ((value || value === 0) && (['winterGrossCapTest', 'winterNetCapTest', 'ratedCoolDryTemp', 'winterCapRecordNetHead', 'winterCapRateMedNetHead'].includes(field))) {
            numberBoundValidationOnEdit(value, field, setDataErrors, {id: id}, columnMeta, 999999.999, 0);
        }
        const localData = handleTestResultChanges(data, id, field, value, dataErrors, setDataErrors, resourceOptions);

        if (field === 'winterCapTestDur') {
            const isValid = value >= 1.0;

            if (!isValid) {
                const errorMessage = 'Operational Test Duration is required to be at least one hour or greater.';
                toast.error(errorMessage);
            }
        }

        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify && field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
        // ToDo: If resourceNm is changed, call API to ensure Resource is available for this TestResults grid. Model after code in PlantsGrid.handlePlantNameBlur.
        if (['plantNm', 'resourceNm'].includes(field)) {
            const dataItem = localData.find((item) => item.id === id);
            if (dataItem && dataItem.plantNm && dataItem.resourceNm) {
                validateUniqueResource(dataItem);
            }
        }

    };
    const validateUniqueResource = (dataItem: TestResultsWinterEditData) => {
        let localErrors = removeDataError(dataItem.id, 'plantNm,resourceNm', dataErrors);
        const params = new URLSearchParams();
        params.set('id', dataItem.id.toString());
        params.set('plantNm', dataItem.plantNm);
        params.set('resourceNm', dataItem.resourceNm);
        axios.get('/api/auth/ra/resources/validate/plant-and-resource', { params })
            .then((resp) => {
                const isUnique = !resp.data; // Check if response data is falsy, indicating uniqueness
                if (isUnique) {
                    toast.warn('Plant Name and Resource ID combination must be unique when considered together. See error grid at the bottom.');
                    localErrors = addDataError(dataItem.id, 'plantNm,resourceNm', 'Plant Name, Resource ID',
                        `${dataItem.plantNm}, ${dataItem.resourceNm}`, plantAndResourceNmDuplicate, true, localErrors);
                }
                // Set dataErrors here to save removal of previous validation with the result of API check.
                // Replace dataError entry only if still invalid.
                // Failure in API call would leave dataErrors the same as if this function were never called.
                setDataErrors(localErrors);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get('/api/auth/ra/test-results/winter/validation')
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    };

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: TestResultsWinterEditData = {
            verified: false,
            id: sequence++,
            respEntNm: '',
            plantNm: '',
            resourceNm: '',
            energySource: '',
            winterGrossCapTest: null,
            winterNetCapTest: null,
            winterCapTestDt: null,
            winterCapTestDur: null,
            winterCapTestDryTemp: null,
            winterCapRecordNetHead: null,
            winterCapRateMedNetHead: null,
            subYearId: subYear,
            comments: '',
            lastUserModBy: '',
            lastUserModDt: null,
            verifiedBy: '',
            verifiedDt: null,
            inEdit: true
        };

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    };

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        resetRemove();
    };

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete('/api/auth/ra/test-results/winter/delete', {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                setData(localData);
                resetRemove();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const save = () => {
        if (filterEmptyRequiredColumns(data, dataErrors, setDataErrors)) {
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));

        axios.post('/api/auth/ra/test-results/winter/save', newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post('/api/auth/ra/test-results/winter/verify', updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const handleUpload = (uploadedData: Array<TestResultsWinterEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            const upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                return {
                    ...u,
                    verified: false,
                    id: sequence++,
                    respEntNm: resetOption(u.respEntNm, entityOptions, ''),
                    plantNm: resetOption(u.plantNm, plantOptions, ''),
                    resourceNm: resetChildOption(u.plantNm, u.resourceNm, resourceOptions, ''),
                    subYearId: subYear,
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true
                };
            });

        const dataUploaded: Array<TestResultsWinterEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u);
        });

        setData(dataUploaded);
        setTempSequence(sequence);

    };

    const processUploadEdits = (upload: TestResultsWinterEditData, item: TestResultsWinterEditData) => {
        let respEntNm = resetOption(upload.respEntNm, entityOptions, item.respEntNm);
        if (respEntNm !== item.respEntNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'respEntNm', respEntNm));
        }
        let plantNm = resetOption(upload.plantNm, plantOptions, item.plantNm)
        if (plantNm !== item.plantNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plantNm', plantNm));
        }
        let resourceNm = resetChildOption(plantNm, upload.resourceNm, resourceOptions, item.resourceNm);
        if (resourceNm !== item.resourceNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceNm', resourceNm));
        }
        let energySource = resetChildDependentOption(plantNm, upload.resourceNm, resourceOptions, item.energySource);
        if (upload.winterGrossCapTest !== item.winterGrossCapTest) {
            let field = 'winterGrossCapTest';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.winterNetCapTest !== item.winterNetCapTest) {
            let field = 'winterNetCapTest';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (!isSameDateTime(upload.winterCapTestDt, item.winterCapTestDt)) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'winterCapTestDt', upload.winterCapTestDt));
        }
        if (upload.winterCapTestDur !== item.winterCapTestDur) {
            let field = 'winterCapTestDur';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 1);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.winterCapTestDryTemp !== item.winterCapTestDryTemp) {
            let field = 'winterCapTestDryTemp';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, -999999.999);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.winterCapRecordNetHead !== item.winterCapRecordNetHead) {
            let field = 'winterCapRecordNetHead';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.winterCapRateMedNetHead !== item.winterCapRateMedNetHead) {
            let field = 'winterCapRateMedNetHead';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.comments !== item.comments) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'comments', upload.comments));
        }

        return {
            ...item,
            respEntNm: respEntNm,
            plantNm: plantNm,
            resourceNm: resourceNm,
            energySource: energySource,
            winterGrossCapTest: upload.winterGrossCapTest,
            winterNetCapTest: upload.winterNetCapTest,
            winterCapTestDt: upload.winterCapTestDt,
            winterCapTestDur: upload.winterCapTestDur,
            winterCapTestDryTemp: upload.winterCapTestDryTemp,
            winterCapRecordNetHead: upload.winterCapRecordNetHead,
            winterCapRateMedNetHead: upload.winterCapRateMedNetHead,
            comments: upload.comments
        };
    };

    const RespEntDropDownCell = useMemo(() => getDropDownCell(entityOptions, 'Entity'), [entityOptions]);
    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const PlantCell = getDropDownCell(plantOptions, 'Plant Name');
    const ResourceCell = getChildDropDownCell(resourceOptions, 'resourceNm', 'plantNm');
    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const DecimalCell = useMemo(() => getDecimalCell(3, true, 999999.999, 0), []);
    const DecimalCellMinOne = useMemo(() => getDecimalCell(3, true, 999999.999, 1), []);
    const DecimalCellNegative = useMemo(() => getDecimalCell(3, true, 999999.999, -999999.999), []);

    const formatExport = (data: Array<TestResultsWinterData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO'

        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };
    const title = 'Winter Capability Test Results';

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api='/api/auth/ra/test-results/winter/upload' setUploadRecords={handleUpload}/>}
                {isStaff && modifyRole && seasonOpen && !inEdit && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && modifyRole && seasonOpen && !inVerify && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        sortable={true}
                        filterable={true}
                        resizable={true}
                        style={{ height: "510px" }}
                        {...dataState}
                        editField='inEdit'
                        onDataStateChange={dataStateChange}
                        onItemChange={itemChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                    > {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                        {
                            columnMeta.length
                                ? columnMeta.map((col: ColumnMeta, index) => {
                                    switch (col.nm) {
                                        case 'verified':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='103px' cell={VerifiedCell} filter={"boolean"}/>
                                        case 'id':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' editable={false}/>
                                        case 'respEntNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='155px' cell={RespEntDropDownCell}/>
                                        case 'plantNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='419px' cell={PlantCell}/>
                                        case 'resourceNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={ResourceCell}/>
                                        case 'energySource':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='220px' editable={false}/>
                                        case 'winterGrossCapTest':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='231px' cell={DecimalCell}/>
                                        case 'winterNetCapTest':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='216px' cell={DecimalCell}/>
                                        case 'winterCapTestDt':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='195px' cell={DateCell} format={DATE_TIME_FORMAT}/>
                                        case 'winterCapTestDur':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='246px' cell={DecimalCellMinOne}/>
                                        case 'winterCapTestDryTemp':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='293px' cell={DecimalCellNegative}/>
                                        case 'winterCapRecordNetHead':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='259px' cell={DecimalCell}/>
                                        case 'winterCapRateMedNetHead':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='285px' cell={DecimalCell}/>
                                        case 'comments':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={InputCell}/>
                                        case 'subYearId':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        case 'lastUserModBy':
                                        case 'verifiedBy':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' editable={false}/>
                                        case 'lastUserModDt':
                                        case 'verifiedDt':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        default:
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>;
                                    }
                                })
                                : <GridColumn/>
                        }
                    </Grid>
                </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={title}/>}
        </div>
    );
};

export default TestResultsWinterGrid;
