import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {isDifferent} from '../../../services/data-grid-service';

export interface ChildDropDownOption {
    text: string,
    value: string | number,
    parentValue: string | number,
    childValue?: string | number
}

interface ChildDropDownCellProps extends GridCellProps {
    options: Array<ChildDropDownOption>,
    placeholder: string,
    parentField: string
}

export const ChildDropDownCell = (props: ChildDropDownCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const dataValue = field && dataItem[field] ? dataItem[field] : '';
    const parentDataValue = dataItem[props.parentField] ? dataItem[props.parentField] : '';
    const placeholder = props.placeholder ? `Select ${props.placeholder} ...` : 'Select ...';
    const defaultItem = {text: placeholder, value: ''};
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value.value
            });
        }
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit
                ? isChanged
                    ? <DropDownList
                        onChange={onChange}
                        data={props.options.filter((item) => item.parentValue === parentDataValue)}
                        value={props.options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem={defaultItem}
                        disabled={!parentDataValue}
                        title={originalValue}
                    />
                    : <DropDownList
                        onChange={onChange}
                        data={props.options.filter((item) => item.parentValue === parentDataValue)}
                        value={props.options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem={defaultItem}
                        disabled={!parentDataValue}
                    />
                : dataValue.toString()
            }
        </td>
    );
};

export const getChildDropDownCell = (options: Array<ChildDropDownOption>, placeholder: string, parentField: string) => {
    return (props: GridCellProps) => (
        <ChildDropDownCell {...props} options={options} placeholder={placeholder} parentField={parentField}/>
    );
};