import React, {useEffect, useMemo, useState} from 'react';
import {Grid, GridColumn, GridItemChangeEvent} from '@progress/kendo-react-grid';
import axios from 'axios';
import ActionPanel from "../../common/action-panel/ActionPanel";
import SavePanel from "../../common/action-panel/SavePanel";
import {ColumnMeta} from '../../../interfaces/grid-interfaces';
import {PrmData} from '../../../interfaces/prm-interface';
import {getPercentNoBackgroundCell} from "../../common/grid/PercentNoBackgroundCell";

interface PrmGridProps {
    title: string;
    dataApi: string,
    columnApi: string
}

const PrmGrid: React.FC<PrmGridProps> = ({title, dataApi, columnApi}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [inEdit, setInEdit] = useState(false);
    const [data, setData] = useState<Array<PrmData>>([]);
    const [originalData, setOriginalData] = useState<Array<PrmData>>([]);
    const [editedRows, setEditedRows] = useState<Array<PrmData>>([]);
    const PercentCell = useMemo(() => getPercentNoBackgroundCell(2,true),[]);

    useEffect(() => {
        axios.get(columnApi)
            .then((resp) => {
                const columns: ColumnMeta[] = resp.data
                    .filter((c: any) => c.visInd === '1')
                    .sort((a: any, b: any) => a.sortId - b.sortId);
                setColumnMeta(columns);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [columnApi]);

    useEffect(() => {
        axios.get(dataApi)
            .then((resp) => {
                const apiData = resp.data.map((d: any) => ({...d, inEdit: false}));
                const origData = resp.data.map((d: any) => ({...d, inEdit: false}));
                setData(apiData);
                setOriginalData(origData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    const itemChange = (event: GridItemChangeEvent) => {
        const localData = [...data];
        const item = localData.find((d) => d.id === event.dataItem.id);
        if (item) {
            item[event.field || ''] = event.value;
            setData(localData);
            setEditedRows([...editedRows, item]);
        }
    };

    const toggleEdit = () => {
        if (inEdit) {
            setData(originalData.map((item: any) => ({...item, inEdit: false})));
            setInEdit(false);
        } else {
            setOriginalData(data.map((item: any) => ({...item, inEdit: false})));
            setData(data.map(item => ({...item, inEdit: true})));
            setInEdit(true);
        }
    }

    const save = () => {
        axios.post('/api/auth/data-manage/prm/save', data)
            .then(() => {
                setOriginalData(data.map((item: any) => ({...item, inEdit: false})));
                setData(data.map(item => ({...item, inEdit: false})));
                setInEdit(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div>
            <ActionPanel title={title}>
                <SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>
            </ActionPanel>
            <Grid data={data} onItemChange={itemChange} editField="inEdit">
                {
                    columnMeta.length
                        ? columnMeta.map((col: ColumnMeta, index) => {
                            if (col.nm === 'percentVal') {
                                return (<GridColumn key={index} field={col.nm} title={col.vnm} cell={PercentCell} editor='numeric'/>);
                            }
                            return (
                                <GridColumn key={index} field={col.nm} title={col.vnm} editable={col.editInd !== '0'}/>
                            );
                        })
                        : <GridColumn/>
                }
            </Grid>
        </div>
    );
}


export default PrmGrid;

