import {Icon} from "semantic-ui-react";

interface NotificationIconProps {
    notificationCount: number,
    menuIcon: any,
}

const NotificationIcon = (props: NotificationIconProps) => {

    if (props.notificationCount) {
        return (
            <>
                <Icon className={props.menuIcon} style={{color: 'red'}}/>
                <label style={{color: 'red'}}> {props.notificationCount} </label>
            </>
        )
    } else {
        return (
            <Icon className={props.menuIcon} style={{color: 'white'}}/>
        )
    }
}

export default NotificationIcon;