import React, {useEffect, useState} from 'react';
import {GridCellProps} from '@progress/kendo-react-grid';
import {Input} from '@progress/kendo-react-inputs';
import {isDifferent} from '../../../services/data-grid-service';

interface OnBlurCellProps extends GridCellProps{
    handleBlur: (id: number, field: string, value: string) => void
}

// Use this grid cell component when you need to validate a value after the user is finished typing (and not at every keystroke).
export const OnBlurCell = (props: OnBlurCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value = field && dataItem[field] ? dataItem[field] : '';
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    const [inputValue, setInputValue] = useState<string>(value);
    
    useEffect(() => {
        setInputValue(value);
    }, [value]);
    
    const onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
        if (field) {
            props.handleBlur(props.dataItem.id, field, inputValue);
        }
    };
    
    const handleChange = (e: any) => {
        setInputValue(e.target.value);
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit 
                ? isChanged
                    ? <Input value={inputValue} onChange={handleChange} onBlur={onBlur} title={originalValue}/>
                    : <Input value={inputValue} onChange={handleChange} onBlur={onBlur}/>
                : value
            }
        </td>
    );
};

export const getOnBlurCell = (handleBlur: (id: number, field: string, value: string) => void) => {
    return ((props: GridCellProps) =>
        <OnBlurCell {...props} handleBlur={handleBlur}/>
    );
};