import * as actionTypes from '../actions/actionTypes';
import initialState from "./initialState";

export default function authReducer(state = initialState.authState, action:any){
    switch(action.type) {
        case actionTypes.CHANGE_TOKEN:
            return {
                ...state,
                userToken: action.payload
            };
        case actionTypes.UPDATE_USER_PRINCIPAL:
            localStorage.setItem("roles",JSON.stringify(action.payload));
            return {
                ...state,
                roles: action.payload
            };
        case actionTypes.UPDATE_CURRENT_USER:
            localStorage.setItem("userName",action.payload);
            return {
                ...state,
                currentUser: action.payload
            };
        case actionTypes.UPDATE_USER_ROLES_ENTITIES:
            localStorage.setItem("userRolesEntities",JSON.stringify(action.payload));
            return {
                ...state,
                userRolesEntities: action.payload
            };
        case actionTypes.UPDATE_USER_ENTITIES:
            localStorage.setItem("userEntities",JSON.stringify(action.payload));
            return {
                ...state,
                userEntities: action.payload
            };
    }
    return state;
};


