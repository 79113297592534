import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent
} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from '../../interfaces/grid-interfaces';
import {ResourcesData, ResourcesEditData} from '../../interfaces/resources-interface';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    getDistinctListFromField,
    initialDataState, numberBoundValidationOnEdit, numberBoundValidationOnUpload,
    processColumnMeta,
    processDropDownListOptions,
    removeDataError,
    removeDataErrorByValue,
    resetChildDependentOption,
    resetChildOption,
    resetOption,
    toastPreSaveValidationErrors,
    updateEditedRows,
    updateEditedVerified
} from '../../services/data-grid-service';
import {DropDownOption, getDropDownCell, getYesNoDropDownCell} from '../common/grid/DropDownCell';
import {CustomLocalizationProvider, getVerifiedCell} from '../common/grid/VerifiedCell';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import TooltipContainer from '../common/grid/TooltipContainer';
import {getOnBlurCell} from '../common/grid/OnBlurCell';
import {getDecimalCell} from '../common/grid/DecimalCell';
import {DateCell, DATE_ONLY_FORMAT} from '../common/grid/DateCell';
import ExportPanel from '../common/action-panel/ExportPanel';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import AddPanel from "../common/action-panel/AddPanel";
import {toast} from "react-toastify";
import ImportPanel from "../common/action-panel/ImportPanel";
import RemovePanel from "../common/action-panel/RemovePanel";
import RemoveModal from "../common/action-panel/RemoveModal";
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import {ChildDropDownOption, getChildDropDownCell} from '../common/grid/ChildDropDownCell';
import ValidatePanel from "../common/action-panel/ValidatePanel";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import format from "date-fns/format";
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';

const plantNmEmpty = 'Plant Name must have a value to save the record.';
const respEntNmEmpty = 'Last Modifying Entity must have a value to save the record.';
const resourceNmEmpty = 'Resource ID must have value to save record.';
const energySourceEmpty = 'Energy Source must have value to save Prime Mover.';
const plantAndResourceNmDuplicate = 'Plant Name and Resource ID match an existing record, please fix the issue or edit an existing record.';

const ResourcesGrid: React.FC<PageRole> = ({isStaff, readRole, addRole, modifyRole, removeRole}) =>
    {
        const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
        const [data, setData] = useState<Array<ResourcesData>>([]);
        const [originalData, setOriginalData] = useState<Array<ResourcesData>>([]);
        const [tempSequence, setTempSequence] = useState<number>(-10000);
        const [subYear, setSubYear] = useState<number>(0);
        const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
        const [inEdit, setInEdit] = useState<boolean>(false);
        const [inVerify, setInVerify] = useState<boolean>(false);
        const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
        const globalPageSize = localStorage.getItem(`globalPageSize`)
        const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});
        const [entityOptions, setEntityOptions] = useState<Array<DropDownOption>>([]);
        const [plantOptions, setPlantOptions] = useState<Array<DropDownOption>>([]);
        const [marketOptions, setMarketOptions] = useState<Array<DropDownOption>>([]);
        const [statusOptions, setStatusOptions] = useState<Array<DropDownOption>>([]);
        const [genTypeOptions, setGenTypeOptions] = useState<Array<ChildDropDownOption>>([]);
        const [primeOptions, setPrimeOptions] = useState<Array<DropDownOption>>([]);
        const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
        const [inRemove, setInRemove] = useState<boolean>(false);
        const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
        const [removeRecords, setRemoveRecords] = useState<Array<ResourcesData>>();
        const [selectedRows, setSelectedRows] = useState<Array<ResourcesData>>([]);
        const [removalConstraintErrors, setRemovalConstraintErrors] = useState<Array<{resourceId: number, errors: String}>>([]);
        const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
        const [loadingData, setLoadingData] = useState<boolean>(false);
        const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
        const [seasonOpen, setSeasonOpen] = useState<boolean>(true);

        useEffect(() => {
            axios.get('/api/auth/ra/resources/columns')
                .then((resp) => {
                    setColumnMeta(processColumnMeta(resp.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }, []);

        useEffect(() => {
            // Resources uses the current season subyear
            axios.get('/api/auth/ra/subyear/current')
                .then((resp) => {
                    setSubYear(resp.data);
                })
        }, []);

        useEffect(() => {
            axios.get('/api/auth/ra/dropdown/entities')
                .then((resp) => {
                    setEntityOptions(processDropDownListOptions(resp.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }, []);
        
        useEffect(() => {
            axios.get('/api/auth/ra/plants/dropdown')
                .then((resp) => {
                    setPlantOptions(processDropDownListOptions(resp.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }, []);

        useEffect(() => {
            axios.get('/api/auth/ra/resources/dropdown/market-registration')
                .then((resp) => {
                    setMarketOptions(processDropDownListOptions(resp.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }, []);

        useEffect(() => {
            axios.get('/api/auth/ra/resources/dropdown/status')
                .then((resp) => {
                    setStatusOptions(processDropDownListOptions(resp.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }, []);
        
        useEffect(() => {
            axios.get('/api/auth/ra/resources/dropdown/gen-type')
                .then((resp) => {
                    setPrimeOptions(processDropDownListOptions(getDistinctListFromField(resp.data, 'primeMover')));
                    const genTypes = resp.data.map((item: any) => ({
                        text: item.energySource,
                        value: item.energySource,
                        parentValue: item.primeMover,
                        childValue: item.fuelType
                    }));
                    setGenTypeOptions(genTypes);
                })
                .catch((error) => {
                    console.log(error);
                });
        }, []);

        const getData = useCallback(() => {
            if (!readRole) {
                return;
            }
            setLoadingData(true);
            Promise.all([axios.get('/api/auth/ra/resources'), axios.get('/api/auth/ra/season-window/Summer'), axios.get('/api/auth/ra/season-window/Winter')])
                .then((resp) => {
                    setData(deepCopyGridDataWithOriginal(resp[0].data));
                    setOriginalData(deepCopyGridData(resp[0].data));
                    setTempSequence(-10000);
                    setRemoveRecords([]);
                    setSelectedRows([]);
                    setRemovalConstraintErrors([]);
                    setDataErrors([]);
                    if (!isStaff) {
                        const seasonsOpen = resp[1].data && resp[2].data;
                        if (!seasonsOpen) {
                            toast.info('Resource Adequacy window is closed');
                        }
                        setSeasonOpen(seasonsOpen);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoadingData(false);
                });
        }, [isStaff, readRole]);

        useEffect(() => {
            getData();
        }, [getData]);

        const refreshData = () => {
            getData();
        };

        const clearFilters = () => {
            const noFilterDataState = {...dataState};
            delete noFilterDataState.filter;
            setDataState(noFilterDataState);
        };

        const itemChange = (event: GridItemChangeEvent) => {
            const field = event.field || '';
            if (!field) {
                return;
            }
            handleChange(event.dataItem.id, field, event.value);
        };
        
        const handleChange = (id: number, field: string, value: any) => {
            const localData = data.map(item => {
                if (item.id === id) {
                    const dataItem = {...item};
                    dataItem[field] = value;
                    if ((['plantNm', 'resourceNm'].includes(field)) && dataItem.plantNm && dataItem.resourceNm) {
                        validateUniqueResource(dataItem);
                    }
                    if (field === 'primeMover') {
                        dataItem.energySource = '';
                        dataItem.fuelType = '';
                        setDataErrors((prevDataErrors) => addDataError(item.id, 'energySource', 'Energy Source', '',
                            energySourceEmpty, false, prevDataErrors));
                    }
                    if (field === 'energySource') {
                        const option = genTypeOptions.find((item) => item.value === dataItem.energySource && item.parentValue === dataItem.primeMover);
                        dataItem.fuelType = option?.childValue as string;
                    }
                    if (value && (['respEntNm', 'plantNm', 'resourceNm', 'energySource'].includes(field))) {
                        setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                    }
                    if ((value || value === 0) && (['namePlateCapacity'].includes(field))) {
                        numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 999999.999, 0.001);
                    }
                    if ((value || value === 0) && (['planModelMaxCapSummer', 'planModelMaxCapWinter'].includes(field))) {
                        numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 999999.999, 0);
                    }
                    return dataItem;
                } else {
                    return item;
                }
            });
            setData(localData);
            if (inEdit) {
                setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
            }
            if (inVerify) {
                setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
            }
        };
        
        const validateUniqueResource = (dataItem: ResourcesEditData) => {
            let localErrors = removeDataError(dataItem.id, 'plantNm,resourceNm', dataErrors);
            const params = new URLSearchParams();
            params.set('id', dataItem.id.toString());
            params.set('plantNm', dataItem.plantNm);
            params.set('resourceNm', dataItem.resourceNm);
            axios.get('/api/auth/ra/resources/validate/plant-and-resource', {params})
                .then((resp) => {
                    if (resp.data) {
                        toast.warn('Plant Name and Resource ID must be unique when considered together. See error grid at the bottom.');
                        localErrors = addDataError(dataItem.id, 'plantNm,resourceNm', 'Plant Name, Resource ID',
                            `${dataItem.plantNm}, ${dataItem.resourceNm}`, plantAndResourceNmDuplicate, true, localErrors);
                    }
                    // Set dataErrors here to save removal of previous validation with the result of API check.
                    // Replace dataError entry only if still invalid.
                    // Failure in API call would leave dataErrors the same as if this function were never called.
                    setDataErrors(localErrors);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        const handleUpload = (uploadedData: Array<any>) => {
            let uploadedRecord = uploadedData['first'];
            let error = uploadedData['second'];
            let sequence = tempSequence;
            if (error) {
                toast.error('Uploaded record(s) has a record key that doesn\'t match any current records. Please leave record key blank on new entries or verify that it matches an existing record.');
            }
            const localData = data.map((item) => {
                let upload = uploadedRecord.find((u: ResourcesEditData) => {
                    return u.id === item.id;
                });
                if (!!upload) {
                    return processUploadEdits(upload, item);
                }
                return item;
            });
            const newUploadData = uploadedRecord
                .filter((u: ResourcesEditData) => u.id <= 0)
                .map((u: ResourcesEditData) => {
                    // Reset non-editable fields to default or empty value.
                    // Reset option fields to valid option or empty value.
                    // Direct input fields can take update value without modification. API won't set values of wrong format.
                    return {
                        ...u,
                        verified: false,
                        id: sequence++,
                        resourceStatus: resetOption(u.resourceStatus, statusOptions, ''),
                        metered: '',
                        marketRegistration: resetOption(u.marketRegistration, marketOptions, ''),
                        respEntNm: resetOption(u.respEntNm, entityOptions, ''),
                        subYearId: subYear,
                        primeMover: resetOption(u.primeMover, primeOptions, ''),
                        energySource: resetChildOption(u.primeMover, u.energySource, genTypeOptions, ''),
                        fuelType: resetChildDependentOption(u.primeMover, u.energySource, genTypeOptions, ''),
                        lastUserModBy: '',
                        lastUserModDt: null,
                        verifiedBy: '',
                        verifiedDt: null,
                        inEdit: true
                    };
                })
                .filter((item: ResourcesEditData) => data.findIndex((dataItem) => item.plantNm === dataItem.plantNm && dataItem.resourceNm === item.resourceNm) === -1);

            const dataUploaded: Array<ResourcesEditData> = [];
            newUploadData.forEach((u: ResourcesEditData) => {
                dataUploaded.push(u);
            });
            localData.forEach((u) => {
                dataUploaded.push(u);
            });
            setData(dataUploaded);
            setTempSequence(sequence);
        };

        const processUploadEdits = (upload: ResourcesEditData, item: ResourcesEditData) => {
            // make sure no id/plantNm+resourceNm validation conflicts occur

            // need to pass every field change to updateEditedRows
            let respEntNm = resetOption(upload.respEntNm, entityOptions, item.respEntNm);
            if (respEntNm?.toLowerCase() !== item.respEntNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'respEntNm', respEntNm));
            }
            if (upload.plantNm?.toLowerCase() !== item.plantNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plantNm', upload.plantNm));
            }
            if (upload.resourceNm?.toLowerCase() !== item.resourceNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceNm', upload.resourceNm));
            }
            if (upload.resourceNm?.toLowerCase() !== item.resourceNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceNm', upload.resourceNm));
            }
            if (upload.resourceStatus?.toLowerCase() !== item.resourceStatus.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceStatus', upload.resourceStatus));
            }
            if (upload.metered?.toLowerCase() !== item.metered.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'metered', upload.metered));
            }
            if (upload.marketRegistration !== item.marketRegistration) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'marketRegistration', upload.marketRegistration));
            }
            if (upload.initialOperationDt !== item.initialOperationDt) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'initialOperationDt', upload.initialOperationDt));
            }
            if (upload.plannedRetirementDt !== item.plannedRetirementDt) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plannedRetirementDt', upload.plannedRetirementDt));
            }
            if (upload.retirementDt !== item.retirementDt) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'retirementDt', upload.retirementDt));
            }
            if (upload.namePlateCapacity !== item.namePlateCapacity) {
                let field = 'namePlateCapacity';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0.001);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (isStaff) {
                if (upload.planModelMaxCapSummer !== item.planModelMaxCapSummer) {
                    let field = 'planModelMaxCapSummer';
                    numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                    setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
                }
                if (upload.planModelMaxCapWinter !== item.planModelMaxCapWinter) {
                    let field = 'planModelMaxCapWinter';
                    numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                    setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
                }
            } else {
                if (upload.planModelMaxCapSummer !== item.planModelMaxCapSummer) {
                    upload.planModelMaxCapSummer = item.planModelMaxCapSummer;
                }
                if (upload.planModelMaxCapWinter !== item.planModelMaxCapWinter) {
                    upload.planModelMaxCapWinter = item.planModelMaxCapWinter;
                }
            }
            if (upload.primeMover?.toLowerCase() !== item.primeMover.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'primeMover', upload.primeMover));
            }
            if (upload.energySource?.toLowerCase() !== item.energySource.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'energySource', upload.energySource));
            }
            return {
                ...item,
                respEntNm: respEntNm,
                plantNm: upload.plantNm,
                resourceNm: upload.resourceNm,
                resourceStatus: upload.resourceStatus,
                metered: upload.metered,
                marketRegistration: upload.marketRegistration,
                initialOperationDt: upload.initialOperationDt,
                plannedRetirementDt: upload.plannedRetirementDt,
                retirementDt: upload.retirementDt,
                namePlateCapacity: upload.namePlateCapacity,
                planModelMaxCapSummer: isStaff ? upload.planModelMaxCapSummer : item.planModelMaxCapSummer,
                planModelMaxCapWinter: isStaff ? upload.planModelMaxCapWinter : item.planModelMaxCapWinter,
                primeMover: upload.primeMover,
                energySource: upload.energySource,
                comments: upload.comments
            };
        };

        const addNewRow = () => {
            let sequence = tempSequence;
            const defaultNewRow: ResourcesEditData = {
                verified: false,
                id: sequence++,
                respEntNm: '',
                plantNm: '',
                resourceNm: '',
                resourceStatus: '',
                metered: '',
                marketRegistration: '',
                initialOperationDt: null,
                plannedRetirementDt: null,
                retirementDt: null,
                namePlateCapacity: 0,
                planModelMaxCapSummer: 0,
                planModelMaxCapWinter: 0,
                primeMover: '',
                energySource: '',
                fuelType: '',
                comments: '',
                subYearId: subYear,
                lastUserModBy: '',
                lastUserModDt: null,
                verifiedBy: '',
                verifiedDt: null,
                inEdit: true
            };
            const localData = [...data];
            localData.unshift(defaultNewRow);
            setData(localData);
            setTempSequence(sequence);
        }

        const handleRemoveChange = (event: ChangeEvent<HTMLInputElement>, dataItem: ResourcesEditData) => {
            if (event.target.checked) {
                const params = new URLSearchParams();
                params.set('resourceId', dataItem.id.toString());
                axios.get('/api/auth/ra/resources/remove/check-constraints', {params})
                    .then((response) => {
                        if (response.data.length > 0) {
                            setRemovalConstraintErrors((prevRemovalConstraintErrors) => [...prevRemovalConstraintErrors, {resourceId: dataItem.id, errors: response.data}]);
                            const errorDisplayText = 'Remove corresponding record(s) from the following pages/grids first: ' + response.data;
                            setDataErrors((prevDataErrors) => addDataError(dataItem.id, 'removal', 'Remove', '',
                                errorDisplayText, true, prevDataErrors));
                        } else {
                            setRemovalConstraintErrors((prevRemovalConstraintErrors) => prevRemovalConstraintErrors.filter((item) => item.resourceId !== dataItem.id));
                            setSelectedRows([...selectedRows, dataItem]);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setSelectedRows(selectedRows.filter(row => row.id !== dataItem.id));
            }
        };

        const removalSelectionCell = (props: GridCellProps) => {
            const {dataItem} = props;
            const errorForRow = removalConstraintErrors.find(item => item.resourceId === dataItem.id);
            const style = {
                backgroundColor: errorForRow ? 'rgb(255,0,0)' : ''
            }

            return (
                <td style={style}>
                    {!errorForRow && <input
                        type="checkbox"
                        checked={selectedRows.some((row) => row.id === dataItem.id)}
                        onChange={(e) => handleRemoveChange(e, dataItem)}
                    />}
                    {errorForRow && <input
                        type="checkbox"
                        checked={selectedRows.some((row) => row.id === dataItem.id)}
                        onChange={(e) => handleRemoveChange(e, dataItem)}
                        disabled
                    />}
                </td>
            );
        };

        const toggleRemove = () => {
            if (inRemove) {
                setDataErrors((prevDataErrors) => prevDataErrors.filter((error) => error.field !== 'removal'));
            }
            setInRemove(!inRemove);
            setSelectedRows([]);
            setRemovalConstraintErrors([]);
        };

        const openModal = () => {
            toggleRemove();
            setRemoveRecords(selectedRows);
            setRemoveModalOpen(true);
        };

        const closeRemoveModal = () => {
            setInRemove(false);
            setRemoveRecords([]);
            setRemoveModalOpen(false);
        };

        const confirmRemove = () => {
            if (removeRecords?.length) {
                axios.delete('/api/auth/ra/resources/remove', {data: removeRecords.map(x => x.id)})
                    .then(() => {
                        setData(data.filter((row) => !removeRecords.map(x => x.id).includes(row.id)));
                        setEditedRows(editedRows.filter((row) => !(removeRecords.map(x => x.id)).includes(row.id)));
                        toast.success('The record(s) with record key(s): ' + removeRecords.map(x => x.id) + ' have been successfully removed.');
                        setDataErrors(dataErrors.filter((error) => !(removeRecords.map(x => x.id)).includes(error.id)));
                        closeRemoveModal();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                toast.warn('No record selected. Please select a record or rectify issues related to a failed selection.');
            }
        };

        const dataStateChange = (event: GridDataStateChangeEvent) => {
            setDataState(event.dataState);
        };

        const toggleEdit = () => {
            if (inEdit) {
                cancelEdits();
                setDataErrors([]);
            } else {
                enterEditMode();
            }
        }

        const enterEditMode = () => {
            setData(data.map(item => ({...item, inEdit: true})));
            setInEdit(true);
        };


        const cancelEdits = () => {
            getData();
            setInEdit(false);
            setInRemove(false);
            setEditedRows([]);
        };

        const save = () => {
            if (filterEmptyRequiredColumns()) {
                return;
            }
            if (dataErrors.filter((error) => error.blocking).length > 0) {
                toastPreSaveValidationErrors();
                return;
            } else {
                // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
                const newRows = data.filter((item: ResourcesEditData) => item.id < 0);
                // check for duplicate plant name and resource id combos
                if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
                    cancelEdits();
                    return;
                }
                const updatedIdList = editedRows.map((item) => item.id);
                const updatedData = data.filter((item: ResourcesEditData) => updatedIdList.includes(item.id));
                const newAndUpdatedData = updatedData.concat(newRows.filter((item: ResourcesEditData) => !updatedIdList.includes(item.id)));
                axios.post('/api/auth/ra/resources/save', newAndUpdatedData)
                    .then(() => {
                        getData();
                        setInEdit(false);
                        setEditedRows([]);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        };

        const filterEmptyRequiredColumns = (): boolean => {
            const missingRequired = data.filter((item) => !item.respEntNm || !item.plantNm || !item.resourceNm);

            if (missingRequired.length === 0) {
                return false;
            }
            toast.error('There are columns missing required data. Please provide value to save changes.');
            let localErrors = [...dataErrors];
            missingRequired.forEach((item) => {
                if (!item.respEntNm) {
                    localErrors = addDataError(item.id, 'respEntNm', 'Last Modifying Entity', '',
                        respEntNmEmpty, true, localErrors);
                }
                if (!item.plantNm) {
                    localErrors = addDataError(item.id, 'plantNm', 'Plant Name', '',
                        plantNmEmpty, true, localErrors);
                }
                if (!item.resourceNm) {
                    localErrors = addDataError(item.id, 'resourceNm', 'Resource ID', '',
                        resourceNmEmpty, true, localErrors);
                }
                if (item.primeMover && !item.energySource) {
                    localErrors = addDataError(item.id, 'energySource', 'Energy Source', '',
                        energySourceEmpty, false, localErrors);
                }
            });
            setDataErrors(localErrors);
            return true;
        }

        const toggleVerify = () => {
            if (inVerify) {
                cancelVerify();
            } else {
                enterVerifyMode();
            }
        };

        const enterVerifyMode = () => {
            setInVerify(true);
        };

        const cancelVerify = () => {
            setData(deepCopyGridDataWithOriginal(originalData));
            setInVerify(false);
            setEditedVerified([]);
        }


        const saveVerifyChanges = () => {
            if (editedVerified.length === 0) {
                cancelVerify();
                return;
            }
            const updatedData = data.filter((item) => editedVerified.includes(item.id));
            axios.post('/api/auth/ra/resources/verify', updatedData)
                .then(() => {
                    getData();
                    setInVerify(false);
                    setEditedVerified([]);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        const handleSelectAll = () => {
            // Adjust filter criteria to ignore pagination for processing
            const tempDataState = {...dataState}
            delete tempDataState.skip
            delete tempDataState.take

            // Iterate over filtered row data, set verified flag, push to grid
            const filteredData = process(data, tempDataState);
            const localData = filteredData.data.map((item) => {
                const dataItem = {...item};
                dataItem['verified'] = true;
                setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
                return dataItem;
            });
            setData(localData);
        };

        const handleResourceNameBlur = (id: number, field: string, value: string) => {
            handleChange(id, field, value);
        };

        const validationButtonHandler = () => {
            setValidationErrors([]);
            setLoadingValidation(true);
            axios.get('/api/auth/ra/resources/validate')
                .then(response => {
                    setValidationErrors(response.data);
                    getData();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoadingValidation(false);
                });
        }

        const RespEntDropDownCell = getDropDownCell(entityOptions, 'Entity');
        const VerifiedCell = getVerifiedCell(inVerify && isStaff);
        const PlantCell = getDropDownCell(plantOptions, 'Plant');
        const ResourceNameCell = getOnBlurCell(handleResourceNameBlur);
        const MeteredCell = getYesNoDropDownCell();
        const MarketRegistrationCell = getDropDownCell(marketOptions, 'Market Registration');
        const MaxCapCellEditable = useMemo(() => getDecimalCell(3, true, 999999.999, 0.001), []);
        const MaxCapCell = useMemo(() => getDecimalCell(3, isStaff, 999999.999, 0), [isStaff]);
        const EnergySourceCell = getChildDropDownCell(genTypeOptions, 'Energy Source', 'primeMover');
        const ResourceStatusCell = getDropDownCell(statusOptions, 'Status');
        const PrimeMoverCell = getDropDownCell(primeOptions, 'Prime Mover');
        const CommentCell = getOnBlurCell(handleChange);

        const formatExport = (data: Array<ResourcesData>) => {
            return data.map(item => ({
                ...item,
                verified: item.verified ? 'YES' : 'NO'
            }));
        };

        const _export = useRef<ExcelExport | null>(null);

        const excelExport = () => {
            if (_export.current !== null) {
                _export.current?.save(formatExport(data));
            }
        };

        const pageChange = (event: GridPageChangeEvent) => {
            const tempPage = event.page;
            if (tempPage) {
                setDataState({
                    ...dataState,
                    skip: tempPage.skip,
                    take: tempPage.take
                });
                localStorage.setItem(`globalPageSize`,tempPage.take.toString());
            }
        };

        return (
            <div>
                <ActionPanel title={'Resources'}>
                    {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                    {isStaff && removeRole && inEdit && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                    {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                    {!inRemove && inEdit && <ImportPanel api='/api/auth/ra/resources/upload' setUploadRecords={handleUpload} />}
                    {isStaff && modifyRole && seasonOpen && !inEdit && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                    <ClearFilterPanel clearFilter={clearFilters}/>
                    {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                    {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                    {!inEdit && modifyRole && seasonOpen && !inVerify && <ValidatePanel disabled={inEdit || inVerify} loading={loadingValidation} validate={validationButtonHandler}/>}
                </ActionPanel>
                <RemoveModal open={removeModalOpen} closeModal={closeRemoveModal} confirmRemove={confirmRemove}/>
                <TooltipContainer>
                    <CustomLocalizationProvider>
                    <ExcelExport data={data} fileName={`Resources_${format(new Date(), 'MM-dd-yyyy')}.xlsx`} ref={_export}>
                        <Grid
                            data={process(data, dataState)}
                            dataItemKey='id'
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            style={{ height: "510px" }}
                            {...dataState}
                            editField='inEdit'
                            onDataStateChange={dataStateChange}
                            onItemChange={itemChange}
                            pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                            onPageChange={pageChange}
                        >
                            {inRemove && <GridColumn field='removal' title='Remove' width='85x' cell={removalSelectionCell}/>}
                            {
                                columnMeta.length
                                    ? columnMeta.map((col: ColumnMeta, index) => {
                                        switch (col.nm) {
                                            case 'verified':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='103px' cell={VerifiedCell} filter={"boolean"}/>
                                            case 'id':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='115px' editable={false}/>
                                            case 'respEntNm':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='155px' cell={RespEntDropDownCell}/>
                                            case 'plantNm':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='419px' cell={PlantCell}/>
                                            case 'resourceNm':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={ResourceNameCell}/>
                                            case 'resourceStatus':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='113px' cell={ResourceStatusCell}/>
                                            case 'metered':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='133px' cell={MeteredCell}/>
                                            case 'marketRegistration':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='147px' cell={MarketRegistrationCell}/>
                                            case 'initialOperationDt':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' cell={DateCell} format={DATE_ONLY_FORMAT}/>
                                            case 'plannedRetirementDt':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='190px' cell={DateCell} format={DATE_ONLY_FORMAT}/>
                                            case 'retirementDt':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='197px' cell={DateCell} format={DATE_ONLY_FORMAT}/>
                                            case 'namePlateCapacity':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='152px' cell={MaxCapCellEditable}/>
                                            case 'planModelMaxCapSummer':
                                            case 'planModelMaxCapWinter':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='335px' cell={MaxCapCell}/>
                                            case 'primeMover':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='260px' cell={PrimeMoverCell}/>
                                            case 'energySource':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='208px' cell={EnergySourceCell}/>
                                            case 'fuelType':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='234px' editable={false}/>
                                            case 'comments':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={CommentCell}/>
                                            case 'subYearId':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                            case 'lastUserModBy':
                                            case 'verifiedBy':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                            case 'lastUserModDt':
                                            case 'verifiedDt':
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='230px' editable={false}/>
                                            default:
                                                return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                        }
                                    })
                                    : <GridColumn/>
                            }
                        </Grid>
                    </ExcelExport>
                    </CustomLocalizationProvider>
                </TooltipContainer>
                {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
                {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'Resources'}/>}
            </div>
        );
    }
;

export default ResourcesGrid;
