import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import {
    Grid,
    GridColumn, GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import {
    process,
    State
} from '@progress/kendo-data-query';
import {initialDataState} from "../../services/data-grid-service";
import {ApiValidations} from "../../interfaces/grid-interfaces";
import {connect} from "react-redux";

interface HomeRaGridProps {
    currentCatagory?: string
}

const HomeRaGrid: React.FC<HomeRaGridProps> = (currentCatagory?) => {
        const [data, setData] = useState<Array<{ gridName: string, messages: number }>>([]);
        const [dataState, setDataState] = useState<State>({...initialDataState, take: 20});


        const getData = () => {
            axios.get('/api/auth/ra/validations')
                .then((resp) => {
                    const gridData = [
                        {gridName: 'Plants', season: 'All', title: 'Plants', messages: 0},
                        {gridName: 'Resources', season: 'All', title: 'Resources', messages: 0},
                        {gridName: 'TestResultsOp', season: 'All', title: 'Operational Test Results', messages: 0},
                        {gridName: 'TestResultsSummer', season: 'All', title: 'Summer Capability Test Results', messages: 0},
                        {gridName: 'TestResultsWinter', season: 'All', title: 'Winter Capability Test Results', messages: 0},
                        {gridName: 'ResourceOwnershipSummer', season: 'Summer', title: 'Resource Ownership Summer', messages: 0},
                        {gridName: 'ResourceOwnershipWinter', season: 'Winter', title: 'Resource Ownership Winter', messages: 0},
                        {gridName: 'PurchasesSalesSummer', season: 'Summer', title: 'Purchases & Sales Summer', messages: 0},
                        {gridName: 'PurchasesSalesWinter', season: 'Winter', title: 'Purchases & Sales Winter', messages: 0},
                        {gridName: 'CapacityAdjustmentsSummer', season: 'Summer', title: 'Yearly Summer Capacity Adjustment Submissions', messages: 0},
                        {gridName: 'CapacityAdjustmentsWinter', season: 'Winter', title: 'Yearly Winter Capacity Adjustment Submissions', messages: 0},
                        {gridName: 'MonthlyDemandSummer', season: 'Summer', title: 'Summer Monthly Demand and Energy', messages: 0},
                        {gridName: 'MonthlyDemandWinter', season: 'Winter', title: 'Winter Monthly Demand and Energy', messages: 0},
                        {gridName: 'YearlyPeakSummer', season: 'Summer', title: 'Summer Yearly Peak Demand', messages: 0},
                        {gridName: 'YearlyPeakWinter', season: 'Winter', title: 'Winter Yearly Peak Demand', messages: 0}
                    ];

                    resp.data.forEach((dataItem: ApiValidations) => {
                        for (let gridDataItem of gridData) {
                            if ((dataItem.dataSetName === gridDataItem.gridName) && (dataItem.seasonName === gridDataItem.season)) {
                                gridDataItem.messages++;
                            }
                        }
                    })

                    setData(gridData);

                })
                .catch((error) => {
                    console.log(error);
                });
        };

        useEffect(() => {
            getData();
        }, []);

        useEffect(() => {
            if (currentCatagory) {
                getData();
            }
        }, [currentCatagory])

        const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

        const dataStateChange = (event: GridDataStateChangeEvent) => {
            setDataState(event.dataState);
        };

        return (
            <Grid
                data={process(data, dataState)}
                dataItemKey='id'
                pageable={false}
                sortable={true}
                filterable={true}
                resizable={true}
                {...dataState}
                onDataStateChange={dataStateChange}
                style={{width: 625}}
            >
                {
                    columnNames.length
                        ? columnNames.map((columnName, index) => {
                            switch (columnName) {
                                case 'gridName':
                                    return <GridColumn key={index} field={'title'} title={'Grid Name'} width='350px'/>
                                case 'messages':
                                    return <GridColumn key={index} field={'messages'} title={'Number of Validation Messages'} width='250px'/>
                                default:
                                    return null
                            }
                        })
                        : <GridColumn/>
                }
            </Grid>
        );
    }
;

const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory
    }
};

export default connect(mapStateToProps)(HomeRaGrid);
