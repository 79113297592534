import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import CapacityAdjustmentsGrid from './CapacityAdjustmentsGrid';
import {SubmittingEntityDropdown} from './SubmittingEntityDropdown';
import {connect} from 'react-redux';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';

const CapacityAdjustmentsPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    const [submitEntId, setSubmitEntId] = useState<number>();

    useEffect(() => {
        setPageRole(getCompressedPageRole('CapacityAdjustments', roles));
    }, [roles]);

    return (
        <Container fluid style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <Header size="large">Capacity Adjustments</Header>
            <SubmittingEntityDropdown submitEntId={submitEntId} setSubmitEntId={setSubmitEntId}/>
            <br/>
            <br/>
            <AccordionWrapper accordionHeader={'Yearly Summer Capacity Adjustment Submissions'}>
                <CapacityAdjustmentsGrid
                    title='Yearly Summer Capacity Adjustment Submissions'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/capacity/summer'
                    season='Summer'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Yearly Winter Capacity Adjustment Submissions'}>
                <CapacityAdjustmentsGrid
                    title='Yearly Winter Capacity Adjustment Submissions'
                    submitEntId={submitEntId}
                    dataApi='/api/auth/ra/submissions/capacity/winter'
                    season='Winter'
                    isStaff={pageRole.isStaff}
                    readRole={pageRole.readRole}
                    modifyRole={pageRole.modifyRole}
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(CapacityAdjustmentsPage);
