export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';

export const CHANGE_TOKEN = 'CHANGE_TOKEN';

export const UPDATE_USER_PRINCIPAL = 'UPDATE_USER_PRINCIPAL';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const UPDATE_CURRENT_CHANGESET = 'UPDATE_CURRENT_CHANGESET';

export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB';

export const UPDATE_SUBMITTING_ENTITY = 'UPDATE_SUBMITTING_ENTITY';

export const UPDATE_CHANGESET_OR_BASECASE = 'UPDATE_CHANGESET_OR_BASECASE';

export const ADD_ROWS_TO_CHANGESET = 'ADD_ROWS_TO_CHANGESET';

export const REMOVE_ROW_FROM_CHANGESET = 'REMOVE_ROW_FROM_CHANGESET';

export const UPDATE_CHANGESET_PENDING_SAVE = 'UPDATE_CHANGESET_PENDING_SAVE';

export const SET_CHANGESET_ROW_UPDATED = 'SET_CHANGESET_ROW_UPDATED';

export const UPDATE_USER_ENTITIES = 'UPDATE_USER_ENTITIES';

export const UPDATE_EDST_CATEGORY = 'UPDATE_EDST_CATEGORY';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export const SET_SUBYEAR_ID = 'SET_SUBYEAR_ID';

export const SET_USER_IN_AUTHOR_ENTITY_GROUP = 'SET_USER_IN_AUTHOR_ENTITY_GROUP';

export const SET_CHANGESET_AUTHOR_IS_MEMBER = 'SET_CHANGESET_AUTHOR_IS_MEMBER';

export const INCREMENT_CHANGESET_VERSION = 'INCREMENT_CHANGESET_VERSION';

export const RESET_CHANGESET_VERSION = 'RESET_CHANGESET_VERSION';

export const INCREMENT_BASECASE_VERSION = 'INCREMENT_BASECASE_VERSION';

export const RESET_BASECASE_VERSION = 'RESET_BASECASE_VERSION';

export const SET_CONTACTMODALACTIVE = 'SET_CONTACTMODALACTIVE';

export const SET_STAKEHOLDERMODALACTIVE = 'SET_STAKEHOLDERMODALACTIVE';

export const SET_DELETEMODALACTIVE = 'SET_DELETEMODALACTIVE';

export const SET_CURRENTROW = 'SET_CURRENTROW';

export const SET_STAKEHOLDERCURRENTROW = 'SET_STAKEHOLDERCURRENTROW';

export const SET_DELETESCREEN = 'SET_DELETESCREEN';

export const SET_ADDORMODIFYCONTACT = 'SET_ADDORMODIFYCONTACT';

export const SET_ENTITYMODALACTIVE = 'SET_ENTITYMODALACTIVE';

export const SET_CURRENTENTITYROW = 'SET_CURRENTENTITYROW';

export const SET_ADDORMODIFYENTITY = 'SET_ADDORMODIFYENTITY';

export const SET_ACTIVETAB = 'SET_ACTIVETAB';

export const SET_USERTYPE = 'SET_USERTYPE';

export const SET_SUBYEAR = 'SET_SUBYEAR';

export const SET_ALLENTITIES = 'SET_ALLENTITIES';

export const SET_ROLLOVERMODALACTIVE = 'SET_ROLLOVERMODALACTIVE';

export const UPDATE_USER_ROLES_ENTITIES = 'UPDATE_USER_ROLES_ENTITIES';

export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';

export const UPDATE_ACTIVE_MENU_ITEM = 'UPDATE_ACTIVE_MENU_ITEM';