//import * as React from 'react';
import {
    Container,
     Header,
} from "semantic-ui-react";
import TabGrids from "./TabGrids";

/**Entry point into the entity management screen*/
export function EntityManagement(){
    return (
        <Container fluid={true} style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
            <Header size={'large'}>Entity Management</Header>
            <TabGrids/>
        </Container>
    )
}

