import {connect} from "react-redux";
import 'react-redux';
import ChangesetDetails from '../common/ChangesetDetails';
import HomePageDropdown from "../common/HomePageDropdown";
import React, {useEffect, useState} from "react";
import * as actionTypes from "../../store/actions/actionTypes";
import {Principal} from "../../auth";
import HomeRaGrid from "./HomeRaGrid";

interface MdAndRaHomeWrapperProps {
    currentCategory: string,
    roles: Principal[],
    updateCurrentCategory: (category: string) => void,
}

const MdAndRaHomeWrapper: React.FC<MdAndRaHomeWrapperProps> = ({currentCategory, roles, updateCurrentCategory}) => {
    const [dropdownOptions, setDropdownOptions] = useState<Array<any>>([]);

    useEffect(() => {
        const optionRA = 'Resource Adequacy';
        const optionMDWG = 'Modeling Development';
        const options = [];
        if (roles) {
            if (roles?.some(role => role.cat === 'ResAdq')) {
                options.push(optionRA);
            }
            if (roles?.some(role => role.cat === 'ModDev')) {
                options.push(optionMDWG);
            }
        }
        setDropdownOptions(options);
    }, [roles]);

    return (

        <div>
            <HomePageDropdown options={dropdownOptions} dispatch={updateCurrentCategory} currentValue={currentCategory}/>
            <br/>
            <br/>
            {currentCategory === 'RA' ? <HomeRaGrid/> : <ChangesetDetails currentCategory={currentCategory}/>}
        </div>
    )
};



const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory,
        currentUser: state.authReducer.currentUser,
        roles: state.authReducer.roles
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentCategory: (category: string) => dispatch(
            {type: actionTypes.UPDATE_EDST_CATEGORY, payload: category})
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(MdAndRaHomeWrapper);