import * as React from 'react';
import axios from 'axios';
import '../../../CSS/react-table-custom.css';
import GridUtil from "../../common/GridUtil";
import ReactTableHOC from "../../common/EdstGrid";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import ChangesetService from "../../../services/changeset-services";

interface SelectedChangesetDetailsState {
    selectedChangesetData: any
    selectedChangesetMetaData: any
    selectedChangesetLoading: boolean
    edstGridInputKey: any
    filtered: any
    edstGridInputFilterKey: any
    originalBaseCaseData: any
}

interface SelectedChangesetDetailsProps {
    currentChangeset: Changeset
}

const changesetClient = new ChangesetService();
const orderBy = require('lodash/orderBy');
class SelectedChangesetDetails extends React.Component<SelectedChangesetDetailsProps, SelectedChangesetDetailsState> {
    constructor(props: SelectedChangesetDetailsProps, state: SelectedChangesetDetailsState) {
        super(props,state);
        this.state = {
            selectedChangesetData: [],
            selectedChangesetMetaData: [],
            selectedChangesetLoading: false,
            edstGridInputKey: null,
            filtered: [],
            edstGridInputFilterKey: null,
            originalBaseCaseData: []
        };
    }

    handleFilterChange = (filter:any) => {
        let currentFilter = this.state.filtered;
        currentFilter = filter;
        this.setState({filtered:currentFilter});
    };

    componentDidUpdate(prevProps: Readonly<SelectedChangesetDetailsProps>, prevState: Readonly<SelectedChangesetDetailsState>, snapshot?: any): void {
        //Sometimes when loading Changeset History component the currentChangeset.number gets set to 0 which throws errors
        //when making the API call. Checking excluding currentChageset.number of 0 prevents the call when not needed.
        if (prevProps !== this.props && this.props.currentChangeset.number !== 0) {
            this.getUserChangesets();
        }
    }

    getUserChangesets = () => {
        axios.get(`/api/auth/activeChangeSetData`, {params:
                {changesetId: this.props.currentChangeset.number,
                tabName: this.props.currentChangeset.comments} // reusing comments for the tab name
            }).then( resp => {
                if(resp.data.status !== 500) {
                    let recordKeys = '';
                    let mirrorRecs = '';
                    // we need to put the ADD records at the bottom of our array bc they won't have a corresponding basecase record to compare to
                    resp.data.data = orderBy(resp.data.data,['action','pK','pk'],['desc','asc','asc']);
                    for (let i = 0; i < resp.data.data.length; i++) {
                        if (resp.data.data[i]['pK'] === undefined) {
                            recordKeys += resp.data.data[i]['pk'];
                        }
                        else {
                            recordKeys += resp.data.data[i]['pK'];
                        }
                        if (resp.data.data[i]['mirrorRec']) {
                            mirrorRecs += resp.data.data[i]['mirrorRec'];
                        }
                        if (i < resp.data.data.length - 1) {
                            recordKeys += ',';
                            if (mirrorRecs !== '') {
                                mirrorRecs += ',';
                            }
                        }
                    }
                    axios.get('/api/auth/basecase/previousBaseCaseData', {params:
                            {tabName: this.props.currentChangeset.comments, // reusing comments for the tab name
                        recordKeys: recordKeys,
                        mirrorRecs: mirrorRecs}
                    }).then(baseResp => {
                        changesetClient.checkNoChange(resp.data.data, baseResp.data.data, resp.data.columnMetaDataList, this.props.currentChangeset.comments).then(rowData =>{
                        this.setState({
                            selectedChangesetData: resp.data.status !== 500 ?  rowData: [],
                            selectedChangesetMetaData: resp.data.status !== 500 ? resp.data.columnMetaDataList : [],
                            selectedChangesetLoading: false,
                            edstGridInputKey: Date.now(),
                            originalBaseCaseData: baseResp.data.data
                        })
                        })
                        //this.setState({originalBaseCaseData: baseResp.data.data});
                    }).catch(resp => {
                        toast.error('Error retrieving original basecase data for changeset ' + this.props.currentChangeset.number + `- ${resp.message}`);
                    });
                }
                else {
                    this.setState({
                        selectedChangesetData: [],
                        selectedChangesetMetaData: [],
                        selectedChangesetLoading: false,
                        edstGridInputKey: Date.now()
                    })
                }
        }).catch(resp => {
            toast.error(`Error retrieving user changesets - ${resp.message}`);
            this.setState({selectedChangesetLoading: false});
        });
        this.setState({selectedChangesetLoading: true});
    };

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
        this.setState({filtered:[], edstGridInputFilterKey: Date.now() });
    };

    getFiltered = () => {
        if (this.state.filtered) {
            return 'visible'
        }
        return 'hidden'
    };

    getHashTagColumn = () => {
        return [{
            Header: '#',
            Cell: null,
            Filter: <Button color={'black'} size={'mini'} style={{visibility:this.getFiltered}} onClick={this.clearFilterOnClick}>Clear Filters</Button>,
            sortable: false,
            minWidth: 200
        }];
    };

    public render() {
        return (
            <ReactTableHOC
                data={this.state.selectedChangesetData}
                previousData={this.state.originalBaseCaseData}
                columnMetaData={this.state.selectedChangesetMetaData}
                defaultPageSize={50}
                filterable={true}
                filtered={this.state.filtered}
                onFilterChanged={this.handleFilterChange}
                inputFilterKey={this.state.edstGridInputFilterKey}
                padded={'very'}
                className={'-striped SelectedChangesetDetails'}
                tableClassName={'SelectedChangesetDetails'}
                defaultFilterMethod={GridUtil.filterMethod}
                loading={this.state.selectedChangesetLoading}
                inputKey={this.state.edstGridInputKey}
                size={'large'}
                color={'black'}
                tableStyle={{height:'500px'}}
                utilityColumn={this.getHashTagColumn()}
                getTheadProps={() => {
                    return {
                        style: {
                            background: "black",
                            color: "white",
                            borderRadius: '5px'
                        }
                    };
                }}
            />
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentChangeset: state.changeSetReducer.currentChangeset
    }
};

export default connect(mapStateToProps)(SelectedChangesetDetails)
