//import * as React from "react";
import {Button, Modal} from "semantic-ui-react";

function SppVerifiedModal({modalActive, continueModal, cancelModal, bulkUploadRecordKeys}: any){
    return(
        <div>
                <Modal open={modalActive} size={"large"}>
                    <Modal.Header>Edit Verified Row</Modal.Header>
                    {bulkUploadRecordKeys.length?
                        <Modal.Content>
                            You attempted to modify the following record keys that were already verified by SPP staff. By continuing SPP staff will need
                            to re-verify the data. Click Continue to proceed or Cancel to return to all rows to the previously verified state.
                            <p>Record Keys: {bulkUploadRecordKeys.join()}</p>
                        </Modal.Content>:
                    <Modal.Content>
                        The row you are attempting to edit has already been verified by SPP staff. By editing this row SPP staff will need
                        to re-verify the data. Click Continue to edit the row or Cancel to return to the verified state.
                    </Modal.Content>
                        }
                    <Modal.Actions>
                        <Button onClick={() => {continueModal();}}
                                positive={true}>
                            Continue
                        </Button>
                        <Button onClick={() => {cancelModal();}}
                                negative={true}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
        </div>
    )
}


export default SppVerifiedModal;

