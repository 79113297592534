import * as React from 'react';
import {Button, Header, Icon} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import UserChangesetsGrid from "./UserChangesetsGrid";
import ValidationMessages from "./ValidationMessages";
import {AccordionSegment} from "../../common/AccordionSegment";
import AttachmentGrid from "./AttachmentGrid";
import SelectedChangesetDetails from "./SelectedChangesetDetails";
import {connect} from "react-redux";
import ChangesetService from "../../../services/changeset-services";

interface ChangesetHistoryState {
    currentChangeset: Changeset
    newChangesetRow: any,
    uploadFile:any


}

interface ChangesetHistoryProps {
    currentChangeset: Changeset
    userType: string
}

//let fileDownload = require('js-file-download');
const changesetClient = new ChangesetService();
class ChangesetHistory extends React.Component<RouteComponentProps<{}> & ChangesetHistoryProps,ChangesetHistoryState> {
    constructor(props: RouteComponentProps<{}> & ChangesetHistoryProps, state: ChangesetHistoryState) {
        super(props, state);
        this.state = {
            currentChangeset: {
                name: '',
                number: 0,
                comments: '',
                status: '',
                changeSetAuthorName:''
            },
            newChangesetRow: [],
            uploadFile: {},
        };
    }
    public render() {
        return (
            <div style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
                <Header size={'large'}>Changeset History</Header>
                <AccordionSegment segmentHeader={'User Changesets'} size={'tiny'}>
                    <UserChangesetsGrid/>
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Selected Changeset Details'} size={'tiny'} contentHidden={this.props.currentChangeset.number === 0} >
                    <SelectedChangesetDetails/>
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Validation Messages'} size={'tiny'} contentHidden={this.props.currentChangeset.number === 0}>
                    <ValidationMessages/>
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Attached Documents'} contentHidden={this.props.currentChangeset.number === 0} size={'tiny'}>
                    <AttachmentGrid/>
                </AccordionSegment>
                <Button icon={true} labelPosition='left' onClick={changesetClient.downloadExcel} UserType={this.props.userType} color={'green'}>
                    <Icon name='download'/>
                    Download data
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentChangeset: state.changeSetReducer.currentChangeset,
        userType: state.defaultReducer.userType,
    }
};

export default connect(mapStateToProps)(ChangesetHistory)