import * as React from 'react';
import {
    Button,
    Checkbox,
    CheckboxProps,
    Container,
    Divider,
    Grid,
    Header,
    Input,
    InputOnChangeData,
    Label,
    Message,
    Modal
} from "semantic-ui-react";
import {Redirect, RouteComponentProps} from "react-router";
import AdminService from "../../services/admin-services";
import GeneralService from "../../services/general-services";

interface NewUserState {
    user: User
    password: string
    confirmPassword: string
    email: string
    userNameError: boolean
    validationErrors: string[]
    passwordError: boolean
    confirmPasswordError: boolean
    emailError: boolean
    longNameError: boolean
    phoneNumberError: boolean
    openConfirmNewUserModal: boolean
    redirectToUserManagement: boolean
    messageVisible: boolean
    emailVerifiedUnique: boolean
    emailUniqueError: boolean
    addUserLoading: boolean
    submitLoading: boolean
}

const adminService = new AdminService();
const generalService = new GeneralService();
export class RegisterNewUser extends React.Component<RouteComponentProps<{}>, NewUserState> {
    constructor(props: RouteComponentProps<{}>, state: NewUserState) {
        super(props, state);
        this.state = {
            user: {
                nm: '',
                password: '',
                confirmPassword: '',
                email: '',
                longNm: '',
                phone: '',
                comments: '',
                activeInd: false,
                ndaCompDutyInd: false,
                ndaNonCompDutyInd: false,
            },
            password: '',
            confirmPassword: '',
            email: '',
            userNameError: false,
            validationErrors: [],
            passwordError: false,
            confirmPasswordError: false,
            emailError: false,
            longNameError: false,
            phoneNumberError: false,
            openConfirmNewUserModal: false,
            redirectToUserManagement: false,
            messageVisible: false,
            emailVerifiedUnique: false,
            emailUniqueError: false,
            addUserLoading: false,
            submitLoading: false
        };
    }

    handleChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData | CheckboxProps) => {
        let {user, password, confirmPassword, email, emailVerifiedUnique,
            userNameError, emailError, longNameError, phoneNumberError} = this.state;
        if (data.name === 'nm' && data.value !== undefined) {
            let value = data.value.toString().toLowerCase();
            if (generalService.validateUsername(value)) {
                user.nm = value;
                userNameError = false;
            }
        } else if (data.name === 'password' && data.value !== undefined) {
            password = data.value.toString();
            user.password = password;
        } else if (data.name === 'confirmPassword' && data.value !== undefined) {
            confirmPassword = data.value.toString();
            user.confirmPassword = confirmPassword;
        } else if (data.name === 'email' && data.value !== undefined) {
            email = data.value.toString();
            emailVerifiedUnique = false;
            user.email = email;
            if (emailError && generalService.validateEmail(email)) {
                emailError = false;
            }
        } else if (data.name === 'longNm' && data.value !== undefined) {
            user.longNm = data.value.toString();
            if (longNameError) {
                longNameError = false;
            }
        } else if (data.name === 'phone' && data.value !== undefined) {
            user.phone = data.value.toString();
            if (phoneNumberError) {
                phoneNumberError = false;
            }
        } else if (data.type === 'checkbox') {
            user[data.name] = data.checked;
        } else {
            user[data.name] = data.value;
        }
        this.setState({
            user, password, confirmPassword, email, emailVerifiedUnique,
            userNameError, emailError, longNameError, phoneNumberError
        });
    };

    validateForm = () => {
        let {password, confirmPassword, email, messageVisible, passwordError, confirmPasswordError, emailError} = this.state;
        let errors: string[] = [];
        if (password.length === 0) {
            errors.push('Password cannot be blank.');
        } else {
            errors = generalService.validatePassword(password);
            
            if (errors.length === 0) {
                passwordError = false;
            }
            if (confirmPassword !== password) {
                errors.push('Confirm password does not match password.');
            } else {
                confirmPasswordError = false;
            }
        }
        if (email !== '' && !generalService.validateEmail(email)) {
            errors.push('Email syntax is invalid.');
        }
        if (errors.length === 0) {
            messageVisible = false;
        }
        this.setState({validationErrors: errors, messageVisible, passwordError, confirmPasswordError, emailError});
    };

    submitNewUser = () => {
        this.setState({submitLoading: true});
        adminService.createNewUser(this.state.user)
            .then(() => {
                this.closeModal();
                this.clearForm();
                this.setState({
                    redirectToUserManagement: true,
                    submitLoading: false
                })
            })
            .catch(() => {
                this.setState({
                    submitLoading: false
                });
            });
    };

    addUser = async () => {
        this.validateForm();
        let userNameError = this.state.user.nm === '';
        let passwordError = this.state.validationErrors.length > 0 || this.state.user.password === '';
        let confirmPasswordError = this.state.user.confirmPassword !== this.state.user.password
            || this.state.user.confirmPassword === '';
        let emailError = this.state.user.email === '' || !generalService.validateEmail(this.state.user.email);
        let longNameError = this.state.user.longNm === '';
        let phoneNumberError = this.state.user.phone === '';
        let { emailVerifiedUnique, emailUniqueError } = this.state;

        if (!emailError && !emailVerifiedUnique) {
            this.setState({addUserLoading: true});
            await adminService.verifyEmailUnique(this.state.user.email)
                .then((data) => {
                    if (data) {
                        emailVerifiedUnique = true;
                        emailUniqueError = false;
                    } else {
                        emailUniqueError = true;
                        this.setState((prevState) => ({
                            validationErrors: [
                                ...prevState.validationErrors,
                                `The email ${this.state.user.email} is already in use, please select another.`
                            ]
                        }))
                    }
                });
        }
        this.setState({addUserLoading: false});

        let openConfirmNewUserModal = !userNameError && !passwordError && !confirmPasswordError
            && !emailError && !longNameError && !phoneNumberError && emailVerifiedUnique;
        let messageVisible = passwordError || confirmPasswordError || emailError || emailUniqueError;
        
        this.setState({
            userNameError,
            passwordError,
            confirmPasswordError,
            emailError,
            longNameError,
            phoneNumberError,
            openConfirmNewUserModal,
            messageVisible,
            emailVerifiedUnique,
            emailUniqueError
        });
    };

    clearForm = () => {
      this.setState({
          user: {
              nm: '',
              password: '',
              confirmPassword: '',
              email: '',
              longNm: '',
              phone: '',
              comments: '',
              activeInd: false,
              ndaCompDutyInd: false,
              ndaNonCompDutyInd: false,
          },
          password: '',
          confirmPassword: '',
          email: '',
          userNameError: false,
          validationErrors: [],
          passwordError: false,
          confirmPasswordError: false,
          emailError: false,
          longNameError: false,
          phoneNumberError: false,
          messageVisible: false
      })
    };

    closeModal = () => {
        this.setState({openConfirmNewUserModal:false})
    };

    render () {
        if (this.state.redirectToUserManagement) {
            return (
                <Redirect to={'/UserManagement'}/>
            )   
        }        
        
        let {
            user,
            userNameError,
            passwordError,
            confirmPasswordError,
            emailError,
            longNameError,
            phoneNumberError
        } = this.state;
        
        return (
            <Container style={{paddingTop:'50px'}}>
                  <Grid textAlign={'left'}>
                      <Grid.Row>
                          <Header size={'large'}>Register New User</Header>
                      </Grid.Row>
                      <Grid.Row>
                          <Header size={'small'}>Create a new account</Header>
                          <Divider horizontal={true}/>
                      </Grid.Row>
                  </Grid>
                <Grid columns={4}>
                      <Grid.Row>
                          <Input error={userNameError}
                                 placeholder={userNameError ? 'User Name is required' : ''}
                                 name={'nm'}
                                 value={user.nm}
                                 required={true}
                                 style={{width:'40%'}}
                                 type={'text'}
                                 label={<Label color={'black'}>User Name</Label>}
                                 labelPosition={'left'}
                                 onChange={this.handleChange}/>
                      </Grid.Row>
                      <Grid.Row>
                          <Input
                              error={passwordError}
                              placeholder={passwordError ? 'Password is required' : ''}
                              name={'password'}
                              value={user.password}
                              style={{width:'40%'}}
                              type={'password'}
                              label={<Label color={'black'}>Password</Label>}
                              labelPosition={'left'}
                              onChange={this.handleChange}
                          />
                      </Grid.Row>
                      <Grid.Row>
                          <Input error={confirmPasswordError}
                                 placeholder={confirmPasswordError ? 'Confirm password does not match password' : ''}
                                 name={'confirmPassword'}
                                 value={user.confirmPassword}
                                 style={{width:'40%'}}
                                 type={'password'}
                                 label={<Label color={'black'}>Confirm password</Label>}
                                 labelPosition={'left'}
                                 onChange={this.handleChange}
                          />
                      </Grid.Row>
                      <Grid.Row>
                          <Input error={emailError}
                                 placeholder={emailError ? 'Email is required' : ''}
                                 name={'email'}
                                 value={user.email}
                                 style={{width:'40%'}}
                                 type={'text'}
                                 label={<Label color={'black'}>Email</Label>}
                                 labelPosition={'left'}
                                 onChange={this.handleChange}/>
                      </Grid.Row>
                      <Grid.Row>
                          <Input error={longNameError}
                                 placeholder={longNameError ? 'Long Name is required' : ''}
                                 name={'longNm'}
                                 value={user.longNm}
                                 style={{width:'40%'}}
                                 type={'text'} label={<Label color={'black'}>Long Name</Label>}
                                 labelPosition={'left'}
                                 onChange={this.handleChange}/>
                      </Grid.Row>
                      <Grid.Row>
                          <Input error={phoneNumberError}
                                 placeholder={phoneNumberError ? 'Phone Number is required' : ''}
                                 name={'phone'}
                                 value={user.phone}
                                 style={{width:'40%'}}
                                 type={'text'}
                                 label={<Label color={'black'}>Phone Number</Label>}
                                 labelPosition={'left'}
                                 onChange={this.handleChange}
                          />
                      </Grid.Row>
                      <Grid.Row>
                          <Input
                              name={'comments'}
                              value={user.comments}
                              style={{width:'40%'}}
                              type={'text'}
                              label={<Label color={'black'}>Comments</Label>}
                              labelPosition={'left'}
                              onChange={this.handleChange}
                          />
                      </Grid.Row>
                      <Grid.Row/>
                      <Checkbox checked={user.activeInd} name={'activeInd'}
                                toggle={true} type={'checkbox'} label={<label>Active Indicator</label>}
                                onChange={this.handleChange}/>
                      <Checkbox checked={user.ndaCompDutyInd} name={'ndaCompDutyInd'}
                                toggle={true} type={'checkbox'} label={<label>Competitive Indicator</label>}
                                onChange={this.handleChange}/>
                      <Checkbox checked={user.ndaNonCompDutyInd} name={'ndaNonCompDutyInd'}
                                toggle={true} type={'checkbox'} label={<label>NonCompetitive Indicator</label>}
                                onChange={this.handleChange}/>
                      <Grid.Row/>
                    {this.state.messageVisible &&
                    <ErrorMessage errors={this.state.validationErrors} />
                    }
                      <Grid.Row>
                        <Button
                            type={'submit'} color={'black'}
                            onClick={this.addUser} content={'Add User'}
                            loading={this.state.addUserLoading}
                        />
                      </Grid.Row>
                </Grid>

                <Modal open={this.state.openConfirmNewUserModal} onClose={this.closeModal} size={'small'}>
                    <Modal.Header>Confirm New User</Modal.Header>
                    <Modal.Content>
                        <p><b>
                            Confirm the information displayed below. If anything is incorrect click "Cancel", otherwise
                            click "Submit" to create the new user.
                        </b></p>
                        <Grid centered={true}>
                            <Grid.Row>
                                <Input
                                    value={user.nm}
                                    style={{width:'40%'}}
                                    type={'text'}
                                    label={<Label color={'black'}>User Name</Label>}
                                    labelPosition={'left'}
                                />
                            </Grid.Row>
                            <Grid.Row>
                                <Input
                                    value={user.longNm}
                                    style={{width:'40%'}}
                                    type={'text'}
                                    label={<Label color={'black'}>Long Name</Label>}
                                    labelPosition={'left'}
                                />
                            </Grid.Row>
                            <Grid.Row>
                                <Input
                                    value={user.email}
                                    style={{width:'40%'}}
                                    type={'text'}
                                    label={<Label color={'black'}>Email</Label>}
                                    labelPosition={'left'}
                                />
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color={'black'} onClick={this.submitNewUser}
                                content={'Submit'} loading={this.state.submitLoading}/>
                        <Button color={'black'} onClick={this.closeModal} content={'Cancel'}/>
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const ErrorMessage = (props: {errors: string[]}) => {
    let i: number = 0;
    return (
        <Message>
            <Message.List>
                {props.errors.map(error => (
                    <Message.Item key={i++}>{error}</Message.Item>
                ))}
            </Message.List>
        </Message>
    )
};