import * as React from 'react';
import {
    Container,
    DropdownItemProps,
    Grid,
    Header, Icon,
    Label,
    Menu, Modal,
    Tab,
    Table,
    TabProps
} from 'semantic-ui-react';
import {connect} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import {Redirect} from "react-router";
import ChangesetService from "../../services/changeset-services";
import {Principal} from "../../auth";
import ValidationMessages from "../Administration/ChangesetHistory/ValidationMessages";
import CategoryDropDown from "./CategoryDropDown";

interface ChangesetDetailsState {
    currentStatusTab: string
    currentRecordTab: string
    data: any
    currentRecords: any
    currentRecordCount: number
    statusTypes: string[]
    recordTypes: string[]
    redirect: string
    loading:boolean
    categories:string[],
    categoryOptions:DropdownItemProps[]
    currentCategory:string
    showWarnings:boolean
}

interface ChangesetDetailsProps {
    updateCurrentChangeset: (currentChangeset: Changeset) => void
    updateCurrentCategory: (category: string) => void
    currentUser:string
    roles:Principal[]
    updateCurrentTab: (currentTab: string) => void,
    updateActiveMenuItem: (activeMenuItem: string) => void,
    currentCategory: string
}

const client = new ChangesetService();
class ChangesetDetails extends React.Component<ChangesetDetailsProps,ChangesetDetailsState> {
    constructor(props:ChangesetDetailsProps,state: ChangesetDetailsState) {
        super(props,state);
        this.state = {
            currentStatusTab: 'PENDING',
            currentRecordTab: 'Capacity Adjustments',
            data:[{}],
            currentRecords: [],
            currentRecordCount: 0,
            statusTypes: ['PENDING','QUEUED','APPROVED','WARN','DRAFT'],
            recordTypes: [],
            redirect: '',
            loading: true,
            categories:[],
            categoryOptions:[],
            currentCategory:'',
            showWarnings:false
        }
    }

    getUserChangesets = () => {
        client.getUserChangesets().then((data:any[]) => {
            if (data) {
                let changesetArray = {};
                data.forEach((d: any) => {
                    if (changesetArray[d.visTabNm]) {
                        if (changesetArray[d.visTabNm][d.disp]) {
                            changesetArray[d.visTabNm][d.disp].push(d);
                        } else {
                            changesetArray[d.visTabNm][d.disp] = [];
                            changesetArray[d.visTabNm][d.disp].push(d);
                        }
                    } else {
                        changesetArray[d.visTabNm] = [];
                        changesetArray[d.visTabNm] = {};
                        changesetArray[d.visTabNm][d.disp] = [];
                        changesetArray[d.visTabNm][d.disp].push(d);
                    }
                });
                this.setState({data: changesetArray});
            }
            this.setState({loading:false});
        }).catch(() => {
            this.setState({loading: false});
        });

        this.setState({loading:true});
    };

    tabStatusChange = (e:any,a:TabProps) => {
        if (a.activeIndex !== undefined) {
            this.setState({currentStatusTab:a.panes![a.activeIndex].menuItem.key});
        }
        this.getChangesetsForCurrentTab();
    };

    tabRecordTypeChange = (e:any,a:TabProps) => {
        if (a.activeIndex !== undefined) {
            this.setState({currentRecordTab:a.panes![a.activeIndex].menuItem.key});
        }
        this.getChangesetsForCurrentTab();
    };

    getChangesetsForCurrentTab = () => {
        let {data,currentRecordTab,currentStatusTab} = this.state;

        if (data[currentRecordTab]){
            if (data[currentRecordTab][currentStatusTab]){
                return data[currentRecordTab][currentStatusTab];
            }
            return [];
        }
        return [];
    };

    goToChangeset = (event:any) => {
        let tabNm = event.target.dataset.tabnm;
        let changeset: Changeset = {
            name: event.target.dataset.nm,
            number: event.target.textContent,
            status: event.target.dataset.disp,
            comments: event.target.dataset.comment,
            changeSetAuthorName: event.target.dataset.author
        };
        this.props.updateCurrentChangeset(changeset);
        if (tabNm === 'DemandSideManagement') {
            tabNm = 'DemandAndEnergy'
        }
        this.props.updateActiveMenuItem(tabNm);
        let redirectPage = '/' + tabNm;
        if (tabNm !== 'CapacityAdjustments' && tabNm !== 'DemandAndEnergy') {
            redirectPage = `${redirectPage}/${changeset.number}`;
        }
        this.setState({redirect: (changeset.status === 'APPROVED') ? `/ChangesetHistory` : redirectPage})
    };

    getWarnings = (e:any) => {
        let currentChangeset:Changeset = {
            number: e.currentTarget.dataset.changeset,
            name: '',
            comments: '',
            status: '',
            changeSetAuthorName: ''
        };
        this.props.updateCurrentChangeset(currentChangeset);
        this.setState({showWarnings:true})
    };

    getChangesetTable = () => {
        return (
            <Container style={{height:'600px',overflow:'auto'}}>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Changeset ID</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Author</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.getChangesetsForCurrentTab().map((changeset:any) =>
                        <Table.Row key={changeset.id}>
                            <Table.Cell>
                                <span style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}
                                     onClick={this.goToChangeset}
                                     data-tabnm={changeset.tabNm}
                                     data-nm={changeset.nm}
                                     data-comment={changeset.comments}
                                     data-author={changeset.userNm}
                                     data-disp={changeset.disp}>{changeset.id}</span>
                                <Icon style={{
                                            visibility: this.state.currentStatusTab === 'WARN' ? 'visible':'hidden',
                                            cursor:'pointer'}}
                                      color={'yellow'} name={'exclamation triangle'} data-changeset={changeset.id} onClick={this.getWarnings}/>
                            </Table.Cell>
                            <Table.Cell>{changeset.nm}</Table.Cell>
                            <Table.Cell>{changeset.userNm}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            </Container>
        )
    };

    componentDidMount(): void {
        this.getUserChangesets();
        this.props.updateCurrentTab("Home")
    }

    getCounts = (record:string) => {
        let {data} = this.state;
        let total = 0;
        if(data[record]) {
            for (let status in data[record]) {
                total += data[record][status].length;
            }
            return total;
        }
        return 0
    };
    getHighlight = (record:string) => {
        let {data} = this.state;
    //itedst-973
        if (data[record]) {
            for (let status in data[record]) {
                if( (status === 'PENDING') || status === ('DRAFT') ){
                    return 'red';
                }
            }
        }
        return 'grey';
    };

    getStatusCounts = (status:string) => {
        let {data,currentRecordTab} = this.state;
        if (currentRecordTab && data && data[currentRecordTab]) {
            if (data[currentRecordTab][status]) {
                return data[currentRecordTab][status].length
            }
        }
        return 0;
    };

    closeWarningModal = () => {
        this.props.updateCurrentChangeset({
            number:0,
            name:'',
            comments:'',
            status:'',
            changeSetAuthorName: ''
        });
        this.setState({showWarnings:false})
    };

    updateRecordTypes = (recordTypes: string[]) => {
        this.setState({recordTypes: recordTypes});
    };

    updateCurrentRecordTab = (currentRecordTab: string) => {
        this.setState({currentRecordTab: currentRecordTab});
    };

    public render() {
        const {recordTypes,statusTypes} = this.state;

        const panes2:any = [];
        recordTypes.forEach((r:any) => {
            panes2.push({
                menuItem: (
                    <Menu.Item key={`${r}`} >{r}
                        <Label attached={"top right"} circular={true} color={this.getHighlight(r)} size={"tiny"}>{this.getCounts(r)}</Label>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane loading={this.state.loading} attached={true}>{this.getChangesetTable()}</Tab.Pane>
            })
        });

        const panes:any = [];
        statusTypes.forEach((t:any) => {
            panes.push(
                { menuItem: (
                        <Menu.Item key={`${t}`} >{t}
                            <Label attached={"top right"} circular={true} size={"tiny"}>{this.getStatusCounts(t)}</Label>
                        </Menu.Item>
                    ),
                    render: () => <Tab onTabChange={this.tabRecordTypeChange} menuPosition={"right"} menu={{vertical:true, attached:true}} panes={panes2}/>});
        });

        if (this.state.redirect !== '') {
            return <Redirect to={this.state.redirect} push={true}/>
        }

        return (
            <div>
                <Modal open={this.state.showWarnings} closeOnDimmerClick={true} onClose={this.closeWarningModal}>
                    <Modal.Header>Warnings</Modal.Header>
                    <Modal.Content>
                        <ValidationMessages/>
                    </Modal.Content>
                </Modal>
                <Grid>
                    <Grid.Row columns={'equal'}>
                        <Grid.Column>
                            <Header>Changeset Details </Header>
                        </Grid.Column>
                        <Grid.Column floated={'right'}>
                            <CategoryDropDown
                                recordTypes={this.state.recordTypes}
                                updateRecordTypes={this.updateRecordTypes}
                                currentRecordTab={this.state.currentRecordTab}
                                updateCurrentRecordTab={this.updateCurrentRecordTab}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row/>
                </Grid>
                <Tab panes={panes} onTabChange={this.tabStatusChange}/>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        roles: state.authReducer.roles
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentChangeset: (currentChangeset: Changeset) => dispatch (
            {type: actionTypes.UPDATE_CURRENT_CHANGESET, payload: currentChangeset}),
        updateCurrentCategory: (category: string) => dispatch(
            {type: actionTypes.UPDATE_EDST_CATEGORY, payload: category}),
        updateCurrentTab: (currentTab: string) => dispatch (
            {type: actionTypes.UPDATE_CURRENT_TAB, payload: currentTab}),
        updateActiveMenuItem: (activeMenuItem: string) => dispatch(
            {type: actionTypes.UPDATE_ACTIVE_MENU_ITEM, payload: activeMenuItem}),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ChangesetDetails);