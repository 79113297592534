import * as React from 'react';
import {
    Button,
    Checkbox,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Header,
    Icon,
    Label,
    Modal,
    ModalProps
} from "semantic-ui-react";
import axios from "axios";
import {CommentData} from "./Comment";
import {toast} from "react-toastify";
import {connect} from "react-redux";

interface PostingReplyModalProps{
    postingId: number,
    handlePostReply(newComment: CommentData): void
}

type OwnProps = PostingReplyModalProps & ModalProps;

interface PropsFromState {
    sppStaff: boolean,
    userEntities: any[]
}

type AllProps = OwnProps & PropsFromState;

interface PostingReplyModalState {
    open: boolean,
    entities: Array<object>,
    selectedEntity: string,
    commentText: string,
    privateCheck: boolean,
    admin: boolean,
    privacyHeading: string,
    privacyContent: string,
}

const publicHeading: string = 'Public';
const publicContent: string = 'Everyone can view this comment';

const privateHeading: string = 'Private';
const privateContent: string = 'Only your entity and SPP can view this comment';

class PostingReplyModal extends React.Component<AllProps, PostingReplyModalState> {
    constructor(props: AllProps, state: PostingReplyModalState) {
        super(props, state);
        this.state = {
            open: false,
            entities: [],
            selectedEntity: '',
            commentText: '',
            privateCheck: true,
            admin: false,
            privacyHeading: privateHeading,
            privacyContent: privateContent,
        };
   }

   componentDidMount() {
        this.getUserEntity();
   }

    handleFieldChange = (e:any, value:any) => {
        this.setState({ [e.target.name.toString()] : value.value } as any);
    };

    handleSelectChange = (e: any, data: any) => {
        this.setState({selectedEntity: data.value});
    };

    togglePrivacy = () => {
        const {privateCheck} = this.state;
        if (privateCheck) { // private was previously checked: will be public now
            this.setState((prevState) => {
                return {
                    privateCheck: !prevState.privateCheck,
                    privacyHeading: publicHeading,
                    privacyContent: publicContent
                }
            })
        } else { // private was not previously checked: will be private now
            this.setState((prevState) => {
                return {
                    privateCheck: !prevState.privateCheck,
                    privacyHeading: privateHeading,
                    privacyContent: privateContent
                }
            })
        }
    };

    toggleModal = () => {
        const {admin, open, entities, selectedEntity} = this.state;
        if (open) { // toggle from open to closed
            // reset modal inputs when closing
            if (admin) {
                this.setState({
                    open: false,
                    privateCheck: false,
                    privacyHeading: publicHeading,
                    privacyContent: publicContent,
                });
            } else {
                this.setState({
                   open: false,
                   privateCheck: true,
                   privacyHeading: privateHeading,
                   privacyContent: privateContent,
                });
            }
            if (selectedEntity !== 'SPP(ISO)' && entities.length > 1) {
                this.setState({selectedEntity: ''});
            }
        } else { // toggle from closed to open
            this.setState({open: true});
        }
    };

    getUserEntity = () => {
        let dropdownItems: any[] = this.props.userEntities.map((item: any) => ({
            key: item.respEntTypeNm,
            text: item.respEntTypeNm,
            value: item.respEntTypeNm
        }));

        if (this.props.sppStaff) {
            this.setState({
                selectedEntity: 'SPP(ISO)',
                admin: true,
                privateCheck: false,
                privacyHeading: publicHeading,
                privacyContent: publicContent,
            });
        } else if (dropdownItems.length === 1) {
            this.setState({selectedEntity: this.props.userEntities[0].respEntTypeNm});
        } else {
            this.setState({entities: [...dropdownItems]});
        }
    };

    showPrivacyCheck = () => {
        return (
        !this.state.admin ?
            <PrivacyCheckbox
                privacy={this.state.privateCheck}
                togglePrivacy={this.togglePrivacy}
            />
            : null
        )
    };

    handleReply = () => {
        if(this.state.privateCheck && this.state.entities.length > 0 && this.state.selectedEntity === ''){
            toast.warn('You must select an entity');
            return;
        }
        const data = {
            'postingId': this.props.postingId.toString(),
            'parentId': null,
            'respEntNameType': this.state.selectedEntity,
            'entityLock': this.state.privateCheck ? this.state.selectedEntity : null,
            'comment': this.state.commentText,
            'isPrivate': this.state.privateCheck.toString(),
        };
        axios.post('/api/auth/comments/comment',data,{
            withCredentials: true,
            responseType: 'json'
        }).then((resp) => {
            this.props.handlePostReply(resp.data);
        }).catch((resp) => {
            console.log('PostingReplyModal - handleReply failed:',resp.data);
        });
        this.toggleModal();
    };

    render() {
        return (
            <>
                <Button floated={'left'} size={'mini'} color={'black'} onClick={this.toggleModal}>
                    <Icon name='comment'/>
                    Reply
                </Button>
                <Modal open={this.state.open} closeOnDimmerClick={true} closeIcon={true} onClose={this.toggleModal}>
                    <Modal.Header>Reply</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <PrivacyHeading privacyHeader={this.state.privacyHeading} privacyContent={this.state.privacyContent}/>
                            {this.showPrivacyCheck()}
                            <UserEntity
                                entities={this.state.entities}
                                selectedEntity={this.state.selectedEntity}
                                handleEntitySelection={this.handleSelectChange}
                            />
                            <Form.Input name={'commentText'} label={'Comment'} labelPosition={'left'} onChange={this.handleFieldChange} />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleReply} content={'Submit'}/>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        sppStaff: state.authReducer.userRolesEntities.sppStaff,
        userEntities: state.authReducer.userEntities
    }
};

export default connect<PropsFromState, {}, OwnProps>(mapStateToProps, {})(PostingReplyModal);


interface PrivacyHeadingProps {
    privacyHeader: string,
    privacyContent: string,
}

const PrivacyHeading: React.FC<PrivacyHeadingProps> = (props: PrivacyHeadingProps) => {
    return (
        <Header as={'h2'}>
            <Header.Content>
                {props.privacyHeader}
                <Header.Subheader>{props.privacyContent}</Header.Subheader>
            </Header.Content>
        </Header>
    )
};

interface PrivacyCheckboxProps {
    privacy: boolean,
    togglePrivacy: () => void
}

const PrivacyCheckbox: React.FC<PrivacyCheckboxProps> = (props: PrivacyCheckboxProps) => (
        <Checkbox label={'Private'} checked={props.privacy} onChange={props.togglePrivacy}/>
);

interface UserEntityProps {
    entities: DropdownItemProps[],
    selectedEntity: string,
    handleEntitySelection: (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void
}

const UserEntity: React.FC<UserEntityProps> = (props: UserEntityProps) => {
    if (props.entities.length === 0) {
        return (
            <Label>{props.selectedEntity}</Label>
        )
    } else {
        return (
            <Dropdown placeholder="Select Entity"
                      selection={true}
                      clearable={true}
                      options={props.entities}
                      onChange={props.handleEntitySelection}/>
        )
    }
};

