import * as React from "react";
import {Redirect} from "react-router";
import {compose} from "redux";
import {connect} from "react-redux";
import {Principal} from "../../auth";
import {toast} from "react-toastify";
import GeneralService from "../../services/general-services";

interface AuthorizationProps {
    roles: Principal[]
}

const find = require('lodash/find');
const generalService = new GeneralService();
const withAuthorization = <P extends AuthorizationProps>(WrappedComponent:React.ComponentType<P>, rolesRequired: Principal[] | undefined) => {
    return (props: any) => {

        let {path} = props.match;

        if (!generalService.loggedIn(true)) {
            toast.dismiss();
            return (
                <Redirect to={'/login'}/>
            )
        }

        if (rolesRequired !== undefined && rolesRequired !== null) {
            let roles = rolesRequired.map((role:Principal) => {
                return find(props.roles,role);

            });
            if (roles[0] || rolesRequired[0].tabNm === 'any') {
                return (
                    <WrappedComponent {...props}/>
                )    
            } else {
                toast.info(`You do not have permission to view ${path.split('/')[1]}`);
                return (
                    <Redirect to={'/accessDenied'}/>
                )
            }
            
        } else {
            let rolesForPath = props.roles.filter((value:Principal) => {
                return value.tabNm === path.split('/')[1]
            });

            if (rolesForPath.length > 0) {
                return <WrappedComponent {...props}/>
            } else {
                toast.info(`You do not have permission to view ${path.split('/')[1]}`);
                return <Redirect to={'/accessDenied'}/>
            }
        }
    }
};

const mapStateToProps = (state: any) => {
    return {
        roles: state.authReducer.roles,
        ...state.authReducer
    }
};

export default compose(connect(mapStateToProps,null),withAuthorization);


