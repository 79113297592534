import * as React from 'react';
import {Button, Container, Form, Grid, Header, InputOnChangeData} from "semantic-ui-react";
import {Redirect} from "react-router";
import AdminService from "../../services/admin-services";
import {Principal} from "../../auth";
import * as actionTypes from "../../store/actions/actionTypes";
import {connect} from "react-redux";
import history from "../../history";
import {toast} from "react-toastify";

interface VerifyCodeProps {
    toggleLogin: (loggedIn: boolean) => void;
    location: any;
    user: string;
    updateUserEntities: (entities: string[]) => void,
    updateUserPrincipal: (roles: Principal[]) => void,
    userRolesEntities: (userRolesAndEntities: any) => void
}

interface VerifyCodeState {
    token: string
    redirectHome:boolean
    error:string
    verificationCode:string
    submittingCode:boolean
    authenticated:boolean
    display: boolean
}

const adminService = new AdminService();
class VerifyCode extends React.Component<VerifyCodeProps,VerifyCodeState> {
    constructor(props:VerifyCodeProps,state:VerifyCodeState){
        super(props,state);
        this.state = {
            token: '',
            redirectHome:false,
            error:'',
            verificationCode:'',
            submittingCode:false,
            authenticated:true,
            display: false
        }
    }

    componentDidMount() {
        adminService.isAuthenticated(localStorage.getItem('userName') || '', sessionStorage.getItem('uuid') || '')
            .then((resp:any) => {
                if(!resp) {
                    this.setState({authenticated: false});
                }
                else {
                    this.setState({authenticated: true, display: true});
                }
            });
    };

    submitCode = () => {
        let adminClient = new AdminService();
        adminClient.verifyCode(this.state.verificationCode)
            .then((data:any) => {
                if (data.message === 'Success') {
                    sessionStorage.removeItem('uuid');
                    localStorage.setItem('edst_token',data.token);
                    this.props.updateUserPrincipal(data.userAuthResponse.roles);
                    this.props.updateUserEntities(data.userAuthResponse.respEntities);
                    this.props.userRolesEntities(data.userAuthResponse.userRolesAndEntitiesDTO);
                    history.push({pathname: '/', state: {from: this.props.location.pathname}});
                }else if (data.message === 'Locked out') {
                    // lockout can only occur here by entering the wrong verify code 3 times
                    toast.error('Account has been locked. Please try again later.');
                    history.push({pathname: '/login', state: {from: this.props.location.pathname}});
                } else if (data.message === 'Verification code has expired') {
                    toast.error('Verification code has expired. Please log in again.');
                    history.push({pathname: '/login', state: {from: this.props.location.pathname}});
                } else { // (data.message === 'Invalid verification code')
                    this.setState({error:data.message, submittingCode: false});
                }
            }).catch(() => {
                this.setState({submittingCode:false});
            });
        this.setState({submittingCode:true})
    };

    codeChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.setState({verificationCode:data.value})
    };
    
    getLocation = () => {
        let location: any = {
            pathname: '/',
            search: '',
            state: {from: this.props.location}
        };
        
        if (this.props.location.state && this.props.location.state.from) {
            if (this.props.location.state.from.pathname !== 'login') {
                location.pathname = this.props.location.state.from.pathname;
            }
            if (this.props.location.state.from.search) {
                location.search = this.props.location.state.from.search;
            }
        }
        return location;
    };

    render(): React.ReactNode {
        if(!this.state.authenticated) {
            return (
                <Redirect to={'/login'} />
            )
        }

        return (
            <Container fluid={true}>
                <div className={'loginDiv'}>
                    <div className={'loginForm'}>
                        {this.state.display &&
                            <Grid centered={true} className={'loginInput'}>
                                <Grid.Row>
                                    <Header content={'Verify'} size={'large'}/>
                                </Grid.Row>
                                <Grid.Row>
                                    <Form onSubmit={this.submitCode}>
                                        <Form.Input autoFocus={true} type={'text'} placeholder={'Enter Verification Code'}
                                                    value={this.state.verificationCode} onChange={this.codeChange}/>
                                        <Button type={'submit'} color={'red'} content={'Submit'}
                                                loading={this.state.submittingCode}/>
                                    </Form>
                                </Grid.Row>
                                <Grid.Row>
                                    <p color={'red'}>{this.state.error}</p>
                                </Grid.Row>
                            </Grid>
                        }
                    </div>
                </div>
            </Container>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.authReducer.currentUser,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateUserPrincipal: (roles: Principal[]) => dispatch(
            {type: actionTypes.UPDATE_USER_PRINCIPAL, payload: roles}),
        updateUserEntities: (userEntities: string[]) => dispatch(
            {type: actionTypes.UPDATE_USER_ENTITIES, payload: userEntities}),
        userRolesEntities: (userRolesAndEntities: string[]) => dispatch(
            {type: actionTypes.UPDATE_USER_ROLES_ENTITIES, payload: userRolesAndEntities})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);