import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';
import React from 'react';

// Disabled prop should be true if grid is in edit or verify mode.
interface ExportPanelProps {
    disabled: boolean,
    exportData: () => void
}

const ExportPanel: React.FC<ExportPanelProps> = ({disabled, exportData}) => {
    return (
        <>
            <AppBarSection>
                <Button onClick={exportData} disabled={disabled}>Export</Button>
            </AppBarSection>
            <AppBarSpacer style={{width: 4}}/>
        </>
    );
};

export default ExportPanel;