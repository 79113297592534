import {Grid, GridCellProps, GridColumn} from '@progress/kendo-react-grid';
import React from 'react';
import {UiValidations} from '../../../interfaces/grid-interfaces';

interface ValidationPreSaveGridProps {
    data: Array<UiValidations>
}

const EmptyValueCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;

    const value = field && (dataItem[field] || dataItem[field] === 0) ? dataItem[field] : 'no value';

    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {value}
        </td>
    );
};

const ValidationPreSaveGrid: React.FC<ValidationPreSaveGridProps> = ({data}) => {
    return (
        <Grid
            data={data}
            pageable={true}
            sortable={true}
            filterable={true}
            resizable={true}
        >
            <GridColumn field='message' title='Validation Error' editable={false} width='500px'/>
            {(() => {
                if (window.location.href.indexOf("DemandAndEnergy") < 1 && window.location.href.indexOf("CapacityAdjustments") < 1){
                    return (
                        <GridColumn field='id' title='Record Key' editable={false} width='100px'/>
                    )
                }
                else return null;
            })()}
            <GridColumn field='heading' title='Column Name' editable={false} width='200px'/>
            <GridColumn field='value' title='Column Value' editable={false} width='300px' cell={EmptyValueCell}/>
        </Grid>
    );
};

export default ValidationPreSaveGrid;