import * as React from 'react';
import {Container} from "semantic-ui-react";
import ReactTable from 'react-table';
import axios from 'axios';
import '../../CSS/react-table-custom.css';
import GridUtil from "../common/GridUtil";
import {Loading} from "../common/Loading";
import {toast} from "react-toastify";

interface NotificationsState {
    data: any;
    columns: any;
    edit: boolean;
    updatedData: any;
    currentRow: number;
    redirect: boolean;
    filtered: any;
    openModal: boolean,
    selectedRowColor: string,
    status: number,
    loading: boolean,
    showAddRowsButton: boolean,
    currentAction: string
    cellInfo:any

}

interface NotificationsProps {
    // currentUser: string
    // roles: Principal[]
}

class NotificationsGrid extends React.Component<NotificationsProps, NotificationsState> {
    constructor(props: NotificationsProps, state: NotificationsState) {
        super(props,state);
        this.state = {
            data: [],
            columns: [],
            edit: false,
            updatedData:[],
            currentRow: -1,
            redirect:false,
            filtered: [],
            openModal: false,
            selectedRowColor: '',
            status: 200,
            loading: true,
            showAddRowsButton: false,
            currentAction: '',
            cellInfo:{}
        };
    }
    
    clearFilterOnClick = () => {
        this.setState({filtered:[] });
    };

    componentDidUpdate(prevProps: Readonly<NotificationsProps>, prevState: Readonly<NotificationsState>, snapshot?: any): void {
        let theads = document.querySelectorAll(".notificationsGrid .rt-thead");
        let tbodies = document.querySelectorAll(".notificationsGrid .rt-tbody");
        let tbody = tbodies[0];

        if (tbody.scrollHeight > tbody.clientHeight) {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).classList.add("vertical-scrollbar-present");
            }
        }
        else {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).classList.remove("vertical-scrollbar-present");
            }
        }
    }
    
    metadata = [
        {
            header: 'Notification Read',
            accessor: 'notificationReadInd'
        }, {
            header: 'Notification Id',
            accessor: 'notificationId'
        }, {
            header: 'Changeset Id',
            accessor: 'chgSId'
        }, {
            header: 'Notification Type',
            accessor: 'notificationType'
        }, {
            header: 'Table Name',
            accessor: 'tabNm'
        }, {
            header: 'Responsible Entity',
            accessor: 'respEntityNm'
        }, {
            header: 'Category',
            accessor: 'category'
        }, {
            header: 'Subject',
            accessor: 'subject'
        }, {
            header: 'Message',
            accessor: 'body'
        }, {
            header: 'Notification Date',
            accessor: 'creDt'
        }
    ];

    componentDidMount() {
        let theads = document.querySelectorAll(".notificationsGrid .rt-thead");
        let tbodies = document.querySelectorAll(".notificationsGrid .rt-tbody");
        let tbody = tbodies[0];

        tbody.addEventListener("scroll", () => {
            for (let i = 0; i < theads.length; i++) {
                theads.item(i).scrollLeft = tbody.scrollLeft;
            }
        });

        axios.get('/api/auth/notificationTracking/notificationsByUser')
            .then(resp => {
                const data = resp.data ? resp.data : [];
                const columns = this.metadata.map((c: any) => ({
                    Header: c.header,
                    accessor: c.accessor
                }));
                this.setState({data, columns});
            })
            .catch(resp => {
                toast.error(`Error retrieving notification data - ${resp.message}`);
            })
            .finally(() => {
                this.setState({loading: false});
            });
    }

    pageSizeChanged = (pageSize:number) => {
        localStorage.setItem(`Notifications`,pageSize.toString());
    };

    // Change rows for unread notification to a different background color
    getTRPropsType(state: any, rowInfo: any) {
        // Need this to make sure row info object has been assigned a value
        if (rowInfo && rowInfo.row && rowInfo.row.notificationReadInd === 'No') {
            return {
                style:
                    {background: '#CCE2C7', minWidth: state.rowMinWidth, textAlign: "center"}
            }
        }
        return {style:{minWidth: state.rowMinWidth, textAlign: "center"}}
    }

    public render() {
            const {data} = this.state;
            let pageSize = localStorage.getItem(`Notifications`);

            return (
                <Container fluid={true} style={{paddingLeft:'50px', paddingRight:'50px'}}>
                    <ReactTable
                        data={data}
                        className='-highlight notificationsGrid'
                        columns={this.state.columns}
                        defaultPageSize={pageSize ? +pageSize : 200}
                        pageSizeOptions={[5,10,20,25,50,100,200]}
                        filterable={true}
                        filtered={this.state.filtered}
                        style={{height:'500px', width: '100%'}}
                        onPageSizeChange={this.pageSizeChanged}
                        loadingText={'Loading...'}
                        loading={this.state.loading}
                        defaultFilterMethod={GridUtil.filterMethod}
                        onFilteredChange={filtered => this.setState({filtered})}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: "black",
                                    color: "white",
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={this.getTRPropsType}
                        getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                        getTheadFilterTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                    />
                    <Loading active={this.state.loading}/>
                </Container>
            );
        }

}

export default NotificationsGrid;
