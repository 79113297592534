import * as React from "react";
import {Dropdown, DropdownItemProps, DropdownProps} from "semantic-ui-react";
import DropDownService from "../../services/drop-down-service";


interface  EdstDropDownProps {
    cellInfo: any,
    columnMetaData: any,
    editModeGridIndex: number,
    onClick?: (cellInfo:any) => void
}

interface EdstDropDownState {
    options: DropdownItemProps[]
    optionsLoading: boolean
    currentValue: string
}



const dropDownClient = new DropDownService();
export class EdstDropDown extends React.Component<DropdownProps & EdstDropDownProps,EdstDropDownState> {
    constructor(props: DropdownProps & EdstDropDownProps,state: EdstDropDownState) {
        super(props,state);
        this.state = {
            options: [],
            optionsLoading: false,
            currentValue: ''
        }
    }

    componentDidMount(): void {
        let options:DropdownItemProps[] = [];
        let {value} = this.props;
        if (this.props.value !== '') {
            options.push({key: value, value: value, text: value} as any)
        }
        this.setState({currentValue:this.props.value,options} as any)
    }

    fetchOptions = () => {
        //check that the optional onClick prop has been defined
        if(this.props.onClick){
            this.props.onClick(this.props.cellInfo);
        }
        let options:DropdownItemProps[] = [];
        let {value} = this.props;
        if (this.props.value !== '') {
            options.push({key: value, value: value, text: value} as any)
        }
        dropDownClient.getDropDownValues(this.props.columnMetaData.callbackRoute,this.props.cellInfo.original)
             .then((dropDownValues:string[]) => {
                dropDownValues.forEach(v => {
                    if (v !== this.props.value) {
                        options.push({key: v, value: v, text: v});
                    }
                });
                this.setState({options,optionsLoading:false})
            });
        this.setState({optionsLoading:true})
    };

    render(): React.ReactNode {
        return (
            <Dropdown {...this.props} options={this.state.options}
                      nullonchange={this.props.columnMetaData.nullOnChange}
                      accKey={this.props.cellInfo.index} loading={this.state.optionsLoading}
                      className={'inputClass' + this.props.editModeGridIndex}
                      id={this.props.cellInfo.column.id + "-" + this.props.cellInfo.index} onClick={this.fetchOptions}
            />
        )
    }
}

export default EdstDropDown;